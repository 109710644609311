import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_VISIONS,
  ADD_NEW_VISION,
  GET_VISION_DETAILS,
  UPDATE_VISION,
  DELETE_VISION,
  STATUS_CHANGE_VISION,
  SAVE_ORDERING_VISION,
} from "./actionTypes"

import {
  getVisionsSuccess,
  getVisionsFail,
  addNewVisionSuccess,
  addNewVisionFail,
  getVisionDetailsSuccess,
  getVisionDetailsFail,
  updateVisionSuccess,
  updateVisionFail,
  deleteVisionSuccess,
  deleteVisionFail,
  statusChangeVisionSuccess,
  statusChangeVisionFail,

  saveOrderingVisionSuccess,
  saveOrderingVisionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getVisionList,
  addNewVision,
  getVisionDetails,
  updateVision,
  deleteVision,
  statusChangeVision,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchVisions({ payload: vision }) {
  try {
    const response = yield call(getVisionList, vision)
    yield put(getVisionsSuccess(response))
  } catch (error) {
    yield put(getVisionsFail(error))
  }
}

export function* watchFetchVisions() {
  yield takeEvery(GET_VISIONS, fetchVisions);
}

function* onAddNewVision({ payload: vision }) {
  try {
      const response = yield call(addNewVision, vision);
      if(response.status === 200){
        yield put(addNewVisionSuccess(response));
      } else {
        yield put(addNewVisionFail(response));
      }
  } catch (error) {
      yield put(addNewVisionFail(error));
  }
}

export function* watchAddNewVision() {
  yield takeEvery(ADD_NEW_VISION, onAddNewVision);
}

function* onVisionDetails({ payload: vision }) {
  try {
      const response = yield call(getVisionDetails, vision);
      if(response.status === 200){
        yield put(getVisionDetailsSuccess(response));
      } else {
        yield put(getVisionDetailsFail(response));
      }
  } catch (error) {
      yield put(getVisionDetailsFail(error));
  }
}

export function* watchVisionDetails() {
  yield takeEvery(GET_VISION_DETAILS, onVisionDetails);
}

function* onVisionUpdate({ payload: vision }) {
  try {
      const response = yield call(updateVision, vision);
      if(response.status === 200){
        yield put(updateVisionSuccess(response));
      } else {
        yield put(updateVisionFail(response));
      }
  } catch (error) {
      yield put(updateVisionFail(error));
  }
}

export function* watchUpdateVision() {
  yield takeEvery(UPDATE_VISION, onVisionUpdate);
}


//Delete Data
function* onDeleteVision({ payload: vision }) {
  try {
    const response = yield call(deleteVision, vision);
    yield put(deleteVisionSuccess({ vision, ...response }));
  } catch (error) {
    yield put(deleteVisionFail(error));
  }
}

export function* watchDeleteVision() {
  yield takeEvery(DELETE_VISION, onDeleteVision);
}



//Status Change
function* onStatusChangVision({ payload: vision }) {
  try {
    const response = yield call(statusChangeVision, vision);
    yield put(statusChangeVisionSuccess({ vision, ...response }));
  } catch (error) {
    yield put(statusChangeVisionFail(error));
  }
}

export function* watchStatusChangeVision() {
  yield takeEvery(STATUS_CHANGE_VISION, onStatusChangVision);
}


//Save Ordering
function* onSaveOrderingVision({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingVisionSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingVisionFail(error));
  }
}

export function* watchSaveOrderingVision() {
  yield takeEvery(SAVE_ORDERING_VISION, onSaveOrderingVision);
}



function* visionSaga() {
  yield all(
    [
      fork(watchFetchVisions),
      fork(watchAddNewVision),
      fork(watchVisionDetails),
      fork(watchUpdateVision),
      fork(watchDeleteVision),
      fork(watchStatusChangeVision),
      fork(watchSaveOrderingVision)
    ]
  );

}

export default visionSaga
