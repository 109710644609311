/* VISIONS */
export const GET_VISIONS = "GET_VISIONS";
export const GET_VISIONS_SUCCESS = "GET_VISIONS_SUCCESS";
export const GET_VISIONS_FAIL = "GET_VISIONS_FAIL";

export const ADD_NEW_VISION = "ADD_NEW_VISION";
export const ADD_NEW_VISION_SUCCESS = "ADD_NEW_VISION_SUCCESS";
export const ADD_NEW_VISION_FAIL = "ADD_NEW_VISION_FAIL";

export const UPDATE_VISION = "UPDATE_VISION";
export const UPDATE_VISION_SUCCESS = "UPDATE_VISION_SUCCESS";
export const UPDATE_VISION_FAIL = "UPDATE_VISION_FAIL";

export const GET_VISION_DETAILS = "GET_VISION_DETAILS";
export const GET_VISION_DETAILS_SUCCESS = "GET_VISION_DETAILS_SUCCESS";
export const GET_VISION_DETAILS_FAIL = "GET_VISION_DETAILS_FAIL";

export const RESET_VISION_FLAG = "RESET_VISION_FLAG";

export const DELETE_VISION = "DELETE_VISION";
export const DELETE_VISION_SUCCESS = "DELETE_VISION_SUCCESS";
export const DELETE_VISION_FAIL = "DELETE_VISION_FAIL";

export const STATUS_CHANGE_VISION = "STATUS_CHANGE_VISION";
export const STATUS_CHANGE_VISION_SUCCESS = "STATUS_CHANGE_VISION_SUCCESS";
export const STATUS_CHANGE_VISION_FAIL = "STATUS_CHANGE_VISION_FAIL";


export const SAVE_ORDERING_VISION = "SAVE_ORDERING_VISION";
export const SAVE_ORDERING_VISION_SUCCESS = "SAVE_ORDERING_VISION_SUCCESS";
export const SAVE_ORDERING_VISION_FAIL = "SAVE_ORDERING_VISION_FAIL";
export const SAVE_ORDERING_VISION_RESET_FLAG = "SAVE_ORDERING_VISION_RESET_FLAG";


export const MODIFY_VISION_FLAG = "MODIFY_VISION_FLAG";