import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  MODIFY_CAT_FLAG,
  RESET_ADD_CATEGORY,
  STATUS_CHANGE_CAT_SUCCESS,
  STATUS_CHANGE_CAT_FAIL,

  SAVE_ORDERING_CATEGORY_SUCCESS,
  SAVE_ORDERING_CATEGORY_FAIL,
  SAVE_ORDERING_CATEGORY_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  categories: [],
  category: [],
  totalDataCount: 0,
  message: "",
  status_code: "",
  isWhyusDelete: false,
};

const Categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
      };

    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload.data,
        isSuccess: true,
      };

    case GET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter((getData) => {
          return getData._id !== action.payload.data._id;
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isCatRemove: true,
        isCatRemoveFail: false,
      };

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isCatRemove: false,
        isCatRemoveFail: true,
      };

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        status_code: action.payload.status,
      };

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        message: action.payload.message,
        status_code: action.payload.status,
      };
    case RESET_ADD_CATEGORY:
      return {
        ...state,
        message: "",

        status_code: "",
      };
    case MODIFY_CAT_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    //Status Change
    case STATUS_CHANGE_CAT_SUCCESS:
      return {
        ...state,
        categories: state.categories.map((cat) =>
          cat._id.toString() === action.payload.data._id.toString()
            ? { ...cat, ...action.payload.data }
            : cat
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_CAT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };



    //Save Ordering
    case SAVE_ORDERING_CATEGORY_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_CATEGORY_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    default:
      return state;
  }
};

export default Categories;
