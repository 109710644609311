/* Faq */
export const GET_FAQS = "GET_FAQS";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAIL = "GET_FAQS_FAIL";

export const ADD_NEW_FAQ = "ADD_NEW_FAQ";
export const ADD_NEW_FAQ_SUCCESS = "ADD_NEW_FAQ_SUCCESS";
export const ADD_NEW_FAQ_FAIL = "ADD_NEW_FAQ_FAIL";

export const UPDATE_FAQ = "UPDATE_FAQ";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAIL = "UPDATE_FAQ_FAIL";

export const GET_FAQ_DETAILS = "GET_FAQ_DETAILS";
export const GET_FAQ_DETAILS_SUCCESS = "GET_FAQ_DETAILS_SUCCESS";
export const GET_FAQ_DETAILS_FAIL = "GET_FAQ_DETAILS_FAIL";

export const DELETE_FAQ = "DELETE_FAQ";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL";
export const MODIFY_FAQ_FLAG = "MODIFY_FAQ_FLAG";

export const RESET_FAQ_FLAG = "RESET_FAQ_FLAG";

// STATUS CHANGE
export const STATUS_CHANGE_FAQ = "STATUS_CHANGE_FAQ";
export const STATUS_CHANGE_FAQ_SUCCESS = "STATUS_CHANGE_FAQ_SUCCESS";
export const STATUS_CHANGE_FAQ_FAIL = "STATUS_CHANGE_FAQ_FAIL";


export const SAVE_ORDERING_FAQ = "SAVE_ORDERING_FAQ";
export const SAVE_ORDERING_FAQ_SUCCESS = "SAVE_ORDERING_FAQ_SUCCESS";
export const SAVE_ORDERING_FAQ_FAIL = "SAVE_ORDERING_FAQ_FAIL";
export const SAVE_ORDERING_FAQ_RESET_FLAG = "SAVE_ORDERING_FAQ_RESET_FLAG";

//GET FAQ FEEDBACK
export const GET_FAQ_FEEDBACK = "GET_FAQ_FEEDBACK";
export const GET_FAQ_FEEDBACK_SUCCESS = "GET_FAQ_FEEDBACK_SUCCESS";
export const GET_FAQ_FEEDBACK_FAIL = "GET_FAQ_FEEDBACK_FAIL";