/* ORDERS */
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const ADD_NEW_ORDER = "ADD_NEW_ORDER";
export const ADD_NEW_ORDER_SUCCESS = "ADD_NEW_ORDER_SUCCESS";
export const ADD_NEW_ORDER_FAIL = "ADD_NEW_ORDER_FAIL";

export const ORDER_PAYMENT_STATUS_CHANGE = "ORDER_PAYMENT_STATUS_CHANGE";
export const ORDER_PAYMENT_STATUS_CHANGE_SUCCESS = "ORDER_PAYMENT_STATUS_CHANGE_SUCCESS";
export const ORDER_PAYMENT_STATUS_CHANGE_FAIL = "ORDER_PAYMENT_STATUS_CHANGE_FAIL";

export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL";

export const RESET_ORDER_FLAG = "RESET_ORDER_FLAG";

export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

export const STATUS_CHANGE_ORDER = "STATUS_CHANGE_ORDER";
export const STATUS_CHANGE_ORDER_SUCCESS = "STATUS_CHANGE_ORDER_SUCCESS";
export const STATUS_CHANGE_ORDER_FAIL = "STATUS_CHANGE_ORDER_FAIL";

export const MODIFY_ORDER_FLAG = "MODIFY_ORDER_FLAG";