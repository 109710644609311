import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updateSeo,
  resetSeoFlag,
  getSeoDetails,
  getPages
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import Select from "react-select";

const SeoEdit = () => {
  document.title = "Edit SEO | Dabbawala";
  const { id } = useParams();
  const { type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [pagetype, setPagetype] = useState([]);
  const [description, setData] = useState();

  const { isSeoUpdate, isSeoUpdateFail, error, success, details,pages } =
    useSelector((state) => ({
      isSeoUpdate: state.Seos.isSeoUpdate,
      isSeoUpdateFail: state.Seos.isSeoUpdateFail,
      error: state.Seos.error,
      success: state.Seos.success,
      details: state.Seos.details,
      pages: state.Pages.pages,
    }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: details && details.title ? details.title : "",
      // meta_title: details && details.meta_title ? details.meta_title : "",
      description: details && details.description ? details.description : "",
      keywords: details && details.keywords ? details.keywords : "",
      author: details && details.author ? details.author : "",
      type: details && details.type ? details.type : "",
      type_id: details && details.type_id ? details.type_id : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title Name"),
      // meta_title: Yup.string().required("Please Enter Meta Title Name"),
      keywords: Yup.string().required("Please Enter Keyword Name"),
      author: Yup.string().required("Please Enter Author Name"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.title,
        // meta_title: values.meta_title,
        keywords: values.keywords,
        author: values.author,
        description: description,
        type: type,
        type_id: id,
      };
      dispatch(updateSeo(data));
    },
  });

  useEffect(() => {
    if (isSeoUpdate || isSeoUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetSeoFlag());
      }, 3000);
    }
  }, [dispatch, isSeoUpdate, isSeoUpdateFail]);

  useEffect(() => {
    dispatch(getSeoDetails({ typeId: id ,type:type,}));
  }, [dispatch, id,type]);

  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);

  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
    console.log(pages);
  }, [pages]);


  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit SEO" pageTitle="Seos" pageUrl="/seos" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit SEO" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                      <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title name"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      {/* <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                             Meta Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Meta title"
                              name="meta_title"
                              value={validation.values.meta_title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.meta_title &&
                                validation.errors.meta_title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.meta_title &&
                            validation.errors.meta_title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.meta_title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Keyword *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Keyword name"
                              name="keywords"
                              value={validation.values.keywords || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.keywords &&
                                validation.errors.keywords
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.keywords &&
                            validation.errors.keywords ? (
                              <FormFeedback type="invalid">
                                {validation.errors.keywords}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        {/* <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="product-title-input">
                              Type *
                            </Label>
                            <Select
                              type="text"
                              name="type"
                              placeholder="Enter Seo Type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "type",
                                    value: selectedOption,
                                  },
                                };
                            
                                validation.handleChange(event);
                              }}
                              options={pagetype}
                              onBlur={validation.handleBlur}
                              value={validation.values.type || ""}
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="product-title-input">
                              Type ID *
                            </Label>
                            <Select
                              type="text"
                              name="type_id"
                              placeholder="Enter Type ID"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "type_id",
                                    value: selectedOption,
                                  },
                                };
                              
                                validation.handleChange(event);
                              }}
                              options={pagetype}
                              onBlur={validation.handleBlur}
                              value={validation.values.type_id || ""}
                              invalid={
                                validation.touched.type_id &&
                                validation.errors.type_id
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type_id &&
                            validation.errors.type_id ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type_id}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}


                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Author *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Author name"
                              name="author"
                              value={validation.values.author || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.author &&
                                validation.errors.author
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.author &&
                            validation.errors.author ? (
                              <FormFeedback type="invalid">
                                {validation.errors.author}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/pages" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isSeoUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isSeoUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default SeoEdit;
