import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Calender Redux States
import {
 
  ADD_NEW_JOB_TYPE,
  GET_JOB_TYPE_DETAILS,
  UPDATE_JOB_TYPE,
  GET_JOB_TYPE_LIST,
  DELETE_JOB_TYPE,
  STATUS_CHANGE_JOBTYPE

} from "./actionTypes";

import {

  addNewJobTypeSuccess,
  addNewJobTypeFail,
  getJobTypeDetailsSuccess,
  getJobTypeDetailsFail,
  updateJobTypeSuccess,
  updateJobTypeFail,
  getJobTypeListSuccess,
  getJobTypeListFail,
  deleteJobTypeSuccess,
  deleteJobTypeFail,
  statusChangeJobTypeSuccess,
  statusChangeJobTypeFail

} from "./actions";

//Include Both Helper File with needed methods
import {
  // getJobList,
  addNewJobType,
  getJobTypeDetails,
  updateJobType,
  getJobTypeList,
  delJobType,
  statusChangeJobType
} from "../../helpers/fakebackend_helper";

function* onAddNewJobType({ payload: job }) {
  try {
      const response = yield call(addNewJobType, job);
      if(response.status === 200){
        yield put(addNewJobTypeSuccess(response));
      } else {
        yield put(addNewJobTypeFail(response));
      }
  } catch (error) {
      yield put(addNewJobTypeFail(error));
  }
}

export function* watchAddNewJobType() {
  yield takeEvery(ADD_NEW_JOB_TYPE, onAddNewJobType);
}


// Job Type List
function* onJobTypeList({ payload: job }) {
  try {
    const response = yield call(getJobTypeList,job);
    if (response.status === 200) {
      yield put(getJobTypeListSuccess(response));
    } else {
      yield put(getJobTypeListFail(response));
    }
  } catch (error) {
    yield put(getJobTypeListFail(error));
  }
}

export function* watchJobTypeList() {
  yield takeEvery(GET_JOB_TYPE_LIST, onJobTypeList);
}

function* onJobTypeDetails({ payload: job }) {
  try {
      const response = yield call(getJobTypeDetails, job);
      if(response.status === 200){
        yield put(getJobTypeDetailsSuccess(response));
      } else {
        yield put(getJobTypeDetailsFail(response));
      }
  } catch (error) {
      yield put(getJobTypeDetailsFail(error));
  }
}


export function* watchJobTypeDetails() {
  yield takeEvery(GET_JOB_TYPE_DETAILS, onJobTypeDetails);
}

//Status Change
function* onStatusChangeJobType({ payload: jobtype }) {
  try {
    const response = yield call(statusChangeJobType, jobtype);
    yield put(statusChangeJobTypeSuccess({ jobtype, ...response }));
  } catch (error) {
    yield put(statusChangeJobTypeFail(error));
  }
}

export function* watchStatusChangeJobType() {
  yield takeEvery(STATUS_CHANGE_JOBTYPE, onStatusChangeJobType);
}


function* onJobTypeUpdate({ payload: job }) {
  try {
      const response = yield call(updateJobType, job);
      if(response.status === 200){
        yield put(updateJobTypeSuccess(response));
      } else {
        yield put(updateJobTypeFail(response));
      }
  } catch (error) {
      yield put(updateJobTypeFail(error));
  }
}

export function* watchUpdateJobType() {
  yield takeEvery(UPDATE_JOB_TYPE, onJobTypeUpdate);
}
function* delJobTypes({payload : data}) {
  try {
    const response = yield call(delJobType,data)
    yield put(deleteJobTypeSuccess(response))
  } catch (error) {
    yield put(deleteJobTypeFail(error))
  }
}
export function* watchDeleteJobType() {
  yield takeEvery(DELETE_JOB_TYPE, delJobTypes);
}
function* jobTypeSaga() {
  yield all(
    [
      
      fork(watchAddNewJobType),
      fork(watchUpdateJobType),
      fork(watchJobTypeList),
      fork(watchJobTypeDetails),
      fork(watchDeleteJobType),
      fork(watchStatusChangeJobType)
     
    ]
  );
}

export default jobTypeSaga;
