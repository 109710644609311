import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_MISSIONS,
  ADD_NEW_MISSION,
  GET_MISSION_DETAILS,
  UPDATE_MISSION,
  DELETE_MISSION,
  STATUS_CHANGE_MISSION,
  SAVE_ORDERING_MISSION,
} from "./actionTypes"

import {
  getMissionsSuccess,
  getMissionsFail,
  addNewMissionSuccess,
  addNewMissionFail,
  getMissionDetailsSuccess,
  getMissionDetailsFail,
  updateMissionSuccess,
  updateMissionFail,
  deleteMissionSuccess,
  deleteMissionFail,
  statusChangeMissionSuccess,
  statusChangeMissionFail,

  saveOrderingMissionSuccess,
  saveOrderingMissionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMissionList,
  addNewMission,
  getMissionDetails,
  updateMission,
  deleteMission,
  statusChangeMission,

  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchMissions({ payload: mission }) {
  try {
    const response = yield call(getMissionList, mission)
    yield put(getMissionsSuccess(response))
  } catch (error) {
    yield put(getMissionsFail(error))
  }
}

export function* watchFetchMissions() {
  yield takeEvery(GET_MISSIONS, fetchMissions);
}

function* onAddNewMission({ payload: mission }) {
  try {
      const response = yield call(addNewMission, mission);
      if(response.status === 200){
        yield put(addNewMissionSuccess(response));
      } else {
        yield put(addNewMissionFail(response));
      }
  } catch (error) {
      yield put(addNewMissionFail(error));
  }
}

export function* watchAddNewMission() {
  yield takeEvery(ADD_NEW_MISSION, onAddNewMission);
}

function* onMissionDetails({ payload: mission }) {
  try {
      const response = yield call(getMissionDetails, mission);
      if(response.status === 200){
        yield put(getMissionDetailsSuccess(response));
      } else {
        yield put(getMissionDetailsFail(response));
      }
  } catch (error) {
      yield put(getMissionDetailsFail(error));
  }
}

export function* watchMissionDetails() {
  yield takeEvery(GET_MISSION_DETAILS, onMissionDetails);
}

function* onMissionUpdate({ payload: mission }) {
  try {
      const response = yield call(updateMission, mission);
      if(response.status === 200){
        yield put(updateMissionSuccess(response));
      } else {
        yield put(updateMissionFail(response));
      }
  } catch (error) {
      yield put(updateMissionFail(error));
  }
}

export function* watchUpdateMission() {
  yield takeEvery(UPDATE_MISSION, onMissionUpdate);
}


//Delete Data
function* onDeleteMission({ payload: mission }) {
  try {
    const response = yield call(deleteMission, mission);
    yield put(deleteMissionSuccess({ mission, ...response }));
  } catch (error) {
    yield put(deleteMissionFail(error));
  }
}

export function* watchDeleteMission() {
  yield takeEvery(DELETE_MISSION, onDeleteMission);
}



//Status Change
function* onStatusChangMission({ payload: mission }) {
  try {
    const response = yield call(statusChangeMission, mission);
    yield put(statusChangeMissionSuccess({ mission, ...response }));
  } catch (error) {
    yield put(statusChangeMissionFail(error));
  }
}

export function* watchStatusChangeMission() {
  yield takeEvery(STATUS_CHANGE_MISSION, onStatusChangMission);
}


//Save Ordering
function* onSaveOrderingMission({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingMissionSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingMissionFail(error));
  }
}

export function* watchSaveOrderingMission() {
  yield takeEvery(SAVE_ORDERING_MISSION, onSaveOrderingMission);
}




function* missionSaga() {
  yield all(
    [
      fork(watchFetchMissions),
      fork(watchAddNewMission),
      fork(watchMissionDetails),
      fork(watchUpdateMission),
      fork(watchDeleteMission),
      fork(watchStatusChangeMission),
      fork(watchSaveOrderingMission),
    ]
  );

}

export default missionSaga
