import {
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAIL,
  ADD_NEW_TEAM_SUCCESS,
  ADD_NEW_TEAM_FAIL,
  GET_TEAM_DETAILS_SUCCESS,
  GET_TEAM_DETAILS_FAIL,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  RESET_TEAM_FLAG,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  STATUS_CHANGE_TEAM_SUCCESS,
  STATUS_CHANGE_TEAM_FAIL,
  MODIFY_TEAM_FLAG, 

  SAVE_ORDERING_TEAM_SUCCESS,
  SAVE_ORDERING_TEAM_FAIL,
  SAVE_ORDERING_TEAM_RESET_FLAG,

} from "./actionTypes";

const INIT_STATE = {
  teams: []
};

const Teams = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_TEAMS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_TEAM_SUCCESS:
      return {
        ...state,
        teams: [...state.teams, action.payload.data],
        success: action.payload.message,
        isTeamAdd: true,
        isTeamAddFail: false,
      };

    case ADD_NEW_TEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isTeamAdd: false,
        isTeamAddFail: true,
      };

    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        teams: state.teams.map(team =>
          team._id.toString() === action.payload.data._id.toString()
            ? { ...team, ...action.payload.data }
            : team
        ),
        success: action.payload.message,
        isTeamUpdate: true,
        isTeamUpdateFail: false
      };

    case UPDATE_TEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isTeamUpdate: false,
        isTeamUpdateFail: true,
      };

    case GET_TEAM_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_TEAM_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_TEAM_SUCCESS:
      return { 
        ...state,
        teams: state.teams.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_TEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_TEAM_SUCCESS:
      return {
        ...state,
        teams: state.teams.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_TEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    //Save Ordering
    case SAVE_ORDERING_TEAM_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_TEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_TEAM_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    case MODIFY_TEAM_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case MODIFY_TEAM_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_TEAM_FLAG:
      return {
        ...state,
        isTeamAdd: false,
        isTeamAddFail: false,
        isSuccess: false,
        isTeamDetails: false,
        isTeamUpdate: false,
        isTeamUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Teams;
