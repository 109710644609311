
export const ADD_NEW_JOB_LOCATION_TYPE = "ADD_NEW_JOB_LOCATION_TYPE";
export const ADD_NEW_JOB_LOCATION_TYPE_SUCCESS = "ADD_NEW_JOB_LOCATION_TYPE_SUCCESS";
export const ADD_NEW_JOB_LOCATION_TYPE_FAIL = "ADD_NEW_JOB_LOCATION_TYPE_FAIL";

export const UPDATE_JOB_LOCATION_TYPE = "UPDATE_JOB_LOCATION_TYPE";
export const UPDATE_JOB_LOCATION_TYPE_SUCCESS = "UPDATE_JOB_LOCATION_TYPE_SUCCESS";
export const UPDATE_JOB_LOCATION_TYPE_FAIL = "UPDATE_JOB_LOCATION_TYPE_FAIL";

export const GET_JOB_LOCATION_DETAILS = "GET_JOB_LOCATION_DETAILS";
export const GET_JOB_LOCATION_DETAILS_SUCCESS = "GET_JOB_LOCATION_DETAILS_SUCCESS";
export const GET_JOB_LOCATION_DETAILS_FAIL = "GET_JOB_LOCATION_DETAILS_FAIL";


// JOB_lOCATION_TYPE LIST
export const GET_JOB_LOCATION_TYPE_LIST = "GET_JOB_LOCATION_TYPE_LIST";
export const GET_JOB_LOCATION_TYPE_LIST_SUCCESS = "GET_JOB_LOCATION_TYPE_LIST_SUCCESS";
export const GET_JOB_LOCATION_TYPE_LIST_FAIL = "GET_JOB_LOCATION_TYPE_LIST_FAIL";
// DELETE JOB LOCATION TYPE LIST
export const DELETE_JOB_LOCATION_TYPE = "DELETE_JOB_LOCATION_TYPE";
export const DELETE_JOB_LOCATION_TYPE_SUCCESS = "DELETE_JOB_LOCATION_TYPE_SUCCESS";
export const DELETE_JOB_LOCATION_TYPE_FAIL = "DELETE_JOB_LOCATION_TYPE_FAIL";
export const MODIFY_JOB_LOCATION_TYPE_FLAG = "MODIFY_JOB_LOCATION_TYPE_FLAG";

export const RESET_JOB_LOCATION_TYPE_FLAG = "RESET_JOB_LOCATION_TYPE_FLAG";

// Status_Change
export const STATUS_CHANGE_JOB_LOCATION = "STATUS_CHANGE_JOB_LOCATION";
export const STATUS_CHANGE_JOB_LOCATION_SUCCESS = "STATUS_CHANGE_JOB_LOCATION_SUCCESS";
export const STATUS_CHANGE_JOB_LOCATION_FAIL = "STATUS_CHANGE_JOB_LOCATION_FAIL";