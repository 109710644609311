import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory, useParams } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
    Card,
    CardBody,
    Col,
    Container,
    Button,
    Spinner,
    Row,
    Input,
    Label,
    FormFeedback,
    Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import {
    updateTakeofTime,
    resetTakeofTimeFlag,
    getTakeofTimeDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";


const TakeoftimeEdit = () => {
    document.title = "Edit Take of Time | Dabbawala";
    const dispatch = useDispatch();
    
    const { id } = useParams();
    const [time, setTime] = useState("");
    const [isCreating, setIsCreating] = useState(false);
 

    const {
        istakeoftimeUpdate,
        istakeoftimeUpdateFail,
        error,
        success,
        details,
    } = useSelector((state) => ({
        istakeoftimeUpdate: state.takeofTime.istakeoftimeUpdate,
        istakeoftimeUpdateFail: state.takeofTime.istakeoftimeUpdateFail,
        error: state.takeofTime.error,
        success: state.takeofTime.success,
        details: state.takeofTime.details,
    }));

    

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            // time: "",
            time: details && details.time ? details.time: "",
            id: details && details._id ? details._id : "",
        },
        validationSchema: Yup.object({
            time: Yup.string().required("Please Enter Time"),
        }),
        onSubmit: (values) => {

            const data = {
                
                id: id,
                time: time
            };
            console.log(data);
            dispatch(updateTakeofTime(data));
        },
    });



    useEffect(() => {
        if (istakeoftimeUpdate || istakeoftimeUpdateFail) {
            setIsCreating(false);
            validation.resetForm();
            setTimeout(() => {
                dispatch(resetTakeofTimeFlag());
            
            }, 3000);
        }
    }, [dispatch, istakeoftimeUpdate, istakeoftimeUpdateFail, validation]);

    //   useEffect(() => {
    //     dispatch(getCategories({ category_type }));
    //   }, [dispatch, category_type]);

    useEffect(() => {
        dispatch(getTakeofTimeDetails({ id: id }));
    }, [dispatch, id]);


    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Edit Take of Time" pageTitle="Take of Time" pageUrl="/takeoftime" />
                    <Row>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Edit Take of Time" />

                                    <CardBody className="card-body">
                                        <div className="live-preview">
                                            <Row className="gy-4">
                                                <Col xxl={3} md={4}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Time
                                                        </Label>

                                                        <Flatpickr
                                                            type="text"
                                                            name="time"
                                                            className="form-control"
                                                            placeholder="Select Time"
                                                            onChange={(selectedDates, startTimeStr, instance) => {
                                                                let event = {
                                                                    target: {
                                                                        name: "time",
                                                                        value: startTimeStr,

                                                                    },
                                                                };
                                                                setTime(startTimeStr)
                                                                validation.handleChange(event);
                                                            }}
                                                            options={{
                                                                noCalendar: true,
                                                                enableTime: true,
                                                                dateFormat: 'H:i',
                                                                time_24hr: true
                                                            }}
                                                            value={validation.values.time || ""}
                                                        />
                                                        {validation.touched.time &&
                                                            validation.errors.time ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.time}
                                                            </FormFeedback>
                                                        ) : null}

                                                    </div>

                                                </Col>

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/takeoftime" className="btn btn-danger w-sm me-1">
                                        Cancel
                                    </Link>
                                    <Button
                                        color="success"
                                        className="btn-load"
                                        disabled={isCreating}
                                        type="submit"
                                    >
                                        <span className="d-flex align-items-center">
                                            {isCreating && (
                                                <Spinner size="sm" className="flex-shrink-0 me-2">
                                                    {" "}
                                                    Update{" "}
                                                </Spinner>
                                            )}
                                            <span className="flex-grow-1">Update</span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {istakeoftimeUpdate ? (
                        <MsgToast
                            msg={success}
                            color="success"
                            icon="ri-checkbox-circle-line"
                        />
                    ) : null}
                    {istakeoftimeUpdateFail ? (
                        <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
                    ) : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
};

export default TakeoftimeEdit;
