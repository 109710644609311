/* TAKE_OF_TIME */
export const GET_TAKE_OF_TIME = "GET_TAKE_OF_TIME";
export const GET_TAKE_OF_TIME_SUCCESS = "GET_TAKE_OF_TIME_SUCCESS";
export const GET_TAKE_OF_TIME_FAIL = "GET_TAKE_OF_TIME_FAIL";

export const ADD_NEW_TAKE_OF_TIME = "ADD_NEW_TAKE_OF_TIME";
export const ADD_NEW_TAKE_OF_TIME_SUCCESS = "ADD_NEW_TAKE_OF_TIME_SUCCESS";
export const ADD_NEW_TAKE_OF_TIME_FAIL = "ADD_NEW_TAKE_OF_TIME_FAIL";

export const TAKE_OF_TIME_PAYMENT_STATUS_CHANGE = "TAKE_OF_TIME_PAYMENT_STATUS_CHANGE";
export const TAKE_OF_TIME_PAYMENT_STATUS_CHANGE_SUCCESS = "TAKE_OF_TIME_PAYMENT_STATUS_CHANGE_SUCCESS";
export const TAKE_OF_TIME_PAYMENT_STATUS_CHANGE_FAIL = "TAKE_OF_TIME_PAYMENT_STATUS_CHANGE_FAIL";

export const UPDATE_TAKE_OF_TIME = "UPDATE_TAKE_OF_TIME";
export const UPDATE_TAKE_OF_TIME_SUCCESS = "UPDATE_TAKE_OF_TIME_SUCCESS";
export const UPDATE_TAKE_OF_TIME_FAIL = "UPDATE_TAKE_OF_TIME_FAIL";

export const GET_TAKE_OF_TIME_DETAILS = "GET_TAKE_OF_TIME_DETAILS";
export const GET_TAKE_OF_TIME_DETAILS_SUCCESS = "GET_TAKE_OF_TIME_DETAILS_SUCCESS";
export const GET_TAKE_OF_TIME_DETAILS_FAIL = "GET_TAKE_OF_TIME_DETAILS_FAIL";

export const RESET_TAKE_OF_TIME_FLAG = "RESET_TAKE_OF_TIME_FLAG";

export const DELETE_TAKE_OF_TIME = "DELETE_TAKE_OF_TIME";
export const DELETE_TAKE_OF_TIME_SUCCESS = "DELETE_TAKE_OF_TIME_SUCCESS";
export const DELETE_TAKE_OF_TIME_FAIL = "DELETE_TAKE_OF_TIME_FAIL";

export const STATUS_CHANGE_TAKE_OF_TIME = "STATUS_CHANGE_TAKE_OF_TIME";
export const STATUS_CHANGE_TAKE_OF_TIME_SUCCESS = "STATUS_CHANGE_TAKE_OF_TIME_SUCCESS";
export const STATUS_CHANGE_TAKE_OF_TIME_FAIL = "STATUS_CHANGE_TAKE_OF_TIME_FAIL";

export const MODIFY_TAKE_OF_TIME_FLAG = "MODIFY_TAKE_OF_TIME_FLAG";

export const SAVE_ORDERING_TAKE_OF_TIME = "SAVE_ORDERING_TAKE_OF_TIME";
export const SAVE_ORDERING_TAKE_OF_TIME_SUCCESS = "SAVE_ORDERING_TAKE_OF_TIME_SUCCESS";
export const SAVE_ORDERING_TAKE_OF_TIME_FAIL = "SAVE_ORDERING_TAKE_OF_TIME_FAIL";
export const SAVE_ORDERING_TAKE_OF_TIME_RESET_FLAG = "SAVE_ORDERING_TAKE_OF_TIME_RESET_FLAG";