/* TEAMS */
export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAIL = "GET_TEAMS_FAIL";

export const ADD_NEW_TEAM = "ADD_NEW_TEAM";
export const ADD_NEW_TEAM_SUCCESS = "ADD_NEW_TEAM_SUCCESS";
export const ADD_NEW_TEAM_FAIL = "ADD_NEW_TEAM_FAIL";

export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";

export const GET_TEAM_DETAILS = "GET_TEAM_DETAILS";
export const GET_TEAM_DETAILS_SUCCESS = "GET_TEAM_DETAILS_SUCCESS";
export const GET_TEAM_DETAILS_FAIL = "GET_TEAM_DETAILS_FAIL";

export const RESET_TEAM_FLAG = "RESET_TEAM_FLAG";

export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";

export const STATUS_CHANGE_TEAM = "STATUS_CHANGE_TEAM";
export const STATUS_CHANGE_TEAM_SUCCESS = "STATUS_CHANGE_TEAM_SUCCESS";
export const STATUS_CHANGE_TEAM_FAIL = "STATUS_CHANGE_TEAM_FAIL";

export const MODIFY_TEAM_FLAG = "MODIFY_TEAM_FLAG";

export const SAVE_ORDERING_TEAM = "SAVE_ORDERING_TEAM";
export const SAVE_ORDERING_TEAM_SUCCESS = "SAVE_ORDERING_TEAM_SUCCESS";
export const SAVE_ORDERING_TEAM_FAIL = "SAVE_ORDERING_TEAM_FAIL";
export const SAVE_ORDERING_TEAM_RESET_FLAG = "SAVE_ORDERING_TEAM_RESET_FLAG";