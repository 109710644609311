import {
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  STATUS_CHANGE_USER_SUCCESS,
  STATUS_CHANGE_USER_FAIL,
  RESET_USER_FLAG,
  RESET_ADMIN_FLAG,
  MODIFY_USER_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  users: []
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };
      case ADD_NEW_USER_SUCCESS:
        return {
          ...state,
          users: [...state.users, action.payload.data],
          success: action.payload.message,
          isUserAdd: true,
          isUserAddFail: false,
        };
  
      case ADD_NEW_USER_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isUserAdd: false,
          isUserAddFail: true,
        };
        case UPDATE_USER_SUCCESS:
          return {
            ...state,
            users: state.users.map(user =>
              user._id.toString() === action.payload.data._id.toString()
                ? { ...user, ...action.payload.data }
                : user
            ),
            success: action.payload.message,
            isUserUpdate: true,
            isUserUpdateFail: false
          };
    
        case UPDATE_USER_FAIL:
          return {
            ...state,
            error: action.payload.message,
            isUserUpdate: false,
            isUserUpdateFail: true,
          };
    
        case GET_USER_DETAILS_SUCCESS:
          return {
            ...state,
            details: action.payload.data[0] ? action.payload.data[0] : [],
            isSuccess: true,
          };
    
        case GET_USER_DETAILS_FAIL:
          return {
            ...state,
            error: action.payload.message,
          };
    
        
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(getData =>
          {
             return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isUserRemove: true,
        isUserRemoveFail: false,
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isUserRemove: false,
        isUserRemoveFail: true,
      };
    //Status Change
    case STATUS_CHANGE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) =>
        user._id.toString() === action.payload.data._id.toString()
            ? { ...user, ...action.payload.data }
            : user
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };
    case RESET_USER_FLAG:
      return {
        ...state,
        isUserAdd: false,
        isUserAddFail: false,
        isSuccess: false,
        isUserDetails: false,
        isUserUpdate: false,
        isUserUpdateFail: false,
        error: false,
      };
      case RESET_ADMIN_FLAG:
        return {
          ...state,
          isAdminAdd: false,
          isAdminAddFail: false,
          isSuccess: false,
          isAdminDetails: false,
          isAdminUpdate: false,
          isAdminUpdateFail: false,
          error: false,
        };

    case MODIFY_USER_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };


    default:
      return state;
  }
};

export default Users;
