/* FUNFACTS */
export const GET_FUNFACTS = "GET_FUNFACTS";
export const GET_FUNFACTS_SUCCESS = "GET_FUNFACTS_SUCCESS";
export const GET_FUNFACTS_FAIL = "GET_FUNFACTS_FAIL";

export const ADD_NEW_FUNFACT = "ADD_NEW_FUNFACT";
export const ADD_NEW_FUNFACT_SUCCESS = "ADD_NEW_FUNFACT_SUCCESS";
export const ADD_NEW_FUNFACT_FAIL = "ADD_NEW_FUNFACT_FAIL";

export const UPDATE_FUNFACT = "UPDATE_FUNFACT";
export const UPDATE_FUNFACT_SUCCESS = "UPDATE_FUNFACT_SUCCESS";
export const UPDATE_FUNFACT_FAIL = "UPDATE_FUNFACT_FAIL";

export const GET_FUNFACT_DETAILS = "GET_FUNFACT_DETAILS";
export const GET_FUNFACT_DETAILS_SUCCESS = "GET_FUNFACT_DETAILS_SUCCESS";
export const GET_FUNFACT_DETAILS_FAIL = "GET_FUNFACT_DETAILS_FAIL";

export const RESET_FUNFACT_FLAG = "RESET_FUNFACT_FLAG";

export const DELETE_FUNFACT = "DELETE_FUNFACT";
export const DELETE_FUNFACT_SUCCESS = "DELETE_FUNFACT_SUCCESS";
export const DELETE_FUNFACT_FAIL = "DELETE_FUNFACT_FAIL";

export const SAVE_ORDERING_FUNFACT = "SAVE_ORDERING_FUNFACT";
export const SAVE_ORDERING_FUNFACT_SUCCESS = "SAVE_ORDERING_FUNFACT_SUCCESS";
export const SAVE_ORDERING_FUNFACT_FAIL = "SAVE_ORDERING_FUNFACT_FAIL";
export const SAVE_ORDERING_FUNFACT_RESET_FLAG = "SAVE_ORDERING_FUNFACT_RESET_FLAG";

export const STATUS_CHANGE_FUNFACT = "STATUS_CHANGE_FUNFACT";
export const STATUS_CHANGE_FUNFACT_SUCCESS = "STATUS_CHANGE_FUNFACT_SUCCESS";
export const STATUS_CHANGE_FUNFACT_FAIL = "STATUS_CHANGE_FUNFACT_FAIL";

export const MODIFY_FUNFACT_FLAG = "MODIFY_FUNFACT_FLAG";