import {
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAIL,
  ADD_NEW_SERVICE_SUCCESS,
  ADD_NEW_SERVICE_FAIL,
  GET_SERVICE_DETAILS_SUCCESS,
  GET_SERVICE_DETAILS_FAIL,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  RESET_SERVICE_FLAG,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
  SAVE_ORDERING_SERVICE_SUCCESS,
  SAVE_ORDERING_SERVICE_FAIL,
  SAVE_ORDERING_SERVICE_RESET_FLAG,
  STATUS_CHANGE_SERVICE_SUCCESS,
  STATUS_CHANGE_SERVICE_FAIL,
  MODIFY_SERVICE_FLAG, 
} from "./actionTypes";

const INIT_STATE = {
  services: []
};

const Services = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_SERVICE_SUCCESS:
      return {
        ...state,
        services: [...state.services, action.payload.data],
        success: action.payload.message,
        isServiceAdd: true,
        isServiceAddFail: false,
      };

    case ADD_NEW_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isServiceAdd: false,
        isServiceAddFail: true,
      };

    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        services: state.services.map(service =>
          service._id.toString() === action.payload.data._id.toString()
            ? { ...service, ...action.payload.data }
            : service
        ),
        success: action.payload.message,
        isServiceUpdate: true,
        isServiceUpdateFail: false
      };

    case UPDATE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isServiceUpdate: false,
        isServiceUpdateFail: true,
      };

    case GET_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_SERVICE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_SERVICE_SUCCESS:
      return { 
        ...state,
        services: state.services.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    //Save Ordering
    case SAVE_ORDERING_SERVICE_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_SERVICE_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };
  
      
    //Status Change
    case STATUS_CHANGE_SERVICE_SUCCESS:
      return {
        ...state,
        services: state.services.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_SERVICE_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_SERVICE_FLAG:
      return {
        ...state,
        isServiceAdd: false,
        isServiceAddFail: false,
        isSuccess: false,
        isServiceDetails: false,
        isServiceUpdate: false,
        isServiceUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Services;
