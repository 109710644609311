import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { addNewAuthor, resetAuthorFlag } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import "moment-timezone";


const AuthorCreate = () => {
    document.title = "Create Author | Defendit - Admin Panel";
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const history = useHistory();
    const { isAuthorAdd, isAuthorAddFail, error, success} = useSelector(state => ({
        isAuthorAdd: state.Authors.isAuthorAdd,
        isAuthorAddFail: state.Authors.isAuthorAddFail,
        error: state.Authors.error,
        success: state.Authors.success,
    }));
   

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            description: "",  
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Author Name"),
            description: Yup.string().required("Please Enter Description"),
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            const data = {
                name: values.name,
                description: values.description,
            }
            console.log(data)
            dispatch(addNewAuthor(data))
        },
    });

    

    useEffect(() => {
        if (isAuthorAdd || isAuthorAddFail) {
            setIsCreating(false)
            validation.resetForm();
            setTimeout(() => {
                dispatch(resetAuthorFlag())
                history.push("/authors");
            }, 3000);
        }
    }, [dispatch, isAuthorAdd, isAuthorAddFail, validation]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Create Author" pageTitle="Authors" pageUrl="/authors" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Create Author" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">
                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Name of the Author</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Author Name"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                
                                                
                            

                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Author Description*
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            id="placeholderInput"
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Enter description"
                                                            name="description"
                                                            value={validation.values.description || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.description &&
                                                                    validation.errors.description
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.description &&
                                                            validation.errors.description ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.description}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                               

                                               

                                                

                                            </Row>

                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/authors" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                                            <span className="flex-grow-1">
                                                Create
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isAuthorAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isAuthorAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default AuthorCreate;