import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateProductCategory,
  resetProductCategoryFlag,
  getProductCategoryDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";

const CategoryEdit = () => {
  document.title = "Edit Category | CEO";
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const [catImg, setCatImg] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const {
    isProductcategoryUpdate,
    isProductcategoryUpdateFail,
    error,
    success,
    details,
  } = useSelector((state) => ({
    isProductcategoryUpdate: state.Productcategories.isProductcategoryUpdate,
    isProductcategoryUpdateFail:
      state.Productcategories.isProductcategoryUpdateFail,
    error: state.Productcategories.error,
    success: state.Productcategories.success,
    details: state.Productcategories.details,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: details && details?.title ? details.title : "",
      description: details && details?.description ? details.description : "",
      image: details && details?.image ? details.image : "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Title"),
      description: Yup.string().max(200),
    }),
    onSubmit: (values) => {
      console.log(values);
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        image: catImg,
        description: values.description
      };
      dispatch(updateProductCategory(data))
      //console.log(data);
    },
  });
  // console.log(id);
  // console.log(validation.category);
  useEffect(() => {
    if (isProductcategoryUpdate || isProductcategoryUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetProductCategoryFlag());
        history.push("/product-categories");
      }, 3000);
    }
  }, [
    dispatch,
    isProductcategoryUpdate,
    isProductcategoryUpdateFail,
    history,
    validation,
  ]);
  useEffect(() => {
    dispatch(getProductCategoryDetails({ id: id }));
  }, [dispatch, id]);

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setCatImg);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Update Category"
            pageTitle="Categories"
            pageUrl="/product-categories"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Update Category" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        {/* <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Icon *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="icon"
                              name="icon"
                              value={validation.values.icon || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.icon &&
                                validation.errors.icon
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.icon &&
                            validation.errors.icon ? (
                              <FormFeedback type="invalid">
                                {validation.errors.icon}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Category Icon *
                            </Label>
                            <Input
                            
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImgChange}
                            />
                            <img src={validation.values.image || ""} alt="" id="image1" width="100px"></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              rows="3"
                              className="form-control"
                              placeholder="Enter description"
                              name="description"
                              value={validation.values.description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.description &&
                                validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link
                    to="/product-categories"
                    className="btn btn-danger w-sm me-1"
                  >
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isProductcategoryUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isProductcategoryUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default CategoryEdit;
