import {
    GET_TAKE_OF_TIME,
    GET_TAKE_OF_TIME_SUCCESS,
    GET_TAKE_OF_TIME_FAIL,
    ADD_NEW_TAKE_OF_TIME,
    ADD_NEW_TAKE_OF_TIME_SUCCESS,
    ADD_NEW_TAKE_OF_TIME_FAIL,
    GET_TAKE_OF_TIME_DETAILS,
    GET_TAKE_OF_TIME_DETAILS_SUCCESS,
    GET_TAKE_OF_TIME_DETAILS_FAIL,
    UPDATE_TAKE_OF_TIME,
    UPDATE_TAKE_OF_TIME_SUCCESS,
    UPDATE_TAKE_OF_TIME_FAIL,
    DELETE_TAKE_OF_TIME,
    DELETE_TAKE_OF_TIME_SUCCESS,
    DELETE_TAKE_OF_TIME_FAIL,
    MODIFY_TAKE_OF_TIME_FLAG,
    RESET_TAKE_OF_TIME_FLAG,
    STATUS_CHANGE_TAKE_OF_TIME,
    STATUS_CHANGE_TAKE_OF_TIME_SUCCESS,
    STATUS_CHANGE_TAKE_OF_TIME_FAIL,
    SAVE_ORDERING_TAKE_OF_TIME,
    SAVE_ORDERING_TAKE_OF_TIME_SUCCESS,
    SAVE_ORDERING_TAKE_OF_TIME_FAIL,
    SAVE_ORDERING_TAKE_OF_TIME_RESET_FLAG
  } from "./actionTypes";
  
  export const getTakeofTime = data => ({
    type: GET_TAKE_OF_TIME,
    payload: data,
  });
  
  export const getTakeofTimeSuccess = data => ({
    type: GET_TAKE_OF_TIME_SUCCESS,
    payload: data,
  });
  
  export const getTakeofTimeFail = error => ({
    type: GET_TAKE_OF_TIME_FAIL,
    payload: error,
  });
  
  export const addNewTakeofTime = takeoftime => ({
    type: ADD_NEW_TAKE_OF_TIME,
    payload: takeoftime,
  });
  
  export const addNewTakeofTimeSuccess = takeoftime => ({
    type: ADD_NEW_TAKE_OF_TIME_SUCCESS,
    payload: takeoftime,
  });
  
  export const addNewTakeofTimeFail = error => ({
    type: ADD_NEW_TAKE_OF_TIME_FAIL,
    payload: error,
  });
  
  export const updateTakeofTime = takeoftime => ({
    type: UPDATE_TAKE_OF_TIME,
    payload: takeoftime,
  });
  
  export const updateTakeofTimeSuccess = takeoftime => ({
    type: UPDATE_TAKE_OF_TIME_SUCCESS,
    payload: takeoftime,
  });
  
  export const updateTakeofTimeFail = error => ({
    type: UPDATE_TAKE_OF_TIME_FAIL,
    payload: error,
  });
  
  export const getTakeofTimeDetails = takeoftime => ({
    type: GET_TAKE_OF_TIME_DETAILS,
    payload: takeoftime,
  });
  
  export const getTakeofTimeDetailsSuccess = takeoftime => ({
    type: GET_TAKE_OF_TIME_DETAILS_SUCCESS,
    payload: takeoftime,
  });
  
  export const getTakeofTimeDetailsFail = error => ({
    type: GET_TAKE_OF_TIME_DETAILS_FAIL,
    payload: error,
  });
  
  export const delTakeofTime = data => ({
    type: DELETE_TAKE_OF_TIME,
    payload: data,
  });
  
  export const deleteTakeofTimeSuccess = data => ({
    type: DELETE_TAKE_OF_TIME_SUCCESS,
    payload: data,
  });
  
  export const deleteTakeofTimeFail = data => ({
    type: DELETE_TAKE_OF_TIME_FAIL,
    payload: data,
  });
  export const statusChangeTakeofTime = takeoftime => ({
    type: STATUS_CHANGE_TAKE_OF_TIME,
    payload: takeoftime,
  });
  
  export const statusChangeTakeofTimeSuccess = takeoftime => ({
    type: STATUS_CHANGE_TAKE_OF_TIME_SUCCESS,
    payload: takeoftime,
  });
  
  export const statusChangeTakeofTimeFail = error => ({
    type: STATUS_CHANGE_TAKE_OF_TIME_FAIL,
    payload: error,
  });
  
  export const modifyTakeofTimeFlag = () => {
    return {
      type: MODIFY_TAKE_OF_TIME_FLAG,
    }
  }
  
  export const resetTakeofTimeFlag = () => {
    return {
      type: RESET_TAKE_OF_TIME_FLAG,
    }
  }

  //Save Ordaring
export const saveOrderingTakeofTime = data => ({
  type: SAVE_ORDERING_TAKE_OF_TIME,
  payload: data,
});

export const saveOrderingTakeofTimeSuccess = data => ({
  type: SAVE_ORDERING_TAKE_OF_TIME_SUCCESS,
  payload: data,
});

export const saveOrderingTakeofTimeFail = error => ({
  type: SAVE_ORDERING_TAKE_OF_TIME_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingTakeofTimeResetFlag = () => {
  return {
    type: SAVE_ORDERING_TAKE_OF_TIME_RESET_FLAG,
  }
}
 
  