import {
  GET_VISIONS,
  GET_VISIONS_SUCCESS,
  GET_VISIONS_FAIL,
  ADD_NEW_VISION,
  ADD_NEW_VISION_SUCCESS,
  ADD_NEW_VISION_FAIL,
  GET_VISION_DETAILS,
  GET_VISION_DETAILS_SUCCESS,
  GET_VISION_DETAILS_FAIL,
  UPDATE_VISION,
  UPDATE_VISION_SUCCESS,
  UPDATE_VISION_FAIL,
  RESET_VISION_FLAG,
  DELETE_VISION,
  DELETE_VISION_SUCCESS,
  DELETE_VISION_FAIL,
  STATUS_CHANGE_VISION,
  STATUS_CHANGE_VISION_SUCCESS,
  STATUS_CHANGE_VISION_FAIL,
  MODIFY_VISION_FLAG,

  SAVE_ORDERING_VISION,
  SAVE_ORDERING_VISION_SUCCESS,
  SAVE_ORDERING_VISION_FAIL,
  SAVE_ORDERING_VISION_RESET_FLAG,
} from "./actionTypes";

export const getVisions = data => ({
  type: GET_VISIONS,
  payload: data,
});

export const getVisionsSuccess = data => ({
  type: GET_VISIONS_SUCCESS,
  payload: data,
});

export const getVisionsFail = error => ({
  type: GET_VISIONS_FAIL,
  payload: error,
});

export const addNewVision = vision=> ({
  type: ADD_NEW_VISION,
  payload: vision,
});

export const addNewVisionSuccess = vision => ({
  type: ADD_NEW_VISION_SUCCESS,
  payload: vision,
});

export const addNewVisionFail = error => ({
  type: ADD_NEW_VISION_FAIL,
  payload: error,
});

export const updateVision = vision => ({
  type: UPDATE_VISION,
  payload: vision,
});

export const updateVisionSuccess = vision => ({
  type: UPDATE_VISION_SUCCESS,
  payload: vision,
});

export const updateVisionFail = error => ({
  type: UPDATE_VISION_FAIL,
  payload: error,
});

export const getVisionDetails = vision => ({
  type: GET_VISION_DETAILS,
  payload: vision,
});

export const getVisionDetailsSuccess = vision => ({
  type: GET_VISION_DETAILS_SUCCESS,
  payload: vision,
});

export const getVisionDetailsFail = error => ({
  type: GET_VISION_DETAILS_FAIL,
  payload: error,
});

export const resetVisionFlag = () => {
  return {
    type: RESET_VISION_FLAG,
  }
}

//Delete
export const deleteVision = vision => ({
  type: DELETE_VISION,
  payload: vision,
});

export const deleteVisionSuccess = vision => ({
  type: DELETE_VISION_SUCCESS,
  payload: vision,
});

export const deleteVisionFail = error => ({
  type: DELETE_VISION_FAIL,
  payload: error,
});

//Status Change
export const statusChangeVision = vision => ({
  type: STATUS_CHANGE_VISION,
  payload: vision,
});

export const statusChangeVisionSuccess = vision => ({
  type: STATUS_CHANGE_VISION_SUCCESS,
  payload: vision,
});

export const statusChangeVisionFail = error => ({
  type: STATUS_CHANGE_VISION_FAIL,
  payload: error,
});



//Save Ordaring
export const saveOrderingVision = data => ({
  type: SAVE_ORDERING_VISION,
  payload: data,
});

export const saveOrderingVisionSuccess = data => ({
  type: SAVE_ORDERING_VISION_SUCCESS,
  payload: data,
});

export const saveOrderingVisionFail = error => ({
  type: SAVE_ORDERING_VISION_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingVisionResetFlag = () => {
  return {
    type: SAVE_ORDERING_VISION_RESET_FLAG,
  }
}




//Modify Flag for toaster
export const modifyVisionFlag = () => {
  return {
    type: MODIFY_VISION_FLAG,
  }
}