import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_USERS,
  ADD_NEW_USER,
  GET_USER_DETAILS,
  UPDATE_USER,
  DELETE_USER,
  STATUS_CHANGE_USER,
} from "./actionTypes"

import {
  getUserSuccess,
  getUserFail,
  addNewUserSuccess,
  addNewUserFail,
  getUserDetailsSuccess,
  getUserDetailsFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  statusChangeUserSuccess,
  statusChangeUserFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUserList,addNewUser,  getUserDetails,
  updateUser,  delUser,
  statusChangeUser,
} from "../../helpers/fakebackend_helper"

function* fetchUsers({ payload: user }) {
  try {
    const response = yield call(getUserList, user)
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(getUserFail(error))
  }
}

export function* watchFetchUser() {
  yield takeEvery(GET_USERS, fetchUsers);
}

function* onAddNewUser({ payload: user }) {
  try {
      const response = yield call(addNewUser, user);
      if(response.status === 200){
        yield put(addNewUserSuccess(response));
      } else {
        yield put(addNewUserFail(response));
      }
  } catch (error) {
      yield put(addNewUserFail(error));
  }
}

export function* watchAddNewUser() {
  yield takeEvery(ADD_NEW_USER, onAddNewUser);
}
function* onUserDelete({ payload: user }) {
  try {
    const response = yield call(delUser, user);
    yield put(deleteUserSuccess({ user, ...response }));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, onUserDelete);
}

function* onUserDetails({ payload: user }) {
  try {
      const response = yield call(getUserDetails, user);
      if(response.status === 200){
        yield put(getUserDetailsSuccess(response));
      } else {
        yield put(getUserDetailsFail(response));
      }
  } catch (error) {
      yield put(getUserDetailsFail(error));
  }
}

export function* watchUserDetails() {
  yield takeEvery(GET_USER_DETAILS, onUserDetails);
}

function* onUserUpdate({ payload: user }) {
  try {
      const response = yield call(updateUser, user);
      if(response.status === 200){
        yield put(updateUserSuccess(response));
      } else {
        yield put(updateUserFail(response));
      }
  } catch (error) {
      yield put(updateUserFail(error));
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, onUserUpdate);
}

//Status Change
function* onStatusChangeUser({ payload: user }) {
  try {
    const response = yield call(statusChangeUser, user);
    yield put(statusChangeUserSuccess({ user, ...response }));
  } catch (error) {
    yield put(statusChangeUserFail(error));
  }
}

export function* watchStatusChangeUser() {
  yield takeEvery(STATUS_CHANGE_USER, onStatusChangeUser);
}
function* userSaga() {
  yield all(
    [
      fork(watchFetchUser),
      fork(watchAddNewUser),
      fork(watchUserDetails),
      fork(watchUpdateUser),
      fork(watchDeleteUser),
      fork(watchStatusChangeUser),
    ]
  );

}

export default userSaga
