import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory, useParams } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateBlog,
  resetBlogFlag,
  getCategories,
  getBlogDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { convertToBase64 } from "../../../helpers/image";
const BlogEdit = () => {
  document.title = "Edit Blog | Dabbawala";
  const dispatch = useDispatch();
  const { id } = useParams();
  const { alias } = useParams();

  const [isCreating, setIsCreating] = useState(false);
  const [category_type, setCategoryType] = useState("blogs");
  // const [category, setCategory] = useState("");
  const [catType, setCatType] = useState("");
  const [defaultBlog, setDefaultBlog] = useState("");
  const [blogImg, setBlogImage] = useState();
  const [short_description, setData] = useState();
  const history = useHistory()
  // const [pagetype, setPagetype] = useState([]);
  const {
    isBlogUpdate,
    isBlogUpdateFail,
    error,
    success,
    details,
    categories,
  } = useSelector((state) => ({
    isBlogUpdate: state.Blogs.isBlogUpdate,
    isBlogUpdateFail: state.Blogs.isBlogUpdateFail,
    error: state.Blogs.error,
    success: state.Blogs.success,
    details: state.Blogs.details,
    categories: state.Categories.categories,
  }));

  //const setDefaultBlog = {label: }

  // const [selectedCatType, setselectedCatType] = useState("");
  // function handleSelectType(value, label) {
  //   const categoryValue = { value: value, label: label };
  //   setCategoryType(categoryValue);
  // }
  const [selectedCattypeOption, setSelectedCattypeOption] = useState({
    value: details && details?.category ? details.category?._id : "",
    label: details && details?.category ? details.category?.name : "",
  });
 console.log(selectedCattypeOption);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: details && details._id ? details._id : "",
      title: details && details.title ? details.title : "",
      short_description:
        details && details.short_description ? details.short_description : "",
      image: details && details.image ? details.image : "",
      // category:selectedCatType
      category: selectedCattypeOption
      // category: selectedCattypeOption,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: validation.values.id,
        title: values.title,
        alias:alias,
        short_description: short_description,
        image: blogImg,
        category: selectedCattypeOption.value,
      };
      dispatch(updateBlog(data));
    },
  });

  useEffect(() => {
    if (isBlogUpdate || isBlogUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetBlogFlag());
        // history.push("/blogs");
      }, 3000);
    }
  }, [dispatch, isBlogUpdate, isBlogUpdateFail, validation]);
  useEffect(() => {
    dispatch(getCategories({ category_type }));
  }, [dispatch, category_type]);
  useEffect(() => {
    dispatch(getBlogDetails({ alias: alias }));
  }, [dispatch, alias]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setBlogImage);
      }
    }
  };
// console.log(validation.values.category);
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Blog" pageTitle="Blogs" pageUrl="/blogs" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Blog" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Image *
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImgChange}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Blog Name
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="title"
                              className="form-control"
                              placeholder="Blog name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.title || ""}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category Type
                            </Label>
                            <Select
                              value={validation.values.category || ""}
                              onChange={(selectedCatType) => {
                                let event = {
                                  target: {
                                    value: selectedCatType,
                                  },
                                };
                                //console.log(event.target);
                                setSelectedCattypeOption(event.target.value);
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                categories.length &&
                                categories.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))}
                              name="type"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category &&
                                validation.errors.category
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category &&
                            validation.errors.category ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        
                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Short Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="short_description"
                              data={validation.values.short_description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.short_description &&
                            validation.errors.short_description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.short_description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/blogs" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isBlogUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isBlogUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default BlogEdit;
