import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_FUTURETEAM,
  ADD_NEW_FUTURETEAM,
  GET_FUTURETEAM_DETAILS,
  UPDATE_FUTURETEAM,
  STATUS_CHANGE_FUTURETEAM,
  DELETE_FUTURETEAM,
  SAVE_ORDERING_FUTURETEAM,
  
} from "./actionTypes"

import {
  getFutureTeamSuccess, 
  getFutureTeamFail,

  addNewFutureTeamSuccess,
  addNewFutureTeamFail,

  getFutureTeamDetailsSuccess,
  getFutureTeamDetailsFail,

  updateFutureTeamSuccess,
  updateFutureTeamFail,

  statusChangeFutureTeamSuccess,
  statusChangeFutureTeamFail,

  deleteFutureTeamSuccess,
  deleteFutureTeamFail,
  
  saveOrderingFutureTeamSuccess,
  saveOrderingFutureTeamFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFutureTeamList,
  addNewFutureTeam,
  getFutureTeamDetails,
  updateFutureTeam,
  statusChangeFutureTeam,
  deleteFutureTeam,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchFutureTeam({ payload: data }) {
  try {
    const response = yield call(getFutureTeamList, data)
    yield put(getFutureTeamSuccess(response))
  } catch (error) {
    yield put(getFutureTeamFail(error))
  }
}

export function* watchFetchFutureTeam() {
  yield takeEvery(GET_FUTURETEAM, fetchFutureTeam);
}

function* onAddNewFutureTeam({ payload: data }) {
  try {
      const response = yield call(addNewFutureTeam, data);
      if(response.status === 200){
        yield put(addNewFutureTeamSuccess(response));
      } else {
        yield put(addNewFutureTeamFail(response));
      }
  } catch (error) {
      yield put(addNewFutureTeamFail(error));
  }
}

export function* watchAddNewFutureTeam() {
  yield takeEvery(ADD_NEW_FUTURETEAM, onAddNewFutureTeam);
}

function* onFutureTeamDetails({ payload: data }) {
  try {
      const response = yield call(getFutureTeamDetails, data);
      if(response.status === 200){
        yield put(getFutureTeamDetailsSuccess(response));
      } else {
        yield put(getFutureTeamDetailsFail(response));
      }
  } catch (error) {
      yield put(getFutureTeamDetailsFail(error));
  }
}

export function* watchFutureTeamDetails() {
  yield takeEvery(GET_FUTURETEAM_DETAILS, onFutureTeamDetails);
}

function* onFutureTeamUpdate({ payload: data }) {
  try {
      const response = yield call(updateFutureTeam, data);
      if(response.status === 200){
        yield put(updateFutureTeamSuccess(response));
      } else {
        yield put(updateFutureTeamFail(response));
      }
  } catch (error) {
      yield put(updateFutureTeamFail(error));
  }
}

export function* watchUpdateFutureTeam() {
  yield takeEvery(UPDATE_FUTURETEAM, onFutureTeamUpdate);
}


//Delete Data
function* onDeleteFutureTeam({ payload: data }) {
  try {
    const response = yield call(deleteFutureTeam, data);
    yield put(deleteFutureTeamSuccess({ data, ...response }));
  } catch (error) {
    yield put(deleteFutureTeamFail(error));
  }
}

export function* watchDeleteFutureTeam() {
  yield takeEvery(DELETE_FUTURETEAM, onDeleteFutureTeam);
}



//Status Change
function* onStatusChangFutureTeam({ payload: data }) {
  try {
    const response = yield call(statusChangeFutureTeam, data);
    yield put(statusChangeFutureTeamSuccess({ data, ...response }));
  } catch (error) {
    yield put(statusChangeFutureTeamFail(error));
  }
}

export function* watchStatusChangeFutureTeam() {
  yield takeEvery(STATUS_CHANGE_FUTURETEAM, onStatusChangFutureTeam);
}


//Save Ordering
function* onSaveOrderingFutureTeam({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingFutureTeamSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingFutureTeamFail(error));
  }
}

export function* watchSaveOrderingFutureTeam() {
  yield takeEvery(SAVE_ORDERING_FUTURETEAM, onSaveOrderingFutureTeam);
}



function* dataSaga() {
  yield all(
    [
      fork(watchFetchFutureTeam),
      fork(watchAddNewFutureTeam),
      fork(watchFutureTeamDetails),
      fork(watchUpdateFutureTeam),
      fork(watchStatusChangeFutureTeam),
      fork(watchDeleteFutureTeam),
      fork(watchSaveOrderingFutureTeam),
    ]
  );

}

export default dataSaga
