import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewPage, resetPageFlag } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";

const PageCreate = () => {
  document.title = "Create Page | Dabbawala";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState();
  const [description, setData] = useState();
  const { isPageAdd, isPageAddFail, error, success } = useSelector((state) => ({
    isPageAdd: state.Pages.isPageAdd,
    isPageAddFail: state.Pages.isPageAddFail,
    error: state.Pages.error,
    success: state.Pages.success,
  }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      subtitle: "",
      description: "",
      slug: "",
      image:""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title "),
      subtitle: Yup.string().required("Please Enter Subtitle "),
      slug: Yup.string().required("Please Enter Slug"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.title,
        subtitle: values.subtitle,
        slug: values.slug,
        description: description,
        image: image
      };
      dispatch(addNewPage(data));
    },
  });

  useEffect(() => {
    if (isPageAdd || isPageAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetPageFlag());
        history.push('/pages');
      }, 3000);
    }
  }, [dispatch, isPageAdd, isPageAddFail, validation, history]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create Page" pageTitle="Pages" pageUrl="/pages" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Page" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={4} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={4} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Subtitle *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Subtitle"
                              name="subtitle"
                              value={validation.values.subtitle || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.subtitle &&
                                validation.errors.subtitle
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.subtitle &&
                            validation.errors.subtitle ? (
                              <FormFeedback type="invalid">
                                {validation.errors.subtitle}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={4} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Slug *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Slug"
                              name="slug"
                              value={validation.values.slug || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.slug &&
                                validation.errors.slug
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.slug &&
                            validation.errors.slug ? (
                              <FormFeedback type="invalid">
                                {validation.errors.slug}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Image
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImg1Change}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/pages" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isPageAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isPageAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default PageCreate;
