import {
  GET_INGREDIENTS_SUCCESS,
  GET_INGREDIENTS_FAIL,
  ADD_NEW_INGREDIENT_SUCCESS,
  ADD_NEW_INGREDIENT_FAIL,
  GET_INGREDIENT_DETAILS_SUCCESS,
  GET_INGREDIENT_DETAILS_FAIL,
  UPDATE_INGREDIENT_SUCCESS,
  UPDATE_INGREDIENT_FAIL,
  RESET_INGREDIENT_FLAG,
  DELETE_INGREDIENT_SUCCESS,
  DELETE_INGREDIENT_FAIL,
  STATUS_CHANGE_INGREDIENT_SUCCESS,
  STATUS_CHANGE_INGREDIENT_FAIL,
  MODIFY_INGREDIENT_FLAG, 
} from "./actionTypes";

const INIT_STATE = {
  ingredients: []
};

const Ingredients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INGREDIENTS_SUCCESS:
      return {
        ...state,
        ingredients: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_INGREDIENTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredients: [...state.ingredients, action.payload.data],
        success: action.payload.message,
        isIngredientAdd: true,
        isIngredientAddFail: false,
      };

    case ADD_NEW_INGREDIENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isIngredientAdd: false,
        isIngredientAddFail: true,
      };

    case UPDATE_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredients: state.ingredients.map(ingredient =>
          ingredient._id.toString() === action.payload.data._id.toString()
            ? { ...ingredient, ...action.payload.data }
            : ingredient
        ),
        success: action.payload.message,
        isIngredientUpdate: true,
        isIngredientUpdateFail: false
      };

    case UPDATE_INGREDIENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isIngredientUpdate: false,
        isIngredientUpdateFail: true,
      };

    case GET_INGREDIENT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_INGREDIENT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_INGREDIENT_SUCCESS:
      return { 
        ...state,
        ingredients: state.ingredients.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_INGREDIENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredients: state.ingredients.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_INGREDIENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_INGREDIENT_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_INGREDIENT_FLAG:
      return {
        ...state,
        isIngredientAdd: false,
        isIngredientAddFail: false,
        isSuccess: false,
        isIngredientDetails: false,
        isIngredientUpdate: false,
        isIngredientUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Ingredients;
