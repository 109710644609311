import {
  GET_SITE_DETAILS_SUCCESS,
  GET_SITE_DETAILS_FAIL,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAIL,
  RESET_SITE_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  sites: [],
};

const Sites = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SITE_SUCCESS:
      return {
        ...state,
        sites: state.sites.map((site) =>
        site._id.toString() === action.payload.data._id.toString()
            ? { ...site, ...action.payload.data }
            : site
        ),
        success: action.payload.message,
        isSiteUpdate: true,
        isSiteUpdateFail: false,
      };

    case UPDATE_SITE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSiteUpdate: false,
        isSiteUpdateFail: true,
      };

    case GET_SITE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_SITE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };


    case RESET_SITE_FLAG:
      return {
        ...state,
        isSiteAdd: false,
        isSiteAddFail: false,
        isSuccess: false,
        isSiteDetails: false,
        isSiteUpdate: false,
        isSiteUpdateFail: false,
        error: false,
      };

    default:
      return state;
  }
};

export default Sites;
