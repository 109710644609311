import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_FUNFACTS,
  ADD_NEW_FUNFACT,
  GET_FUNFACT_DETAILS,
  UPDATE_FUNFACT,
  DELETE_FUNFACT,
  SAVE_ORDERING_FUNFACT,
  STATUS_CHANGE_FUNFACT
} from "./actionTypes"

import {
  getFunfactsSuccess,
  getFunfactsFail,
  addNewFunfactSuccess,
  addNewFunfactFail,
  getFunfactDetailsSuccess,
  getFunfactDetailsFail,
  updateFunfactSuccess,
  updateFunfactFail,
  deleteFunfactSuccess,
  deleteFunfactFail,
  saveOrderingFunfactSuccess,
  saveOrderingFunfactFail,
  statusChangeFunfactSuccess,
  statusChangeFunfactFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFunfactList,
  addNewFunfact,
  getFunfactDetails,
  updateFunfact,
  deleteFunfact,
  saveOrdering,
  statusChangeFunfact
} from "../../helpers/fakebackend_helper"

function* fetchFunfacts({ payload: funfact }) {
  try {
    const response = yield call(getFunfactList, funfact)
    yield put(getFunfactsSuccess(response))
  } catch (error) {
    yield put(getFunfactsFail(error))
  }
}

export function* watchFetchFunfacts() {
  yield takeEvery(GET_FUNFACTS, fetchFunfacts);
}

function* onAddNewFunfact({ payload: funfact }) {
  try {
      const response = yield call(addNewFunfact, funfact);
      if(response.status === 200){
        yield put(addNewFunfactSuccess(response));
      } else {
        yield put(addNewFunfactFail(response));
      }
  } catch (error) {
      yield put(addNewFunfactFail(error));
  }
}

export function* watchAddNewFunfact() {
  yield takeEvery(ADD_NEW_FUNFACT, onAddNewFunfact);
}

function* onFunfactDetails({ payload: funfact }) {
  try {
      const response = yield call(getFunfactDetails, funfact);
      if(response.status === 200){
        yield put(getFunfactDetailsSuccess(response));
      } else {
        yield put(getFunfactDetailsFail(response));
      }
  } catch (error) {
      yield put(getFunfactDetailsFail(error));
  }
}

export function* watchFunfactDetails() {
  yield takeEvery(GET_FUNFACT_DETAILS, onFunfactDetails);
}

function* onFunfactUpdate({ payload: funfact }) {
  try {
      const response = yield call(updateFunfact, funfact);
      if(response.status === 200){
        yield put(updateFunfactSuccess(response));
      } else {
        yield put(updateFunfactFail(response));
      }
  } catch (error) {
      yield put(updateFunfactFail(error));
  }
}

export function* watchUpdateFunfact() {
  yield takeEvery(UPDATE_FUNFACT, onFunfactUpdate);
}


//Delete Data
function* onDeleteFunfact({ payload: funfact }) {
  try {
    const response = yield call(deleteFunfact, funfact);
    yield put(deleteFunfactSuccess({ funfact, ...response }));
  } catch (error) {
    yield put(deleteFunfactFail(error));
  }
}

export function* watchDeleteFunfact() {
  yield takeEvery(DELETE_FUNFACT, onDeleteFunfact);
}



//Status Change
function* onStatusChangFunfact({ payload: funfact }) {
  try {
    const response = yield call(statusChangeFunfact, funfact);
    yield put(statusChangeFunfactSuccess({ funfact, ...response }));
  } catch (error) {
    yield put(statusChangeFunfactFail(error));
  }
}

export function* watchStatusChangeFunfact() {
  yield takeEvery(STATUS_CHANGE_FUNFACT, onStatusChangFunfact);
}

//Save Ordering
function* onSaveOrderingService({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingFunfactSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingFunfactFail(error));
  }
}

export function* watchSaveOrderingService() {
  yield takeEvery(SAVE_ORDERING_FUNFACT, onSaveOrderingService);
}


function* funfactSaga() {
  yield all(
    [
      fork(watchFetchFunfacts),
      fork(watchAddNewFunfact),
      fork(watchFunfactDetails),
      fork(watchUpdateFunfact),
      fork(watchDeleteFunfact),
      fork(watchSaveOrderingService),
      fork(watchStatusChangeFunfact)
    ]
  );

}

export default funfactSaga
