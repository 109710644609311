import {
  GET_MISSIONS,
  GET_MISSIONS_SUCCESS,
  GET_MISSIONS_FAIL,
  ADD_NEW_MISSION,
  ADD_NEW_MISSION_SUCCESS,
  ADD_NEW_MISSION_FAIL,
  GET_MISSION_DETAILS,
  GET_MISSION_DETAILS_SUCCESS,
  GET_MISSION_DETAILS_FAIL,
  UPDATE_MISSION,
  UPDATE_MISSION_SUCCESS,
  UPDATE_MISSION_FAIL,
  RESET_MISSION_FLAG,
  DELETE_MISSION,
  DELETE_MISSION_SUCCESS,
  DELETE_MISSION_FAIL,
  STATUS_CHANGE_MISSION,
  STATUS_CHANGE_MISSION_SUCCESS,
  STATUS_CHANGE_MISSION_FAIL,
  MODIFY_MISSION_FLAG,


  SAVE_ORDERING_MISSION,
  SAVE_ORDERING_MISSION_SUCCESS,
  SAVE_ORDERING_MISSION_FAIL,
  SAVE_ORDERING_MISSION_RESET_FLAG,
} from "./actionTypes";

export const getMissions = data => ({
  type: GET_MISSIONS,
  payload: data,
});

export const getMissionsSuccess = data => ({
  type: GET_MISSIONS_SUCCESS,
  payload: data,
});

export const getMissionsFail = error => ({
  type: GET_MISSIONS_FAIL,
  payload: error,
});

export const addNewMission = mission=> ({
  type: ADD_NEW_MISSION,
  payload: mission,
});

export const addNewMissionSuccess = mission => ({
  type: ADD_NEW_MISSION_SUCCESS,
  payload: mission,
});

export const addNewMissionFail = error => ({
  type: ADD_NEW_MISSION_FAIL,
  payload: error,
});

export const updateMission = mission => ({
  type: UPDATE_MISSION,
  payload: mission,
});

export const updateMissionSuccess = mission => ({
  type: UPDATE_MISSION_SUCCESS,
  payload: mission,
});

export const updateMissionFail = error => ({
  type: UPDATE_MISSION_FAIL,
  payload: error,
});

export const getMissionDetails = mission => ({
  type: GET_MISSION_DETAILS,
  payload: mission,
});

export const getMissionDetailsSuccess = mission => ({
  type: GET_MISSION_DETAILS_SUCCESS,
  payload: mission,
});

export const getMissionDetailsFail = error => ({
  type: GET_MISSION_DETAILS_FAIL,
  payload: error,
});

export const resetMissionFlag = () => {
  return {
    type: RESET_MISSION_FLAG,
  }
}

//Delete
export const deleteMission = mission => ({
  type: DELETE_MISSION,
  payload: mission,
});

export const deleteMissionSuccess = mission => ({
  type: DELETE_MISSION_SUCCESS,
  payload: mission,
});

export const deleteMissionFail = error => ({
  type: DELETE_MISSION_FAIL,
  payload: error,
});

//Status Change
export const statusChangeMission = mission => ({
  type: STATUS_CHANGE_MISSION,
  payload: mission,
});

export const statusChangeMissionSuccess = mission => ({
  type: STATUS_CHANGE_MISSION_SUCCESS,
  payload: mission,
});

export const statusChangeMissionFail = error => ({
  type: STATUS_CHANGE_MISSION_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingMission = data => ({
  type: SAVE_ORDERING_MISSION,
  payload: data,
});

export const saveOrderingMissionSuccess = data => ({
  type: SAVE_ORDERING_MISSION_SUCCESS,
  payload: data,
});

export const saveOrderingMissionFail = error => ({
  type: SAVE_ORDERING_MISSION_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingMissionResetFlag = () => {
  return {
    type: SAVE_ORDERING_MISSION_RESET_FLAG,
  }
}

//Modify Flag for toaster
export const modifyMissionFlag = () => {
  return {
    type: MODIFY_MISSION_FLAG,
  }
}