import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_JOBS,
  ADD_NEW_JOB,
  GET_JOB_DETAILS,
  UPDATE_JOB,
  DELETE_JOB,
  STATUS_CHANGE_JOB,
  SAVE_ORDERING_JOB,
} from "./actionTypes";

import {
  getJobsSuccess,
  getJobsFail,
  addNewJobSuccess,
  addNewJobFail,
  getJobDetailsSuccess,
  getJobDetailsFail,
  updateJobSuccess,
  updateJobFail,
  deleteJobSuccess,
  deleteJobFail,
  statusChangeJobSuccess,
  statusChangeJobFail,
  saveOrderingJobSuccess,
  saveOrderingJobFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getJobList,
  addNewJob,
  getJobDetails,
  updateJob,
  delJob,
  statusChangeJob,

  saveOrdering,
} from "../../helpers/fakebackend_helper";


function* fetchJobs({ payload: job }) {
  try {
    const response = yield call(getJobList, job);
    yield put(getJobsSuccess(response));
  } catch (error) {
    yield put(getJobsFail(error));
  }
}

export function* watchFetchJobs() {
  yield takeEvery(GET_JOBS, fetchJobs);
}

function* onAddNewJob({ payload: job }) {
  try {
    const response = yield call(addNewJob, job);
    if (response.status === 200) {
      yield put(addNewJobSuccess(response));
    } else {
      yield put(addNewJobFail(response));
    }
  } catch (error) {
    yield put(addNewJobFail(error));
  }
}

export function* watchAddNewJob() {
  yield takeEvery(ADD_NEW_JOB, onAddNewJob);
}

function* onJobDetails({ payload: job }) {
  try {
    const response = yield call(getJobDetails, job);
    if (response.status === 200) {
      yield put(getJobDetailsSuccess(response));
    } else {
      yield put(getJobDetailsFail(response));
    }
  } catch (error) {
    yield put(getJobDetailsFail(error));
  }
}

export function* watchJobDetails() {
  yield takeEvery(GET_JOB_DETAILS, onJobDetails);
}

function* onJobUpdate({ payload: job }) {
  try {
    const response = yield call(updateJob, job);
    if (response.status === 200) {
      yield put(updateJobSuccess(response));
    } else {
      yield put(updateJobFail(response));
    }
  } catch (error) {
    yield put(updateJobFail(error));
  }
}

export function* watchUpdateJob() {
  yield takeEvery(UPDATE_JOB, onJobUpdate);
}

function* onJobDelete({ payload: job }) {
  try {
    const response = yield call(delJob, job);
    yield put(deleteJobSuccess({ job, ...response }));
  } catch (error) {
    yield put(deleteJobFail(error));
  }
}

export function* watchDeleteJob() {
  yield takeEvery(DELETE_JOB, onJobDelete);
}

//Status Change
function* onStatusChangeJob({ payload: job }) {
  try {
    const response = yield call(statusChangeJob, job);
    yield put(statusChangeJobSuccess({ job, ...response }));
  } catch (error) {
    yield put(statusChangeJobFail(error));
  }
}

export function* watchStatusChangeJob() {
  yield takeEvery(STATUS_CHANGE_JOB, onStatusChangeJob);
}


//Save Ordering
function* onSaveOrderingJob({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingJobSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingJobFail(error));
  }
}

export function* watchSaveOrderingJob() {
  yield takeEvery(SAVE_ORDERING_JOB, onSaveOrderingJob);
}

function* jobSaga() {
  yield all([
    fork(watchFetchJobs),
    fork(watchAddNewJob),
    fork(watchJobDetails),
    fork(watchUpdateJob),
    fork(watchDeleteJob),
    fork(watchStatusChangeJob),
    fork(watchSaveOrderingJob),
  ]);
}

export default jobSaga;
