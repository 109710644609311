import {
    GET_TAKE_OF_TIME_SUCCESS,
    GET_TAKE_OF_TIME_FAIL,
    ADD_NEW_TAKE_OF_TIME_SUCCESS,
    ADD_NEW_TAKE_OF_TIME_FAIL,
    GET_TAKE_OF_TIME_DETAILS_SUCCESS,
    GET_TAKE_OF_TIME_DETAILS_FAIL,
    UPDATE_TAKE_OF_TIME_SUCCESS,
    UPDATE_TAKE_OF_TIME_FAIL,
    DELETE_TAKE_OF_TIME_SUCCESS,
    DELETE_TAKE_OF_TIME_FAIL,
    MODIFY_TAKE_OF_TIME_FLAG,
    RESET_TAKE_OF_TIME_FLAG,
    STATUS_CHANGE_TAKE_OF_TIME_SUCCESS,
    STATUS_CHANGE_TAKE_OF_TIME_FAIL,
    SAVE_ORDERING_TAKE_OF_TIME_SUCCESS,
    SAVE_ORDERING_TAKE_OF_TIME_FAIL,
    SAVE_ORDERING_TAKE_OF_TIME_RESET_FLAG,
    

  } from "./actionTypes";
  
  const INIT_STATE = {
    takeoftime: [],
    totalDataCount: 0,
    isSuccess: false
  };
  
  const takeofTime = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_TAKE_OF_TIME_SUCCESS:
        return {
          ...state,
          takeoftime: action.payload.data,
          totalDataCount: action.payload.count,
          isSuccess: true,
        };
       
  
      case GET_TAKE_OF_TIME_FAIL:
        return {
          ...state,
          error: action.payload.message,
          totalDataCount: 0,
          isSuccess: false,
        };
  
      case ADD_NEW_TAKE_OF_TIME_SUCCESS:
        return {
          ...state,
          takeoftime: [...state.takeoftime, action.payload.data],
          success: action.payload.message,
          istakeoftimeAdd: true,
          istakeoftimeAddFail: false,
        };
  
      case ADD_NEW_TAKE_OF_TIME_FAIL:
        return {
          ...state,
          error: action.payload.message,
          istakeoftimeAdd: false,
          istakeoftimeAddFail: true,
        };
  
      case UPDATE_TAKE_OF_TIME_SUCCESS:
        return {
          ...state,
          takeoftime: state.takeoftime.map((takeoftime) =>
            takeoftime._id.toString() === action.payload.data._id.toString()
              ? { ...takeoftime, ...action.payload.data }
              : takeoftime
          ),
          success: action.payload.message,
          istakeoftimeUpdate: true,
          istakeoftimeUpdateFail: false,
        };
  
      case UPDATE_TAKE_OF_TIME_FAIL:
        return {
          ...state,
          error: action.payload.message,
          istakeoftimeUpdate: false,
          istakeoftimeUpdateFail: true,
        };
  
      case GET_TAKE_OF_TIME_DETAILS_SUCCESS:
        return {
          ...state,
          details: action.payload.data,
          isSuccess: true,
        };
      case GET_TAKE_OF_TIME_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload.message,
        };
  
      case DELETE_TAKE_OF_TIME_SUCCESS:
        return {
          ...state,
          takeoftime: state.takeoftime.filter((getData) => {
            return getData._id !== action.payload.data._id;
          }),
          success: action.payload.message,
          isSuccess: true,
          statusSuccess: true,
          statusFailed: false,
          istakeoftimeRemove: true,
          istakeoftimeRemoveFail: false,
        };
  
      case DELETE_TAKE_OF_TIME_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false,
          statusSuccess: false,
          statusFailed: true,
          istakeoftimeRemove: false,
          istakeoftimeRemoveFail: true,
        };

        //Save Ordering
    case SAVE_ORDERING_TAKE_OF_TIME_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_TAKE_OF_TIME_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_TAKE_OF_TIME_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };


      //Status Change
      case STATUS_CHANGE_TAKE_OF_TIME_SUCCESS:
        return {
          ...state,
          takeoftime: state.takeoftime.map((takeoftime) =>
          takeoftime._id.toString() === action.payload.data._id.toString()
              ? { ...takeoftime, ...action.payload.data }
              : takeoftime
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFailed: false,
        };
  
      case STATUS_CHANGE_TAKE_OF_TIME_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFailed: true,
        };
      case RESET_TAKE_OF_TIME_FLAG:
        return {
          ...state,
          istakeoftimeAdd: false,
          istakeoftimeAddFail: false,
          isSuccess: false,
          istakeoftimeDetails: false,
          istakeoftimeUpdate: false,
          istakeoftimeUpdateFail: false,
          error: false,
        };
      case MODIFY_TAKE_OF_TIME_FLAG:
        return {
          ...state,
          statusSuccess: false,
        };
      default:
        return state;
    }
  };
  
  export default takeofTime;
  