import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";
import { hostUrl } from "../config/host";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();
  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  const accessToken = userProfile?.data?.access_token

  useEffect(() => {
    const ac = new AbortController()

    const authenticateUser = async token => {
      try {
        const res = await fetch(`${hostUrl}/authenticate-user`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({})
        })

        const resData = await res.json()

        if (resData.status !== 200) {
          dispatch(logoutUser())
        }
      } catch (err) {
        dispatch(logoutUser())
      }
    }

    if (accessToken) {
      authenticateUser(accessToken)
    }

    return () => ac.abort()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ accessToken ])

  /*
    redirect is un-auth access protected routes via url
    */

  if (!userProfile && loading && !token) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };