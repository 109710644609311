import {
  GET_DIETS,
  GET_DIETS_SUCCESS,
  GET_DIETS_FAIL,
  ADD_NEW_DIET,
  ADD_NEW_DIET_SUCCESS,
  ADD_NEW_DIET_FAIL,
  GET_DIET_DETAILS,
  GET_DIET_DETAILS_SUCCESS,
  GET_DIET_DETAILS_FAIL,
  UPDATE_DIET,
  UPDATE_DIET_SUCCESS,
  UPDATE_DIET_FAIL,
  RESET_DIET_FLAG,
  DELETE_DIET,
  DELETE_DIET_SUCCESS,
  DELETE_DIET_FAIL,
  STATUS_CHANGE_DIET,
  STATUS_CHANGE_DIET_SUCCESS,
  STATUS_CHANGE_DIET_FAIL,
  MODIFY_DIET_FLAG,
  SAVE_ORDERING_DIET,
  SAVE_ORDERING_DIET_SUCCESS,
  SAVE_ORDERING_DIET_FAIL,
  SAVE_ORDERING_DIET_RESET_FLAG,
} from "./actionTypes";

export const getDiets = data => ({
  type: GET_DIETS,
  payload: data,
});

export const getDietsSuccess = data => ({
  type: GET_DIETS_SUCCESS,
  payload: data,
});

export const getDietsFail = error => ({
  type: GET_DIETS_FAIL,
  payload: error,
});

export const addNewDiet = diet=> ({
  type: ADD_NEW_DIET,
  payload: diet,
});

export const addNewDietSuccess = diet => ({
  type: ADD_NEW_DIET_SUCCESS,
  payload: diet,
});

export const addNewDietFail = error => ({
  type: ADD_NEW_DIET_FAIL,
  payload: error,
});

export const updateDiet = diet => ({
  type: UPDATE_DIET,
  payload: diet,
});

export const updateDietSuccess = diet => ({
  type: UPDATE_DIET_SUCCESS,
  payload: diet,
});

export const updateDietFail = error => ({
  type: UPDATE_DIET_FAIL,
  payload: error,
});

export const getDietDetails = diet => ({
  type: GET_DIET_DETAILS,
  payload: diet,
});

export const getDietDetailsSuccess = diet => ({
  type: GET_DIET_DETAILS_SUCCESS,
  payload: diet,
});

export const getDietDetailsFail = error => ({
  type: GET_DIET_DETAILS_FAIL,
  payload: error,
});

export const resetDietFlag = () => {
  return {
    type: RESET_DIET_FLAG,
  }
}

//Delete
export const deleteDiet = diet => ({
  type: DELETE_DIET,
  payload: diet,
});

export const deleteDietSuccess = diet => ({
  type: DELETE_DIET_SUCCESS,
  payload: diet,
});

export const deleteDietFail = error => ({
  type: DELETE_DIET_FAIL,
  payload: error,
});

//Status Change
export const statusChangeDiet = diet => ({
  type: STATUS_CHANGE_DIET,
  payload: diet,
});

export const statusChangeDietSuccess = diet => ({
  type: STATUS_CHANGE_DIET_SUCCESS,
  payload: diet,
});

export const statusChangeDietFail = error => ({
  type: STATUS_CHANGE_DIET_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyDietFlag = () => {
  return {
    type: MODIFY_DIET_FLAG,
  }
}


  //Save Ordaring
  export const saveOrderingDiet = data => ({
    type: SAVE_ORDERING_DIET,
    payload: data,
  });
  
  export const saveOrderingDietSuccess = data => ({
    type: SAVE_ORDERING_DIET_SUCCESS,
    payload: data,
  });
  
  export const saveOrderingDietFail = error => ({
    type: SAVE_ORDERING_DIET_FAIL,
    payload: error,
  });
  
  
  //Save ordering reset Flag for toaster
  export const saveOrderingDietResetFlag = () => {
    return {
      type: SAVE_ORDERING_DIET_RESET_FLAG,
    }
  }