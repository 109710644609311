import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { addNewCoupon, resetCouponFlag, getCoupons } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import Flatpickr from "react-flatpickr";
import "moment-timezone";
import moment from 'moment';
import Select from "react-select";

const CouponCreate = () => {
    document.title = "Create Coupon | Defendit - Admin Panel";
    const dispatch = useDispatch();
    const fp = useRef(null);
    const [date, setDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const discountType = [
        { value: 1, label: "Percentage" },
        { value: 2, label: "Fixed Amount" },
    ]
    const [discount, setDiscount] = useState({ value: 2, label: "Fixed Amount" });
    const [isCreating, setIsCreating] = useState(false);
    const { isCouponAdd, isCouponAddFail, error, success, } = useSelector(state => ({
        isCouponAdd: state.Coupons.isCouponAdd,
        isCouponAddFail: state.Coupons.isCouponAddFail,
        error: state.Coupons.error,
        success: state.Coupons.success,
        coupons: state.Coupons.coupons,
    }));

    function handleDiscount(label, value) {
        setDiscount({ label: label, value: value });
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: '',
            description: "",
            amount: "",
            discount_type: "",
            min_spend_amount: "",
            max_spend_amount: "",
            use_limit: "",
            user_id: "",
        },
        validationSchema: Yup.object({
            code: Yup.string().required("Please Enter Coupon Code"),
            description: Yup.string().required("Please Enter Description"),
            amount: Yup.string().required("Please enter Amount"),
            user_id: Yup.string().required("Please enter User ID"),

            // min_spend_amount: Yup.string().required("Please enter Minimum Spent Amount"),
            // max_spend_amount: Yup.string().required("Please enter Maximum Spent Amount"),
            // use_limit: Yup.string().required("Please enter Use Limit")
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            const data = {
                code: values.code,
                description: values.description,
                discount_type: discount.value,
                amount: values.amount,
                min_spend_amount: values.min_spend_amount,
                max_spend_amount: values.max_spend_amount,
                start_date: date,
                end_date: endDate,
                user_id: values.user_id
            }
            console.log(data)
            dispatch(addNewCoupon(data))
        },
    });


    useEffect(() => {
        dispatch(
            getCoupons());
    }, [dispatch]);

    useEffect(() => {
        if (isCouponAdd || isCouponAddFail) {
            setIsCreating(false)
            validation.resetForm();
            setTimeout(() => {
                dispatch(resetCouponFlag())
            }, 3000);
        }
    }, [dispatch, isCouponAdd, isCouponAddFail, validation]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Create Coupon" pageTitle="Coupons" pageUrl="/coupon" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Create Coupon" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">User ID*</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="User ID"
                                                            name='user_id'
                                                            value={validation.values.user_id || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.user_id && validation.errors.user_id ? true : false
                                                            }
                                                        />
                                                        {validation.touched.user_id && validation.errors.user_id ? (
                                                            <FormFeedback type="invalid">{validation.errors.user_id}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Coupon Code*</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Coupon Code"
                                                            name='code'
                                                            value={validation.values.code || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.code && validation.errors.code ? true : false
                                                            }
                                                        />
                                                        {validation.touched.code && validation.errors.code ? (
                                                            <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col sm={2} className="col-xxl-2">
                                                    <Label>Discount Type*</Label>
                                                    {/* <select
                                                   className="form-select"
                                                    value={discount}
                                                    onChange={(e) => handleDiscount(e.label, e.value)}
                                                    options={discountType}>
                                                </select> */}
                                                    <Select
                                                        value={discount}
                                                        onChange={(e) => handleDiscount(e.label, e.value)}
                                                        options={discountType}
                                                        name="choices-category-input"
                                                        classNamePrefix="select2-selection form-select"
                                                    />

                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Amount*
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            id="placeholderInput"
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Enter Amount"
                                                            name="amount"
                                                            value={validation.values.amount || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.amount &&
                                                                    validation.errors.amount
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.amount &&
                                                            validation.errors.amount ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.amount}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Minimum Spent Amount*
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="placeholderInput"
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Enter Minimum Spent Amount"
                                                            name="min_spend_amount"
                                                            value={validation.values.min_spend_amount || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.min_spend_amount &&
                                                                    validation.errors.min_spend_amount
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.min_spend_amount &&
                                                            validation.errors.min_spend_amount ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.min_spend_amount}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Maximum Spent Amount*
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="placeholderInput"
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Enter Minimum Spent Amount"
                                                            name="max_spend_amount"
                                                            value={validation.values.max_spend_amount || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.max_spend_amount &&
                                                                    validation.errors.max_spend_amount
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.max_spend_amount &&
                                                            validation.errors.max_spend_amount ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.max_spend_amount}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>




                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Limit*</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="limit"
                                                            name='limit'
                                                            value={validation.values.limit || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.limit && validation.errors.limit ? true : false
                                                            }
                                                        />
                                                        {validation.touched.limit && validation.errors.limit ? (
                                                            <FormFeedback type="invalid">{validation.errors.limit}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col sm={4} className="col-xxl-3">
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Valid From*
                                                        </Label>
                                                        <Flatpickr
                                                            ref={fp}
                                                            className="form-control"
                                                            id="datepicker-publish-input"
                                                            placeholder="Select Start date"
                                                            onChange={(e) => {
                                                                moment(setDate(e[0])).format('MM-DD-YYYY')

                                                            }}
                                                            // onChange={validation.handleChange}
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "d.m.y",
                                                            }}

                                                        />

                                                    </div>
                                                </Col>

                                                <Col sm={4} className="col-xxl-3">
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Valid Through*
                                                        </Label>
                                                        <Flatpickr
                                                            ref={fp}
                                                            className="form-control"
                                                            id="datepicker-publish-input"
                                                            placeholder="Select End date"
                                                            // onChange={validation.handleChange}
                                                            onChange={(e) => {

                                                                moment(setEndDate(e[0])).format('MM-DD-YYYY')

                                                            }}
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "d.m.y",
                                                            }}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Coupon Description*
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            id="placeholderInput"
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Enter description"
                                                            name="description"
                                                            value={validation.values.description || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.description &&
                                                                    validation.errors.description
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.description &&
                                                            validation.errors.description ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.description}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                            </Row>

                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/coupon" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Create </Spinner>}
                                            <span className="flex-grow-1">
                                                Create
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isCouponAdd ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isCouponAddFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default CouponCreate;