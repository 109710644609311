import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_INGREDIENTS,
  ADD_NEW_INGREDIENT,
  GET_INGREDIENT_DETAILS,
  UPDATE_INGREDIENT,
  DELETE_INGREDIENT,
  STATUS_CHANGE_INGREDIENT
} from "./actionTypes"

import {
  getIngredientsSuccess,
  getIngredientsFail,
  addNewIngredientSuccess,
  addNewIngredientFail,
  getIngredientDetailsSuccess,
  getIngredientDetailsFail,
  updateIngredientSuccess,
  updateIngredientFail,
  deleteIngredientSuccess,
  deleteIngredientFail,
  statusChangeIngredientSuccess,
  statusChangeIngredientFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getIngredientList,
  addNewIngredient,
  getIngredientDetails,
  updateIngredient,
  deleteIngredient,
  statusChangeIngredient
} from "../../helpers/fakebackend_helper"

function* fetchIngredients({ payload: ingredient }) {
  try {
    const response = yield call(getIngredientList, ingredient)
    yield put(getIngredientsSuccess(response))
  } catch (error) {
    yield put(getIngredientsFail(error))
  }
}

export function* watchFetchIngredients() {
  yield takeEvery(GET_INGREDIENTS, fetchIngredients);
}

function* onAddNewIngredient({ payload: ingredient }) {
  try {
      const response = yield call(addNewIngredient, ingredient);
      if(response.status === 200){
        yield put(addNewIngredientSuccess(response));
      } else {
        yield put(addNewIngredientFail(response));
      }
  } catch (error) {
      yield put(addNewIngredientFail(error));
  }
}

export function* watchAddNewIngredient() {
  yield takeEvery(ADD_NEW_INGREDIENT, onAddNewIngredient);
}

function* onIngredientDetails({ payload: ingredient }) {
  try {
      const response = yield call(getIngredientDetails, ingredient);
      if(response.status === 200){
        yield put(getIngredientDetailsSuccess(response));
      } else {
        yield put(getIngredientDetailsFail(response));
      }
  } catch (error) {
      yield put(getIngredientDetailsFail(error));
  }
}

export function* watchIngredientDetails() {
  yield takeEvery(GET_INGREDIENT_DETAILS, onIngredientDetails);
}

function* onIngredientUpdate({ payload: ingredient }) {
  try {
      const response = yield call(updateIngredient, ingredient);
      if(response.status === 200){
        yield put(updateIngredientSuccess(response));
      } else {
        yield put(updateIngredientFail(response));
      }
  } catch (error) {
      yield put(updateIngredientFail(error));
  }
}

export function* watchUpdateIngredient() {
  yield takeEvery(UPDATE_INGREDIENT, onIngredientUpdate);
}


//Delete Data
function* onDeleteIngredient({ payload: ingredient }) {
  try {
    const response = yield call(deleteIngredient, ingredient);
    yield put(deleteIngredientSuccess({ ingredient, ...response }));
  } catch (error) {
    yield put(deleteIngredientFail(error));
  }
}

export function* watchDeleteIngredient() {
  yield takeEvery(DELETE_INGREDIENT, onDeleteIngredient);
}



//Status Change
function* onStatusChangIngredient({ payload: ingredient }) {
  try {
    const response = yield call(statusChangeIngredient, ingredient);
    yield put(statusChangeIngredientSuccess({ ingredient, ...response }));
  } catch (error) {
    yield put(statusChangeIngredientFail(error));
  }
}

export function* watchStatusChangeIngredient() {
  yield takeEvery(STATUS_CHANGE_INGREDIENT, onStatusChangIngredient);
}



function* ingredientSaga() {
  yield all(
    [
      fork(watchFetchIngredients),
      fork(watchAddNewIngredient),
      fork(watchIngredientDetails),
      fork(watchUpdateIngredient),
      fork(watchDeleteIngredient),
      fork(watchStatusChangeIngredient)
    ]
  );

}

export default ingredientSaga
