/* COMBO */
export const GET_COMBO = "GET_COMBO";
export const GET_COMBO_SUCCESS = "GET_COMBO_SUCCESS";
export const GET_COMBO_FAIL = "GET_COMBO_FAIL";

export const ADD_NEW_COMBO = "ADD_NEW_COMBO";
export const ADD_NEW_COMBO_SUCCESS = "ADD_NEW_COMBO_SUCCESS";
export const ADD_NEW_COMBO_FAIL = "ADD_NEW_COMBO_FAIL";

export const UPDATE_COMBO = "UPDATE_COMBO";
export const UPDATE_COMBO_SUCCESS = "UPDATE_COMBO_SUCCESS";
export const UPDATE_COMBO_FAIL = "UPDATE_COMBO_FAIL";

export const GET_COMBO_DETAILS = "GET_COMBO_DETAILS";
export const GET_COMBO_DETAILS_SUCCESS = "GET_COMBO_DETAILS_SUCCESS";
export const GET_COMBO_DETAILS_FAIL = "GET_COMBO_DETAILS_FAIL";

export const DELETE_COMBO = "DELETE_COMBO";
export const DELETE_COMBO_SUCCESS = "DELETE_COMBO_SUCCESS";
export const DELETE_COMBO_FAIL = "DELETE_COMBO_FAIL";
export const MODIFY_COMBO_FLAG = "MODIFY_COMBO_FLAG";

export const RESET_COMBO_FLAG = "RESET_COMBO_FLAG";

// STATUS CHANGE
export const STATUS_CHANGE_COMBO = "STATUS_CHANGE_COMBO";
export const STATUS_CHANGE_COMBO_SUCCESS = "STATUS_CHANGE_COMBO_SUCCESS";
export const STATUS_CHANGE_COMBO_FAIL = "STATUS_CHANGE_COMBO_FAIL";
