import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_PRODUCTS,
  ADD_NEW_PRODUCT,
  GET_PRODUCT_DETAILS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  STATUS_CHANGE_PRODUCT,
} from "./actionTypes"

import {
  getProductsSuccess,
  getProductsFail,
  addNewProductSuccess,
  addNewProductFail,
  getProductDetailsSuccess,
  getProductDetailsFail,
  updateProductSuccess,
  updateProductFail,
  deleteProductSuccess,
  deleteProductFail,
  statusChangeProductSuccess,
  statusChangeProductFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProductList,
  addNewProduct,
  getProductDetails,
  updateProduct,
  delProduct,
  statusChangeProduct,
} from "../../helpers/fakebackend_helper"

function* fetchProducts({ payload: product }) {
  try {
    const response = yield call(getProductList, product)
    yield put(getProductsSuccess(response))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

export function* watchFetchProducts() {
  yield takeEvery(GET_PRODUCTS, fetchProducts);
}

function* onAddNewProduct({ payload: product }) {
  try {
      const response = yield call(addNewProduct, product);
      if(response.status === 200){
        yield put(addNewProductSuccess(response));
      } else {
        yield put(addNewProductFail(response));
      }
  } catch (error) {
      yield put(addNewProductFail(error));
  }
}

export function* watchAddNewProduct() {
  yield takeEvery(ADD_NEW_PRODUCT, onAddNewProduct);
}

function* onProductDetails({ payload: product }) {
  try {
      const response = yield call(getProductDetails, product);
      if(response.status === 200){
        yield put(getProductDetailsSuccess(response));
      } else {
        yield put(getProductDetailsFail(response));
      }
  } catch (error) {
      yield put(getProductDetailsFail(error));
  }
}

export function* watchProductDetails() {
  yield takeEvery(GET_PRODUCT_DETAILS, onProductDetails);
}

function* onProductUpdate({ payload: product }) {
  try {
      const response = yield call(updateProduct, product);
      if(response.status === 200){
        yield put(updateProductSuccess(response));
      } else {
        yield put(updateProductFail(response));
      }
  } catch (error) {
      yield put(updateProductFail(error));
  }
}

export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, onProductUpdate);
}

function* onDeleteProduct({payload : data}) {
  try {
    const response = yield call(delProduct,data)
    yield put(deleteProductSuccess(response))
  } catch (error) {
    yield put(deleteProductFail(error))
  }
}

export function* watchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct);
}
//Status Change
function* onStatusChangeProduct({ payload: product }) {
  try {
    const response = yield call(statusChangeProduct, product);
    yield put(statusChangeProductSuccess({ product, ...response }));
  } catch (error) {
    yield put(statusChangeProductFail(error));
  }
}

export function* watchStatusChangeProduct() {
  yield takeEvery(STATUS_CHANGE_PRODUCT, onStatusChangeProduct);
}

function* productSaga() {
  yield all(
    [
      fork(watchFetchProducts),
      fork(watchAddNewProduct),
      fork(watchProductDetails),
      fork(watchUpdateProduct),
      fork(watchDeleteProduct),
      fork(watchStatusChangeProduct),
    ]
  );

}

export default productSaga
