import {
  GET_TESTIMONIALS_SUCCESS,
  GET_TESTIMONIALS_FAIL,
  ADD_NEW_TESTIMONIAL_SUCCESS,
  ADD_NEW_TESTIMONIAL_FAIL,
  GET_TESTIMONIAL_DETAILS_SUCCESS,
  GET_TESTIMONIAL_DETAILS_FAIL,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_FAIL,
  RESET_TESTIMONIAL_FLAG,
  DELETE_TESTIMONIAL_SUCCESS,
  DELETE_TESTIMONIAL_FAIL,
  STATUS_CHANGE_TESTIMONIAL_SUCCESS,
  STATUS_CHANGE_TESTIMONIAL_FAIL,
  MODIFY_TESTIMONIAL_FLAG, 
} from "./actionTypes";

const INIT_STATE = {
  testimonials: []
};

const Testimonials = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        testimonials: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_TESTIMONIALS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: [...state.testimonials, action.payload.data],
        success: action.payload.message,
        isTestimonialAdd: true,
        isTestimonialAddFail: false,
      };

    case ADD_NEW_TESTIMONIAL_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isTestimonialAdd: false,
        isTestimonialAddFail: true,
      };

    case UPDATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: state.testimonials.map(testimonial =>
          testimonial._id.toString() === action.payload.data._id.toString()
            ? { ...testimonial, ...action.payload.data }
            : testimonial
        ),
        success: action.payload.message,
        isTestimonialUpdate: true,
        isTestimonialUpdateFail: false
      };

    case UPDATE_TESTIMONIAL_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isTestimonialUpdate: false,
        isTestimonialUpdateFail: true,
      };

    case GET_TESTIMONIAL_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_TESTIMONIAL_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_TESTIMONIAL_SUCCESS:
      return { 
        ...state,
        testimonials: state.testimonials.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_TESTIMONIAL_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: state.testimonials.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_TESTIMONIAL_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_TESTIMONIAL_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_TESTIMONIAL_FLAG:
      return {
        ...state,
        isTestimonialAdd: false,
        isTestimonialAddFail: false,
        isSuccess: false,
        isTestimonialDetails: false,
        isTestimonialUpdate: false,
        isTestimonialUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Testimonials;
