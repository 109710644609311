import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_HIGHLIGHTS,
  ADD_NEW_HIGHLIGHT,
  GET_HIGHLIGHT_DETAILS,
  UPDATE_HIGHLIGHT,
  DELETE_HIGHLIGHT,
  SAVE_ORDERING_HIGHLIGHT,
  STATUS_CHANGE_HIGHLIGHT,
} from "./actionTypes";

import {
  getHighlightsSuccess,
  getHighlightsFail,
  addNewHighlightSuccess,
  addNewHighlightFail,
  getHighlightDetailsSuccess,
  getHighlightDetailsFail,
  updateHighlightSuccess,
  updateHighlightFail,
  deleteHighlightSuccess,
  deleteHighlightFail,

  saveOrderingHighlightSuccess,
  saveOrderingHighlightFail,

  statusChangeHighlightSuccess,
  statusChangeHighlightFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getHighlightList,
  addNewHighlight,
  getHighlightDetails,
  updateHighlight,
  delHighlight,
  saveOrdering,
  statusChangeHighlight,
} from "../../helpers/fakebackend_helper";

function* fetchHighlights({ payload: highlight }) {
  try {
    const response = yield call(getHighlightList, highlight);
    yield put(getHighlightsSuccess(response));
  } catch (error) {
    yield put(getHighlightsFail(error));
  }
}

export function* watchFetchHighlights() {
  yield takeEvery(GET_HIGHLIGHTS, fetchHighlights);
}

function* onAddNewHighlight({ payload: highlight }) {
  try {
    const response = yield call(addNewHighlight, highlight);
    if (response.status === 200) {
      yield put(addNewHighlightSuccess(response));
    } else {
      yield put(addNewHighlightFail(response));
    }
  } catch (error) {
    yield put(addNewHighlightFail(error));
  }
}

export function* watchAddNewHighlight() {
  yield takeEvery(ADD_NEW_HIGHLIGHT, onAddNewHighlight);
}

function* onHighlightDetails({ payload: highlight }) {
  try {
    const response = yield call(getHighlightDetails, highlight);
    if (response.status === 200) {
      yield put(getHighlightDetailsSuccess(response));
    } else {
      yield put(getHighlightDetailsFail(response));
    }
  } catch (error) {
    yield put(getHighlightDetailsFail(error));
  }
}

export function* watchHighlightDetails() {
  yield takeEvery(GET_HIGHLIGHT_DETAILS, onHighlightDetails);
}

function* onHighlightUpdate({ payload: highlight }) {
  try {
    const response = yield call(updateHighlight, highlight);
    if (response.status === 200) {
      yield put(updateHighlightSuccess(response));
    } else {
      yield put(updateHighlightFail, response);
    }
  } catch (error) {
    yield put(updateHighlightFail(error));
  }
}

export function* watchUpdateHighlight() {
  yield takeEvery(UPDATE_HIGHLIGHT, onHighlightUpdate);
}

function* onDeleteHighlight({payload : data}) {
  try {
    const response = yield call(delHighlight,data)
    yield put(deleteHighlightSuccess(response))
  } catch (error) {
    yield put(deleteHighlightFail(error))
  }
}

export function* watchDeleteHighlight() {
  yield takeEvery(DELETE_HIGHLIGHT, onDeleteHighlight);
}


//Save Ordering
function* onSaveOrderingHighlight({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingHighlightSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingHighlightFail(error));
  }
}

export function* watchSaveOrderingHighlight() {
  yield takeEvery(SAVE_ORDERING_HIGHLIGHT, onSaveOrderingHighlight);
}


//Status Change
function* onStatusChangHighlight({ payload: step }) {
  try {
    const response = yield call(statusChangeHighlight, step);
    yield put(statusChangeHighlightSuccess({ step, ...response }));
  } catch (error) {
    yield put(statusChangeHighlightFail(error));
  }
}

export function* watchStatusChangeHighlight() {
  yield takeEvery(STATUS_CHANGE_HIGHLIGHT, onStatusChangHighlight);
}

function* highlightSaga() {
  yield all([
    fork(watchFetchHighlights),
    fork(watchAddNewHighlight),
    fork(watchHighlightDetails),
    fork(watchUpdateHighlight),
    fork(watchDeleteHighlight),
    fork(watchSaveOrderingHighlight),
    fork(watchStatusChangeHighlight),
  ]);
}

export default highlightSaga;
