import {

  ADD_NEW_JOB_TYPE,
  ADD_NEW_JOB_TYPE_SUCCESS,
  ADD_NEW_JOB_TYPE_FAIL,
  UPDATE_JOB_TYPE,
  UPDATE_JOB_TYPE_SUCCESS,
  UPDATE_JOB_TYPE_FAIL,
  GET_JOB_TYPE_DETAILS,
  GET_JOB_TYPE_DETAILS_SUCCESS,
  GET_JOB_TYPE_DETAILS_FAIL,
  GET_JOB_TYPE_LIST,
  GET_JOB_TYPE_LIST_SUCCESS,
  GET_JOB_TYPE_LIST_FAIL,
  DELETE_JOB_TYPE,
  DELETE_JOB_TYPE_SUCCESS,
  DELETE_JOB_TYPE_FAIL,
  MODIFY_JOB_TYPE_FLAG,
  RESET_JOB_TYPE_FLAG,
  STATUS_CHANGE_JOBTYPE,
  STATUS_CHANGE_JOBTYPE_SUCCESS,
  STATUS_CHANGE_JOBTYPE_FAIL
} from "./actionTypes";



export const addNewJobType = jobtype=> ({
  type: ADD_NEW_JOB_TYPE,
  payload: jobtype,
});

export const addNewJobTypeSuccess = jobtype => ({
  type: ADD_NEW_JOB_TYPE_SUCCESS,
  payload: jobtype,
});

export const addNewJobTypeFail = error => ({
  type: ADD_NEW_JOB_TYPE_FAIL,
  payload: error,
});

export const updateJobType = jobtype => ({
  type: UPDATE_JOB_TYPE,
  payload: jobtype,
});

export const updateJobTypeSuccess = jobtype => ({
  type: UPDATE_JOB_TYPE_SUCCESS,
  payload: jobtype,
});

export const updateJobTypeFail = error => ({
  type: UPDATE_JOB_TYPE_FAIL,
  payload: error,
});


export const getJobTypeDetails = jobtype => ({
  type: GET_JOB_TYPE_DETAILS,
  payload: jobtype,
});

export const getJobTypeDetailsSuccess = jobtype => ({
  type: GET_JOB_TYPE_DETAILS_SUCCESS,
  payload: jobtype,
});

export const getJobTypeDetailsFail = error => ({
  type: GET_JOB_TYPE_DETAILS_FAIL,
  payload: error,
});


// Job Type List
export const getJobTypeList = data => ({
  type: GET_JOB_TYPE_LIST,
  payload :data
});

export const getJobTypeListSuccess = jobtype => ({
  type: GET_JOB_TYPE_LIST_SUCCESS,
  payload: jobtype,
});

export const getJobTypeListFail = error => ({
  type: GET_JOB_TYPE_LIST_FAIL,
  payload: error,
});

export const delJobType = data => ({
  type: DELETE_JOB_TYPE,
  payload: data,
});

export const deleteJobTypeSuccess = data => ({
  type: DELETE_JOB_TYPE_SUCCESS,
  payload: data,
});

export const deleteJobTypeFail = data => ({
  type: DELETE_JOB_TYPE_FAIL,
  payload: data,
});
export const modifyJobTypeFlag = () => {
  return {
    type: MODIFY_JOB_TYPE_FLAG,
  }
}


export const resetJobTypeFlag = () => {
  return {
    type: RESET_JOB_TYPE_FLAG,
  }
}

// Status Change
export const statusChangeJobType = jobtype => ({
  type: STATUS_CHANGE_JOBTYPE,
  payload: jobtype,
});

export const statusChangeJobTypeSuccess = jobtype => ({
  type: STATUS_CHANGE_JOBTYPE_SUCCESS,
  payload: jobtype,
});

export const statusChangeJobTypeFail = error => ({
  type: STATUS_CHANGE_JOBTYPE_FAIL,
  payload: error,
});