/* SPICES */
export const GET_SPICES = "GET_SPICES";
export const GET_SPICES_SUCCESS = "GET_SPICES_SUCCESS";
export const GET_SPICES_FAIL = "GET_SPICES_FAIL";

export const ADD_NEW_SPICE = "ADD_NEW_SPICE";
export const ADD_NEW_SPICE_SUCCESS = "ADD_NEW_SPICE_SUCCESS";
export const ADD_NEW_SPICE_FAIL = "ADD_NEW_SPICE_FAIL";

export const UPDATE_SPICE = "UPDATE_SPICE";
export const UPDATE_SPICE_SUCCESS = "UPDATE_SPICE_SUCCESS";
export const UPDATE_SPICE_FAIL = "UPDATE_SPICE_FAIL";

export const GET_SPICE_DETAILS = "GET_SPICE_DETAILS";
export const GET_SPICE_DETAILS_SUCCESS = "GET_SPICE_DETAILS_SUCCESS";
export const GET_SPICE_DETAILS_FAIL = "GET_SPICE_DETAILS_FAIL";

export const RESET_SPICE_FLAG = "RESET_SPICE_FLAG";

export const DELETE_SPICE = "DELETE_SPICE";
export const DELETE_SPICE_SUCCESS = "DELETE_SPICE_SUCCESS";
export const DELETE_SPICE_FAIL = "DELETE_SPICE_FAIL";

export const STATUS_CHANGE_SPICE = "STATUS_CHANGE_SPICE";
export const STATUS_CHANGE_SPICE_SUCCESS = "STATUS_CHANGE_SPICE_SUCCESS";
export const STATUS_CHANGE_SPICE_FAIL = "STATUS_CHANGE_SPICE_FAIL";

export const SAVE_ORDERING_SPICE = "SAVE_ORDERING_SPICE";
export const SAVE_ORDERING_SPICE_SUCCESS = "SAVE_ORDERING_SPICE_SUCCESS";
export const SAVE_ORDERING_SPICE_FAIL = "SAVE_ORDERING_SPICE_FAIL";
export const SAVE_ORDERING_SPICE_RESET_FLAG = "SAVE_ORDERING_SPICE_RESET_FLAG";

export const MODIFY_SPICE_FLAG = "MODIFY_SPICE_FLAG";