module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    //API_URL: "https://api-node.themesbrand.website", 
    API_URL: "https://dabbawalaapi.iosx.in:3091/api/"
  }
};