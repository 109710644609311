import React from "react";
import { Redirect } from "react-router-dom";
//Site Setting Route
import SiteEdit from "../pages/Sites/SiteEdit";
//Jobs
import JobList from "../pages/Jobs/JobList";
import JobCreate from "../pages/Jobs/JobCreate";
import JobEdit from "../pages/Jobs/JobEdit";

//Jobapplies
import JobapplyList from "../pages/Jobapplies/JobapplyList";
import JobapplyCreate from "../pages/Jobapplies/JobapplyCreate";
import JobapplyEdit from "../pages/Jobapplies/JobapplyEdit";

//user
import UserCreate from "../pages/Users/UserCreate";
import UserEdit from "../pages/Users/UserEdit";
import UserList from "../pages/Users/UserList";
//admin
import AdminCreate from "../pages/Admins/AdminCreate";
import AdminList from "../pages/Admins/AdminList";
import AdminEdit from "../pages/Admins/AdminEdit";

//Categories
import Categories from "../pages/Categories/List";
import AddCategory from "../pages/Categories/Add";
import EditCategory from "../pages/Categories/Edit";

//Product-Categories
import ProductCategories from "../pages/ProductCategories/list";
import AddProductCategory from "../pages/ProductCategories/add";
import EditProductCategory from "../pages/ProductCategories/edit";

//Products
import ProductList from "../pages/Products/ProductList";
import ProductCreate from "../pages/Products/ProductCreate";
import ProductEdit from "../pages/Products/ProductEdit";

//Job Types
import JobTypeList from "../pages/JobTypes/JobTypeList";
import JobTypeCreate from "../pages/JobTypes/JobTypeCreate";
import JobTypeEdit from "../pages/JobTypes/JobTypeEdit";

//Blogs
import Blogs from "../pages/Blogs/BlogList";
import AddBlog from "../pages/Blogs/BlogCreate";
import EditBlog from "../pages/Blogs/BlogEdit";

// BlogsAuthors
import AuthorList from "../pages/Author/List";
import AuthorCreate from "../pages/Author/Create";

// Points
import Points from "../pages/Point/PointList";

//Blog-Comments
import BlogComments from "../pages/Blogs/BlogCommentList";

//Job Location Types
import JobLocationTypeList from "../pages/JobLocationTypes/JobLocationTypeList";
import JobLocationTypeCreate from "../pages/JobLocationTypes/JobLocationTypeCreate";
import JobLocationTypeEdit from "../pages/JobLocationTypes/JobLocationTypeEdit";

//Pages
import PageList from "../pages/MyPages/PageList";
import PageCreate from "../pages/MyPages/PageCreate";
import PageEdit from "../pages/MyPages/PageEdit";

// Banner
import BannerList from "../pages/Banners/BannerList";
import BannerCreate from "../pages/Banners/BannerCreate";
import BannerEdit from "../pages/Banners/BannerEdit";

//SEO
import SeoList from "../pages/Seos/SeoList";
import SeoCreate from "../pages/Seos/SeoCreate";
import SeoEdit from "../pages/Seos/SeoEdit";

//Faqs
import FaqList from "../pages/Faqs/FaqList";
import FaqCreate from "../pages/Faqs/FaqCreate";
import FaqEdit from "../pages/Faqs/FaqEdit";

//Faq Feedback
import FaqFeedbackList from "../pages/Faqs/FaqFeedbackList";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

import DashboardEcommerce from "../pages/DashboardEcommerce";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

// Step
import StepList from "../pages/Steps/StepList";
import StepCreate from "../pages/Steps/StepCreate";
import StepEdit from "../pages/Steps/StepEdit";

// Benefit
import BenefitList from "../pages/Benefits/BenefitList";
import BenefitCreate from "../pages/Benefits/BenefitCreate";
import BenefitEdit from "../pages/Benefits/BenefitEdit";

// Ingredient
import IngredientList from "../pages/Ingredients/IngredientList";
import IngredientCreate from "../pages/Ingredients/IngredientCreate";
import IngredientEdit from "../pages/Ingredients/IngredientEdit";

// Spices
import SpiceList from "../pages/Spices/SpiceList";
import SpiceCreate from "../pages/Spices/SpiceCreate";
import SpiceEdit from "../pages/Spices/SpiceEdit";

// Testimonial
import TestimonialList from "../pages/Testimonials/TestimonialList";
import TestimonialCreate from "../pages/Testimonials/TestimonialCreate";
import TestimonialEdit from "../pages/Testimonials/TestimonialEdit";

// Funfact
import FunfactList from "../pages/Funfacts/FunfactList";
import FunfactCreate from "../pages/Funfacts/FunfactCreate";
import FunfactEdit from "../pages/Funfacts/FunfactEdit";

// Service
import ServiceList from "../pages/Services/ServiceList";
import ServiceCreate from "../pages/Services/ServiceCreate";
import ServiceEdit from "../pages/Services/ServiceEdit";

// Team
import TeamList from "../pages/Teams/TeamList";
import TeamCreate from "../pages/Teams/TeamCreate";
import TeamEdit from "../pages/Teams/TeamEdit";

// Mission
import MissionList from "../pages/Missions/MissionList";
import MissionCreate from "../pages/Missions/MissionCreate";
import MissionEdit from "../pages/Missions/MissionEdit";

// Vision
import VisionList from "../pages/Visions/VisionList";
import VisionCreate from "../pages/Visions/VisionCreate";
import VisionEdit from "../pages/Visions/VisionEdit";

//highlight
import HighlightCreate from "../pages/Highlights/HighlightCreate";
import HighlightEdit from "../pages/Highlights/HighlightEdit";
import HighlightList from "../pages/Highlights/HighlightList";


// Future Team
import FutureteamList from "../pages/FutureTeam/FutureteamList";
import FutureteamCreate from "../pages/FutureTeam/FutureteamCreate";
import FutureteamEdit from "../pages/FutureTeam/FutureteamEdit";


// Opening Hour
import OpeninghourList from "../pages/OpeningHour/OpeninghourList";
import OpeninghourCreate from "../pages/OpeningHour/OpeninghourCreate";
import OpeninghourEdit from "../pages/OpeningHour/OpeninghourEdit";

// Allergie
import AllergieList from "../pages/Allergies/AllergieList";
import AllergieCreate from "../pages/Allergies/AllergieCreate";
import AllergieEdit from "../pages/Allergies/AllergieEdit";

// Combo
import ComboList from "../pages/Combos/ComboList";
import ComboCreate from "../pages/Combos/ComboCreate";
import ComboEdit from "../pages/Combos/ComboEdit";

// Diet
import DietList from "../pages/Diets/DietList";
import DietCreate from "../pages/Diets/DietCreate";
import DietEdit from "../pages/Diets/DietEdit";


// Organization
import OrganizationList from "../pages/Organisation/List";
import OrganizationCreate from "../pages/Organisation/Create";
import OrganizationEdit from "../pages/Organisation/Edit";
import OrganizationDetailsList from "../pages/Organisation/DetailsList";

// Coupon
import CouponList from "../pages/Coupon/List";
import CouponCreate from "../pages/Coupon/Create";
import CouponEdit from "../pages/Coupon/Edit";

// Order
import OrderList from "../pages/Orders/OrderList";
import OrderCreate from "../pages/Orders/OrderCreate";
import OrderDetails from "../pages/Orders/OrderDetails";

// Pin
import PinList from "../pages/Pins/PinList";
import PinCreate from "../pages/Pins/PinCreate";
import PinEdit from "../pages/Pins/PinEdit";

// Take of Time
import TakeofTimeList from "../pages/TakeofTime/List";
import TakeofTimeCreate from "../pages/TakeofTime/Create";
import TakeofTimeUpdate from "../pages/TakeofTime/Edit"

import AddonGroupList from "../pages/Addon/AddonGroupList"
import LiveOrder from "../pages/Orders/LiveOrder";

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardEcommerce },
  //Users
  { path: "/user/create", component: UserCreate },
  { path: "/users", component: UserList },
  { path: "/user/edit/:id", component: UserEdit },

  //Admins
  { path: "/admin/create", component: AdminCreate },
  { path: "/admin/edit/:id", component: AdminEdit },
  { path: "/admins", component: AdminList },

  //export * from "./categories/actions";
  { path: "/video-categories", component: Categories },
  { path: "/blog-categories", component: Categories },
  { path: "/faq-categories", component: Categories },
  { path: "/category/create", component: AddCategory },
  { path: "/category/edit/:id", component: EditCategory },

  // Blogs

  { path: "/blogs", component: Blogs },
  { path: "/blog/create", component: AddBlog },
  { path: "/blog/edit/:alias", component: EditBlog },
  { path: "/blog-comments/", component: BlogComments },
  { path: "/blog-comments/:id", component: BlogComments },

  // BlogsAuthos
  { path: "/authors", component: AuthorList},
  { path: "/author/create", component: AuthorCreate},

  // Point
  { path: "/point", component: Points},
 
  //Product-Section

  //Product-Categories

  { path: "/product-categories", component: ProductCategories },
  { path: "/productcategory/create", component: AddProductCategory },
  { path: "/productcategory/edit/:id", component: EditProductCategory },

  
  //Product-Categories

  { path: "/products/:type", component: ProductList },
  { path: "/product/create/:type", component: ProductCreate },
  { path: "/product/edit/:id", component: ProductEdit },

  //Combo
  { path: "/combos", component: ComboList },
  { path: "/combo/create", component: ComboCreate },
  { path: "/combo/edit/:id", component: ComboEdit },


  // Job Types

  { path: "/jobtypes", component: JobTypeList },
  { path: "/jobtype/create", component: JobTypeCreate },
  { path: "/jobtype/edit/:id", component: JobTypeEdit },

  // Job Location Types

  { path: "/joblocationtypes", component: JobLocationTypeList },
  { path: "/joblocationtype/create", component: JobLocationTypeCreate },
  { path: "/joblocationtype/edit/:id", component: JobLocationTypeEdit },

  // Jobs

  { path: "/jobs", component: JobList },
  { path: "/job/create", component: JobCreate },
  { path: "/job/edit/:id", component: JobEdit },

  // Jobappliess

  { path: "/jobapplies", component: JobapplyList },
  { path: "/jobapply/create", component: JobapplyCreate },
  { path: "/jobapply/edit/:id", component: JobapplyEdit },

  //Banner
  { path: "/banners", component: BannerList },
  { path: "/banner/create", component: BannerCreate },
  { path: "/banner/:type/:id", component: BannerEdit },

  //Step
  { path: "/steps", component: StepList },
  { path: "/step/create", component: StepCreate },
  { path: "/step/edit/:id", component: StepEdit },

    //Spice
    { path: "/spices", component: SpiceList },
    { path: "/spice/create", component: SpiceCreate },
    { path: "/spice/edit/:id", component: SpiceEdit },

  //Service
  { path: "/services", component: ServiceList },
  { path: "/service/create", component: ServiceCreate },
  { path: "/service/edit/:id", component: ServiceEdit },

  //Team
  { path: "/teams", component: TeamList },
  { path: "/team/create", component: TeamCreate },
  { path: "/team/edit/:id", component: TeamEdit },

  //Mission
  { path: "/missions", component: MissionList },
  { path: "/mission/create", component: MissionCreate },
  { path: "/mission/edit/:id", component: MissionEdit },

  //Vision
  { path: "/visions", component: VisionList },
  { path: "/vision/create", component: VisionCreate },
  { path: "/vision/edit/:id", component: VisionEdit },

  //Benefit
  { path: "/benefits", component: BenefitList },
  { path: "/benefit/create", component: BenefitCreate },
  { path: "/benefit/edit/:id", component: BenefitEdit },

  //Ingredient
  { path: "/ingredients", component: IngredientList },
  { path: "/ingredient/create", component: IngredientCreate },
  { path: "/ingredient/edit/:id", component: IngredientEdit },

  //Funfact
  { path: "/funfacts", component: FunfactList },
  { path: "/funfact/create", component: FunfactCreate },
  { path: "/funfact/edit/:id", component: FunfactEdit },

  //Testimonial
  { path: "/testimonials", component: TestimonialList },
  { path: "/testimonial/create", component: TestimonialCreate },
  { path: "/testimonial/edit/:id", component: TestimonialEdit },

  // Sites

  { path: "/site/edit/:id", component: SiteEdit },

  // Pages

  { path: "/pages", component: PageList },
  { path: "/page/create", component: PageCreate },
  { path: "/page/edit/:id", component: PageEdit },

  // SEO

  { path: "/seos", component: SeoList },
  { path: "/seo/create", component: SeoCreate },
  { path: "/seo/:type/:id", component: SeoEdit },

  // Faqs

  { path: "/faqs", component: FaqList },
  { path: "/faq/create", component: FaqCreate },
  { path: "/faq/edit/:id", component: FaqEdit },

  // Faqs Feedback
  { path: "/faq-feedback", component: FaqFeedbackList },

  

  //Highlight
  { path: "/highlights", component: HighlightList },
  { path: "/highlight/create", component: HighlightCreate },
  { path: "/highlight/edit/:id", component: HighlightEdit },


  //Future Team
  { path: "/future-team", component: FutureteamList },
  { path: "/future-team/create", component: FutureteamCreate },
  { path: "/future-team/edit/:id", component: FutureteamEdit },


  // Opening Hour
  { path: "/opening-hours", component: OpeninghourList },
  { path: "/opening-hour/create", component: OpeninghourCreate },
  { path: "/opening-hour/edit/:id", component: OpeninghourEdit },

  //Allergie
  { path: "/allergies", component: AllergieList },
  { path: "/allergie/create", component: AllergieCreate },
  { path: "/allergie/edit/:id", component: AllergieEdit },

    //dIET
    { path: "/diets", component: DietList },
    { path: "/diet/create", component: DietCreate },
    { path: "/diet/edit/:id", component: DietEdit },
  
  // Organization
  { path: "/organization", component: OrganizationList},
  { path:"/organization/create", component: OrganizationCreate},
  { path: "/organization/edit/:id", component: OrganizationEdit},
  { path: "/organization/details/:id", component: OrganizationDetailsList},

  // Coupon
  { path: "/coupon", component: CouponList},
  { path:"/coupon/create", component: CouponCreate},
  { path: "/coupon/edit/:id", component: CouponEdit},

  //Order
  { path: "/orders", component: OrderList },
  { path: "/order/create", component: OrderCreate },
  { path: "/order/details/:id", component: OrderDetails },
  { path: "/live-orders", component: LiveOrder },

   //Step
   { path: "/zips", component: PinList },
   { path: "/zip/create", component: PinCreate },
   { path: "/zip/edit/:id", component: PinEdit },

   // Take of Time
  { path: "/takeoftime", component: TakeofTimeList },
  { path: "/takeoftime/create", component: TakeofTimeCreate },
  { path: "/takeoftime/edit/:id", component: TakeofTimeUpdate},

  { path: "/addon-group", component: AddonGroupList },
  
  //User Profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
