import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  Modal, ModalHeader, ModalBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import MsgToast from "../../../Components/Common/MsgToast";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getFaqs,
  delFaq,
  resetFaqFlag,
  modifyFaqFlag,
  statusChangeFaq,
  getFaqFeedback,
} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";

const FaqList = () => {
  document.title = "Faq list | Dabbawala";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setsortByField] = useState("ordering");
  const [ sortByValue, setSortByValue] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const {
    faqs,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
    faqFeedback,
  } = useSelector((state) => ({
    faqs: state.Faqs.faqs,
    totalDataCount: state.Faqs.totalDataCount,
    isSuccess: state.Faqs.isSuccess,
    success: state.Faqs.success,
    error: state.Faqs.error,
    statusSuccess: state.Faqs.statusSuccess,
    statusFailed: state.Faqs.statusFailed,
    faqFeedback: state.Faqs.faqFeedback,
  }));

  useEffect(() => {
    dispatch(
      getFaqFeedback({
        keyword,
        date,
        sortByField,
        sortByValue,
        limit,
        offset,
        feedback : status,
      })
    )
    
  }, [dispatch, keyword, date, sortByField, sortByValue,limit, offset, status]);


  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    setKeyword("");
    setStatus("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setsortByField(fieldName);
  };


  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyFaqFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);
 

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Faq Feedback" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Faq Feedback List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    {/* <Link
                      to="/faq/create"
                      className="btn btn-success add-btn"
                      id="createFaqTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Faq
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createFaqTooltip"
                    >
                      Create Faq
                    </UncontrolledTooltip> */}
                    {/* <Button color="danger" id="deleteTooltip">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                      Delete
                    </UncontrolledTooltip> */}
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(+e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>

                      {/* <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col> */}

                      {/* <Col sm={3} className="col-xxl-2">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col> */}

                      {/* <Col sm={2} className="col-xxl-2">
                        <div>
                            <select className="form-select" data-choices data-choices-search-false
                            id="choices-privacy-status-input"
                            value={status}
                            onChange={(e) => {
                              const value =
                                e.target.value === "true"
                                  ? 'true'
                                  : e.target.value === "false"
                                  ? 'false'
                                  : "";
                              setStatus(value);
                            }}>
                                <option value="">Select Helpful</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                      </Col> */}

                      <Col sm={1} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {faqFeedback?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>Question</th>
                                <th>IP Address</th>
                                <th>User </th>
                                <th>Helpful</th>
                                <th>Created Date</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                            {faqFeedback.map((item) => {
                              // function createMarkup(data) {
                              //   return {__html: data};
                              // }
                              return (
                                <>
                                  <tr key={item._id}>
                                    <td><div dangerouslySetInnerHTML={{__html: item?.faq_details?.question.substring(0,45)}} /></td>
                                    <td>{item?.user_ip}</td>
                                    <td>
                                      {item?.user_id ? (
                                        <>
                                          <div>{item?.user_details?.name}</div>
                                          <div>{item?.user_details?.email}</div>
                                        </>
                                      ):('Guest user')}
                                    </td>
                                    <td>
                                      {(item.feedback === 'true') ? (
                                        <span className="badge badge-soft-success text-uppercase">
                                          Yes
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-danger text-uppercase">
                                          No
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <Moment format="D MMM YYYY">
                                        {item.created_at}
                                      </Moment>
                                    </td>
                                    
                                  </tr>
                                </>
                              )
                             })}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </>
  );
};

export default FaqList;
