import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";


//Categories
import Categories from "./categories/reducer";


//Blog
import Blogs from "./blogs/reducer";
//Job
import Jobs from "./jobs/reducer";


import Users from "./users/reducer";
//Job Type
import JobTypes from "./jobtypes/reducer";
//Job  Location Type
import JobLocationTypes from "./joblocationtypes/reducer";

//Job-Apply
import Jobapplies from "./jobapplies/reducer";

//Banner
import Banners from "./banner/reducer";

//Site
import Sites from "./sites/reducer";

 //Page
 import Pages from "./page/reducer";

  //SEO
  import Seos from "./seo/reducer";

//Faq
import Faqs from "./faqs/reducer";

//Step
import Steps from "./step/reducer";

//Pin
import Pins from "./pin/reducer";

//Funfact
import Funfacts from "./funfact/reducer";

//Testimonial
import Testimonials from "./testimonial/reducer";

//Service
import Services from "./service/reducer";

//Team
import Teams from "./team/reducer";

//Mission
import Missions from "./mission/reducer";

//Vision
import Visions from "./vision/reducer";

//Highlight
import Highlights from "./highlights/reducer";

//Benefit
import Benefits from "./benefit/reducer";

//Ingredient
import Ingredients from "./ingredient/reducer";

//Allergie
import Allergies from "./allergie/reducer";

//Diet
import Diets from "./diet/reducer";

// Organisation
import Organisations from "./Organisation/reducer";

// Coupon
import Coupons from "./coupon/reducer";

// Author
import Authors from "./author/reducer";

//Order
import Orders from "./order/reducer";

// Points
import Points from "./point/reducer";

//Step
import Spices from "./spice/reducer";

//Productcategories
import Productcategories from "./productcategories/reducer";

//Productcategories
import Products from "./products/reducer";

//Future Team
import FutureTeam from "./futureteam/reducer";

//Opening Hour
import OpeningHour from "./openinghour/reducer";

//Combo product
import Combos from "./combos/reducer";

// TakeofTime
import takeofTime from "./takeofTime/reducer";

// Notification
import SocketNotification from "./notification/reducer";



const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    Blogs,
    Categories,
    Jobs,
    JobTypes,
    JobLocationTypes,
    Jobapplies,
    Banners,
    Teams,
    Missions,
    Visions,
    Sites,
    Pages,
    Seos,
    Faqs,
    Steps,
    Pins,
    Spices,
    Funfacts,
    Testimonials,
    Services,
    Benefits,
    Ingredients,
    Allergies,
    Diets,
    Authors,
    Orders,
    Points,
    Organisations,
    Coupons,
    Users,
    Highlights,
    Productcategories,
    Products,
    FutureTeam,
    OpeningHour,
    Combos,
    takeofTime,
    SocketNotification,
});

export default rootReducer;