import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";

import {
  updateSite,
  resetSiteFlag,
  getSiteDetails,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MyUploadAdapter from "../../../helpers/MyUploadAdapter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";

const SiteEdit = () => {
  document.title = "Edit Site | Dabbawala";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [description, setData] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [logo, setLogo] = useState();
  const { isSiteUpdate, isSiteUpdateFail, error, success, details } =
    useSelector((state) => ({
      isSiteUpdate: state.Sites.isSiteUpdate,
      isSiteUpdateFail: state.Sites.isSiteUpdateFail,
      error: state.Sites.error,
      success: state.Sites.success,
      details: state.Sites.details,
    }));


    // const urlField = [
    //   {field: 'instagram_link', message:'Instagram link not valid.'},
    //   {field: 'tiktok_link', message:'Tiktok link not valid.'},
    //   {field: 'snapchat_link', message:'Snapchat link not valid.'},
    // ]


    // const urlPatternValidation = (values, elsement) => {
    //   const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    //   let flag = regex.test(values[elsement.field]);
    //   if(flag === false){
    //     document.getElementById(elsement.field).innerHTML = elsement.message;
    //   }
    //   return flag;
    // };



    useEffect(() => {
      if (isSiteUpdate || isSiteUpdateFail) {
        setIsCreating(false);
        setTimeout(() => {
          dispatch(resetSiteFlag());
        }, 3000);
      }
    }, [dispatch, isSiteUpdate, isSiteUpdateFail]);
  
    useEffect(() => {
      dispatch(getSiteDetails({ id: id }));
    }, [dispatch, id]);


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: details && details.title ? details.title : "",
      logo: details && details.logo ? details.logo : "",
      description: details && details.description ? details.description : "",
      email: details && details.email ? details.email : "",
      phone: details && details.phone ? details.phone : "",
      wa_number: details && details.whatsapp_number ? details.whatsapp_number : "",
      address: details && details.address ? details.address : "",
      copyright: details && details.copyright ? details.copyright : "",
      facebook_link:
        details && details.facebook_link ? details.facebook_link : "",
      twitter_link: details && details.twitter_link ? details.twitter_link : "",
      linkedin_link:
        details && details.linkedin_link ? details.linkedin_link : "",
      instagram_link:
        details && details.instagram_link ? details.instagram_link : "",
      snapchat_link:
        details && details.snapchat_link ? details.snapchat_link : "",
      tiktok_link:
        details && details.tiktok_link ? details.tiktok_link : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title Name"),
      //logo: Yup.string().required("Please Enter Logo"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone Number"),
      //wa_number: Yup.string().required("Please Enter WhatsApp Number"),
      //description: Yup.string().required("Please Enter Description"),
      address: Yup.string().required("Please Enter Address"),
      copyright: Yup.string().required("Please Enter Copyright"),
      facebook_link: Yup.string().required("Please Enter Facebook Link"),
      twitter_link: Yup.string().required("Please Enter Twitter Link"),
      linkedin_link: Yup.string().required("Please Enter LinkedIn Link"),
      instagram_link: Yup.string().required("Please Enter Instagram Link"),
      snapchat_link: Yup.string().required("Please Enter Snapchat Link"),
      tiktok_link: Yup.string().required("Please Enter TikTok Link"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        phone: values.phone,
        whatsapp_number: values.wa_number,
        logo: logo,
        description: description,
        email: values.email,
        address: values.address,
        copyright: values.copyright,
        facebook_link: values.facebook_link,
        twitter_link: values.twitter_link,
        linkedin_link: values.linkedin_link,
        instagram_link: values.instagram_link,
        snapchat_link: values.snapchat_link,
        tiktok_link: values.tiktok_link,
      };
      dispatch(updateSite(data));


      // var validationArray = [];
      // urlField.forEach(elsement => {
      //   validationArray.push(urlPatternValidation(values, elsement));
      // });
      // const finalValidation = validationArray.includes(false);

      // if(finalValidation === false){
      //   console.log(data); 
      // }
      
      //console.log(data); 
    },
  });



  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onLogoChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setLogo);
      }
    }
  };


  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Site" pageTitle="Sites" pageUrl="/sites" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Site" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Site Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Email *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Email ID"
                              name="email"
                              value={validation.values.email || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Phone No *
                            </Label>
                            <Input
                              type="tel"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Phone Number"
                              name="phone"
                              value={validation.values.phone || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.phone &&
                                validation.errors.phone
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phone &&
                            validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Whatsapp No
                            </Label>
                            <Input
                              type="tel"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Whatsapp Number"
                              name="wa_number"
                              value={validation.values.wa_number || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.wa_number &&
                                validation.errors.wa_number
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Address *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Address"
                              name="address"
                              value={validation.values.address || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.address &&
                                validation.errors.address
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.address &&
                            validation.errors.address ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Copyright *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Copyright"
                              name="copyright"
                              value={validation.values.copyright || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.copyright &&
                                validation.errors.copyright
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.copyright &&
                            validation.errors.copyright ? (
                              <FormFeedback type="invalid">
                                {validation.errors.copyright}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Facebook Link *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Facebook Link"
                              name="facebook_link"
                              value={validation.values.facebook_link || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.facebook_link &&
                                validation.errors.facebook_link
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.facebook_link &&
                            validation.errors.facebook_link ? (
                              <FormFeedback type="invalid">
                                {validation.errors.facebook_link}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Twitter Link *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Twitter Link"
                              name="twitter_link"
                              value={validation.values.twitter_link || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.twitter_link &&
                                validation.errors.twitter_link
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.twitter_link &&
                            validation.errors.twitter_link ? (
                              <FormFeedback type="invalid">
                                {validation.errors.twitter_link}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              LinkedIn Link *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="LinkedIn Link"
                              name="linkedin_link"
                              value={validation.values.linkedin_link || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.linkedin_link &&
                                validation.errors.linkedin_link
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.linkedin_link &&
                            validation.errors.linkedin_link ? (
                              <FormFeedback type="invalid">
                                {validation.errors.linkedin_link}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Instagram Link *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="LinkedIn Link"
                              name="instagram_link"
                              value={validation.values.instagram_link || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.instagram_link &&
                                validation.errors.instagram_link
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.instagram_link &&
                            validation.errors.instagram_link ? (
                              <FormFeedback type="invalid">
                                {validation.errors.instagram_link}
                              </FormFeedback>
                            ) : null}
                            <div id="instagram_link" className="invalid-feedback"></div>
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Tiktok Link *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="LinkedIn Link"
                              name="tiktok_link"
                              value={validation.values.tiktok_link || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.tiktok_link &&
                                validation.errors.tiktok_link
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.tiktok_link &&
                            validation.errors.tiktok_link ? (
                              <FormFeedback type="invalid">
                                {validation.errors.tiktok_link}
                              </FormFeedback>
                            ) : null}
                            <div id="tiktok_link" className="invalid-feedback"></div>
                          </div>
                        </Col>


                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Snapchat Link *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="LinkedIn Link"
                              name="snapchat_link"
                              value={validation.values.snapchat_link || ""}
                              onChange={validation.handleChange}

                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.snapchat_link &&
                                validation.errors.snapchat_link
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.snapchat_link &&
                            validation.errors.snapchat_link ? (
                              <FormFeedback type="invalid">
                                {validation.errors.snapchat_link}
                              </FormFeedback>
                            ) : null}
                            <div id="snapchat_link" className="invalid-feedback"></div>
                          </div>
                        </Col>







                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description
                            </Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={validation.values.description || ""}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
                            new MyUploadAdapter(loader);
                        }}
                        config={{
                          toolbar: [
                            "selectAll",
                            "undo",
                            "redo",
                            "bold",
                            "italic",
                            "blockQuote",
                            "imageTextAlternative",
                            "imageUpload",
                            "heading",
                            "imageStyle:full",
                            "imageStyle:side",
                            "indent",
                            "outdent",
                            "link",
                            "numberedList",
                            "bulletedList",
                            "mediaEmbed",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                            "fontBackgroundColor",
                            "fontColor"
                          ],
                          image: {
                            // Configure the available styles.
                            styles: ["alignLeft", "alignCenter", "alignRight"],
                            sizes: ["50%", "75%", "100%"],

                            // Configure the available image resize options.
                            resizeOptions: [
                              {
                                name: "imageResize:original",
                                label: "Original",
                                value: null
                              },
                              {
                                name: "imageResize:50",
                                label: "50%",
                                value: "50"
                              },
                              {
                                name: "imageResize:75",
                                label: "75%",
                                value: "75"
                              }
                            ],

                            // You need to configure the image toolbar, too, so it shows the new style
                            // buttons as well as the resize buttons.
                            toolbar: [
                              "imageStyle:alignLeft",
                              "imageStyle:alignCenter",
                              "imageStyle:alignRight",
                              
                              "resizeImage",
                              "imageResize",
                             
                              "imageTextAlternative"
                            ]
                          }
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setData(data);
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                          console.log(Array.from(editor.ui.componentFactory.names()));
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Logo
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your logo"
                              name="logo"
                              onChange={onLogoChange}
                            />
                            <br />
                            <img
                              src={validation.values.logo || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.logo &&
                            validation.errors.logo ? (
                              <FormFeedback type="invalid">
                                {validation.errors.logo}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  {/* <Link to="/jobs" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link> */}
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isSiteUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isSiteUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default SiteEdit;
