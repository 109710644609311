/* JOBAPPLIES */
export const GET_JOBAPPLIES = "GET_JOBAPPLIES";
export const GET_JOBAPPLIES_SUCCESS = "GET_JOBAPPLIES_SUCCESS";
export const GET_JOBAPPLIES_FAIL = "GET_JOBAPPLIES_FAIL";

export const ADD_NEW_JOBAPPLY = "ADD_NEW_JOBAPPLY";
export const ADD_NEW_JOBAPPLY_SUCCESS = "ADD_NEW_JOBAPPLY_SUCCESS";
export const ADD_NEW_JOBAPPLY_FAIL = "ADD_NEW_JOBAPPLY_FAIL";

export const UPDATE_JOBAPPLY = "UPDATE_JOBAPPLY";
export const UPDATE_JOBAPPLY_SUCCESS = "UPDATE_JOBAPPLY_SUCCESS";
export const UPDATE_JOBAPPLY_FAIL = "UPDATE_JOBAPPLY_FAIL";

export const DELETE_JOBAPPLY = "DELETE_JOBAPPLY";
export const DELETE_JOBAPPLY_SUCCESS = "DELETE_JOBAPPLY_SUCCESS";
export const DELETE_JOBAPPLY_FAIL = "DELETE_JOBAPPLY_FAIL";

// export const GET_JOBAPPLY_DETAILS = "GET_JOBAPPLY_DETAILS";
// export const GET_JOBAPPLY_DETAILS_SUCCESS = "GET_JOBAPPLY_DETAILS_SUCCESS";
// export const GET_JOBAPPLY_DETAILS_FAIL = "GET_JOBAPPLY_DETAILS_FAIL";



export const RESET_JOBAPPLY_FLAG = "RESET_JOBAPPLY_FLAG";
