import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  ADD_NEW_PRODUCT,
  ADD_NEW_PRODUCT_SUCCESS,
  ADD_NEW_PRODUCT_FAIL,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  MODIFY_PRODUCT_FLAG,
  RESET_PRODUCT_FLAG,
  STATUS_CHANGE_PRODUCT,
  STATUS_CHANGE_PRODUCT_SUCCESS,
  STATUS_CHANGE_PRODUCT_FAIL
} from "./actionTypes";

export const getProducts = data => ({
  type: GET_PRODUCTS,
  payload: data,
});

export const getProductsSuccess = data => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: data,
});

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
});

export const addNewProduct = product => ({
  type: ADD_NEW_PRODUCT,
  payload: product,
});

export const addNewProductSuccess = product => ({
  type: ADD_NEW_PRODUCT_SUCCESS,
  payload: product,
});

export const addNewProductFail = error => ({
  type: ADD_NEW_PRODUCT_FAIL,
  payload: error,
});

export const updateProduct = product => ({
  type: UPDATE_PRODUCT,
  payload: product,
});

export const updateProductSuccess = product => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product,
});

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
});

export const getProductDetails = product => ({
  type: GET_PRODUCT_DETAILS,
  payload: product,
});

export const getProductDetailsSuccess = product => ({
  type: GET_PRODUCT_DETAILS_SUCCESS,
  payload: product,
});

export const getProductDetailsFail = error => ({
  type: GET_PRODUCT_DETAILS_FAIL,
  payload: error,
});

export const delProduct = data => ({
  type: DELETE_PRODUCT,
  payload: data,
});

export const deleteProductSuccess = data => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: data,
});

export const deleteProductFail = data => ({
  type: DELETE_PRODUCT_FAIL,
  payload: data,
});
export const statusChangeProduct = product => ({
  type: STATUS_CHANGE_PRODUCT,
  payload: product,
});

export const statusChangeProductSuccess = product => ({
  type: STATUS_CHANGE_PRODUCT_SUCCESS,
  payload: product,
});

export const statusChangeProductFail = error => ({
  type: STATUS_CHANGE_PRODUCT_FAIL,
  payload: error,
});

export const modifyProductFlag = () => {
  return {
    type: MODIFY_PRODUCT_FLAG,
  }
}

export const resetProductFlag = () => {
  return {
    type: RESET_PRODUCT_FLAG,
  }
}
