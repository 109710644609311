/* PRODUCT-CATEGORIES */
export const GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES";
export const GET_PRODUCT_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_CATEGORIES_FAIL = "GET_PRODUCT_CATEGORIES_FAIL";

export const ADD_NEW_PRODUCT_CATEGORY = "ADD_NEW_PRODUCT_CATEGORY";
export const ADD_NEW_PRODUCT_CATEGORY_SUCCESS = "ADD_NEW_PRODUCT_CATEGORY_SUCCESS";
export const ADD_NEW_PRODUCT_CATEGORY_FAIL = "ADD_NEW_PRODUCT_CATEGORY_FAIL";

export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_FAIL = "UPDATE_PRODUCT_CATEGORY_FAIL";

export const GET_PRODUCT_CATEGORY_DETAILS = "GET_PRODUCT_CATEGORY_DETAILS";
export const GET_PRODUCT_CATEGORY_DETAILS_SUCCESS = "GET_PRODUCT_CATEGORY_DETAILS_SUCCESS";
export const GET_PRODUCT_CATEGORY_DETAILS_FAIL = "GET_PRODUCT_CATEGORY_DETAILS_FAIL";

export const RESET_PRODUCT_CATEGORY_FLAG = "RESET_PRODUCT_CATEGORY_FLAG";

export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY_SUCCESS = "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAIL = "DELETE_PRODUCT_CATEGORY_FAIL";

export const STATUS_CHANGE_PRODUCT_CATEGORY = "STATUS_CHANGE_PRODUCT_CATEGORY";
export const STATUS_CHANGE_PRODUCT_CATEGORY_SUCCESS = "STATUS_CHANGE_PRODUCT_CATEGORY_SUCCESS";
export const STATUS_CHANGE_PRODUCT_CATEGORY_FAIL = "STATUS_CHANGE_PRODUCT_CATEGORY_FAIL";

export const SAVE_ORDERING_PRODUCT_CATEGORY = "SAVE_ORDERING_PRODUCT_CATEGORY";
export const SAVE_ORDERING_PRODUCT_CATEGORY_SUCCESS = "SAVE_ORDERING_PRODUCT_CATEGORY_SUCCESS";
export const SAVE_ORDERING_PRODUCT_CATEGORY_FAIL = "SAVE_ORDERING_PRODUCT_CATEGORY_FAIL";
export const SAVE_ORDERING_PRODUCT_CATEGORY_RESET_FLAG = "SAVE_ORDERING_PRODUCT_CATEGORY_RESET_FLAG";

export const MODIFY_PRODUCT_CATEGORY_FLAG = "MODIFY_PRODUCT_CATEGORY_FLAG";