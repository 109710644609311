import {
  UPDATE_SOCKET_NOTIFICATION,
  RESET_SOCKET_NOTIFICATION,
  GET_SOCKET_NOTIFICATION,
  GET_SOCKET_NOTIFICATION_SUCCESS,
  GET_SOCKET_NOTIFICATION_FAIL,
} from "./actionTypes";

export const updateSocketNotification = data => ({
  type: UPDATE_SOCKET_NOTIFICATION,
  payload: data,
});

export const resetSocketNotification = data => ({
  type: RESET_SOCKET_NOTIFICATION,
  payload: data,
});

export const getSocketNotification = data => ({
  type: GET_SOCKET_NOTIFICATION,
  payload: data,
});

export const getSocketNotificationSuccess = data => ({
  type: GET_SOCKET_NOTIFICATION_SUCCESS,
  payload: data,
});

export const getSocketNotificationFail = error => ({
  type: GET_SOCKET_NOTIFICATION_FAIL,
  payload: error,
});

// export const addNewStep = step=> ({
//   type: ADD_NEW_STEP,
//   payload: step,
// });

// export const addNewStepSuccess = step => ({
//   type: ADD_NEW_STEP_SUCCESS,
//   payload: step,
// });

// export const addNewStepFail = error => ({
//   type: ADD_NEW_STEP_FAIL,
//   payload: error,
// });

// export const updateStep = step => ({
//   type: UPDATE_STEP,
//   payload: step,
// });

// export const updateStepSuccess = step => ({
//   type: UPDATE_STEP_SUCCESS,
//   payload: step,
// });

// export const updateStepFail = error => ({
//   type: UPDATE_STEP_FAIL,
//   payload: error,
// });

// export const getStepDetails = step => ({
//   type: GET_STEP_DETAILS,
//   payload: step,
// });

// export const getStepDetailsSuccess = step => ({
//   type: GET_STEP_DETAILS_SUCCESS,
//   payload: step,
// });

// export const getStepDetailsFail = error => ({
//   type: GET_STEP_DETAILS_FAIL,
//   payload: error,
// });

// export const resetStepFlag = () => {
//   return {
//     type: RESET_STEP_FLAG,
//   }
// }

// //Delete
// export const deleteStep = step => ({
//   type: DELETE_STEP,
//   payload: step,
// });

// export const deleteStepSuccess = step => ({
//   type: DELETE_STEP_SUCCESS,
//   payload: step,
// });

// export const deleteStepFail = error => ({
//   type: DELETE_STEP_FAIL,
//   payload: error,
// });

// //Status Change
// export const statusChangeStep = step => ({
//   type: STATUS_CHANGE_STEP,
//   payload: step,
// });

// export const statusChangeStepSuccess = step => ({
//   type: STATUS_CHANGE_STEP_SUCCESS,
//   payload: step,
// });

// export const statusChangeStepFail = error => ({
//   type: STATUS_CHANGE_STEP_FAIL,
//   payload: error,
// });

// //Save Ordaring
// export const saveOrderingStep = data => ({
//   type: SAVE_ORDERING_STEP,
//   payload: data,
// });

// export const saveOrderingStepSuccess = data => ({
//   type: SAVE_ORDERING_STEP_SUCCESS,
//   payload: data,
// });

// export const saveOrderingStepFail = error => ({
//   type: SAVE_ORDERING_STEP_FAIL,
//   payload: error,
// });


// //Save ordering reset Flag for toaster
// export const saveOrderingStepResetFlag = () => {
//   return {
//     type: SAVE_ORDERING_STEP_RESET_FLAG,
//   }
// }



// //Modify Flag for toaster
// export const modifyStepFlag = () => {
//   return {
//     type: MODIFY_STEP_FLAG,
//   }
// }


