import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateBanner,
  resetBannerFlag,
  getPages,
  getBannerDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { convertToBase64 } from "../../../helpers/image";
const BannerEdit = () => {
  document.title = "Edit Banner | Dabbawala";
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState();
  const [title, setTitleData] = useState();
  const [description, setData] = useState();
  const [pagetype, setPagetype] = useState([]);
  const { isBannerUpdate, isBannerUpdateFail, error, success, details, pages } =
    useSelector((state) => ({
      isBannerUpdate: state.Banners.isBannerUpdate,
      isBannerUpdateFail: state.Banners.isBannerUpdateFail,
      error: state.Banners.error,
      success: state.Banners.success,
      details: state.Banners.details,
      pages: state.Pages.pages,
    }));

  const selectedTypeOption = {
    value: details && details.page_type._id,
    label: details && details.page_type.title,
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: details && details.title ? details.title : "",
      description: details && details.description ? details.description : "",
      image: details && details.image ? details.image : "",
      button_name: details && details.button_name ? details.button_name : "",
      button_url: details && details.button_url ? details.button_url : "",
      // type: details && details.type ? details.type : "",
      type: selectedTypeOption,

      type_id: details && details.type_id ? details.type_id : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setIsCreating(true);
      const data = {
        id: id,
        title: title,
        description: description,
        type: 'page',
        type_id: values.type.value,
        button_name: values.button_name,
        button_url: values.button_url,
        image: image,
      };
      dispatch(updateBanner(data));
    },
  });
console.log(details);
  useEffect(() => {
    if (isBannerUpdate || isBannerUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetBannerFlag());
        history.push('/banners');
      }, 3000);
    }
  }, [dispatch, isBannerUpdate, isBannerUpdateFail, validation, history]);
  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getBannerDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
  }, [dispatch, pages]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };
  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
  }, [dispatch, pages]);
  return (
    <>
        <div className="page-content">
          <Container fluid={true}>
            <BreadCrumb
              title="Edit Banner"
              pageTitle="Banners"
              pageUrl="/banners"
            />
            <Row>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Col lg={12}>
                  <Card>
                    <PreviewCardHeader title="Edit Banner" />

                    <CardBody className="card-body">
                      <div className="live-preview">
                        <Row className="gy-4">
                          <Col xxl={3} md={6}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Image *
                              </Label>
                              <Input
                                type="file"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Place your image"
                                name="image"
                                onChange={onImg1Change}
                              />
                              <img
                                src={validation.values.image || ""}
                                alt=""
                                id="image1"
                                width="100px"
                              ></img>
                              {validation.touched.image &&
                              validation.errors.image ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.image}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xxl={3} md={6}>
                            <div>
                              <Label htmlFor="product-title-input">
                                Page *
                              </Label>
                              <Select
                                type="text"
                                name="type"
                                placeholder="Select Page Type"
                                onChange={(selectedOption) => {
                                  let event = {
                                    target: {
                                      name: "type",
                                      value: selectedOption,
                                    },
                                  };
                                  validation.handleChange(event);
                                }}
                                options={pagetype}
                                onBlur={validation.handleBlur}
                                value={validation.values.type || ""}
                                invalid={
                                  validation.touched.type &&
                                  validation.errors.type
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.type &&
                              validation.errors.type ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.type}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={3} md={12}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Title*
                              </Label>
                              <CKEditor
                                editor={ClassicEditor}
                                name="title"
                                data={validation.values.title || ""}
                                onChange={(event, editor) => {
                                  setTitleData(editor.getData());
                                }}
                                invalid={false}
                              />
                              {validation.touched.title &&
                              validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={3} md={6}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Button Name(Optional)
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Button Name"
                                name="button_name"
                                value={validation.values.button_name || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.button_name &&
                                  validation.errors.button_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.button_name &&
                              validation.errors.button_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.button_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xxl={3} md={6}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Button URL(Optional)
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Button Url"
                                name="button_url"
                                value={validation.values.button_url || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.button_url &&
                                  validation.errors.button_url
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.button_url &&
                              validation.errors.button_url ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.button_url}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={3} md={12}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Description
                              </Label>
                              <CKEditor
                                editor={ClassicEditor}
                                name="description"
                                data={validation.values.description || ""}
                                onChange={(event, editor) => {
                                  setData(editor.getData());
                                }}
                                invalid={false}
                              />
                              {validation.touched.description &&
                              validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="text-end mb-4">
                    <Link to="/banners" className="btn btn-danger w-sm me-1">
                      Cancel
                    </Link>
                    <Button
                      color="success"
                      className="btn-load"
                      disabled={isCreating}
                      type="submit"
                    >
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            {" "}
                            Update{" "}
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Update</span>
                      </span>
                    </Button>
                  </div>
                </Col>
              </Form>
            </Row>
            {isBannerUpdate ? (
              <MsgToast
                msg={success}
                color="success"
                icon="ri-checkbox-circle-line"
              />
            ) : null}
            {isBannerUpdateFail ? (
              <MsgToast
                msg={error}
                color="danger"
                icon="ri-error-warning-line"
              />
            ) : null}
            <ToastContainer limit={1} closeButton={false} />
          </Container>
        </div>
    </>
  );
};

export default BannerEdit;
