import {
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  ADD_NEW_BANNER_SUCCESS,
  ADD_NEW_BANNER_FAIL,
  GET_BANNER_DETAILS_SUCCESS,
  GET_BANNER_DETAILS_FAIL,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
  MODIFY_BANNER_FLAG,
  RESET_BANNER_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  banners: [],
};

const Banners = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_BANNERS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case ADD_NEW_BANNER_SUCCESS:
      return {
        ...state,
        banners: [...state.banners, action.payload.data],
        success: action.payload.message,
        isBannerAdd: true,
        isBannerAddFail: false,
      };

    case ADD_NEW_BANNER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBannerAdd: false,
        isBannerAddFail: true,
      };

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        banners: state.banners.map((banner) =>
          banner._id.toString() === action.payload.data._id.toString()
            ? { ...banner, ...action.payload.data }
            : banner
        ),
        success: action.payload.message,
        isBannerUpdate: true,
        isBannerUpdateFail: false,
      };

    case UPDATE_BANNER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBannerUpdate: false,
        isBannerUpdateFail: true,
      };

    case GET_BANNER_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_BANNER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

      case DELETE_BANNER_SUCCESS:
        return { 
          ...state,
          banners: state.banners.filter(getData =>
            {
               return getData._id !== action.payload.data._id
            }
            
          ),
          success: action.payload.message,
          isSuccess: true,
          statusSuccess: true,
          statusFailed: false,
          isBannerRemove: true,
          isBannerRemoveFail: false,
        };
    
      case DELETE_BANNER_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false,
          statusSuccess: false,
          statusFailed: true,
          isBannerRemove: false,
          isBannerRemoveFail: true,
        };
          
    case RESET_BANNER_FLAG:
      return {
        ...state,
        isBannerAdd: false,
        isBannerAddFail: false,
        isSuccess: false,
        isBannerDetails: false,
        isBannerUpdate: false,
        isBannerUpdateFail: false,
        error: false,
      };
      case MODIFY_BANNER_FLAG:
        return {
          ...state,
          statusSuccess: false,
        };
    default:
      return state;
  }
};

export default Banners;
