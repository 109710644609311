import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  CardHeader,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  addNewCombo,
  resetComboFlag,
  //getCategories,
  //getComboCategories,
  getCombo,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { convertToBase64 } from "../../../helpers/image";
import Select from "react-select";
import slugify from "slugify";
import axios from "axios";
import hostUrl from "../../../config";


const ComboCreate = () => {
  document.title = "Create Combo | Dabbawala";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [category_type, setCategoryType] = useState("combos");
  const [image, setImage] = useState(null);
  const [description, setData] = useState();

  const [comboData, setComboData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [allergyData, setAllergyData] = useState();

  const [allergyArray, setAllergyArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [spiceLevelArray, setSpiceLevelArray] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [addOn, setAddOn] = useState();
  const [food, setFood] = useState();

  const [dietData, setDietData] = useState();
  const [spiceData, setSpiceData] = useState();

  
  const [slugData, setSlugData] = useState();

  const { type } = useParams();
  const [Type, setType] = useState(type);
  const {
    isComboAdd,
    isComboAddFail,
    error,
    success,
    categories,
    allergies,
    //combocategories,
    combo,
  } = useSelector((state) => ({
    allergies: state.Allergies.allergies,
    isComboAdd: state.Combos.isComboAdd,
    isComboAddFail: state.Combos.isComboAddFail,
    error: state.Combos.error,
    success: state.Combos.success,
    //combocategories: state.Combocategories.combocategories,
    combos: state.Combos.combos,
  }));

  const selectedDietOption = {
    value: "",
    label: "",
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      slug: "",
      regular_price: "",
      sale_price: "",
      price_point: "",
      short_description: "",
      diet_id: selectedDietOption,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter your title"),
      //slug : Yup.string().required("Please enter your slug"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.title,
        slug: slugData,
        regular_price: values.regular_price,
        sale_price: values.sale_price,
        in_stock: stock.value,
        diet_id: values.diet_id?.value,
        product_id: productArray,
      };
      //console.log(data)
      dispatch(addNewCombo(data));
    },
  });

  useEffect(() => {

    //setCategoryData(combocategories);
    //setComboData(combo);

    // fetch("https://dabbawalaapi.iosx.in:3091/api/combo-categories/tree", {
    //   method: "POST", // or 'PUT'
    //   headers: {},
    //   body: {},
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setCategoryData(data.data);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });

    axios
    .post(
      `${hostUrl.api.API_URL}products/list`,
      { product_type: 1, limit: 1000 },
      {}
    )
    .then(function (response) {
      setFood(response.data);
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    axios
    .post(
      `${hostUrl.api.API_URL}products/list`,
      { product_type: 2 },
      {}
    )
    .then(function (response) {
      setAddOn(response.data);
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [combo]);

  useEffect(() => {
    if (isComboAdd || isComboAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetComboFlag());
        history.push(`/combos`);
      }, 3000);
    }
  }, [dispatch, isComboAdd, isComboAddFail, validation, history]);





  // useEffect(() => {
  //   dispatch(getComboCategories());
  // }, [dispatch]);


  useEffect(() => {
    dispatch( getCombo());
  },[dispatch]);





  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src);
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const types = {
    1: "Food Combo",
    2: "Add-on Combo",
    3: "Merchandise Combo",
  };
  const options = [
    { value: 1, label: "Food Combo" },
    { value: 2, label: "Add-on Combo" },
    { value: 3, label: "Merchandise Combo" },
  ];
  const [selectData, setSelectData] = useState({
    label: types[type],
    value: parseInt(type),
  });

  // function handleSelectGroup(label, value){
  //   setSelectData({label: label, value: value})
  //   setType(value)
  // }

  function handleFeature(label, value) {
    setFeature({ label: label, value: value });
  }

  function handleStock(label, value) {
    setStock({ label: label, value: value });
  }

  const optionsFeature = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const optionsStock = [
    { value: true, label: "In Stock" },
    { value: false, label: "Out Of Stock" },
  ];

  const [feature, setFeature] = useState({ value: false, label: "No" });
  const [stock, setStock] = useState({ value: true, label: "In Stock" });

  function handleChange(e) {
    let a = e.target.checked;
    if (a) {
      setCategoryArray([...categoryArray, e.target.value]);
    } else {
      // remove from list
      setCategoryArray(categoryArray.filter((data) => data !== e.target.value));
    }
  }

  function handleChangeAllergy(e) {
    let a = e.target.checked;
    if (a) {
      setAllergyArray([...allergyArray, e.target.value]);
    } else {
      setAllergyArray(allergyArray.filter((data) => data !== e.target.value));
    }
  }

  function handleChangeSpiceLevel(e) {
    let a = e.target.checked;
    if (a) {
      setSpiceLevelArray([...spiceLevelArray, e.target.value]);
    } else {
      // remove from list
      setSpiceLevelArray(
        spiceLevelArray.filter((data) => data !== e.target.value)
      );
    }
  }


  function handleProduct(e) {
    let a = e.target.checked;
    if (a) {
      setProductArray([...productArray, e.target.value]);
    } else {
      setProductArray(
        productArray.filter((data) => data !== e.target.value)
      );
    }
  }

  function slugCreator(e) {
    setSlugData(
      slugify(e.target.value, {
        replacement: "-",
        lower: true,
        trim: true,
        remove: /[*+~.()'"!:@]/g,
      })
    );
  }

  //console.log(productArray);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Create Combo"
            pageTitle="Combos"
            pageUrl={`/combos`}
          />

          <Row>
            <Col lg={8}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  {/* <CardBody>
                    <Row>
                      <Col lg={6}>
                        <Label>Select Combo Type</Label>

                        <Select
                          value={selectData}
                          onChange={(e) => {
                            handleSelectGroup(e.label, e.value);
                          }}
                          options={options}
                          name="choices-category-input"
                          classNamePrefix="select2-selection form-select"
                        />
                      </Col>
                      </Row>
                      </CardBody> */}

                  <CardBody>
                    {/* <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="combo-title-input"
                      >
                        Combo Title *
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="combo-title-input"
                        placeholder="Enter combo title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.title && validation.errors.title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div> */}
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="combo-title-input"
                          >
                            Combo Title *
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="combo-title-input"
                            name="title"
                            placeholder="Enter combo title"
                            value={validation.values.title || ""}
                            onChange={(e) => {
                              validation.handleChange(e);
                              slugCreator(e);
                            }}
                            onBlur={validation.handleBlur}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.title && validation.errors.title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                        </Col>
                        <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="combo-title-input"
                          >
                            Combo Slug (Unique Combo Identification) *
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="combo-title-input"
                            placeholder="Enter combo slug"
                            name="slug"
                            value={slugData || validation.values.slug}
                            onChange={(e) => {
                              validation.handleChange(e);
                              slugCreator(e);
                            }}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.slug && validation.errors.slug
                                ? true
                                : false
                            }
                          />
                          {validation.touched.slug && validation.errors.slug ? (
                            <FormFeedback type="invalid">
                              {validation.errors.slug}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="combo-title-input"
                          >
                            Regular Price: *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="combo-title-input"
                            placeholder="Enter regular price"
                            name="regular_price"
                            value={validation.values.regular_price || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.regular_price &&
                              validation.errors.regular_price
                                ? true
                                : false
                            }
                          />
                          {validation.touched.regular_price &&
                          validation.errors.regular_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.regular_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="combo-title-input"
                          >
                            Sale Price: *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="combo-title-input"
                            placeholder="Enter sale price"
                            name="sale_price"
                            value={validation.values.sale_price || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.sale_price &&
                              validation.errors.sale_price
                                ? true
                                : false
                            }
                          />
                          {validation.touched.sale_price &&
                          validation.errors.sale_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sale_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="combo-title-input"
                          >
                            Point Price *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="combo-title-input"
                            placeholder="Enter point price"
                            name="price_point"
                            value={validation.values.price_point || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.price_point &&
                              validation.errors.price_point
                                ? true
                                : false
                            }
                          />
                          {validation.touched.price_point &&
                          validation.errors.price_point ? (
                            <FormFeedback type="invalid">
                              {validation.errors.price_point}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}
                    </Row>

                    {/* <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Regular Price: *</Label>
                          <Input
                            type="number"
                            placeholder="Enter Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          ></Input>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Sale Price: *</Label>
                          <Input
                            type="number"
                            placeholder="Enter Price"
                            value={salePrice}
                            onChange={(e) => setSalePrice(e.target.value)}
                          ></Input>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Point Price: </Label>
                          <Input placeholder="Enter Point Price" value={point} onChange={e => setPoint(e.target.value)} type="number"></Input>
                        </div>
                      </Col>
                    </Row>
                  </CardBody> */}

                    {/* <div className="mb-3">
                      <Label>Combo Description *</Label>

                      <CKEditor
                        editor={ClassicEditor}
                        name="description"
                        onChange={(event, editor) => {
                          setData(editor.getData());
                        }}
                        data={validation.values.description || ""}
                        invalid={false}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div> */}
                  </CardBody>
                </Card>

                {/* {Type == "1" && (
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Combo Category *</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {categoryData &&
                          categoryData.map((item, key) => (
                            <li className="col-6"
                              key={key}
                              style={{
                                listStyle: "none",
                                marginBottom: "0.1em",
                              }}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  id={item._id ? item._id : ""}
                                  value={item._id ? item._id : ""}
                                  onChange={(e) => handleChange(e)}
                                />{" "}
                                {item.title}
                              </label> */}

                              {/* <ul>
                                {item.has_sub_category &&
                                  item.sub_category.map((val, i) => (
                                    <>
                                      <li
                                        key={i}
                                        style={{
                                          listStyle: "none",
                                          marginBottom: "0.1em",
                                        }}
                                      >
                                        <label>
                                          <input
                                            type="checkbox"
                                            className="subOption"
                                            id={val._id ? val._id : ""}
                                            value={val._id ? val._id : ""}
                                            onChange={(e) => handleChange(e)}
                                          />{" "}
                                          {val.title}
                                        </label>
                                        <ul>
                                          {val.has_sub_category &&
                                            val.sub_category.map((v, a) => (
                                              <li
                                                key={a}
                                                style={{
                                                  listStyle: "none",
                                                  marginBottom: "0.1em",
                                                }}
                                              >
                                                <label>
                                                  <input
                                                    type="checkbox"
                                                    className="subSubOption"
                                                    id={v._id ? v._id : ""}
                                                    value={v._id ? v._id : ""}
                                                    onChange={(e) =>
                                                      handleChange(e)
                                                    }
                                                  />{" "}
                                                  {v.title}
                                                </label>
                                              </li>
                                            ))}
                                        </ul>
                                      </li>
                                    </>
                                  ))}
                              </ul> */}
                            {/* </li>
                          ))}
                      </Row>
                    </CardBody>
                  </Card>
                )} */}
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Add-ons</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {addOn &&
                          addOn.map((item, key) => (
                            <li className="col-6"
                              key={key}
                              style={{
                                listStyle: "none",
                                marginBottom: "0.1em",
                              }}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  id={item._id ? item._id : ""}
                                  value={item._id ? item._id : ""}
                                  onChange={(e) => handleProduct(e)}
                                />{" "}
                                {item.title}
                              </label>
                            </li>
                          ))}
                      </Row>
                    </CardBody>
                    <CardHeader>
                      <h5 className="card-title mb-0">Dishes</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {food &&
                          food.map((item, key) => (
                            <li className="col-6"
                              key={key}
                              style={{
                                listStyle: "none",
                                marginBottom: "0.1em",
                              }}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  //id={item._id ? item._id : ""}
                                  value={item._id ? item._id : ""}
                                  onChange={(e) => handleProduct(e)}
                                />{" "}
                                {item.title}
                              </label>
                            </li>
                          ))}
                      </Row>
                    </CardBody>
                  </Card>

                {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Combo Gallery</h5>
                </CardHeader>
                <CardBody>
                <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Combo Image *
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImg1Change}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                          </CardBody>
              </Card> */}

                <div className="text-end mb-4">
                  <Link
                    to={`/combos`}
                    className="btn btn-danger w-sm me-1"
                  >
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </form>
              {isComboAdd ? (
                <MsgToast
                  msg={success}
                  color="success"
                  icon="ri-checkbox-circle-line"
                />
              ) : null}
              {isComboAddFail ? (
                <MsgToast
                  msg={error}
                  color="danger"
                  icon="ri-error-warning-line"
                />
              ) : null}
              <ToastContainer limit={1} closeButton={false} />
            </Col>

            <Col lg={4}>
              {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Combo Image *</h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <Input
                      type="file"
                      id="placeholderInput"
                      className="form-control"
                      placeholder="Place your image"
                      name="image"
                      onChange={onImg1Change}
                    />
                    <img
                      src={validation.values.image || ""}
                      alt=""
                      id="image1"
                      width="100px"
                    ></img>
                    {validation.touched.image && validation.errors.image ? (
                      <FormFeedback type="invalid">
                        {validation.errors.image}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card> */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Combo Info</h5>
                </CardHeader>
                <CardBody>
                  {/* <div className="mb-3">
                  <Label>Select Combo Type *</Label>

                  <Select
                    value={selectData}
                    onChange={(e) => {
                      handleSelectGroup(e.label, e.value);
                    }}
                    options={options}
                    name="choices-category-input"
                    classNamePrefix="select2-selection form-select"
                  />
                </div> */}

                  {/* {Type == "1" && (
                    <div className="mb-3">
                      <Label>Popular *</Label>
                      <Select
                        value={feature}
                        onChange={(e) => handleFeature(e.label, e.value)}
                        options={optionsFeature}
                        name="choices-category-input"
                        classNamePrefix="select2-selection form-select"
                      />
                    </div>
                  )} */}
                  <div>
                    <Label>Stock Manage *</Label>
                    <Select
                      value={stock}
                      onChange={(e) => handleStock(e.label, e.value)}
                      options={optionsStock}
                      name="choices-category-input"
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ComboCreate;
