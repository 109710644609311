import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_PRODUCT_CATEGORIES,
  ADD_NEW_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_DETAILS,
  UPDATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  STATUS_CHANGE_PRODUCT_CATEGORY,
  SAVE_ORDERING_PRODUCT_CATEGORY
} from "./actionTypes"

import {
  getProductCategoriesSuccess,
  getProductCategoriesFail,
  addNewProductCategorySuccess,
  addNewProductCategoryFail,
  getProductCategoryDetailsSuccess,
  getProductCategoryDetailsFail,
  updateProductCategorySuccess,
  updateProductCategoryFail,
  deleteProductcategorySuccess,
  deleteProductcategoryFail,
  statusChangeProductcategorySuccess,
  statusChangeProductcategoryFail,
  saveOrderingProductCategorySuccess,
  saveOrderingProductCategoryFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProductCategoryList,
  addProductCategory,
  ProductCategoryDetails,
  updateProductCategory,
  delProductCategory,
  statusChangeProductCategory,
  saveOrdering
} from "../../helpers/fakebackend_helper"

function* fetchProductcategories({ payload: category }) {
  try {
    const response = yield call(getProductCategoryList, category)
    yield put(getProductCategoriesSuccess(response))
  } catch (error) {
    yield put(getProductCategoriesFail(error))
  }
}

export function* watchFetchProductcategories() {
  yield takeEvery(GET_PRODUCT_CATEGORIES, fetchProductcategories);
}

function* onAddNewProductcategory({ payload: category }) {
  try {
      const response = yield call(addProductCategory, category);
      if(response.status === 200){
        yield put(addNewProductCategorySuccess(response));
      } else {
        yield put(addNewProductCategoryFail(response));
      }
  } catch (error) {
      yield put(addNewProductCategoryFail(error));
  }
}

export function* watchAddNewProductcategory() {
  yield takeEvery(ADD_NEW_PRODUCT_CATEGORY, onAddNewProductcategory);
}

function* onProductcategoryDetails({ payload: category }) {
  try {
      const response = yield call(ProductCategoryDetails, category);
      if(response.status === 200){
        yield put(getProductCategoryDetailsSuccess(response));
      } else {
        yield put(getProductCategoryDetailsFail(response));
      }
  } catch (error) {
      yield put(getProductCategoryDetailsFail(error));
  }
}

export function* watchProductcategoryDetails() {
  yield takeEvery(GET_PRODUCT_CATEGORY_DETAILS, onProductcategoryDetails);
}

function* onProductcategoryUpdate({ payload: category }) {
  try {
      const response = yield call(updateProductCategory, category);
      if(response.status === 200){
        yield put(updateProductCategorySuccess(response));
      } else {
        yield put(updateProductCategorySuccess(response));
      }
  } catch (error) {
      yield put(updateProductCategorySuccess(error));
  }
}

export function* watchUpdateProductcategory() {
  yield takeEvery(UPDATE_PRODUCT_CATEGORY, onProductcategoryUpdate);
}


//Delete Data
function* onDeleteProductcategory({ payload: category }) {
  try {
    const response = yield call(delProductCategory, category);
    yield put(deleteProductcategorySuccess({ category, ...response }));
  } catch (error) {
    yield put(deleteProductcategoryFail(error));
  }
}

export function* watchDeleteProductcategory() {
  yield takeEvery(DELETE_PRODUCT_CATEGORY, onDeleteProductcategory);
}



//Status Change
function* onStatusChangProductcategory({ payload: category }) {
  try {
    const response = yield call(statusChangeProductCategory, category);
    yield put(statusChangeProductcategorySuccess({ category, ...response }));
  } catch (error) {
    yield put(statusChangeProductcategoryFail(error));
  }
}

export function* watchStatusChangeProductcategory() {
  yield takeEvery(STATUS_CHANGE_PRODUCT_CATEGORY, onStatusChangProductcategory);
}

//Save Ordering
function* onSaveOrderingProductCategory({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingProductCategorySuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingProductCategoryFail(error));
  }
}

export function* watchSaveOrderingProductCategory() {
  yield takeEvery(SAVE_ORDERING_PRODUCT_CATEGORY, onSaveOrderingProductCategory);
}



function* productcategorySaga() {
  yield all(
    [
      fork(watchFetchProductcategories),
      fork(watchAddNewProductcategory),
      fork(watchProductcategoryDetails),
      fork(watchUpdateProductcategory),
      fork(watchDeleteProductcategory),
      fork(watchStatusChangeProductcategory),
      fork(watchSaveOrderingProductCategory)
    ]
  );

}

export default productcategorySaga
