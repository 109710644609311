import {
    GET_COUPONS,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_FAIL,
    ADD_NEW_COUPON,
    ADD_NEW_COUPON_SUCCESS,
    ADD_NEW_COUPON_FAIL,
    GET_COUPON_DETAILS,
    GET_COUPON_DETAILS_SUCCESS,
    GET_COUPON_DETAILS_FAIL,
    UPDATE_COUPON,
    UPDATE_COUPON_SUCCESS,
    UPDATE_COUPON_FAIL,
    RESET_COUPON_FLAG,
    DELETE_COUPON,
    DELETE_COUPON_SUCCESS,
    DELETE_COUPON_FAIL,
    STATUS_CHANGE_COUPON,
    STATUS_CHANGE_COUPON_SUCCESS,
    STATUS_CHANGE_COUPON_FAIL,
    MODIFY_COUPON_FLAG
  } from "./actionTypes";
  
  export const getCoupons = data => ({
    type: GET_COUPONS,
    payload: data,
  });
  
  export const getCouponsSuccess = data => ({
    type: GET_COUPONS_SUCCESS,
    payload: data,
  });
  
  export const getCouponsFail = error => ({
    type: GET_COUPONS_FAIL,
    payload: error,
  });
  
  export const addNewCoupon = coupon=> ({
    type: ADD_NEW_COUPON,
    payload: coupon,
  });
  
  export const addNewCouponSuccess = coupon => ({
    type: ADD_NEW_COUPON_SUCCESS,
    payload: coupon,
  });
  
  export const addNewCouponFail = error => ({
    type: ADD_NEW_COUPON_FAIL,
    payload: error,
  });
  
  export const updateCoupon = coupon => ({
    type: UPDATE_COUPON,
    payload: coupon,
  });
  
  export const updateCouponSuccess = coupon => ({
    type: UPDATE_COUPON_SUCCESS,
    payload: coupon,
  });
  
  export const updateCouponFail = error => ({
    type: UPDATE_COUPON_FAIL,
    payload: error,
  });
  
  export const getCouponDetails = coupon => ({
    type: GET_COUPON_DETAILS,
    payload: coupon,
  });
  
  export const getCouponDetailsSuccess = coupon => ({
    type: GET_COUPON_DETAILS_SUCCESS,
    payload: coupon,
  });
  
  export const getCouponDetailsFail = error => ({
    type: GET_COUPON_DETAILS_FAIL,
    payload: error,
  });
  
  export const resetCouponFlag = () => {
    return {
      type: RESET_COUPON_FLAG,
    }
  }
  
  //Delete
  export const deleteCoupon = coupon => ({
    type: DELETE_COUPON,
    payload: coupon,
  });
  
  export const deleteCouponSuccess = coupon => ({
    type: DELETE_COUPON_SUCCESS,
    payload: coupon,
  });
  
  export const deleteCouponFail = error => ({
    type: DELETE_COUPON_FAIL,
    payload: error,
  });
  
  //Status Change
  export const statusChangeCoupon = coupon => ({
    type: STATUS_CHANGE_COUPON,
    payload: coupon,
  });
  
  export const statusChangeCouponSuccess = coupon => ({
    type: STATUS_CHANGE_COUPON_SUCCESS,
    payload: coupon,
  });
  
  export const statusChangeCouponFail = error => ({
    type: STATUS_CHANGE_COUPON_FAIL,
    payload: error,
  });
  
  //Modify Flag for toaster
  export const modifyCouponFlag = () => {
    return {
      type: MODIFY_COUPON_FLAG,
    }
  }