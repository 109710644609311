/* Product */
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const ADD_NEW_PRODUCT_SUCCESS = "ADD_NEW_PRODUCT_SUCCESS";
export const ADD_NEW_PRODUCT_FAIL = "ADD_NEW_PRODUCT_FAIL";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
export const MODIFY_PRODUCT_FLAG = "MODIFY_PRODUCT_FLAG";

export const RESET_PRODUCT_FLAG = "RESET_PRODUCT_FLAG";

// STATUS CHANGE
export const STATUS_CHANGE_PRODUCT = "STATUS_CHANGE_PRODUCT";
export const STATUS_CHANGE_PRODUCT_SUCCESS = "STATUS_CHANGE_PRODUCT_SUCCESS";
export const STATUS_CHANGE_PRODUCT_FAIL = "STATUS_CHANGE_PRODUCT_FAIL";
