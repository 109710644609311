import {
  GET_JOBAPPLIES_SUCCESS,
  GET_JOBAPPLIES_FAIL,
  ADD_NEW_JOBAPPLY_SUCCESS,
  ADD_NEW_JOBAPPLY_FAIL,
  // GET_JOBAPPLY_DETAILS_SUCCESS,
  // GET_JOBAPPLY_DETAILS_FAIL,
  UPDATE_JOBAPPLY_SUCCESS,
  UPDATE_JOBAPPLY_FAIL,
  DELETE_JOBAPPLY_SUCCESS,
  DELETE_JOBAPPLY_FAIL,
  RESET_JOBAPPLY_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  jobapplies: [],
};

const Jobapplies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOBAPPLIES_SUCCESS:
      return {
        ...state,
        jobapplies: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case  GET_JOBAPPLIES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case ADD_NEW_JOBAPPLY_SUCCESS:
      return {
        ...state,
        jobapplies: [...state.jobapplies, action.payload.data],
        success: action.payload.message,
        isJobapplyAdd: true,
        isJobapplyAddFail: false,
      };

    case ADD_NEW_JOBAPPLY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isJobapplyAdd: false,
        isJobapplyAddFail: true,
      };

    case UPDATE_JOBAPPLY_SUCCESS:
      return {
        ...state,
        jobapplies: state.jobapplies.map((jobapply) =>
          jobapply._id.toString() === action.payload.data._id.toString()
            ? { ...jobapply, ...action.payload.data }
            : jobapply
        ),
        success: action.payload.message,
        isJobapplyUpdate: true,
        isJobapplyUpdateFail: false,
      };

    case UPDATE_JOBAPPLY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isJobapplyUpdate: false,
        isJobapplyUpdateFail: true,
      };

    // case  GET_JOBAPPLY_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     details: action.payload.data[0] ? action.payload.data[0] : [],
    //     isSuccess: true,
    //   };

    // case GET_JOBAPPLY_DETAILS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload.message,
    //     isSuccess: false,
    //   };

    case DELETE_JOBAPPLY_SUCCESS:
      return {
        ...state,
        jobapplies: state.jobapplies.filter(getData =>
          {
             return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isJobapplyRemove: true,
        isJobapplyRemoveFail: false,
      };

    case DELETE_JOBAPPLY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isJobapplyRemove: false,
        isJobapplyRemoveFail: true,
      };

    case RESET_JOBAPPLY_FLAG:
      return {
        ...state,
        isJobapplyAdd: false,
        isJobapplyAddFail: false,
        isSuccess: false,
        isJobapplyDetails: false,
        isJobapplyUpdate: false,
        isJobapplyUpdateFail: false,
        error: false,
      };



    default:
      return state;
  }
};

export default Jobapplies;
