import {
  GET_MISSIONS_SUCCESS,
  GET_MISSIONS_FAIL,
  ADD_NEW_MISSION_SUCCESS,
  ADD_NEW_MISSION_FAIL,
  GET_MISSION_DETAILS_SUCCESS,
  GET_MISSION_DETAILS_FAIL,
  UPDATE_MISSION_SUCCESS,
  UPDATE_MISSION_FAIL,
  RESET_MISSION_FLAG,
  DELETE_MISSION_SUCCESS,
  DELETE_MISSION_FAIL,
  STATUS_CHANGE_MISSION_SUCCESS,
  STATUS_CHANGE_MISSION_FAIL,
  MODIFY_MISSION_FLAG,
  
  SAVE_ORDERING_MISSION_SUCCESS,
  SAVE_ORDERING_MISSION_FAIL,
  SAVE_ORDERING_MISSION_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  missions: []
};

const Missions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MISSIONS_SUCCESS:
      return {
        ...state,
        missions: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_MISSIONS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_MISSION_SUCCESS:
      return {
        ...state,
        missions: [...state.missions, action.payload.data],
        success: action.payload.message,
        isMissionAdd: true,
        isMissionAddFail: false,
      };

    case ADD_NEW_MISSION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isMissionAdd: false,
        isMissionAddFail: true,
      };

    case UPDATE_MISSION_SUCCESS:
      return {
        ...state,
        missions: state.missions.map(mission =>
          mission._id.toString() === action.payload.data._id.toString()
            ? { ...mission, ...action.payload.data }
            : mission
        ),
        success: action.payload.message,
        isMissionUpdate: true,
        isMissionUpdateFail: false
      };

    case UPDATE_MISSION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isMissionUpdate: false,
        isMissionUpdateFail: true,
      };

    case GET_MISSION_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_MISSION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_MISSION_SUCCESS:
      return { 
        ...state,
        missions: state.missions.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_MISSION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_MISSION_SUCCESS:
      return {
        ...state,
        missions: state.missions.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_MISSION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    //Save Ordering
    case SAVE_ORDERING_MISSION_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_MISSION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_MISSION_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    case MODIFY_MISSION_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_MISSION_FLAG:
      return {
        ...state,
        isMissionAdd: false,
        isMissionAddFail: false,
        isSuccess: false,
        isMissionDetails: false,
        isMissionUpdate: false,
        isMissionUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Missions;
