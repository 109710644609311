export const GET_ORGANIZATIONS = "GET_GET_ORGANIZATIONS";
export const GET_ORGANIZATIONS_SUCCESS = "GET_GET_ORGANIZATIONS_SUCCESS";
export const GET_ORGANIZATIONS_FAIL = "GET_GET_ORGANIZATIONS_FAIL";

export const ADD_NEW_ORGANIZATION = "ADD_NEW_ORGANIZATION";
export const ADD_NEW_ORGANIZATION_SUCCESS = "ADD_NEW_ORGANIZATION_SUCCESS";
export const ADD_NEW_ORGANIZATION_FAIL = "ADD_NEW_ORGANIZATION_FAIL";

export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAIL = "UPDATE_ORGANIZATION_FAIL";

export const GET_ORGANIZATION_DETAILS = "GET_ORGANIZATION_DETAILS";
export const GET_ORGANIZATION_DETAILS_SUCCESS = "GET_ORGANIZATION_DETAILS_SUCCESS";
export const GET_ORGANIZATION_DETAILS_FAIL = "GET_ORGANIZATION_DETAILS_FAIL";

export const RESET_ORGANIZATION_FLAG = "RESET_ORGANIZATION_FLAG";

export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAIL = "DELETE_ORGANIZATION_FAIL";

export const STATUS_CHANGE_ORGANIZATION = "STATUS_CHANGE_ORGANIZATION";
export const STATUS_CHANGE_ORGANIZATION_SUCCESS = "STATUS_CHANGE_ORGANIZATION_SUCCESS";
export const STATUS_CHANGE_ORGANIZATION_FAIL = "STATUS_CHANGE_ORGANIZATION_FAIL";

export const MODIFY_ORGANIZATION_FLAG = "MODIFY_ORGANIZATION_FLAG";