/* JOB TYPES */

export const ADD_NEW_JOB_TYPE = "ADD_NEW_JOB_TYPE";
export const ADD_NEW_JOB_TYPE_SUCCESS = "ADD_NEW_JOB_TYPE_SUCCESS";
export const ADD_NEW_JOB_TYPE_FAIL = "ADD_NEW_JOB_TYPE_FAIL";

export const UPDATE_JOB_TYPE = "UPDATE_JOB_TYPE";
export const UPDATE_JOB_TYPE_SUCCESS = "UPDATE_JOB_TYPE_SUCCESS";
export const UPDATE_JOB_TYPE_FAIL = "UPDATE_JOB_TYPE_FAIL";


export const GET_JOB_TYPE_DETAILS = "GET_JOB_TYPE_DETAILS";
export const GET_JOB_TYPE_DETAILS_SUCCESS = "GET_JOB_TYPE_DETAILS_SUCCESS";
export const GET_JOB_TYPE_DETAILS_FAIL = "GET_JOB_TYPE_DETAILS_FAIL";

// JOB_TYPE
export const GET_JOB_TYPE_LIST = "GET_JOB_TYPE_LIST";
export const GET_JOB_TYPE_LIST_SUCCESS = "GET_JOB_TYPE_LIST_SUCCESS";
export const GET_JOB_TYPE_LIST_FAIL = "GET_JOB_TYPE_LIST_FAIL";

export const DELETE_JOB_TYPE = "DELETE_JOB_TYPE";
export const DELETE_JOB_TYPE_SUCCESS = "DELETE_JOB_TYPE_SUCCESS";
export const DELETE_JOB_TYPE_FAIL = "DELETE_JOB_TYPE_FAIL";
export const MODIFY_JOB_TYPE_FLAG = "MODIFY_JOB_TYPE_FLAG";

export const RESET_JOB_TYPE_FLAG = "RESET_JOB_TYPE_FLAG";

// Status_Change
export const STATUS_CHANGE_JOBTYPE = "STATUS_CHANGE_JOBTYPE";
export const STATUS_CHANGE_JOBTYPE_SUCCESS = "STATUS_CHANGE_JOBTYPE_SUCCESS";
export const STATUS_CHANGE_JOBTYPE_FAIL = "STATUS_CHANGE_JOBTYPE_FAIL";