import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  ADD_NEW_PRODUCT_SUCCESS,
  ADD_NEW_PRODUCT_FAIL,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  MODIFY_PRODUCT_FLAG,
  RESET_PRODUCT_FLAG,
  STATUS_CHANGE_PRODUCT_SUCCESS,
  STATUS_CHANGE_PRODUCT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  products: [],
};

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case ADD_NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload.data],
        success: action.payload.message,
        isProductAdd: true,
        isProductAddFail: false,
      };

    case ADD_NEW_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isProductAdd: false,
        isProductAddFail: true,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map((product) =>
          product._id.toString() === action.payload.data._id.toString()
            ? { ...product, ...action.payload.data }
            : product
        ),
        success: action.payload.message,
        isProductUpdate: true,
        isProductUpdateFail: false,
      };

    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isProductUpdate: false,
        isProductUpdateFail: true,
      };

    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter((getData) => {
          return getData._id !== action.payload.data._id;
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isProductRemove: true,
        isProductRemoveFail: false,
      };

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isProductRemove: false,
        isProductRemoveFail: true,
      };
    //Status Change
    case STATUS_CHANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map((product) =>
          product._id.toString() === action.payload.data._id.toString()
            ? { ...product, ...action.payload.data }
            : product
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };
    case RESET_PRODUCT_FLAG:
      return {
        ...state,
        isProductAdd: false,
        isProductAddFail: false,
        isSuccess: false,
        isProductDetails: false,
        isProductUpdate: false,
        isProductUpdateFail: false,
        error: false,
      };
    case MODIFY_PRODUCT_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };
    default:
      return state;
  }
};

export default Products;
