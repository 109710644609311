import {
  UPDATE_SOCKET_NOTIFICATION,
  RESET_SOCKET_NOTIFICATION,
  GET_SOCKET_NOTIFICATION_SUCCESS,
  GET_SOCKET_NOTIFICATION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  orderNotification: [],
  socketNotification: []
};

const SocketNotification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SOCKET_NOTIFICATION:
      return {
        ...state,
        socketNotification: [...state.socketNotification,action.payload.data],
        orderNotification: [...state.orderNotification,action.payload.data],
      };
    case RESET_SOCKET_NOTIFICATION:
      return {
        ...state,
        socketNotification: [],
      };

    case GET_SOCKET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        orderNotification: action.payload.data,
      };

    case GET_SOCKET_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return state;
  }
};
export default SocketNotification;
