/* HIGHLIGHTS */
export const GET_HIGHLIGHTS = "GET_HIGHLIGHTS";
export const GET_HIGHLIGHTS_SUCCESS = "GET_HIGHLIGHTS_SUCCESS";
export const GET_HIGHLIGHTS_FAIL = "GET_HIGHLIGHTS_FAIL";

export const ADD_NEW_HIGHLIGHT = "ADD_NEW_HIGHLIGHT";
export const ADD_NEW_HIGHLIGHT_SUCCESS = "ADD_NEW_HIGHLIGHT_SUCCESS";
export const ADD_NEW_HIGHLIGHT_FAIL = "ADD_NEW_HIGHLIGHT_FAIL";

export const UPDATE_HIGHLIGHT = "UPDATE_HIGHLIGHT";
export const UPDATE_HIGHLIGHT_SUCCESS = "UPDATE_HIGHLIGHT_SUCCESS";
export const UPDATE_HIGHLIGHT_FAIL = "UPDATE_HIGHLIGHT_FAIL";


export const GET_HIGHLIGHT_DETAILS = "GET_HIGHLIGHT_DETAILS";
export const GET_HIGHLIGHT_DETAILS_SUCCESS = "GET_HIGHLIGHT_DETAILS_SUCCESS";
export const GET_HIGHLIGHT_DETAILS_FAIL = "GET_HIGHLIGHT_DETAILS_FAIL";

export const DELETE_HIGHLIGHT = "DELETE_HIGHLIGHT";
export const DELETE_HIGHLIGHT_SUCCESS = "DELETE_HIGHLIGHT_SUCCESS";
export const DELETE_HIGHLIGHT_FAIL = "DELETE_HIGHLIGHT_FAIL";

export const RESET_HIGHLIGHT_FLAG = "RESET_HIGHLIGHT_FLAG";

export const MODIFY_HIGHLIGHT_FLAG = "MODIFY_HIGHLIGHT_FLAG";


export const SAVE_ORDERING_HIGHLIGHT = "SAVE_ORDERING_HIGHLIGHT";
export const SAVE_ORDERING_HIGHLIGHT_SUCCESS = "SAVE_ORDERING_HIGHLIGHT_SUCCESS";
export const SAVE_ORDERING_HIGHLIGHT_FAIL = "SAVE_ORDERING_HIGHLIGHT_FAIL";
export const SAVE_ORDERING_HIGHLIGHT_RESET_FLAG = "SAVE_ORDERING_HIGHLIGHT_RESET_FLAG";

export const STATUS_CHANGE_HIGHLIGHT = "STATUS_CHANGE_HIGHLIGHT";
export const STATUS_CHANGE_HIGHLIGHT_SUCCESS = "STATUS_CHANGE_HIGHLIGHT_SUCCESS";
export const STATUS_CHANGE_HIGHLIGHT_FAIL = "STATUS_CHANGE_HIGHLIGHT_FAIL";