import {
  GET_TESTIMONIALS,
  GET_TESTIMONIALS_SUCCESS,
  GET_TESTIMONIALS_FAIL,
  ADD_NEW_TESTIMONIAL,
  ADD_NEW_TESTIMONIAL_SUCCESS,
  ADD_NEW_TESTIMONIAL_FAIL,
  GET_TESTIMONIAL_DETAILS,
  GET_TESTIMONIAL_DETAILS_SUCCESS,
  GET_TESTIMONIAL_DETAILS_FAIL,
  UPDATE_TESTIMONIAL,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_FAIL,
  RESET_TESTIMONIAL_FLAG,
  DELETE_TESTIMONIAL,
  DELETE_TESTIMONIAL_SUCCESS,
  DELETE_TESTIMONIAL_FAIL,
  STATUS_CHANGE_TESTIMONIAL,
  STATUS_CHANGE_TESTIMONIAL_SUCCESS,
  STATUS_CHANGE_TESTIMONIAL_FAIL,
  MODIFY_TESTIMONIAL_FLAG
} from "./actionTypes";

export const getTestimonials = data => ({
  type: GET_TESTIMONIALS,
  payload: data,
});

export const getTestimonialsSuccess = data => ({
  type: GET_TESTIMONIALS_SUCCESS,
  payload: data,
});

export const getTestimonialsFail = error => ({
  type: GET_TESTIMONIALS_FAIL,
  payload: error,
});

export const addNewTestimonial = testimonial=> ({
  type: ADD_NEW_TESTIMONIAL,
  payload: testimonial,
});

export const addNewTestimonialSuccess = testimonial => ({
  type: ADD_NEW_TESTIMONIAL_SUCCESS,
  payload: testimonial,
});

export const addNewTestimonialFail = error => ({
  type: ADD_NEW_TESTIMONIAL_FAIL,
  payload: error,
});

export const updateTestimonial = testimonial => ({
  type: UPDATE_TESTIMONIAL,
  payload: testimonial,
});

export const updateTestimonialSuccess = testimonial => ({
  type: UPDATE_TESTIMONIAL_SUCCESS,
  payload: testimonial,
});

export const updateTestimonialFail = error => ({
  type: UPDATE_TESTIMONIAL_FAIL,
  payload: error,
});

export const getTestimonialDetails = testimonial => ({
  type: GET_TESTIMONIAL_DETAILS,
  payload: testimonial,
});

export const getTestimonialDetailsSuccess = testimonial => ({
  type: GET_TESTIMONIAL_DETAILS_SUCCESS,
  payload: testimonial,
});

export const getTestimonialDetailsFail = error => ({
  type: GET_TESTIMONIAL_DETAILS_FAIL,
  payload: error,
});

export const resetTestimonialFlag = () => {
  return {
    type: RESET_TESTIMONIAL_FLAG,
  }
}

//Delete
export const deleteTestimonial = testimonial => ({
  type: DELETE_TESTIMONIAL,
  payload: testimonial,
});

export const deleteTestimonialSuccess = testimonial => ({
  type: DELETE_TESTIMONIAL_SUCCESS,
  payload: testimonial,
});

export const deleteTestimonialFail = error => ({
  type: DELETE_TESTIMONIAL_FAIL,
  payload: error,
});

//Status Change
export const statusChangeTestimonial = testimonial => ({
  type: STATUS_CHANGE_TESTIMONIAL,
  payload: testimonial,
});

export const statusChangeTestimonialSuccess = testimonial => ({
  type: STATUS_CHANGE_TESTIMONIAL_SUCCESS,
  payload: testimonial,
});

export const statusChangeTestimonialFail = error => ({
  type: STATUS_CHANGE_TESTIMONIAL_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyTestimonialFlag = () => {
  return {
    type: MODIFY_TESTIMONIAL_FLAG,
  }
}