import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
//import {updateSocketNotification, getSocketNotification, resetSocketNotification} from "../store/actions";
import {
  statusChangeOrder,
  statusChangeOrderPayment,
  resetOrderFlag,
  getOrderDetails,
  getSocketNotification,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { call } from "redux-saga/effects";
import { hostUrl } from "../../../config/host";
import axios from 'axios';
import Select from "react-select";

const OrderEdit = () => {
  document.title = "Edit Order | Defendit - Admin Panel";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const { isOrderUpdate, isOrderUpdateFail, error, success, details, orderNotification } =
    useSelector((state) => ({
      isOrderUpdate: state.Orders.isOrderUpdate,
      isOrderUpdateFail: state.Orders.isOrderUpdateFail,
      error: state.Orders.error,
      success: state.Orders.success,
      details: state.Orders.details,
      orderNotification: state.SocketNotification.orderNotification,
    }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: details && details.title ? details.title : "",
      icon: details && details.icon ? details.icon : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Order Name"),
      icon: Yup.string().required("Please Enter Icon"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);

      const data = {
        id: id,
        title: values.name,
        icon: values.icon,
      };
      //dispatch(updateOrder(data))
    },
  });

  useEffect(() => {
    if (isOrderUpdate || isOrderUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetOrderFlag());
      }, 3000);
    }
  }, [dispatch, isOrderUpdate, isOrderUpdateFail]);

  useEffect(() => {
    dispatch(getOrderDetails({ id: id }));
    dispatch(getSocketNotification({ id: id }));
  }, [dispatch, id]);


  const [reasonData, setReasonData] = useState(null);
  const [reasonOpt, setReasonOpt] = useState([]);
  const [reasonId, setReasonId] = useState(null);
  const [orderId, setOrderId] = useState(null);



  useEffect(() => {
    axios
      .post(`${hostUrl}/order-cancel-reason/list`, {  }, {})
      .then(function (response) {
        setReasonData(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  useEffect(() => {
    setOrderId(details?.order_id)
  },[details])

  useEffect(() => {
    if (reasonOpt.length < reasonData?.length) {
      reasonData?.forEach(element => {
        reasonOpt.push({value: element._id, label: element.name})
      });  
    }
  }, [reasonData])

  function handleCahnge(e) {
    console.log(e);
    setReasonId(e.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    //console.log(reasonId, orderId);
    axios
      .post(`${hostUrl}/order/order-cancel`,
       { id: details._id, order_cancel_reason_id: reasonId, type: "admin" },
       { })
      .then(function (response) {
        //setReasonData(response.data);
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }
  

  const [modal, setmodal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);


  //console.log(details);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Order Details" pageTitle="Orders" pageUrl="/orders" />
          <Row>
            <Col lg={9}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">
                      Order #{details?.order_id}
                    </h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="apps-invoices-details"
                        className="btn btn-success btn-sm"
                      >
                        <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                        Invoice
                      </Link>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card">
                    <table className="table table-nowrap align-middle table-borderless mb-0">
                      <thead className="table-light text-muted">
                        <tr>
                          <th scope="col">Product Details</th>
                          <th scope="col">Item Price</th>
                          <th scope="col">Quantity</th>
                          {/* <th scope="col">Rating</th> */}
                          <th scope="col" className="text-end">
                            Total Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {details?.order_detail?.map((product, key) => (
                          <tr key={key}>
                            <td>
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
                                  <img
                                    src={product?.product_info?.image}
                                    alt=""
                                    className="img-fluid d-block"
                                  />
                                </div>
                                {/* <p>{product?.product_info?.image}</p> */}
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-muted mb-0">
                                    <span className="fw-medium">
                                      {product?.product_info.title}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>${product?.price}</td>
                            <td>{product?.quantity}</td>
                            <td className="fw-medium text-end">
                              ${parseFloat(product?.price)*parseFloat(product?.quantity)}
                            </td>
                          </tr>
                        ))}
                        <tr className="border-top border-top-dashed">
                          <td colSpan="3"></td>
                          <td colSpan="2" className="fw-medium p-0">
                            <table className="table table-borderless mb-0">
                              <tbody>
                                <tr>
                                  <td>Sub Total :</td>
                                  <td className="text-end">${details?.order_total_amount}</td>
                                </tr>
                                <tr>
                                  <td>
                                    Discount{" "}
                                    <span className="text-muted">
                                    </span>{" "}
                                    : :
                                  </td>
                                  <td className="text-end">-${details?.coupon_discount_amount}</td>
                                </tr>
                                <tr>
                                  <td>Shipping Charge :</td>
                                  <td className="text-end">${details?.shipping_charge_amount}</td>
                                </tr>
                                <tr className="border-top border-top-dashed">
                                  <th scope="row">Total (USD) :</th>
                                  <th className="text-end">${parseFloat(details?.order_total_amount) - parseFloat(details?.coupon_discount_amount) + parseFloat(details?.coupon_discount_amount)}</th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
              <Row>
                
                <Col lg={8}></Col>
                <Col lg={2}><Button color="danger" onClick={() => setmodal(!modal)}>Cancel</Button></Col>
                <Col lg={1}><Button color="success">Confirm</Button></Col>
                
              </Row>
            </Col>

            <Col xl={3}>
              {/* <Card>
                <CardHeader>
                  <div className="d-flex">
                    <h5 className="card-title flex-grow-1 mb-0">
                      <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
                      Logistics Details
                    </h5>
                    <div className="flex-shrink-0">
                      <Link to="#" className="badge badge-soft-primary fs-11">
                        Track Order
                      </Link>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="text-center">
                    <lord-icon
                      src="https://cdn.lordicon.com/uetqnvvg.json"
                      trigger="loop"
                      colors="primary:#405189,secondary:#0ab39c"
                      style={{ width: "80px", height: "80px" }}
                    ></lord-icon>
                    <h5 className="fs-16 mt-2">RQK Logistics</h5>
                    <p className="text-muted mb-0">ID: MFDS1400457854</p>
                    <p className="text-muted mb-0">Payment Mode : Debit Card</p>
                  </div>
                </CardBody>
              </Card> */}

              <Card>
                <CardHeader>
                  <div className="d-flex">
                    <h5 className="card-title flex-grow-1 mb-0">
                      Customer Details
                    </h5>
                    <div className="flex-shrink-0">
                      <Link to="#" className="link-secondary">
                        View Profile
                      </Link>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <ul className="list-unstyled mb-0 vstack gap-3">
                    <li>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            //   src={avatar3}
                            alt=""
                            className="avatar-sm rounded"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6 className="fs-14 mb-1">Joseph Parkers</h6>
                          <p className="text-muted mb-0">Customer</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                      josephparker@gmail.com
                    </li>
                    <li>
                      <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                      +(256) 245451 441
                    </li>
                  </ul>
                </CardBody>
              </Card>

              {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}
                    Billing Address
                  </h5>
                </CardHeader>
                <CardBody>
                  <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                    <li className="fw-medium fs-14">Joseph Parker</li>
                    <li>+(256) 245451 451</li>
                    <li>2186 Joyce Street Rocky Mount</li>
                    <li>New York - 25645</li>
                    <li>United States</li>
                  </ul>
                </CardBody>
              </Card> */}

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}
                    Shipping Address
                  </h5>
                </CardHeader>
                <CardBody>
                  <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                    <li className="fw-medium fs-14">Joseph Parker</li>
                    <li>+(256) 245451 451</li>
                    <li>{details?.shipping_info?.address}</li>
                    <li>{details?.shipping_info?.city} - {details?.shipping_info?.zipcode}</li>
                    <li>{details?.shipping_info?.country_code} </li>
                  </ul>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "}
                    Payment Details
                  </h5>
                </CardHeader>
                <CardBody>
                  <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Transactions:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">#VLZ124561278124</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Payment Method:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">Debit Card</h6>
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Card Holder Name:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">Joseph Parker</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Card Number:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">xxxx xxxx xxxx 2456</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Total Amount:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">$415.96</h6>
                    </div>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={modal}
          centered={true}
        >
          <ModalHeader>
                      Cancel Order
                    </ModalHeader>
                    <ModalBody>
                      Are you sure want to cancel this order?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button
                          onClick={() => {
                            setmodal(false);
                          }
                         }
                          className="btn btn-sm btn-light">
                          No
                        </Button>

                        <Button
                          onClick={() => setReasonModal(!reasonModal)}
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody>
        </Modal>
        <Modal
          isOpen={reasonModal}
          centered={true}
          size='md'
        >
          <ModalHeader>
                      Select Reason
                    </ModalHeader>
                    <ModalBody>
                      <form onSubmit={handleSubmit}>
                     <Select 
                      options={reasonOpt}
                      onChange={e => handleCahnge(e)}
                     />
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button
                          onClick={() => {
                            setReasonModal(false);
                          }
                         }
                          className="btn btn-sm btn-light">
                          No
                        </Button>

                        <Button
                          type="submit"
                          //onClick={() => setReasonModaL(!reasonModal)}
                          className="btn btn-sm btn-danger ms-2"
                          disabled={reasonId ? false : true}
                          //onClick={e => handleSubmit(e)}
                        >
                          Yes
                        </Button>
                      </div>
                      </form>
                    </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default OrderEdit;
