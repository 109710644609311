import {
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  ADD_NEW_ORDER_SUCCESS,
  ADD_NEW_ORDER_FAIL,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  ORDER_PAYMENT_STATUS_CHANGE_SUCCESS,
  ORDER_PAYMENT_STATUS_CHANGE_FAIL,
  RESET_ORDER_FLAG,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  STATUS_CHANGE_ORDER_SUCCESS,
  STATUS_CHANGE_ORDER_FAIL,
  MODIFY_ORDER_FLAG, 
} from "./actionTypes";

const INIT_STATE = {
  orders: []
};

const Orders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_ORDER_SUCCESS:
      return {
        ...state,
        orders: [...state.orders, action.payload.data],
        success: action.payload.message,
        isOrderAdd: true,
        isOrderAddFail: false,
      };

    case ADD_NEW_ORDER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isOrderAdd: false,
        isOrderAddFail: true,
      };

    case ORDER_PAYMENT_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        orders: state.orders.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };

    case ORDER_PAYMENT_STATUS_CHANGE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_ORDER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_ORDER_SUCCESS:
      return { 
        ...state,
        orders: state.orders.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_ORDER_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_ORDER_FLAG:
      return {
        ...state,
        isOrderAdd: false,
        isOrderAddFail: false,
        isSuccess: false,
        isOrderDetails: false,
        isOrderUpdate: false,
        isOrderUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Orders;
