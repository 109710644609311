/* FUTURETEAM */
export const GET_FUTURETEAM = "GET_FUTURETEAM";
export const GET_FUTURETEAM_SUCCESS = "GET_FUTURETEAM_SUCCESS";
export const GET_FUTURETEAM_FAIL = "GET_FUTURETEAM_FAIL";

export const ADD_NEW_FUTURETEAM = "ADD_NEW_FUTURETEAM";
export const ADD_NEW_FUTURETEAM_SUCCESS = "ADD_NEW_FUTURETEAM_SUCCESS";
export const ADD_NEW_FUTURETEAM_FAIL = "ADD_NEW_FUTURETEAM_FAIL";

export const GET_FUTURETEAM_DETAILS = "GET_FUTURETEAM_DETAILS";
export const GET_FUTURETEAM_DETAILS_SUCCESS = "GET_FUTURETEAM_DETAILS_SUCCESS";
export const GET_FUTURETEAM_DETAILS_FAIL = "GET_FUTURETEAM_DETAILS_FAIL";

export const UPDATE_FUTURETEAM = "UPDATE_FUTURETEAM";
export const UPDATE_FUTURETEAM_SUCCESS = "UPDATE_FUTURETEAM_SUCCESS";
export const UPDATE_FUTURETEAM_FAIL = "UPDATE_FUTURETEAM_FAIL";

export const STATUS_CHANGE_FUTURETEAM = "STATUS_CHANGE_FUTURETEAM";
export const STATUS_CHANGE_FUTURETEAM_SUCCESS = "STATUS_CHANGE_FUTURETEAM_SUCCESS";
export const STATUS_CHANGE_FUTURETEAM_FAIL = "STATUS_CHANGE_FUTURETEAM_FAIL";

export const DELETE_FUTURETEAM = "DELETE_FUTURETEAM";
export const DELETE_FUTURETEAM_SUCCESS = "DELETE_FUTURETEAM_SUCCESS";
export const DELETE_FUTURETEAM_FAIL = "DELETE_FUTURETEAM_FAIL";

export const SAVE_ORDERING_FUTURETEAM = "SAVE_ORDERING_FUTURETEAM";
export const SAVE_ORDERING_FUTURETEAM_SUCCESS = "SAVE_ORDERING_FUTURETEAM_SUCCESS";
export const SAVE_ORDERING_FUTURETEAM_FAIL = "SAVE_ORDERING_FUTURETEAM_FAIL";
export const SAVE_ORDERING_FUTURETEAM_RESET_FLAG = "SAVE_ORDERING_FUTURETEAM_RESET_FLAG";

export const RESET_FUTURETEAM_FLAG = "RESET_FUTURETEAM_FLAG";
export const MODIFY_FUTURETEAM_FLAG = "MODIFY_FUTURETEAM_FLAG";