import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogcomments,
  deleteBlogComment,
  modifyBlogCommentFlag,
  statusChangeBlogComment,
  resetBlogCommentFlag,
} from "../../../store/actions";
import Flatpickr from "react-flatpickr";
import MsgToast from "../../../Components/Common/MsgToast";
import Loader from "../../../Components/Common/Loader";
import Moment from "react-moment";
import "moment-timezone";
function List() {
  const dispatch = useDispatch();
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState("");
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortByField] = useState("created_at");
  const [sortValue, setSortByValue] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  
  let { id } = useParams();
  useEffect(() => {
    dispatch(getBlogcomments({ limit, date, keyword, blog_id: id }));
  }, [dispatch, limit, offset, date, keyword, id]);

  const {
    blogcomments,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
  } = useSelector((state) => ({
    blogcomments: state.Blogs.blogcomments,
    totalDataCount: state.Blogs.totalDataCount,
    isSuccess: state.Blogs.isSuccess,
    success: state.Blogs.success,
    error: state.Blogs.error,
    statusSuccess: state.Blogs.statusSuccess,
    statusFailed: state.Blogs.statusFailed,
  }));
  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };
  const delBlogcomment = (id) => {
    dispatch(deleteBlogComment({ id }));
  };
  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyBlogCommentFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);
  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(
        getBlogcomments({
          limit,
          dateRange,
          offset,
          sortKey,
          sortValue,
          keyword,
        })
      );
    }
  }, [dispatch, date, limit, offset, sortKey, sortValue, keyword]);
  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [dispatch, limit, totalDataCount]);

  function onClickStatusChange(id, is_approve) {
    //console.log(id, status);
    dispatch(statusChangeBlogComment({ id, is_approve }));
  }

  const resetFilter = () => {
    dispatch(resetBlogCommentFlag());
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    dispatch(
      getBlogcomments({ keyword, date, sortKey, sortValue, limit, offset })
    );
  };

  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");

  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
  };

  //console.log(blogcomments);

  document.title = "Blog-Comment List | Dabbawala";
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Comments" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Comment List
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(+e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-2">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={3} className="col-xxl-2">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      {/* <Col sm={2} className="col-xxl-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={resetFilter}
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            Filters
                          </button>
                        </div>
                      </Col> */}
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {blogcomments.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th>
                                <th>Blog title</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="name"
                                  onClick={() => sortBy("name")}
                                >
                                  Name
                                </th>

                                <th
                                  className="sort cursor-pointer"
                                  data-sort="email"
                                  onClick={() => sortBy("email")}
                                >
                                  Email
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="comment"
                                  onClick={() => sortBy("comment")}
                                >
                                  Comment
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Approval</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {blogcomments.map((item) => (
                                <tr key={item._id}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value={item._id}
                                      />
                                    </div>
                                  </th>
                                  <td>{item?.blog_title?.title.substring(0,15)}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.email}</td>
                                  <td>{item?.comment.substring(0,25)}</td>

                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    {item.is_approve === true ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-warning text-uppercase">
                                        Pending
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <UncontrolledDropdown>
                                        <DropdownToggle className="dropdown-menu-icon">
                                          <i className="ri-more-2-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                        <DropdownItem
                                            className="list-inline-item"
                                            title="Status Change"
                                          >
                                            <div
                                              onClick={() =>
                                                onClickStatusChange(
                                                  item._id,
                                                  // item.status ? true : false,
                                                  item.is_approve
                                                    ? false
                                                    : true
                                                )
                                              }
                                              className="p-0 bg-transparent border-0 text-warning d-flex align-items-center"
                                            >
                                              <i className="ri-exchange-line fs-16 mr-2"></i>Status Change
                                            </div>
                                            <i className="fs-16"></i>
                                        
                                      </DropdownItem>
                                          <DropdownItem>
                                            <div
                                              className="p-0 bg-transparent border-0 d-flex align-items-center text-danger"
                                              onClick={() =>
                                                openDeleteConfrontation(
                                                  item?._id
                                                )
                                              }
                                            >
                                              <i className="ri-delete-bin-5-fill fs-16 mr-2"></i>{" "}
                                              Delete
                                            </div>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                  <Modal
                    isOpen={modal}
                    toggle={openDeleteConfrontation}
                    trigger="click"
                  >
                    <ModalHeader>Delete</ModalHeader>
                    <ModalBody>
                      Are you sure want to delete?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button
                          onClick={() => {
                            setModal(!modal);
                          }}
                          className="btn btn-sm btn-light"
                        >
                          No
                        </Button>

                        <Button
                          onClick={() => {
                            delBlogcomment(deletedId);
                            setModal(!modal);
                          }}
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </>
  );
}

export default List;
