/* SERVICES */
export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";

export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE";
export const ADD_NEW_SERVICE_SUCCESS = "ADD_NEW_SERVICE_SUCCESS";
export const ADD_NEW_SERVICE_FAIL = "ADD_NEW_SERVICE_FAIL";

export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL";

export const GET_SERVICE_DETAILS = "GET_SERVICE_DETAILS";
export const GET_SERVICE_DETAILS_SUCCESS = "GET_SERVICE_DETAILS_SUCCESS";
export const GET_SERVICE_DETAILS_FAIL = "GET_SERVICE_DETAILS_FAIL";

export const RESET_SERVICE_FLAG = "RESET_SERVICE_FLAG";

export const DELETE_SERVICE = "DELETE_SERVICE";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";

export const STATUS_CHANGE_SERVICE = "STATUS_CHANGE_SERVICE";
export const STATUS_CHANGE_SERVICE_SUCCESS = "STATUS_CHANGE_SERVICE_SUCCESS";
export const STATUS_CHANGE_SERVICE_FAIL = "STATUS_CHANGE_SERVICE_FAIL";

export const SAVE_ORDERING_SERVICE = "SAVE_ORDERING_SERVICE";
export const SAVE_ORDERING_SERVICE_SUCCESS = "SAVE_ORDERING_SERVICE_SUCCESS";
export const SAVE_ORDERING_SERVICE_FAIL = "SAVE_ORDERING_SERVICE_FAIL";
export const SAVE_ORDERING_SERVICE_RESET_FLAG = "SAVE_ORDERING_SERVICE_RESET_FLAG";

export const MODIFY_SERVICE_FLAG = "MODIFY_SERVICE_FLAG";