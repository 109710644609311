import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_FAQS,
  ADD_NEW_FAQ,
  GET_FAQ_DETAILS,
  UPDATE_FAQ,
  DELETE_FAQ,
  STATUS_CHANGE_FAQ,

  SAVE_ORDERING_FAQ,

  GET_FAQ_FEEDBACK,
} from "./actionTypes"

import {
  getFaqsSuccess,
  getFaqsFail,
  addNewFaqSuccess,
  addNewFaqFail,
  getFaqDetailsSuccess,
  getFaqDetailsFail,
  updateFaqSuccess,
  updateFaqFail,
  deleteFaqSuccess,
  deleteFaqFail,
  statusChangeFaqSuccess,
  statusChangeFaqFail,

  saveOrderingFaqSuccess,
  saveOrderingFaqFail,

  getFaqFeedbackSuccess,
  getFaqFeedbackFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFaqList,
  addNewFaq,
  getFaqDetails,
  updateFaq,
  delFaq,
  statusChangeFaq,

  saveOrdering,

  getFaqFeedbackList,
} from "../../helpers/fakebackend_helper"

function* fetchFaqs({ payload: faq }) {
  try {
    const response = yield call(getFaqList, faq)
    yield put(getFaqsSuccess(response))
  } catch (error) {
    yield put(getFaqsFail(error))
  }
}

export function* watchFetchFaqs() {
  yield takeEvery(GET_FAQS, fetchFaqs);
}

function* onAddNewFaq({ payload: faq }) {
  try {
      const response = yield call(addNewFaq, faq);
      if(response.status === 200){
        yield put(addNewFaqSuccess(response));
      } else {
        yield put(addNewFaqFail(response));
      }
  } catch (error) {
      yield put(addNewFaqFail(error));
  }
}

export function* watchAddNewFaq() {
  yield takeEvery(ADD_NEW_FAQ, onAddNewFaq);
}

function* onFaqDetails({ payload: faq }) {
  try {
      const response = yield call(getFaqDetails, faq);
      if(response.status === 200){
        yield put(getFaqDetailsSuccess(response));
      } else {
        yield put(getFaqDetailsFail(response));
      }
  } catch (error) {
      yield put(getFaqDetailsFail(error));
  }
}

export function* watchFaqDetails() {
  yield takeEvery(GET_FAQ_DETAILS, onFaqDetails);
}

function* onFaqUpdate({ payload: faq }) {
  try {
      const response = yield call(updateFaq, faq);
      if(response.status === 200){
        yield put(updateFaqSuccess(response));
      } else {
        yield put(updateFaqFail(response));
      }
  } catch (error) {
      yield put(updateFaqFail(error));
  }
}

export function* watchUpdateFaq() {
  yield takeEvery(UPDATE_FAQ, onFaqUpdate);
}

function* onDeleteFaq({payload : data}) {
  try {
    const response = yield call(delFaq,data)
    yield put(deleteFaqSuccess(response))
  } catch (error) {
    yield put(deleteFaqFail(error))
  }
}

export function* watchDeleteFaq() {
  yield takeEvery(DELETE_FAQ, onDeleteFaq);
}
//Status Change
function* onStatusChangeFaq({ payload: faq }) {
  try {
    const response = yield call(statusChangeFaq, faq);
    yield put(statusChangeFaqSuccess({ faq, ...response }));
  } catch (error) {
    yield put(statusChangeFaqFail(error));
  }
}

export function* watchStatusChangeFaq() {
  yield takeEvery(STATUS_CHANGE_FAQ, onStatusChangeFaq);
}


//Save Ordering
function* onSaveOrderingFaq({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingFaqSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingFaqFail(error));
  }
}

export function* watchSaveOrderingFaq() {
  yield takeEvery(SAVE_ORDERING_FAQ, onSaveOrderingFaq);
}



function* fetchFaqFeedback({ payload: faq }) {
  try {
    const response = yield call(getFaqFeedbackList, faq)
    yield put(getFaqFeedbackSuccess(response))
  } catch (error) {
    yield put(getFaqFeedbackFail(error))
  }
}

export function* watchFaqFeedback() {
  yield takeEvery(GET_FAQ_FEEDBACK, fetchFaqFeedback);
}



function* faqSaga() {
  yield all(
    [
      fork(watchFetchFaqs),
      fork(watchAddNewFaq),
      fork(watchFaqDetails),
      fork(watchUpdateFaq),
      fork(watchDeleteFaq),
      fork(watchStatusChangeFaq),
      fork(watchSaveOrderingFaq),
      fork(watchFaqFeedback),
    ]
  );

}

export default faqSaga
