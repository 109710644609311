import {
  ADD_NEW_JOB_TYPE_SUCCESS,
  ADD_NEW_JOB_TYPE_FAIL,
  UPDATE_JOB_TYPE_SUCCESS,
  UPDATE_JOB_TYPE_FAIL,
  GET_JOB_TYPE_DETAILS_SUCCESS,
  GET_JOB_TYPE_DETAILS_FAIL,
  GET_JOB_TYPE_LIST_SUCCESS,
  GET_JOB_TYPE_LIST_FAIL,
  DELETE_JOB_TYPE_SUCCESS,
  DELETE_JOB_TYPE_FAIL,
  STATUS_CHANGE_JOBTYPE_SUCCESS,
  STATUS_CHANGE_JOBTYPE_FAIL,
  MODIFY_JOB_TYPE_FLAG,
  RESET_JOB_TYPE_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  jobTypeList: [],
};

const JobTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_JOB_TYPE_SUCCESS:
      return {
        ...state,
        jobTypeList: [...state.jobTypeList, action.payload.data],
        success: action.payload.message,
        isJobTypeAdd: true,
        isJobTypeAddFail: false,
      };

    case ADD_NEW_JOB_TYPE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isJobTypeAdd: false,
        isJobTypeAddFail: true,
      };

    case UPDATE_JOB_TYPE_SUCCESS:
      return {
        ...state,
        jobTypeList: state.jobTypeList.map((job) =>
          job._id.toString() === action.payload.data._id.toString()
            ? { ...job, ...action.payload.data }
            : job
        ),
        success: action.payload.message,
        isJobTypeUpdate: true,
        isJobTypeUpdateFail: false,
      };

    case UPDATE_JOB_TYPE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isJobTypeUpdate: false,
        isJobTypeUpdateFail: true,
      };

    case GET_JOB_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data ? action.payload.data : [],
      };

    case GET_JOB_TYPE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

      //Status Change
    case STATUS_CHANGE_JOBTYPE_SUCCESS:
      return {
        ...state,
        jobTypeList: state.jobTypeList.map((job) =>
          job._id.toString() === action.payload.data._id.toString()
            ? { ...job, ...action.payload.data }
            : job
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_JOBTYPE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    //Job Type List
    case GET_JOB_TYPE_LIST_SUCCESS:
      return {
        ...state,
        jobTypeList: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_JOB_TYPE_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount:0,
        isSuccess: false,
      };
      case DELETE_JOB_TYPE_SUCCESS:
        return { 
          ...state,
          jobTypeList: state.jobTypeList.filter(getData =>
            {
               return getData._id !== action.payload.data._id
            }
            
          ),
          success: action.payload.message,
          isSuccess: true,
          statusSuccess: true,
          statusFailed: false,
          isJobTypeRemove: true,
          isJobTypeRemoveFail: false,
        };
    
      case DELETE_JOB_TYPE_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false,
          statusSuccess: false,
          statusFailed: true,
          isJobTypeRemove: false,
          isJobTypeRemoveFail: true,
        };
        case MODIFY_JOB_TYPE_FLAG:
          return {
            ...state,
            statusSuccess: false,
          };
    case RESET_JOB_TYPE_FLAG:
      return {
        ...state,
        isJobTypeAdd: false,
        isJobTypeAddFail: false,
        isSuccess: false,
        isJobTypeDetails: false,
        isJobTypeUpdate: false,
        isJobTypeUpdateFail: false,
        error: false,
      };

    default:
      return state;
  }
};

export default JobTypes;
