import { GET_PROFILE,GET_PROFILE_SUCCESS,GET_PROFILE_ERROR,PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG } from "./actionTypes"

export const getUser = user => {
  return {
    type: GET_PROFILE,
    payload:  user ,
  }
}

export const getProfileSuccess = user => ({
  type: GET_PROFILE_SUCCESS,
  payload: user,
});

export const getProfileFail = error => ({
  type: GET_PROFILE_ERROR,
  payload: error,
});

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
