import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isJob, setIsjob] = useState(false);
  const [isBlog, setIsBlog] = useState(false);
  const [isPoint, setIsPoint] = useState(false);
  const [isCoupon, setIsCoupon] = useState(false);
  const [isOrganization, setIsOrganization] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [isFaq, setIsFaq] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [isSpice, setIsSpice] = useState(false);
  const [isHighlights, setIsHighlights] = useState(false);
  const [isOrder, setIsOrder] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [isBanners, setIsBanners] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
  }, [history, iscurrentState, isDashboard]);

  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },
    {
      label: "User Management",
      isHeader: true,
    },
    {
      id: "admins",
      label: "Admins",
      icon: "ri-shield-user-fill",
      link: "/admins",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Admins");
      },
    },
    {
      id: "users",
      label: "Customers",
      icon: "ri-user-line",
      link: "/users",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Users");
      },
    },

    {
      label: "Product management",
      isHeader: true,
    },
    {
      id: "products",
      label: "Products",
      icon: "ri-question-answer-line",
      link: "/#",
      stateVariables: isProduct,
      click: function (e) {
        e.preventDefault();
        setIsProduct(!isProduct);
        setIscurrentState("Products");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "productlist1",
          label: "Food List",
          link: "/products/1",
          parentId: "products",
        },
        {
          id: "productlist2",
          label: "Add-on Group",
          link: "/addon-group",
          parentId: "products",
        },
        {
          id: "productlist3",
          label: "Merchandise List",
          link: "/products/3",
          parentId: "products",
        },
        {
          id: "productlist4",
          label: "Combo Deal",
          link: "/combos",
          parentId: "products",
        },
        {
          id: "product-categories",
          label: "Categories",
          link: "/product-categories",
          parentId: "products",
        },
        {
          id: "allergie",
          label: "Allergies",
          link: "/allergies",
          parentId: "products",
        },
        {
          id: "spicelevellist",
          label: "Spice Level",
          link: "/spices",
          parentId: "products",
        },
        {
          id: "dietlist",
          label: "Diets",
          link: "/diets",
          parentId: "products",
        },
        {
          id: "takeoftime",
          label: "Take Of Time",
          link: "/takeoftime",
          parentId: "products",
        },
        {
          id: "couponlist",
          label: "Coupons",
          link: "/coupon",
          parentId: "products",
        },
      ],
    },
   

    // {
    //     id: "podcasts",
    //     label: "Podcasts",
    //     icon: "ri-mini-program-line",
    //     link: "/podcasts",
    //     click: function (e) {
    //         e.preventDefault();
    //         setIscurrentState('Podcasts');
    //     }
    // },

    {
      label: "Order management",
      isHeader: true,
    },
    {
      id: "orders",
      label: "Orders",
      icon: "ri-shopping-bag-3-line",
      link: "/#",
      stateVariables: isOrder,
      click: function (e) {
        e.preventDefault();
        setIsOrder(!isOrder);
        setIscurrentState("Orders");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "list",
          label: "List",
          link: "/orders",
          parentId: "orders",
        },
        // {
        //   id: "history",
        //   label: "History",
        //   link: "/history",
        //   parentId: "orders",
        // },
      ],
    },
    {
      id: "menu",
      label: "Menu",
      icon: "ri-function-line",
      link: "/#",
      stateVariables: isMenu,
      click: function (e) {
        e.preventDefault();
        setIsMenu(!isMenu);
        setIscurrentState("Menu");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "ingredients",
          label: "Ingredients",
          link: "/ingredients",
          parentId: "menu",
        },
        {
          id: "items",
          label: "Items",
          link: "/items",
          parentId: "menu",
        },
      ],
    },

    {
      id: "delivery",
      label: "Delivery",
      icon: "ri-shopping-bag-3-line",
      link: "/#",
      stateVariables: isDelivery,
      click: function (e) {
        e.preventDefault();
        setIsDelivery(!isDelivery);
        setIscurrentState("Menu");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "options",
          label: "Options",
          link: "/options",
          parentId: "delivery",
        },
        {
          id: "price",
          label: "Prices",
          link: "/prices",
          parentId: "delivery",
        },
      ],
    },



    {
      label: "Blog management",
      isHeader: true,
    },
    {
      id: "blogs",
      label: "Blogs",
      icon: "ri-article-line",
      link: "/#",
      stateVariables: isBlog,
      click: function (e) {
        e.preventDefault();
        setIsBlog(!isBlog);
        setIscurrentState("Blogs");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "bloglist",
          label: "Create blog",
          link: "/blogs",
          parentId: "blogs",
        },
        {
          id: "blog-categories",
          label: "Categories",
          link: "/blog-categories",
          parentId: "blogs",
        },
        {
          id: "blog-authors",
          label: "Authors",
          link: "/authors",
          parentId: "authors",
        },
        {
          id: "blogcomment",
          label: "Comments",
          link: "/blog-comments",
          parentId: "blogs",
        },
      ],
    },

    // Point Management
    {
      label: "Point management",
      isHeader: true,
    },
    {
      id: "points",
      label: "Points",
      icon: "ri-article-line",
      link: "/#",
      stateVariables: isPoint,
      click: function (e) {
        e.preventDefault();
        setIsPoint(!isPoint);
        setIscurrentState("Points");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "pointlist",
          label: "List",
          link: "/point",
          parentId: "points",
        },
      ],
    },

    // Organization Management
    {
      label: "Organization management",
      isHeader: true,
    },
    {
      id: "organizations",
      label: "Organizations",
      icon: "ri-article-line",
      link: "/#",
      stateVariables: isOrganization,
      click: function (e) {
        e.preventDefault();
        setIsOrganization(!isOrganization);
        setIscurrentState("Organizations");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "organizationlist",
          label: "List",
          link: "/organization",
          parentId: "organizations",
        },
        // {
        //   id: "organizationdetails",
        //   label: "Details",
        //   link: "/details",
        //   parentId: "organizations",
        // },
      ],
    },

    {
      label: "Job management",
      isHeader: true,
    },

    {
      id: "jobs",
      label: "Jobs",
      icon: "ri-briefcase-line",
      link: "/#",
      stateVariables: isJob,
      click: function (e) {
        e.preventDefault();
        setIsjob(!isJob);
        setIscurrentState("Jobs");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "jobApplylist",
          label: "Applications",
          link: "/jobapplies",
          parentId: "jobs",
        },
        {
          id: "job-type",
          label: "Job Type",
          link: "/jobtypes",
          parentId: "jobs",
        },
        {
          id: "location-type",
          label: "Location Type",
          link: "/joblocationtypes",
          parentId: "jobs",
        },
        {
          id: "jobslist",
          label: "List",
          link: "/jobs",
          parentId: "jobs",
        },
      ],
    },
    {
      label: "Zipcode management",
      isHeader: true,
    },
    {
      id: "pins",
      label: "Zip Codes",
      icon: "ri-article-line",
      link: "/#",
      stateVariables: isPin,
      click: function (e) {
        e.preventDefault();
        setIsPin(!isPin);
        setIscurrentState("Pins");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "ziplist",
          label: "Zip Code list",
          link: "/zips",
          parentId: "pins",
        },
      ],
    },
    {
      label: "Content management",
      isHeader: true,
    },
    {
      id: "faqs",
      label: "Faqs",
      icon: "ri-question-answer-line",
      link: "/#",
      stateVariables: isFaq,
      click: function (e) {
        e.preventDefault();
        setIsFaq(!isFaq);
        setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "faq-categories",
          label: "Categories",
          link: "/faq-categories",
          parentId: "faqs",
        },
        {
          id: "faqlist",
          label: "List",
          link: "/faqs",
          parentId: "faqs",
        },
        {
          id: "faqfeedback",
          label: "FAQ Feedback",
          link: "/faq-feedback",
          parentId: "faqs",
        },
      ],
    },
    
    {
      id: "steps",
      label: "Steps",
      icon: " ri-stack-line",
      link: "/steps",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Steps");
      },
    },
    {
      id: "services",
      label: "Services",
      icon: " ri-briefcase-3-line",
      link: "/services",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Services");
      },
    },
    {
      id: "benefits",
      label: "Benefits",
      icon: " ri-service-line",
      link: "/benefits",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Benefits");
      },
    },
    // {
    //   id: "testimonials",
    //   label: "Testimonials",
    //   icon: " ri-chat-quote-line",
    //   link: "/testimonials",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Testimonials");
    //   },
    // },
    {
      id: "funfacts",
      label: "Funfacts",
      icon: " ri-chat-quote-line",
      link: "/funfacts",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Funfacts");
      },
    },
    {
      id: "teams",
      label: "Teams",
      icon: " ri-chat-quote-line",
      link: "/teams",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Teams");
      },
    },
    {
      id: "missions",
      label: "Missions",
      icon: " ri-chat-quote-line",
      link: "/missions",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Missions");
      },
    },
    {
      id: "visions",
      label: "Visions",
      icon: " ri-chat-quote-line",
      link: "/visions",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Visions");
      },
    },
    {
      id: "highlights",
      label: "Highlights",
      icon: " ri-stack-line",
      link: "/highlights",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Highlights");
      },
    },
    {
      id: "futureteam",
      label: "Future Team",
      icon: " ri-stack-line",
      link: "/future-team",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Futureteam");
      },
    },
    {
      id: "openinghours",
      label: "Opening Hours",
      icon: "ri-time-line",
      link: "/opening-hours",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Openinghours");
      },
    },
    {
      id: "pages",
      label: "Pages",
      icon: "ri-pages-line",
      link: "/pages",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Pages");
      },
    },

    {
      id: "banners",
      label: "Banners",
      icon: " ri-image-line",
      link: "/banners",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Banners");
      },
    },

    {
      id: "settings",
      label: "Site Settings",
      icon: "ri-settings-2-line",
      link: "/site/edit/624c1d79ac364e5ef3796d76",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Settings");
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
