/* BLOGS */
export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL";

/* BLOG-COMMENTS */
export const GET_BLOGCOMMENTS = "GET_BLOGCOMMENTS";
export const GET_BLOGCOMMENTS_SUCCESS = "GET_BLOGCOMMENTS_SUCCESS";
export const GET_BLOGCOMMENTS_FAIL = "GET_BLOGCOMMENTS_FAIL";


export const ADD_NEW_BLOG = "ADD_NEW_BLOG";
export const ADD_NEW_BLOG_SUCCESS = "ADD_NEW_BLOG_SUCCESS";
export const ADD_NEW_BLOG_FAIL = "ADD_NEW_BLOG_FAIL";

export const UPDATE_BLOG = "UPDATE_BLOG";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL";

export const GET_BLOG_DETAILS = "GET_BLOG_DETAILS";
export const GET_BLOG_DETAILS_SUCCESS = "GET_BLOG_DETAILS_SUCCESS";
export const GET_BLOG_DETAILS_FAIL = "GET_BLOG_DETAILS_FAIL";


export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";

export const DELETE_BLOGCOMMENT = "DELETE_BLOGCOMMENT";
export const DELETE_BLOGCOMMENT_SUCCESS = "DELETE_BLOGCOMMENT_SUCCESS";
export const DELETE_BLOGCOMMENT_FAIL = "DELETE_BLOGCOMMENT_FAIL";

export const MODIFY_BLOG_FLAG = "MODIFY_BLOG_FLAG";
export const MODIFY_BLOGCOMMENT_FLAG = "MODIFY_BLOGCOMMENT_FLAG";

export const RESET_BLOG_FLAG = "RESET_BLOG_FLAG";
export const RESET_BLOGCOMMENT_FLAG = "RESET_BLOGCOMMENT_FLAG";

// STATUS CHANGE
export const STATUS_CHANGE_BLOG = "STATUS_CHANGE_BLOG";
export const STATUS_CHANGE_BLOG_SUCCESS = "STATUS_CHANGE_BLOG_SUCCESS";
export const STATUS_CHANGE_BLOG_FAIL = "STATUS_CHANGE_BLOG_FAIL";

export const STATUS_CHANGE_BLOGCOMMENT = "STATUS_CHANGE_BLOGCOMMENT";
export const STATUS_CHANGE_BLOGCOMMENT_SUCCESS = "STATUS_CHANGE_BLOGCOMMENT_SUCCESS";
export const STATUS_CHANGE_BLOGCOMMENT_FAIL = "STATUS_CHANGE_BLOGCOMMENT_FAIL";

export const SAVE_ORDERING_BLOG = "SAVE_ORDERING_BLOG";
export const SAVE_ORDERING_BLOG_SUCCESS = "SAVE_ORDERING_BLOG_SUCCESS";
export const SAVE_ORDERING_BLOG_FAIL = "SAVE_ORDERING_BLOG_FAIL";
export const SAVE_ORDERING_BLOG_RESET_FLAG = "SAVE_ORDERING_BLOG_RESET_FLAG";