import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import slugify from "slugify";
import axios from "axios";
import hostUrl from "../../../config";

import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  CardHeader,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateCombo,
  resetComboFlag,
  getComboDetails,
  getProducts,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertToBase64 } from "../../../helpers/image";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ComboEdit = () => {
  document.title = "Edit Combo | Dabbawala";
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState(null);
  const [comboData, setComboData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [allergyData, setAllergyData] = useState();
  const [productArray, setProductArray] = useState([]);
  const [dietData, setDietData] = useState();
  const [spiceData, setSpiceData] = useState();
  const [description, setData] = useState();
  const [Type, setType] = useState();
  const [spiceLevelArray, setSpiceLevelArray] = useState([]);
  const [addOn, setAddOn] = useState();
  const [food, setFood] = useState();

  const {
    isComboUpdate,
    isComboUpdateFail,
    error,
    success,
    details,
    categories,
    products,
  } = useSelector((state) => ({
    isComboUpdate: state.Combos.isComboUpdate,
    isComboUpdateFail: state.Combos.isComboUpdateFail,
    error: state.Combos.error,
    success: state.Combos.success,
    details: state.Combos.details,
    categories: state.Categories.categories,
    products: state.Products.products,
  }));

  // useEffect(() => {
  //   dispatch( getProducts({product_type: 2}));
  //   setAddOn(products)
  //   console.log(addOn);
  // },[dispatch, products]);

  // useEffect(() => {
  //   dispatch( getProducts({product_type: 1}));
  //   setFood(products)
  //   console.log(food);
  // },[dispatch, products]);

  useEffect(() => {

    //setComboData(combo);
    axios
      .post(
        `${hostUrl.api.API_URL}products/list`,
        { product_type: 1, limit: 1000 },
        {}
      )
      .then(function (response) {
        setFood(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

      axios
      .post(
        `${hostUrl.api.API_URL}products/list`,
        { product_type: 2 },
        {}
      )
      .then(function (response) {
        setAddOn(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    
  }, [products]);

  // console.log(food);
  // console.log(addOn);

  const selectedSpiceLevelOption = {
    value: details && details.spice_level ? details.spice_level?._id : "",
    label: details && details.spice_level ? details.spice_level?.title : "",
  };

  const selectedDietOption = {
    value: details && details.diet ? details.diet?._id : "",
    label: details && details.diet ? details.diet?.title : "",
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: details && details?.title ? details.title : "",
      slug: details && details?.slug ? details.slug : "",
      
      sale_price: details?.sale_price,
      regular_price: details?.regular_price
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please enter your title"),
      slug: Yup.string().required("Please enter your slug"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        slug: values.slug,
        regular_price: values.regular_price,
        sale_price: values.sale_price,
        in_stock: stock.value,
        product_id: productArray,
      };
      //console.log(data)
      dispatch(updateCombo(data));
    },
  });

  useEffect(() => {
    if (isComboUpdate || isComboUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetComboFlag());
        history.push(`/combos`);
      }, 3000);
    }
  }, [dispatch, isComboUpdate, isComboUpdateFail, validation, history]);

  useEffect(() => {
    dispatch(getComboDetails({ id: id }));
  }, [dispatch, id]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const type = details?.combo_type;
  const types = {
    1: "Food Combo",
    2: "Add-on Combo",
    3: "Merchandise Combo",
  };
  const options = [
    { value: 1, label: "Food Combo" },
    { value: 2, label: "Add-on Combo" },
    { value: 3, label: "Merchandise Combo" },
  ];
  const [selectData, setSelectData] = useState();

  useEffect(() => {
    setSelectData({
      label: types[type],
      value: parseInt(type),
    });
  }, [type]);

  function handleSelectGroup(label, value) {
    setSelectData({ label: label, value: value });
    setType(value);
  }

  function handleFeature(label, value) {
    setFeature({ label: label, value: value });
  }

  function handleStock(label, value) {
    setStock({ label: label, value: value });
  }

  const optionsStock = [
    { value: true, label: "In Stock" },
    { value: false, label: "Out of Stock" },
  ];

  const optionsFeature = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const [feature, setFeature] = useState();
  const [stock, setStock] = useState();
  const [allergyArray, setAllergyArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);

  useEffect(() => {
    setData(details?.short_description);
    setType(type);
    setStock({
      value: details?.in_stock,
      label: details?.in_stock === true ? "In Stock" : "Out of Stock",
    });

    const productData = details?.product_details
      ? [...new Set(details?.product_details.map((item) => item._id))]
      : null;
      setProductArray(productData);


  }, [details]);
   console.log(productArray);
  //Category On change
  function handleChange(e) {
    let a = e.target.checked;
    if (a) {
      setCategoryArray([...categoryArray, e.target.value]);
    } else {
      setCategoryArray(categoryArray.filter((cat) => cat !== e.target.value));
    }
  }

  //Allergy Onchange
  function handleChangeAllergy(e) {
    let a = e.target.checked;
    if (a) {
      setAllergyArray([...allergyArray, e.target.value]);
    } else {
      setAllergyArray(allergyArray.filter((cat) => cat !== e.target.value));
    }
  }

  function handleChangeSpiceLevel(e) {
    let a = e.target.checked;
    if (a) {
      setSpiceLevelArray([...spiceLevelArray, e.target.value]);
    } else {
      // remove from list
      setSpiceLevelArray(spiceLevelArray.filter((data) => data !== e.target.value));
    }
  }


  function handleProduct(e) {
    let a = e.target.checked;
    if (a) {
      setProductArray([...productArray, e.target.value]);
    } else {
      setProductArray(
        productArray.filter((data) => data !== e.target.value)
      );
    }
  }

  //console.log(details);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Edit Combo"
            pageTitle="Combos"
            pageUrl={`/combos/${type}`}
          />

          <Row>
            <Col lg={8}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  {/* <CardBody>
                    <Row>
                      <Col lg={6}>
                        <Label>Select Combo Type</Label>

                        <Select
                          value={selectData}
                          onChange={(e) => {
                            handleSelectGroup(e.label, e.value);
                          }}
                          options={options}
                          name="choices-category-input"
                          classNamePrefix="select2-selection form-select"
                        />
                      </Col>
                      </Row>
                      </CardBody> */}

                  <CardBody>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="combo-title-input"
                        >
                          Combo Title *
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="combo-title-input"
                          name="title"
                          placeholder="Enter combo title"
                          value={validation.values.title || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          validate={{
                            required: { value: true },
                          }}
                          invalid={
                            validation.touched.title && validation.errors.title
                              ? true
                              : false
                          }
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="combo-title-input"
                        >
                          Combo Slug (Unique Combo Identification) *
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="combo-title-input"
                          placeholder="Enter combo slug"
                          name="slug"
                          value={validation.values.slug || ""}
                          onChange={validation.handleChange}
                          validate={{
                            required: { value: true },
                          }}
                          invalid={
                            validation.touched.slug && validation.errors.slug
                              ? true
                              : false
                          }
                        />
                        {validation.touched.slug && validation.errors.slug ? (
                          <FormFeedback type="invalid">
                            {validation.errors.slug}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="combo-title-input"
                          >
                            Regular Price: *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="combo-title-input"
                            placeholder="Enter regular price"
                            name="regular_price"
                            value={validation.values.regular_price || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.regular_price &&
                              validation.errors.regular_price
                                ? true
                                : false
                            }
                          />
                          {validation.touched.regular_price &&
                          validation.errors.regular_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.regular_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="combo-title-input"
                          >
                            Sale Price: *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="combo-title-input"
                            placeholder="Enter sale price"
                            name="sale_price"
                            value={validation.values.sale_price || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.sale_price &&
                              validation.errors.sale_price
                                ? true
                                : false
                            }
                          />
                          {validation.touched.sale_price &&
                          validation.errors.sale_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sale_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Add-ons</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {addOn &&
                          addOn.map((item, key) => (
                            <li className="col-6"
                              key={key}
                              style={{
                                listStyle: "none",
                                marginBottom: "0.1em",
                              }}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  //id={item._id ? item._id : ""}
                                  value={item._id ? item._id : ""}
                                  onChange={(e) => handleProduct(e)}
                                  checked={productArray?.includes(item._id)}
                                />{" "}
                                {item.title}
                              </label>
                            </li>
                          ))}
                      </Row>
                    </CardBody>

                    <CardHeader>
                      <h5 className="card-title mb-0">Dishes</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {food &&
                          food.map((item, key) => (
                            <li className="col-6"
                              key={key}
                              style={{
                                listStyle: "none",
                                marginBottom: "0.1em",
                              }}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  //id={item._id ? item._id : ""}
                                  value={item._id ? item._id : ""}
                                  onChange={(e) => handleProduct(e)}
                                  checked={productArray?.includes(item._id)}
                                />{" "}
                                {item.title}
                              </label>
                            </li>
                          ))}
                      </Row>
                    </CardBody>
                  </Card>

                <div className="text-end mb-4">
                  <Link
                    to={`/combos`}
                    className="btn btn-danger w-sm me-1"
                  >
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </form>
              {isComboUpdate ? (
                <MsgToast
                  msg={success}
                  color="success"
                  icon="ri-checkbox-circle-line"
                />
              ) : null}
              {isComboUpdateFail ? (
                <MsgToast
                  msg={error}
                  color="danger"
                  icon="ri-error-warning-line"
                />
              ) : null}
              <ToastContainer limit={1} closeButton={false} />
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Combo Info</h5>
                </CardHeader>
                <CardBody>
                  {/* <div className="mb-3">
                  <Label>Select Combo Type *</Label>

                  <Select
                    value={selectData}
                    onChange={(e) => {
                      handleSelectGroup(e.label, e.value);
                    }}
                    options={options}
                    name="choices-category-input"
                    classNamePrefix="select2-selection form-select"
                  />
                </div> */}
                  <div>
                    <Label>Stock Manage *</Label>
                    <Select
                      value={stock}
                      onChange={(e) => handleStock(e.label, e.value)}
                      options={optionsStock}
                      name="choices-category-input"
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>
                </CardBody>
              </Card>
              
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ComboEdit;
