import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_SITE_DETAILS,
  UPDATE_SITE,
} from "./actionTypes"

import {
  getSiteDetailsSuccess,
  getSiteDetailsFail,
  updateSiteSuccess,
  updateSiteFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSiteDetails,
  updateSite,
} from "../../helpers/fakebackend_helper"


function* onSiteDetails({ payload: site }) {
  try {
      const response = yield call(getSiteDetails, site);
      if(response.status === 200){
        yield put(getSiteDetailsSuccess(response));
      } else {
        yield put(getSiteDetailsFail(response));
      }
  } catch (error) {
      yield put(getSiteDetailsFail(error));
  }
}


export function* watchSiteDetails() {
  yield takeEvery(GET_SITE_DETAILS, onSiteDetails);
}

function* onSiteUpdate({ payload: site }) {
  try {
      const response = yield call(updateSite, site);
      if(response.status === 200){
        yield put(updateSiteSuccess(response));
      } else {
        yield put(updateSiteFail(response));
      }
  } catch (error) {
      yield put(updateSiteFail(error));
  }
}

export function* watchUpdateSite() {
  yield takeEvery(  UPDATE_SITE, onSiteUpdate);
}



function* siteSaga() {
  yield all(
    [
     
      fork(watchSiteDetails),
      fork(watchUpdateSite),
    ]
  );

}

export default siteSaga
