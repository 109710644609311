import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_ORDERS,
  ADD_NEW_ORDER,
  GET_ORDER_DETAILS,
  ORDER_PAYMENT_STATUS_CHANGE,
  DELETE_ORDER,
  STATUS_CHANGE_ORDER
} from "./actionTypes"

import {
  getOrdersSuccess,
  getOrdersFail,
  addNewOrderSuccess,
  addNewOrderFail,
  getOrderDetailsSuccess,
  getOrderDetailsFail,
  statusChangeOrderPaymentSuccess,
  statusChangeOrderPaymentFail,
  deleteOrderSuccess,
  deleteOrderFail,
  statusChangeOrderSuccess,
  statusChangeOrderFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getOrderList,
  addNewOrder,
  getOrderDetails,
  statusChangeOrderPayment,
  deleteOrder,
  statusChangeOrder
} from "../../helpers/fakebackend_helper"

function* fetchOrders({ payload: order }) {
  try {
    const response = yield call(getOrderList, order)
    yield put(getOrdersSuccess(response))
  } catch (error) {
    yield put(getOrdersFail(error))
  }
}

export function* watchFetchOrders() {
  yield takeEvery(GET_ORDERS, fetchOrders);
}

function* onAddNewOrder({ payload: order }) {
  try {
      const response = yield call(addNewOrder, order);
      if(response.status === 200){
        yield put(addNewOrderSuccess(response));
      } else {
        yield put(addNewOrderFail(response));
      }
  } catch (error) {
      yield put(addNewOrderFail(error));
  }
}

export function* watchAddNewOrder() {
  yield takeEvery(ADD_NEW_ORDER, onAddNewOrder);
}

function* onOrderDetails({ payload: order }) {
  try {
      const response = yield call(getOrderDetails, order);
      if(response.status === 200){
        yield put(getOrderDetailsSuccess(response));
      } else {
        yield put(getOrderDetailsFail(response));
      }
  } catch (error) {
      yield put(getOrderDetailsFail(error));
  }
}

export function* watchOrderDetails() {
  yield takeEvery(GET_ORDER_DETAILS, onOrderDetails);
}


function* onStatusChangOrderPayment({ payload: order }) {
  try {
    const response = yield call(statusChangeOrderPayment, order);
    yield put(statusChangeOrderPaymentSuccess({ order, ...response }));
  } catch (error) {
    yield put(statusChangeOrderPaymentFail(error));
  }
}

export function* watchStatusChangeOrderPayment() {
  yield takeEvery(ORDER_PAYMENT_STATUS_CHANGE, onStatusChangOrderPayment);
}

//Delete Data
function* onDeleteOrder({ payload: order }) {
  try {
    const response = yield call(deleteOrder, order);
    yield put(deleteOrderSuccess({ order, ...response }));
  } catch (error) {
    yield put(deleteOrderFail(error));
  }
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, onDeleteOrder);
}



//Status Change
function* onStatusChangOrder({ payload: order }) {
  try {
    const response = yield call(statusChangeOrder, order);
    yield put(statusChangeOrderSuccess({ order, ...response }));
  } catch (error) {
    yield put(statusChangeOrderFail(error));
  }
}

export function* watchStatusChangeOrder() {
  yield takeEvery(STATUS_CHANGE_ORDER, onStatusChangOrder);
}



function* orderSaga() {
  yield all(
    [
      fork(watchFetchOrders),
      fork(watchAddNewOrder),
      fork(watchOrderDetails),
      fork(watchStatusChangeOrderPayment),
      fork(watchDeleteOrder),
      fork(watchStatusChangeOrder)
    ]
  );

}

export default orderSaga
