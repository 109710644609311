import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Calender Redux States
import {
  ADD_NEW_JOB_LOCATION_TYPE,
  UPDATE_JOB_LOCATION_TYPE,
  GET_JOB_LOCATION_DETAILS,
  GET_JOB_LOCATION_TYPE_LIST,
  DELETE_JOB_LOCATION_TYPE,
  STATUS_CHANGE_JOB_LOCATION
} from "./actionTypes";

import {
  addNewJobLocationTypeSuccess,
  addNewJobLocationTypeFail,
  updateJobLocationTypeSuccess,
  updateJobLocationTypeFail,
  getJobLocationDetailsSuccess,
  getJobLocationDetailsFail,
  getJobLocationTypeListSuccess,
  getJobLocationTypeListFail,
  deleteJobLocationTypeListSuccess,
  deleteJobLocationTypeListFail,
  statusChangeJobLocationSuccess,
  statusChangeJobLocationFail
} from "./actions";

//Include Both Helper File with needed methods
import {
  addNewJobLocationType,
  getJobLocationDetails,
  updateJobLocationType,
  getJobLocationTypeList,
  deleteJobLocTypeList,
  statusChangeJobLocation
} from "../../helpers/fakebackend_helper";

function* onAddNewJobLocationType({ payload: job }) {
  try {
    const response = yield call(addNewJobLocationType, job);
    if (response.status === 200) {
      yield put(addNewJobLocationTypeSuccess(response));
    } else {
      yield put(addNewJobLocationTypeFail(response));
    }
  } catch (error) {
    yield put(addNewJobLocationTypeFail(error));
  }
}

export function* watchAddNewJobLocationType() {
  yield takeEvery(ADD_NEW_JOB_LOCATION_TYPE, onAddNewJobLocationType);
}

function* onJobLocationDetails({ payload: job }) {
  try {
      const response = yield call(getJobLocationDetails, job);
      if(response.status === 200){
        yield put(getJobLocationDetailsSuccess(response));
      } else {
        yield put(getJobLocationDetailsFail(response));
      }
  } catch (error) {
      yield put(getJobLocationDetailsFail(error));
  }
}

//Status Change
function* onStatusChangeJobLocation({ payload: job }) {
  try {
    const response = yield call(statusChangeJobLocation, job);
    yield put(statusChangeJobLocationSuccess({ job, ...response }));
  } catch (error) {
    yield put(statusChangeJobLocationFail(error));
  }
}

export function* watchStatusChangeJobLocation() {
  yield takeEvery(STATUS_CHANGE_JOB_LOCATION, onStatusChangeJobLocation);
}


export function* watchJobLocationDetails() {
  yield takeEvery(GET_JOB_LOCATION_DETAILS, onJobLocationDetails);
}


// Job Location Type List
function* onJobLocationTypeList({ payload: job }) {
  try {
    const response = yield call(getJobLocationTypeList, job);
    if (response.status === 200) {
      yield put(getJobLocationTypeListSuccess(response));
    } else {
      yield put(getJobLocationTypeListFail(response));
    }
  } catch (error) {
    yield put(getJobLocationTypeListFail(error));
  }
}

export function* watchJobLocationTypeList() {
  yield takeEvery(GET_JOB_LOCATION_TYPE_LIST, onJobLocationTypeList);
}
function* onJobLocationTypeUpdate({ payload: job }) {
  try {
      const response = yield call(updateJobLocationType, job);
      if(response.status === 200){
        yield put(updateJobLocationTypeSuccess(response));
      } else {
        yield put(updateJobLocationTypeFail(response));
      }
  } catch (error) {
      yield put(updateJobLocationTypeFail(error));
  }
}

export function* watchUpdateJobLocationType() {
  yield takeEvery(UPDATE_JOB_LOCATION_TYPE, onJobLocationTypeUpdate);
}


function* deleteJobLocTypeLists({payload : data}) {
  try {
    const response = yield call(deleteJobLocTypeList,data)
    yield put(deleteJobLocationTypeListSuccess(response))
  } catch (error) {
    yield put(deleteJobLocationTypeListFail(error))
  }
}

export function* watchDeleteJobLocationType() {
  yield takeEvery(DELETE_JOB_LOCATION_TYPE, deleteJobLocTypeLists);
}

function* jobLocationTypeSaga() {
  yield all([
    fork(watchAddNewJobLocationType),
    fork(watchUpdateJobLocationType),
    fork(watchJobLocationTypeList),
    fork(watchJobLocationDetails),
    fork(watchDeleteJobLocationType),
    fork(watchStatusChangeJobLocation)

  ]);
}

export default jobLocationTypeSaga;
