import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_SEOS,
  ADD_NEW_SEO,
  GET_SEO_DETAILS,
  UPDATE_SEO
} from "./actionTypes"

import {
  getSeosSuccess,
  getSeosFail,
  addNewSeoSuccess,
  addNewSeoFail,
  getSeoDetailsSuccess,
  getSeoDetailsFail,
  updateSeoSuccess,
  updateSeoFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSeoList,
  addNewSeo,
  getSeoDetails,
  updateSeo
} from "../../helpers/fakebackend_helper"

function* fetchSeos({ payload: seo }) {
  try {
    const response = yield call(getSeoList, seo)
    yield put(getSeosSuccess(response))
  } catch (error) {
    yield put(getSeosFail(error))
  }
}

export function* watchFetchSeos() {
  yield takeEvery(GET_SEOS, fetchSeos);
}

function* onAddNewSeo({ payload: seo }) {
  try {
      const response = yield call(addNewSeo, seo);
      if(response.status === 200){
        yield put(addNewSeoSuccess(response));
      } else {
        yield put(addNewSeoFail(response));
      }
  } catch (error) {
      yield put(addNewSeoFail(error));
  }
}

export function* watchAddNewSeo() {
  yield takeEvery(ADD_NEW_SEO, onAddNewSeo);
}

function* onSeoDetails({ payload: seo }) {
  try {
      const response = yield call(getSeoDetails, seo);
      if(response.status === 200){
        yield put(getSeoDetailsSuccess(response));
      } else {
        yield put(getSeoDetailsFail(response));
      }
  } catch (error) {
      yield put(getSeoDetailsFail(error));
  }
}

export function* watchSeoDetails() {
  yield takeEvery(GET_SEO_DETAILS, onSeoDetails);
}

function* onSeoUpdate({ payload: seo }) {
  try {
      const response = yield call(updateSeo, seo);
      if(response.status === 200){
        yield put(updateSeoSuccess(response));
      } else {
        yield put(updateSeoFail(response));
      }
  } catch (error) {
      yield put(updateSeoFail(error));
  }
}

export function* watchUpdateSeo() {
  yield takeEvery(UPDATE_SEO, onSeoUpdate);
}

function* seoSaga() {
  yield all(
    [
      fork(watchFetchSeos),
      fork(watchAddNewSeo),
      fork(watchSeoDetails),
      fork(watchUpdateSeo),
    ]
  );

}

export default seoSaga
