import {
  ADD_NEW_JOB_LOCATION_TYPE_SUCCESS,
  ADD_NEW_JOB_LOCATION_TYPE_FAIL,
  UPDATE_JOB_LOCATION_TYPE_SUCCESS,
  UPDATE_JOB_LOCATION_TYPE_FAIL,
  GET_JOB_LOCATION_DETAILS_SUCCESS,
  GET_JOB_LOCATION_DETAILS_FAIL,
  GET_JOB_LOCATION_TYPE_LIST_SUCCESS,
  GET_JOB_LOCATION_TYPE_LIST_FAIL,
  DELETE_JOB_LOCATION_TYPE_SUCCESS,
  DELETE_JOB_LOCATION_TYPE_FAIL,
  MODIFY_JOB_LOCATION_TYPE_FLAG,
  RESET_JOB_LOCATION_TYPE_FLAG,
  STATUS_CHANGE_JOB_LOCATION_SUCCESS,
  STATUS_CHANGE_JOB_LOCATION_FAIL
} from "./actionTypes";

const INIT_STATE = {
  jobLocationTypeList: [],
};

const JobLocationTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_JOB_LOCATION_TYPE_SUCCESS:
      return {
        ...state,
        jobLocationTypeList: [...state.jobLocationTypeList, action.payload.data],
        success: action.payload.message,
        isJobLocationTypeAdd: true,
        isJobLocationTypeAddFail: false,
      };

    case ADD_NEW_JOB_LOCATION_TYPE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isJobLocationTypeAdd: false,
        isJobLocationTypeAddFail: true,
      };

    case UPDATE_JOB_LOCATION_TYPE_SUCCESS:
      return {
        ...state,
        jobLocationTypeList: state.jobLocationTypeList.map((job) =>
          job._id.toString() === action.payload.data._id.toString()
            ? { ...job, ...action.payload.data }
            : job
        ),
        success: action.payload.message,
        isJobLocationTypeUpdate: true,
        isJobLocationTypeUpdateFail: false,
      };

    case  UPDATE_JOB_LOCATION_TYPE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isJobLocationUpdate: false,
        isJobLocationUpdateJob: true,
      };

      
    case GET_JOB_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data?action.payload.data:[],
      };
     

    case GET_JOB_LOCATION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

      //Status Change
    case STATUS_CHANGE_JOB_LOCATION_SUCCESS:
      return {
        ...state,
        jobLocationTypeList: state.jobLocationTypeList.map((job) =>
          job._id.toString() === action.payload.data._id.toString()
            ? { ...job, ...action.payload.data }
            : job
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_JOB_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    //Job Location Type List
    case  GET_JOB_LOCATION_TYPE_LIST_SUCCESS:
      return {
        ...state,
        jobLocationTypeList: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_JOB_LOCATION_TYPE_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
      };
      case DELETE_JOB_LOCATION_TYPE_SUCCESS:
              return { 
                ...state,
                jobLocationTypeList: state.jobLocationTypeList.filter(getData =>
                  {
                     return getData._id !== action.payload.data._id
                  }
                  
                ),
                success: action.payload.message,
                isSuccess: true,
                statusSuccess: true,
                statusFailed: false,
                isJobLocTypeRemove: true,
                isJobLocTypeRemoveFail: false,
              };
          
            case DELETE_JOB_LOCATION_TYPE_FAIL:
              return {
                ...state,
                error: action.payload.message,
                isSuccess: false,
                statusSuccess: false,
                statusFailed: true,
                isJobLocTypeRemove: false,
                isJobLocTypeRemoveFail: true,
              };
              case MODIFY_JOB_LOCATION_TYPE_FLAG:
                return {
                  ...state,
                  statusSuccess: false,
                };


    case RESET_JOB_LOCATION_TYPE_FLAG:
      return {
        ...state,
        isJobLocationTypeAdd: false,
        isJobLocationTypeAddFail: false,
        isSuccess: false,
        isJobLocationDetails: false,
        isJobLocationTypeUpdate: false,
        isJobLocationTypeUpdateFail: false,
        error: false,
      };

    default:
      return state;
  }
};

export default JobLocationTypes;
