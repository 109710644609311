import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateService,
  resetServiceFlag,
  getPages,
  getServiceDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { convertToBase64 } from "../../../helpers/image";
const ServiceEdit = () => {
  document.title = "Edit Service | Dabbawala";
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState();
  const history = useHistory();
  //const [description, setData] = useState();
  const [pagetype, setPagetype] = useState([]);
  const { isServiceUpdate, isServiceUpdateFail, error, success, details, pages } =
    useSelector((state) => ({
      isServiceUpdate: state.Services.isServiceUpdate,
      isServiceUpdateFail: state.Services.isServiceUpdateFail,
      error: state.Services.error,
      success: state.Services.success,
      details: state.Services.details,
      pages: state.Pages.pages,
    }));
  const selectedTypeOption = {
    value: details && details._id,
    label: details && details.type,
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: details && details.title ? details.title : "",
      description: details && details.description ? details.description : "",
      image: details && details.image ? details.image : "",
      link: details && details.link ? details.link : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description")
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        description: values.description,
        link: values.link,
        image: image
      };

      dispatch(updateService(data));
    },
  });

  useEffect(() => {
    if (isServiceUpdate || isServiceUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetServiceFlag());
        history.push("/services");
      }, 3000);
    }
  }, [dispatch, isServiceUpdate, isServiceUpdateFail, validation]);
  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getServiceDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
  }, [dispatch, pages]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };
  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
  }, [dispatch, pages]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Edit Service"
            pageTitle="Services"
            pageUrl="/services"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Service" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">

                        <Col xxl={6} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Image *
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImg1Change}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={6} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                            Description*
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              rows="3"
                              className="form-control"
                              placeholder="Enter description"
                              name="description"
                              value={validation.values.description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.description &&
                                validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                            htmlFor="placeholderInput"
                            className="form-label"
                            >
                            Link
                            </Label>
                          <Input
                          type="text"
                          id="placeholderInput"
                          className="form-control"
                          placeholder="Enter Link"
                          name="link"
                          value={validation.values.link || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          validate={{
                            required: { value: false },
                          }}
                          invalid={
                            validation.touched.link &&
                            validation.errors.link
                              ? true
                              : false
                          }
                          />
                          {validation.touched.link &&
                          validation.errors.link ? (
                          <FormFeedback type="invalid">
                          {validation.errors.link}
                          </FormFeedback>
                          ) : null}
                          </div>
                        </Col>

                        
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/services" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isServiceUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isServiceUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default ServiceEdit;
