import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  CardHeader,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  addNewProduct,
  resetProductFlag,
  //getCategories,
  getProductCategories,
  getProducts,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { convertToBase64 } from "../../../helpers/image";
import Select from "react-select";
import slugify from "slugify";
import axios from "axios";
import hostUrl from "../../../config";

const ProductCreate = () => {
  document.title = "Create Product | Dabbawala";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [category_type, setCategoryType] = useState("products");
  const [image, setImage] = useState(null);
  const [description, setData] = useState();

  const [productData, setProductData] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [allergyData, setAllergyData] = useState();

  const [allergyArray, setAllergyArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [spiceLevelArray, setSpiceLevelArray] = useState([]);
  const [addonProductArray, setAddonProductArray] = useState([]);

  const [categoryOption, serCategoryOption] = useState([]);
  const [allergyOption, serAllergyOption] = useState([]);
  const [spiceOption, setSpiceOption] = useState([]);

  const [dietData, setDietData] = useState();
  const [spiceData, setSpiceData] = useState();

  const [slugData, setSlugData] = useState();

  const { type } = useParams();
  const [Type, setType] = useState(type);
  const {
    isProductAdd,
    isProductAddFail,
    error,
    success,
    categories,
    allergies,
    productcategories,
    products,
  } = useSelector((state) => ({
    allergies: state.Allergies.allergies,
    isProductAdd: state.Products.isProductAdd,
    isProductAddFail: state.Products.isProductAddFail,
    error: state.Products.error,
    success: state.Products.success,
    productcategories: state.Productcategories.productcategories,
    products: state.Products.products,
  }));

  const selectedDietOption = {
    value: "",
    label: "",
  };

  const buyWithOptions = [
    { value: 1, label: "Price" },
    { value: 2, label: "Point" },
  ];

  const selectedBuyWithOption = {
    value: "",
    label: "",
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      plu_code: "",
      regular_price: "",
      sale_price: "",
      price_point: "",
      short_description: "",
      diet_id: selectedDietOption,
      buy_with: selectedBuyWithOption,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter your title"),
      short_description: Yup.string().max(200)
      //slug : Yup.string().required("Please enter your slug"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        product_type: parseInt(Type),
        title: values.title,
        plu_code: slugData,
        regular_price: values.regular_price,
        sale_price: values.sale_price,
        price_point: enable ? '' : values.price_point,
        short_description: values.short_description,
        featured: feature.value,
        in_stock: stock.value,
        status: 1,
        image: image,
        product_category_id: categoryArray,
        allergie_id: allergyArray,

        spice_level_id: spiceLevelArray,
        diet_id: values.diet_id?.value,

        addon_id: addonProductArray,

        buy_with: values.buy_with.value,
        size: values.size,
      };
      //console.log(data)
      dispatch(addNewProduct(data));
    },
  });

  useEffect(() => {

    setCategoryData(productcategories);
    setProductData(products);

    axios
      .post(
        `${hostUrl.api.API_URL}product_ingredients/list`,
        { status: true, type: 1 },
        {}
      )
      .then(function (response) {
        setAllergyData(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `${hostUrl.api.API_URL}product_ingredients/list`,
        { status: true, type: 2 },
        {}
      )
      .then(function (response) {
        setSpiceData(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `${hostUrl.api.API_URL}product_ingredients/list`,
        { status: true, type: 3 },
        {}
      )
      .then(function (response) {
        setDietData(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [productcategories, products]);

  useEffect(() => {
    if (isProductAdd || isProductAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetProductFlag());
      }, 3000);
    }
  }, [dispatch, isProductAdd, isProductAddFail, validation, history]);





  useEffect(() => {
    dispatch(getProductCategories());
  }, [dispatch]);


  useEffect(() => {
    dispatch( getProducts({product_type: 2}));
  },[dispatch]);





  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src);
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const types = {
    1: "Food Product",
    2: "Add-on Product",
    3: "Merchandise Product",
  };
  const options = [
    { value: 1, label: "Food Product" },
    { value: 2, label: "Add-on Product" },
    { value: 3, label: "Merchandise Product" },
  ];
  const [selectData, setSelectData] = useState({
    label: types[type],
    value: parseInt(type),
  });

  // function handleSelectGroup(label, value){
  //   setSelectData({label: label, value: value})
  //   setType(value)
  // }

  function handleFeature(label, value) {
    setFeature({ label: label, value: value });
  }

  function handleStock(label, value) {
    setStock({ label: label, value: value });
  }

  const optionsFeature = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const optionsStock = [
    { value: 1, label: "In Stock" },
    { value: 2, label: "Sold Out for Today" },
    { value: 3, label: "Out Of Stock" },
  ];

  const [feature, setFeature] = useState({ value: false, label: "No" });
  const [stock, setStock] = useState({ value: 1, label: "In Stock" });

  function handleChange(e) {
    //let a = e.target.value;
    // if (a) {
    //   setCategoryArray([...categoryArray, e.target.value]);
    // } else {
    //   // remove from list
    //   setCategoryArray(categoryArray.filter((data) => data !== e.target.value));
    // }
    let a = []
    e.forEach((item) => {
      a.push(item.value)
    })
    setCategoryArray(a)
  }

  function handleChangeAllergy(e) {
    // let a = e.target.checked;
    // if (a) {
    //   setAllergyArray([...allergyArray, e.target.value]);
    // } else {
    //   setAllergyArray(allergyArray.filter((data) => data !== e.target.value));
    // }
    console.log(e);
    let a = []
    e.forEach((item) => {
      a.push(item.value)
    })
    setAllergyArray(a)
  }
console.log(allergyArray);
  function handleChangeSpiceLevel(e) {
    // let a = e.target.checked;
    // if (a) {
    //   setSpiceLevelArray([...spiceLevelArray, e.target.value]);
    // } else {
    //   // remove from list
    //   setSpiceLevelArray(
    //     spiceLevelArray.filter((data) => data !== e.target.value)
    //   );
    // }
    let a = []
    e.forEach((item) => {
      a.push(item.value)
    })
    setSpiceLevelArray(a)
  }


  function handleChangeAddonProduct(e) {
    let a = e.target.checked;
    if (a) {
      setAddonProductArray([...addonProductArray, e.target.value]);
    } else {
      setAddonProductArray(
        addonProductArray.filter((data) => data !== e.target.value)
      );
    }
  }

  // function slugCreator(e) {
  //   setSlugData(
  //     slugify(e.target.value, {
  //       replacement: "-",
  //       lower: true,
  //       trim: true,
  //       remove: /[*+~.()'"!:@]/g,
  //     })
  //   );
  // }
  console.log(categoryArray);

  useEffect(() => {
    categoryData.forEach((item) => {
      categoryOption.push({label: item.title, value: item._id})
    })
  }, [categoryData])

  useEffect(() => {
    spiceData?.forEach((item) => {
      if (spiceData?.length > spiceOption?.length) {
        spiceOption.push({label: item.title, value: item._id})
      }
    })
  }, [spiceData])

  useEffect(() => {
    allergyData?.forEach((item) => {
      if (allergyData?.length > allergyOption?.length) {
        
        allergyOption.push({label: item.title, value: item._id})
      }
    })
  }, [allergyData])
  
  

  useEffect(() => {
    setSlugData(Date.now());
  }, []);

  const [enable, setEnable] = useState(true)
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={Type === "1" ? "Create Food" : Type === "2" ? "Create Add-On" : " Create Merchandise"}
            pageTitle="Products"
            pageUrl={`/products/${type}`}
          />

          <Row>
            <Col lg={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  {/* <CardBody>
                    <Row>
                      <Col lg={6}>
                        <Label>Select Product Type</Label>

                        <Select
                          value={selectData}
                          onChange={(e) => {
                            handleSelectGroup(e.label, e.value);
                          }}
                          options={options}
                          name="choices-category-input"
                          classNamePrefix="select2-selection form-select"
                        />
                      </Col>
                      </Row>
                      </CardBody> */}

                  <CardBody>
                    {/* <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="product-title-input"
                      >
                        Product Title *
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="product-title-input"
                        placeholder="Enter product title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.title && validation.errors.title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div> */}
                    <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          {Type === "1" ? "Food" : Type === "2" ? "Add-On" : " Merchandise"} Name *
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="product-title-input"
                          name="title"
                          placeholder="Enter product name"
                          value={validation.values.title || ""}
                          onChange={(e) => {
                            validation.handleChange(e);
                          }}
                          onBlur={validation.handleBlur}
                          validate={{
                            required: { value: true },
                          }}
                          invalid={
                            validation.touched.title && validation.errors.title
                              ? true
                              : false
                          }
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          PLU code {" "}
                          <button type='button' style={{position: 'absolute', left: '80px'}} onClick={() => setSlugData(Date.now())}>Generate</button>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="product-title-input"
                          placeholder="Enter product slug"
                          name="slug"
                          value={slugData}
                          onChange={(e) => {
                            validation.handleChange(e);
                          }}
                          validate={{
                            required: { value: true },
                          }}
                          invalid={
                            validation.touched.slug && validation.errors.slug
                              ? true
                              : false
                          }
                        />
                        {validation.touched.slug && validation.errors.slug ? (
                          <FormFeedback type="invalid">
                            {validation.errors.slug}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>
                    <Row>
                      {Type !== "3" && <><Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Regular Price: *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="product-title-input"
                            placeholder="Enter regular price"
                            name="regular_price"
                            value={validation.values.regular_price || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.regular_price &&
                              validation.errors.regular_price
                                ? true
                                : false
                            }
                          />
                          {validation.touched.regular_price &&
                          validation.errors.regular_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.regular_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Sale Price: *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="product-title-input"
                            placeholder="Enter sale price"
                            name="sale_price"
                            value={validation.values.sale_price || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.sale_price &&
                              validation.errors.sale_price
                                ? true
                                : false
                            }
                          />
                          {validation.touched.sale_price &&
                          validation.errors.sale_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sale_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col></>}

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Point Price
                          </Label>
                        <input type='checkbox' onClick={() => {setEnable(!enable);}}></input>

                          <Input
                            type="number"
                            className="form-control"
                            id="product-title-input"
                            placeholder={enable?"Disabled":"Enter point price"}
                            name="price_point"
                            readOnly={enable}
                            value={validation.values.price_point || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.price_point &&
                              validation.errors.price_point
                                ? true
                                : false
                            }
                          />
                          {validation.touched.price_point &&
                          validation.errors.price_point ? (
                            <FormFeedback type="invalid">
                              {validation.errors.price_point}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    {/* <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Regular Price: *</Label>
                          <Input
                            type="number"
                            placeholder="Enter Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          ></Input>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Sale Price: *</Label>
                          <Input
                            type="number"
                            placeholder="Enter Price"
                            value={salePrice}
                            onChange={(e) => setSalePrice(e.target.value)}
                          ></Input>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Point Price: </Label>
                          <Input placeholder="Enter Point Price" value={point} onChange={e => setPoint(e.target.value)} type="number"></Input>
                        </div>
                      </Col>
                    </Row>
                  </CardBody> */}

                    {/* <div className="mb-3">
                      <Label>Product Description</Label>

                      <CKEditor
                        editor={ClassicEditor}
                        name="description"
                        onChange={(event, editor) => {
                          setData(editor.getData());
                        }}
                        data={validation.values.description || ""}
                        invalid={false}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div> */}
                    <div className="mb-3">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              rows="3"
                              className="form-control"
                              placeholder="Enter description"
                              name="short_description"
                              value={validation.values.short_description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.short_description &&
                                validation.errors.short_description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.short_description &&
                            validation.errors.short_description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.short_description}
                              </FormFeedback>
                            ) : null}
                          </div>
                  </CardBody>
                </Card>

                <Row>
                <Col lg={6}>
                <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Image</h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <Input
                      type="file"
                      id="placeholderInput"
                      className="form-control"
                      placeholder="Place your image"
                      name="image"
                      onChange={onImg1Change}
                    />
                    <img
                      src={validation.values.image || ""}
                      alt=""
                      id="image1"
                      width="100px"
                    ></img>
                    {validation.touched.image && validation.errors.image ? (
                      <FormFeedback type="invalid">
                        {validation.errors.image}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              </Col>

              <Col>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Info</h5>
                </CardHeader>
                <CardBody>
                  {/* <div className="mb-3">
                  <Label>Select Product Type *</Label>

                  <Select
                    value={selectData}
                    onChange={(e) => {
                      handleSelectGroup(e.label, e.value);
                    }}
                    options={options}
                    name="choices-category-input"
                    classNamePrefix="select2-selection form-select"
                  />
                </div> */}

                  {Type == "1" && (
                    <div className="mb-3">
                      <Label>Popular *</Label>
                      <Select
                        value={feature}
                        onChange={(e) => handleFeature(e.label, e.value)}
                        options={optionsFeature}
                        name="choices-category-input"
                        classNamePrefix="select2-selection form-select"
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <Label>Stock Manage *</Label>
                    <Select
                      value={stock}
                      onChange={(e) => handleStock(e.label, e.value)}
                      options={optionsStock}
                      name="choices-category-input"
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>



                  {/* <div className="mb-3">
                    <Label>Buy with</Label>
                      <Select
                        type="text"
                        name="buy_with"
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "buy_with",
                              value: selectedOption,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          buyWithOptions &&
                          buyWithOptions.map((item, index) => ({
                            label: item.label,
                            value: item.value,
                          }))
                        }
                        value={validation.values.buy_with || ""}
                      />

                      {validation.touched.diet_id &&
                      validation.errors.diet_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.diet_id}
                        </FormFeedback>
                      ) : null}




                  </div>

                  <div>
                    <Label
                      className="form-label"
                      htmlFor="product-title-input"
                    >
                      Size
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="product-title-input"
                      name="size"
                      placeholder="Enter size"
                      value={validation.values.size || ""}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      validate={{
                        required: { value: false },
                      }}
                      invalid={
                        validation.touched.size && validation.errors.size
                          ? true
                          : false
                      }
                    />
                    {validation.touched.size && validation.errors.size ? (
                      <FormFeedback type="invalid">
                        {validation.errors.size}
                      </FormFeedback>
                    ) : null}
                  </div>*/}
                </CardBody>
              </Card>       
  </Col>
              </Row>

                <Row>
                  <Col lg={6}>
                {Type == "1" && (
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Product Category</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                      <Select
                          closeMenuOnSelect={false}
                          //defaultValue={[colourOptions[0]]}
                          isMulti
                          options={categoryOption}
                          onChange={(e) => handleChange(e)}
                      />
                        
                      </Row>
                    </CardBody>
                  </Card>
                )}
                </Col>
                <Col lg={6}>
                {Type == "1" && (
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Add-on Products</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {productData &&
                          productData.map((item, key) => (
                            <li className="col-6"
                              key={key}
                              style={{
                                listStyle: "none",
                                marginBottom: "0.1em",
                              }}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  id={item._id ? item._id : ""}
                                  value={item._id ? item._id : ""}
                                  onChange={(e) => handleChangeAddonProduct(e)}
                                />{" "}
                                {item.title}
                              </label>
                            </li>
                          ))}
                      </Row>
                    </CardBody>
                  </Card>
                )}
                </Col>
                </Row>

                            
              
               
              {Type == "1" && (
                <>

                <Row>
                <Col lg={4}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Allergies</h5>
                    </CardHeader>
                    <CardBody>
                      <Select
                            closeMenuOnSelect={false}
                            //defaultValue={[colourOptions[0]]}
                            isMulti
                            options={allergyOption}
                            onChange={(e) => handleChangeAllergy(e)}
                        />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Spice Level</h5>
                    </CardHeader>

                    <CardBody>
                    <Select
                            closeMenuOnSelect={false}
                            //defaultValue={[colourOptions[0]]}
                            isMulti
                            options={spiceOption}
                            onChange={(e) => handleChangeSpiceLevel(e)}
                        />
                    </CardBody>

                    {/* <CardBody>
                      <Select
                        type="text"
                        name="spice_level_id"
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "spice_level_id",
                              value: selectedOption,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          spiceData &&
                          spiceData.map((item, index) => ({
                            label: item.title,
                            value: item._id,
                          }))
                        }
                        value={validation.values.spice_level_id || ""}
                      />
                      {validation.touched.spice_level_id &&
                      validation.errors.spice_level_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.spice_level_id}
                        </FormFeedback>
                      ) : null}
                    </CardBody> */}
                  </Card>
                  </Col>
                  <Col lg={4}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Diet</h5>
                    </CardHeader>
                    <CardBody>
                      <Select
                        type="text"
                        name="diet_id"
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "diet_id",
                              value: selectedOption,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          dietData &&
                          dietData.map((item, index) => ({
                            label: item.title,
                            value: item._id,
                          }))
                        }
                        value={validation.values.diet_id || ""}
                      />

                      {validation.touched.diet_id &&
                      validation.errors.diet_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.diet_id}
                        </FormFeedback>
                      ) : null}
                    </CardBody>
                  </Card>
                  </Col>
                  </Row>

                  {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Spice Level</h5>
                </CardHeader>
                <CardBody>
                  <Select
                    placeholder="Select Spice Level"
                    value={spice}
                    onChange={(e) => {
                      handleSpice(e.label, e.value);
                    }}
                    options={optionsSpice}
                    name="choices-category-input"
                    classNamePrefix="select2-selection form-select"
                  />
                </CardBody>
              </Card> */}

                  {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Diet</h5>
                </CardHeader>
                <CardBody>
                  <Select
                    placeholder="Select Diet"
                    value={diet}
                    onChange={(e) => {
                      handleDiet(e.label, e.value);
                    }}
                    options={optionsDiet}
                    name="choices-category-input"
                    classNamePrefix="select2-selection form-select"
                  />
                </CardBody>
              </Card> */}
                </>
              )}

                {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Gallery</h5>
                </CardHeader>
                <CardBody>
                <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Product Image *
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImg1Change}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                          </CardBody>
              </Card> */}

                <div className="text-end mb-4">
                  <Link
                    to={`/products/${type}`}
                    className="btn btn-danger w-sm me-1"
                  >
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </form>
              {isProductAdd ? (
                <MsgToast
                  msg={success}
                  color="success"
                  icon="ri-checkbox-circle-line"
                />
              ) : null}
              {isProductAddFail ? (
                <MsgToast
                  msg={error}
                  color="danger"
                  icon="ri-error-warning-line"
                />
              ) : null}
              <ToastContainer limit={1} closeButton={false} />
            </Col>

           
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProductCreate;
