import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  addNewJob,
  resetJobFlag,
  getJobTypeList,
  getJobLocationTypeList,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import Select from "react-select";

const JobCreate = () => {
  document.title = "Create Job | Dabbawala";
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [jobtype, setJobtype] = useState("");
  const [job_type, setJobType] = useState("");
  const [joblocationtype, setJoblocationtype] = useState("");
  const [location_type, setJobLocationType] = useState("");
  const [description, setData] = useState("");
  const {
    isJobAdd,
    isJobAddFail,
    error,
    success,
    jobTypeList,
    jobLocationTypeList,
  } = useSelector((state) => ({
    isJobAdd: state.Jobs.isJobAdd,
    isJobAddFail: state.Jobs.isJobAddFail,
    error: state.Jobs.error,
    success: state.Jobs.success,
    jobTypeList: state.JobTypes.jobTypeList,
    jobLocationTypeList: state.JobLocationTypes.jobLocationTypeList,
  }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      description: "",
      city: "",
      state: "",
      country: "",
      job_type: "",
      location_type: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      city: Yup.string().required("Please Enter City"),

      state: Yup.string().required("Please Enter State"),
      country: Yup.string().required("Please Enter Country"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setIsCreating(true);
      const data = {
        title: values.title,
        city: values.city,
        country: values.country,
        description: description,
        state: values.state,
        job_type: values.job_type.value,
        location_type: values.location_type.value,
      };
      dispatch(addNewJob(data));
    },
  });

  useEffect(() => {
    if (isJobAdd || isJobAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetJobFlag());
      }, 3000);
    }
  }, [dispatch, isJobAdd, isJobAddFail,validation]);
  useEffect(() => {
    dispatch(getJobTypeList());
    dispatch(getJobLocationTypeList());
    // dispatch(getJobs( job_type,location_type));
  }, [dispatch]);

  useEffect(() => {
    let jobtypes = [];
    if (jobTypeList) {
      for (let i = 0; i < jobTypeList?.length; i++) {
        jobtypes[i] = { label: jobTypeList[i].type, value: jobTypeList[i]._id };
      }
    }
    setJobtype(jobtypes);
    console.log(jobtypes);
  }, [jobTypeList]);

  useEffect(() => {
    let joblocationtypes = [];
    if (jobLocationTypeList) {
      for (let i = 0; i < jobLocationTypeList?.length; i++) {
        joblocationtypes[i] = {
          label: jobLocationTypeList[i].type,
          value: jobLocationTypeList[i]._id,
        };
      }
    }
    setJoblocationtype(joblocationtypes);
    // console.log(joblocationtypes);
  }, [jobLocationTypeList]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create Job" pageTitle="Jobs" pageUrl="/Jobs" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Job" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              City *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="City name"
                              name="city"
                              value={validation.values.city || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.city &&
                                validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              State *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="State name"
                              name="state"
                              value={validation.values.state || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.state &&
                                validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Country *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Country name"
                              name="country"
                              value={validation.values.country || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.country &&
                                validation.errors.country
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.country &&
                            validation.errors.country ? (
                              <FormFeedback type="invalid">
                                {validation.errors.country}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="product-title-input">
                              Job Type *
                            </Label>
                            <Select
                              type="text"
                              name="job_type"
                              placeholder="Enter Job Type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "job_type",
                                    value: selectedOption,
                                  },
                                };
                                setJobType(selectedOption.value);
                                console.log(job_type);
                                validation.handleChange(event);
                              }}
                              options={jobtype}
                              onBlur={validation.handleBlur}
                              value={validation.values.job_type || ""}
                              invalid={
                                validation.touched.job_type &&
                                validation.errors.job_type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.job_type &&
                            validation.errors.job_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.job_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="product-title-input">
                              Job Location Type *
                            </Label>
                            <Select
                              type="text"
                              name="location_type"
                              placeholder="Enter Job Loacation Type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "location_type",
                                    value: selectedOption,
                                  },
                                };
                                setJobLocationType(selectedOption.value);
                                validation.handleChange(event);
                              }}
                              options={joblocationtype}
                              onBlur={validation.handleBlur}
                              value={validation.values.location_type || ""}
                              invalid={
                                validation.touched.location_type &&
                                validation.errors.location_type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.location_type &&
                            validation.errors.location_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.location_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/jobs" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isJobAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isJobAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default JobCreate;
