import {
    GET_POINTS,
    GET_POINTS_SUCCESS,
    GET_POINTS_FAIL,
    ADD_NEW_POINTS,
    ADD_NEW_POINTS_SUCCESS,
    ADD_NEW_POINTS_FAIL,
    GET_POINTS_DETAILS,
    GET_POINTS_DETAILS_SUCCESS,
    GET_POINTS_DETAILS_FAIL,
    POINTS_PAYMENT_STATUS_CHANGE,
    POINTS_PAYMENT_STATUS_CHANGE_SUCCESS,
    POINTS_PAYMENT_STATUS_CHANGE_FAIL,
    RESET_POINTS_FLAG,
    DELETE_POINTS,
    DELETE_POINTS_SUCCESS,
    DELETE_POINTS_FAIL,
    STATUS_CHANGE_POINTS,
    STATUS_CHANGE_POINTS_SUCCESS,
    STATUS_CHANGE_POINTS_FAIL,
    MODIFY_POINTS_FLAG
  } from "./actionTypes";
  
  export const getPoints = data => ({
    type: GET_POINTS,
    payload: data,

  });
  
  export const getPointsSuccess = data => ({
    type: GET_POINTS_SUCCESS,
    payload: data,
  });
  
  export const getPointsFail = error => ({
    type: GET_POINTS_FAIL,
    payload: error,
  });
  
  export const addNewPoint = point=> ({
    type: ADD_NEW_POINTS,
    payload: point,
  });
  
  export const addNewPointSuccess = point => ({
    type: ADD_NEW_POINTS_SUCCESS,
    payload: point,
  });
  
  export const addNewPointFail = error => ({
    type: ADD_NEW_POINTS_FAIL,
    payload: error,
  });
  
  //Status Change
  export const statusChangePointPayment = point => ({
    type: POINTS_PAYMENT_STATUS_CHANGE,
    payload: point,
  });
  
  export const statusChangePointPaymentSuccess = point => ({
    type: POINTS_PAYMENT_STATUS_CHANGE_SUCCESS,
    payload: point,
  });
  
  export const statusChangePointPaymentFail = error => ({
    type: POINTS_PAYMENT_STATUS_CHANGE_FAIL,
    payload: error,
  });
  
  export const getPointDetails = point => ({
    type: GET_POINTS_DETAILS,
    payload: point,
  });
  
  export const getPointDetailsSuccess = point => ({
    type: GET_POINTS_DETAILS_SUCCESS,
    payload: point,
  });
  
  export const getPointDetailsFail = error => ({
    type: GET_POINTS_DETAILS_FAIL,
    payload: error,
  });
  
  export const resetPointFlag = () => {
    return {
      type: RESET_POINTS_FLAG,
    }
  }
  
  //Delete
  export const deletePoint = point => ({
    type: DELETE_POINTS,
    payload: point,
  });
  
  export const deletePointSuccess = point => ({
    type: DELETE_POINTS_SUCCESS,
    payload: point,
  });
  
  export const deletePointFail = error => ({
    type: DELETE_POINTS_FAIL,
    payload: error,
  });
  
  //Status Change
  export const statusChangePoint = point => ({
    type: STATUS_CHANGE_POINTS,
    payload: point,
  });
  
  export const statusChangePointSuccess = point => ({
    type: STATUS_CHANGE_POINTS_SUCCESS,
    payload: point,
  });
  
  export const statusChangePointFail = error => ({
    type: STATUS_CHANGE_POINTS_FAIL,
    payload: error,
  });
  
  //Modify Flag for toaster
  export const modifyPointFlag = () => {
    return {
      type: MODIFY_POINTS_FLAG,
    }
  }