import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateTeam,
  resetTeamFlag,
  getPages,
  getTeamDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { convertToBase64 } from "../../../helpers/image";
const TeamEdit = () => {
  document.title = "Edit Team | Dabbawala";
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState();
  //const [description, setData] = useState();
  const [pagetype, setPagetype] = useState([]);
  const { isTeamUpdate, isTeamUpdateFail, error, success, details, pages } =
    useSelector((state) => ({
      isTeamUpdate: state.Teams.isTeamUpdate,
      isTeamUpdateFail: state.Teams.isTeamUpdateFail,
      error: state.Teams.error,
      success: state.Teams.success,
      details: state.Teams.details,
      pages: state.Pages.pages,
    }));
  console.log(details);
  const selectedTypeOption = {
    value: details && details._id,
    label: details && details.type,
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: details && details.name ? details.name : "",
      description: details && details.description ? details.description : "",
      image: details && details.image ? details.image : "",
      designation: details && details.designation ? details.designation : "",
      // type: details && details.type ? details.type : "",
      //type: selectedTypeOption,

      //type_id: details && details.type_id ? details.type_id : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      description: Yup.string().required("Please Enter Description"),
      designation: Yup.string().required("Please Enter Designation")
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        name: values.name,
        description: values.description,
        designation: values.designation,
        image: image
      };
      dispatch(updateTeam(data));
    },
  });

  useEffect(() => {
    if (isTeamUpdate || isTeamUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetTeamFlag());
        history.push('/teams')
      }, 3000);
    }
  }, [dispatch, isTeamUpdate, isTeamUpdateFail, validation]);
  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getTeamDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
    console.log(pages);
  }, [dispatch, pages]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };
  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
    console.log(pagetypes);
  }, [dispatch, pages]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Edit Team"
            pageTitle="Teams"
            pageUrl="/teams"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Team" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">

                      <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Image *
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImg1Change}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Designation *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Designation"
                              name="designation"
                              value={validation.values.designation || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.designation &&
                                validation.errors.designation
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.designation &&
                            validation.errors.designation ? (
                              <FormFeedback type="invalid">
                                {validation.errors.designation}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        

                        {/* <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="product-title-input">Type *</Label>
                            <Select
                              type="text"
                              designation="type"
                              placeholder="Select Page Type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "type",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              options={pagetype}
                              onBlur={validation.handleBlur}
                              value={validation.values.type || ""}
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}

                        {/* <Col xxl={3} md={4}>
                          <div>
                            <Label htmlFor="product-title-input">
                              Type ID *
                            </Label>
                            <Select
                              type="text"
                              name="type_id"
                              placeholder="Enter Type ID"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "type_id",
                                    value: selectedOption,
                                  },
                                };

                                validation.handleChange(event);
                              }}
                              options={pagetype}
                              onBlur={validation.handleBlur}
                              value={validation.values.type_id || ""}
                              invalid={
                                validation.touched.type_id &&
                                validation.errors.type_id
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type_id &&
                            validation.errors.type_id ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type_id}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}

                        {/* <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                            Description*
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              rows="3"
                              className="form-control"
                              placeholder="Enter description"
                              name="description"
                              value={validation.values.description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.description &&
                                validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                    </Col>

                    {/* <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Link
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Enter Link"
                              name="link"
                              // value={validation.values.link || ""}
                              // onChange={validation.handleChange}
                              // onBlur={validation.handleBlur}
                              // validate={{
                              //   required: { value: true },
                              // }}
                              // invalid={
                              //   validation.touched.title &&
                              //   validation.errors.title
                              //     ? true
                              //     : false
                              //}
                            />
                             {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null} 
                          </div>
                        </Col> */}

                        
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/teams" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isTeamUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isTeamUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default TeamEdit;
