import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  Modal, ModalHeader, ModalBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getJobs,
  delJob,
  resetJobFlag,
  modifyJobFlag,
  statusChangeJob,
  saveOrderingJob,
  saveOrderingJobResetFlag,
} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import MsgToast from "../../../Components/Common/MsgToast";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const JobList = () => {
  document.title = "Job list | Dabbawala";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("ordering");
  const [sortByValue, setSortByValue] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const {
    jobs,
    totalDataCount,
    success,
    isSuccess,
    error,
    statusSuccess,
    statusFailed,
    saveOrderingSuccess,
    saveOrderingFaild,
  } = useSelector((state) => ({
    jobs: state.Jobs.jobs,
    totalDataCount: state.Jobs.totalDataCount,
    isSuccess: state.Jobs.isSuccess,
    success: state.Jobs.success,
    error: state.Jobs.error,

    statusSuccess: state.Jobs.statusSuccess,
    statusFailed: state.Jobs.statusFailed,

    saveOrderingSuccess: state.Jobs.saveOrderingSuccess,
    saveOrderingFaild: state.Jobs.saveOrderingFaild,
  }));

  useEffect(() => {
    dispatch(
      getJobs({
        keyword,
        sortByField,
        sortByValue,
        limit,
        offset,
      })
    );
  }, [dispatch, keyword, sortByField,
    sortByValue, limit, offset]);

  // useEffect(() => {
  //   let dateRange = "";
  //   if (date && date.e && date.e[0] && date.e[1]) {
  //     dateRange = {
  //       start: date.e[0],
  //       end: date.e[1],
  //     };
  //     dispatch(getJobs({ limit, dateRange, offset, keyword }));
  //   }
  //   // console.log(date);
  // }, [dispatch,date,limit, offset, keyword]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyJobFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);

  const resetFilter = () => {
    //dispatch(resetJobFlag())
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    dispatch(getJobs({ keyword, sortByField, sortByValue, limit, offset }))
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === "ASC") {
        setSortByValue("DESC");
      } else {
        setSortByValue("ASC");
      }
    } else {
      setSortByValue("DESC");
    }
    setSortByField(fieldName);
  };
  const deleteJob = (id) => {
    dispatch(delJob({ id }));
  };

  function onClickStatusChange(id, status) {
    console.log(id, status);
    dispatch(statusChangeJob({ id, status }));
  }

    
  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
    
  }




  //Ordring
  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);

  useEffect(() => {
    setDataList(jobs)
  }, [jobs]);

  //console.log(items);
  let a = [];
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for(let i = 0; i<tempData.length; i++){
      dataArray.push({ id: tempData[i]._id, ordering: i+1 });
    }
    setOrderingData(dataArray);
    onClickSaveOrdering(dataArray);
  };

//Ordaring
function onClickSaveOrdering(orderingData) {
  dispatch(saveOrderingJob({
    orderingData: orderingData,
    tableName : 'job',
  }));
  
}


useEffect(() => {
  if (saveOrderingSuccess || saveOrderingFaild) {
    setTimeout(() => {
      dispatch(saveOrderingJobResetFlag());
    }, 3000);
  }
}, [dispatch, saveOrderingSuccess, saveOrderingFaild]);


  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Jobs" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Job List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to="/job/create"
                      className="btn btn-success add-btn"
                      id="createJobTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Job
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createJobTooltip"
                    >
                      Create Job
                    </UncontrolledTooltip>
                    {/* <Button color="danger" id="deleteTooltip">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                      Delete
                    </UncontrolledTooltip> */}
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      {/* <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(+e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col> */}
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      {/* <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col> */}

                      <Col sm={1} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>

                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {dataList?.length ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th> */}
                                {/* <th
                                  className="sort cursor-pointer"
                                  data-sort="image"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Image
                                </th> */}
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="title"
                                  onClick={() => sortBy("title")}
                                >
                                  Name
                                </th>
                                <th>Location</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            
                            <Droppable droppableId="tbody">
                              {
                                (provided) => (<tbody ref={provided.innerRef} {...provided.droppableProps} className="list form-check-all">
                                  {dataList?.map((item, i) => (
                                    <Draggable draggableId={item._id} key={item._id} index={i}>
                                    {(provided) => (
                                      <tr key={item._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      {/* <th scope="row">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="chk_child"
                                            value={item._id}
                                          />
                                        </div>
                                      </th> */}
                                      {/* <td> <img src={item.image} alt="" width="50px" ></img></td> */}
                                      <td>
                                        {item.title}
                                        {/* <br />
                                        <small>{item.job_type}</small>
                                        <br />
                                        <small>{item.location_type}</small> */}
                                      </td>
                                      <td>{item.location_type}</td>
                                      <td>
                                        <Moment format="D MMM YYYY">
                                          {item.created_at}
                                        </Moment>
                                      </td>
                                      <td>
                                      <button style={{backgroundColor: "white", border: "1px solid white", borderRadius: "1px"}} title="Click to change status" onClick={() =>
                                            onClickStatusChange(
                                              item._id,
                                              item.status
                                                ? "false"
                                                : "true"
                                            )
                                          }>
                                            {item?.status ? <span className="badge badge-soft-success text-uppercase">Active</span>:<span className="badge badge-soft-danger text-uppercase">Inactive</span>}
                                          </button>
                                      </td>

                                      <td>
                                        <ul className="list-inline hstack gap-2 mb-0">
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="dropdown-menu-icon"><i className="ri-more-2-fill"></i></DropdownToggle>
                                            <DropdownMenu container="body">
                                              <DropdownItem>
                                                <Link
                                                  to={"/job/edit/" + item._id}
                                                  className="color-btn-black d-flex align-items-center edit-item-btn"
                                                >
                                                  <i className="ri-pencil-fill fs-16 mr-2"></i> Edit
                                                </Link>
                                              </DropdownItem>
                                              <DropdownItem
                                                className="list-inline-item"
                                                title="Status Change"
                                              >
                                                <div
                                                  onClick={() =>
                                                    onClickStatusChange(
                                                      item._id,
                                                      item.status
                                                        ? "false"
                                                        : "true"
                                                    )
                                                  }
                                                  className="p-0 bg-transparent border-0 text-warning d-flex align-items-center"
                                                >
                                                  <i className="ri-exchange-line fs-16 mr-2"></i>Status Change
                                                </div>
                                                <i className="fs-16"></i>
                                            
                                          </DropdownItem>

                                          <DropdownItem>
                                              <div className="p-0 bg-transparent border-0 d-flex align-items-center text-danger"
                                                onClick={()=>openDeleteConfrontation(item?._id)}
                                              >
                                                  <i className="ri-delete-bin-5-fill fs-16 mr-2"></i> Delete
                                              </div>
                                            </DropdownItem>

                                            
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                          {/* <li
                                            className="list-inline-item edit"
                                            title="Edit"
                                          >
                                            <Link
                                              to={`/job/edit/${item._id}`}
                                              className="text-primary d-inline-block edit-item-btn"
                                            >
                                              <i className="ri-pencil-fill fs-16"></i>
                                            </Link>
                                          </li>
                                          <li
                                            className="list-inline-item"
                                            title="Remove"
                                            id={"deletePopover" + item._id}
                                          >
                                            <Button className="p-0 bg-transparent border-0 text-danger">
                                              <i className="ri-delete-bin-5-fill fs-16"></i>
                                            </Button>
                                          </li>
                                          <UncontrolledPopover
                                          trigger="focus"
                                            placement="left"
                                            target={"deletePopover" + item._id}
                                          >
                                            <PopoverHeader>
                                              Delete Job
                                            </PopoverHeader>
                                            <PopoverBody>
                                              Are you sure want to delete?
                                              <div className="d-flex align-items-center justify-content-end mt-2">
                                                <Button className="btn btn-sm btn-light">
                                                  No
                                                </Button>
                                                <Button
                                                  onClick={() =>
                                                    deleteJob(item._id)
                                                  }
                                                  className="btn btn-sm btn-danger ms-2"
                                                >
                                                  Yes
                                                </Button>
                                              </div>
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                          <li
                                            className="list-inline-item"
                                            title="Status Change"
                                          >
                                            <Button
                                              onClick={() =>
                                                onClickStatusChange(
                                                  item._id,
                                                  item.status &&
                                                    item.status === true
                                                    ? false
                                                    : true
                                                )
                                              }
                                              className="p-0 bg-transparent border-0 text-warning"
                                            >
                                              <i className="ri-exchange-line fs-16"></i>
                                            </Button>
                                          </li> */}
                                        </ul>
                                      </td>
                                      </tr>
                                    )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>)
                              }
                            </Droppable>



                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </DragDropContext>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                  <Modal
                    isOpen={modal}
                    toggle={openDeleteConfrontation}
                    trigger="click"
                  >
                    <ModalHeader>
                      Delete
                    </ModalHeader>
                    <ModalBody>
                      Are you sure want to delete?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button 
                          onClick={ () =>
                            {
                              setModal(!modal);
                            }
                          }
                          className="btn btn-sm btn-light">
                          No
                        </Button>

                        <Button
                          onClick={ () =>
                            {
                              deleteJob(deletedId);
                              setModal(!modal);
                            }
                          }
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}


          {saveOrderingSuccess ? (
            <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
            />
          ) : null}
          {saveOrderingFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
      </div>
    </>
  );
};

export default JobList;
