import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_BENEFITS,
  ADD_NEW_BENEFIT,
  GET_BENEFIT_DETAILS,
  UPDATE_BENEFIT,
  DELETE_BENEFIT,
  STATUS_CHANGE_BENEFIT,

  SAVE_ORDERING_BENEFIT,
} from "./actionTypes"

import {
  getBenefitsSuccess,
  getBenefitsFail,
  addNewBenefitSuccess,
  addNewBenefitFail,
  getBenefitDetailsSuccess,
  getBenefitDetailsFail,
  updateBenefitSuccess,
  updateBenefitFail,
  deleteBenefitSuccess,
  deleteBenefitFail,

  statusChangeBenefitSuccess,
  statusChangeBenefitFail,

  saveOrderingBenefitSuccess,
  saveOrderingBenefitFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBenefitList,
  addNewBenefit,
  getBenefitDetails,
  updateBenefit,
  deleteBenefit,
  statusChangeBenefit,

  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchBenefits({ payload: benefit }) {
  try {
    const response = yield call(getBenefitList, benefit)
    yield put(getBenefitsSuccess(response))
  } catch (error) {
    yield put(getBenefitsFail(error))
  }
}

export function* watchFetchBenefits() {
  yield takeEvery(GET_BENEFITS, fetchBenefits);
}

function* onAddNewBenefit({ payload: benefit }) {
  try {
      const response = yield call(addNewBenefit, benefit);
      if(response.status === 200){
        yield put(addNewBenefitSuccess(response));
      } else {
        yield put(addNewBenefitFail(response));
      }
  } catch (error) {
      yield put(addNewBenefitFail(error));
  }
}

export function* watchAddNewBenefit() {
  yield takeEvery(ADD_NEW_BENEFIT, onAddNewBenefit);
}

function* onBenefitDetails({ payload: benefit }) {
  try {
      const response = yield call(getBenefitDetails, benefit);
      if(response.status === 200){
        yield put(getBenefitDetailsSuccess(response));
      } else {
        yield put(getBenefitDetailsFail(response));
      }
  } catch (error) {
      yield put(getBenefitDetailsFail(error));
  }
}

export function* watchBenefitDetails() {
  yield takeEvery(GET_BENEFIT_DETAILS, onBenefitDetails);
}

function* onBenefitUpdate({ payload: benefit }) {
  try {
      const response = yield call(updateBenefit, benefit);
      if(response.status === 200){
        yield put(updateBenefitSuccess(response));
      } else {
        yield put(updateBenefitFail(response));
      }
  } catch (error) {
      yield put(updateBenefitFail(error));
  }
}

export function* watchUpdateBenefit() {
  yield takeEvery(UPDATE_BENEFIT, onBenefitUpdate);
}


//Delete Data
function* onDeleteBenefit({ payload: benefit }) {
  try {
    const response = yield call(deleteBenefit, benefit);
    yield put(deleteBenefitSuccess({ benefit, ...response }));
  } catch (error) {
    yield put(deleteBenefitFail(error));
  }
}

export function* watchDeleteBenefit() {
  yield takeEvery(DELETE_BENEFIT, onDeleteBenefit);
}



//Status Change
function* onStatusChangBenefit({ payload: benefit }) {
  try {
    const response = yield call(statusChangeBenefit, benefit);
    yield put(statusChangeBenefitSuccess({ benefit, ...response }));
  } catch (error) {
    yield put(statusChangeBenefitFail(error));
  }
}

export function* watchStatusChangeBenefit() {
  yield takeEvery(STATUS_CHANGE_BENEFIT, onStatusChangBenefit);
}



//Save Ordering
function* onSaveOrderingBenefit({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingBenefitSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingBenefitFail(error));
  }
}

export function* watchSaveOrderingBenefit() {
  yield takeEvery(SAVE_ORDERING_BENEFIT, onSaveOrderingBenefit);
}



function* benefitSaga() {
  yield all(
    [
      fork(watchFetchBenefits),
      fork(watchAddNewBenefit),
      fork(watchBenefitDetails),
      fork(watchUpdateBenefit),
      fork(watchDeleteBenefit),
      fork(watchStatusChangeBenefit),
      fork(watchSaveOrderingBenefit),
    ]
  );

}

export default benefitSaga
