import {
    GET_CATEGORIES,
    ADD_CATEGORY,
    GET_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_FAIL,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    RESET_ADD_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,
    MODIFY_CAT_FLAG,
    STATUS_CHANGE_CAT,
    STATUS_CHANGE_CAT_SUCCESS,
    STATUS_CHANGE_CAT_FAIL,

    SAVE_ORDERING_CATEGORY,
    SAVE_ORDERING_CATEGORY_SUCCESS,
    SAVE_ORDERING_CATEGORY_FAIL,
    SAVE_ORDERING_CATEGORY_RESET_FLAG,
  } from "./actionTypes";

  export const getCategories = data => ({
    type: GET_CATEGORIES,
    payload: data,
  });
  export const addCategory = data => ({
    type: ADD_CATEGORY,
    payload: data,
  });

  export const editCategory = data => ({
    type: GET_CATEGORY,
    payload: data,
  });

  export const updateCategory = data => ({
    type: UPDATE_CATEGORY,
    payload: data,
  });

  
  export const deleteCategory = data => ({
    type: DELETE_CATEGORY,
    payload: data,
  });

  export const deleteCategorySuccess = data => ({
    type: DELETE_CATEGORY_SUCCESS,
    payload: data,
  });

  export const deleteCategoryFail = data => ({
    type: DELETE_CATEGORY_FAIL,
    payload: data,
  });
 
  
  export const getCategoriesSuccess = categories => ({
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  });
  
  export const getCategoriesFail = error => ({
    type: GET_CATEGORIES_FAIL,
    payload: error,
  });

  export const getCategorySuccess = categories => ({
    type: GET_CATEGORY_SUCCESS,
    payload: categories,
  });
  
  export const getCategoryFail = error => ({
    type: GET_CATEGORY_FAIL,
    payload: error,
  });

  export const AddCategorySuccess = categories => ({
    type: ADD_CATEGORY_SUCCESS,
    payload: categories,
  });
  
  export const AddCategoryFail = error => ({
    type: ADD_CATEGORY_FAIL,
    payload: error,
  });
  export const modifyCatFlag = () => {
    return {
      type: MODIFY_CAT_FLAG,
    }
  }
  export const resetAddCategory = data => ({
    type: RESET_ADD_CATEGORY,
    payload: data,
  });
  
  export const statusChangeCat = cat => ({
    type: STATUS_CHANGE_CAT,
    payload: cat,
  });
  
  export const statusChangeCatSuccess = cat => ({
    type: STATUS_CHANGE_CAT_SUCCESS,
    payload: cat,
  });
  
  export const statusChangeCatFail = error => ({
    type: STATUS_CHANGE_CAT_FAIL,
    payload: error,
  });


  //Save Ordaring
export const saveOrderingCategory = data => ({
  type: SAVE_ORDERING_CATEGORY,
  payload: data,
});

export const saveOrderingCategorySuccess = data => ({
  type: SAVE_ORDERING_CATEGORY_SUCCESS,
  payload: data,
});

export const saveOrderingCategoryFail = error => ({
  type: SAVE_ORDERING_CATEGORY_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingCategoryResetFlag = () => {
  return {
    type: SAVE_ORDERING_CATEGORY_RESET_FLAG,
  }
}
  

