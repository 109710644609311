import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import MsgToast from "../../../Components/Common/MsgToast";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getBanners,
  delBanner,
  getPages,
  resetBannerFlag,
  modifyBannerFlag,
} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";

const BannerList = () => {
  document.title = "Banner list | Dabbawala";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [typeId, setType] = useState("");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const {
    banners,
    pages,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
  } = useSelector((state) => ({
    banners: state.Banners.banners,
    pages: state.Pages.pages,
    totalDataCount: state.Banners.totalDataCount,
    isSuccess: state.Banners.isSuccess,
    success: state.Banners.success,
    error: state.Banners.error,
    statusSuccess: state.Banners.statusSuccess,
    statusFailed: state.Banners.statusFailed,
  }));
console.log(banners);
  useEffect(() => {
    dispatch(
      getBanners({
        keyword,
        date,
        sortByField,
        sortByValue,
        limit,
        offset,
        typeId,
      })
    );
    dispatch(getPages({ title, offset }));
  }, [dispatch,typeId, keyword, date, sortByField, sortByValue, limit, offset, title]);


  // useEffect(() => {
  //   let dateRange = "";
  //   if (date && date.e && date.e[0] && date.e[1]) {
  //     dateRange = {
  //       start: date.e[0],
  //       end: date.e[1],
  //     };
  //     dispatch(getBanners({ limit, dateRange, offset, keyword }));
  //   }
  //   // console.log(date);
  // }, [dispatch, date, limit, offset, keyword]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    setKeyword("");
    setType("");
    fp.current.flatpickr.clear();
    setDate(null);

  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };
  const deleteBanner = (id) => {
    dispatch(delBanner({ id }));
  };
  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyBannerFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);

  function createMarkup() {
    return { __html: "First &middot; Second" };
  }
  
  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
    
  }

  return (
    <>
 
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Banners" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Banner List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to="/banner/create"
                      className="btn btn-success add-btn"
                      id="createBannerTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Banner
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createBannerTooltip"
                    >
                      Create Banner
                    </UncontrolledTooltip>
                    {/* <Button color="danger" id="deleteTooltip">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                      Delete
                    </UncontrolledTooltip> */}
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(parseInt(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={typeId}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value="">Select Page</option>
                            {pages.length &&
                              pages.map((item, index) => (
                                <option value={item._id} key={index}>
                                  {item.title}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                      <Col sm={3} className="col-xxl-2">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={1} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {banners?.length ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th> */}
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="image"
                                  onClick={() => sortBy("image")}
                                >
                                  Image
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="name"
                                  onClick={() => sortBy("name")}
                                >
                                  Name
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="type"
                                  onClick={() => sortBy("type")}
                                >
                                  Page
                                </th>

                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {banners.map((item) => {
                              function createMarkup() {
                                return {__html: item.title};
                              }
                         return (<>
                         <tr key={item._id}>
                          {/* <th scope="row">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="chk_child"
                                value={item._id}
                              />
                            </div>
                          </th> */}
                          <td>
                            {" "}
                            {item?.image && <img
                              className="image avatar-xs rounded-circle"
                              src={item.image}
                              alt=""
                              width="50px"
                            ></img>}
                          </td>
                          <td><div dangerouslySetInnerHTML={createMarkup()} /></td>
                          <td>{item.type}</td>
                          <td>
                            <Moment format="D MMM YYYY">
                              {item.created_at}
                            </Moment>
                          </td>
                          <td>
                            <ul className="list-inline hstack gap-2 mb-0">
                            <UncontrolledDropdown>
                                        <DropdownToggle className="dropdown-menu-icon"><i className="ri-more-2-fill"></i></DropdownToggle>
                                        <DropdownMenu container="body">
                                          <DropdownItem>
                                            <Link
                                              to={`/banner/edit/${item._id}`}
                                              className="color-btn-black d-flex align-items-center edit-item-btn"
                                            >
                                              <i className="ri-pencil-fill fs-16 mr-2"></i> Edit
                                            </Link>
                                          </DropdownItem>
                                      <DropdownItem>
                                          <div className="p-0 bg-transparent border-0 d-flex align-items-center text-danger"
                                            onClick={()=>openDeleteConfrontation(item?._id)}
                                          >
                                              <i className="ri-delete-bin-5-fill fs-16 mr-2"></i> Delete
                                          </div>
                                        </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                              {/* <li
                                className="list-inline-item edit"
                                title="Edit"
                              >
                                <Link
                                  to={`/banner/edit/${item._id}`}
                                  className="text-primary d-inline-block edit-item-btn"
                                >
                                  <i className="ri-pencil-fill fs-16"></i>
                                </Link>
                              </li>
                              <li
                                className="list-inline-item"
                                title="Remove"
                                id={"deletePopover" + item._id}
                              >
                                <Button className="p-0 bg-transparent border-0 text-danger">
                                  <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Button>
                              </li> */}
                              {/* <UncontrolledPopover
                                trigger="focus"
                                placement="left"
                                target={"deletePopover" + item._id}
                              >
                                <PopoverHeader>
                                  Delete Banner List
                                </PopoverHeader>
                                <PopoverBody>
                                  Are you sure want to delete?
                                  <div className="d-flex align-items-center justify-content-end mt-2">
                                    <Button className="btn btn-sm btn-light">
                                      No
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        deleteBanner(item._id)
                                      }
                                      className="btn btn-sm btn-danger ms-2"
                                    >
                                      Yes
                                    </Button>
                                  </div>
                                </PopoverBody>
                              </UncontrolledPopover> */}
                            </ul>
                          </td>
                        </tr></>)
                            
                                    })}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (

                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                        
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                                    <Modal
                    isOpen={modal}
                    toggle={openDeleteConfrontation}
                    trigger="click"
                  >
                    <ModalHeader>
                      Delete
                    </ModalHeader>
                    <ModalBody>
                      Are you sure want to delete?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button 
                          onClick={ () =>
                            {
                              setModal(!modal);
                            }
                          }
                          className="btn btn-sm btn-light">
                          No
                        </Button>

                        <Button
                          onClick={ () =>
                            {
                              deleteBanner(deletedId);
                              setModal(!modal);
                            }
                          }
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
     
    </>
  );
};

export default BannerList;
