import {
  GET_FUTURETEAM_SUCCESS,
  GET_FUTURETEAM_FAIL,

  ADD_NEW_FUTURETEAM_SUCCESS,
  ADD_NEW_FUTURETEAM_FAIL,

  GET_FUTURETEAM_DETAILS_SUCCESS,
  GET_FUTURETEAM_DETAILS_FAIL,

  UPDATE_FUTURETEAM_SUCCESS,
  UPDATE_FUTURETEAM_FAIL,

  STATUS_CHANGE_FUTURETEAM_SUCCESS,
  STATUS_CHANGE_FUTURETEAM_FAIL,

  DELETE_FUTURETEAM_SUCCESS,
  DELETE_FUTURETEAM_FAIL,
  
  SAVE_ORDERING_FUTURETEAM_SUCCESS,
  SAVE_ORDERING_FUTURETEAM_FAIL,
  SAVE_ORDERING_FUTURETEAM_RESET_FLAG,

  RESET_FUTURETEAM_FLAG,
  MODIFY_FUTURETEAM_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  futureteam: []
};

const FutureTeam = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FUTURETEAM_SUCCESS:
      return {
        ...state,
        futureteam: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_FUTURETEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };
    
    //Add New
    case ADD_NEW_FUTURETEAM_SUCCESS:
      return {
        ...state,
        futureteam: [...state.futureteam, action.payload.data],
        success: action.payload.message,
        isFutureTeamAdd: true,
        isFutureTeamAddFail: false,
      };

    case ADD_NEW_FUTURETEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isFutureTeamAdd: false,
        isFutureTeamAddFail: true,
      };

    //Details
    case GET_FUTURETEAM_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_FUTURETEAM_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    //Update
    case UPDATE_FUTURETEAM_SUCCESS:
      return {
        ...state,
        futureteam: state.futureteam.map(step =>
          step._id.toString() === action.payload.data._id.toString()
            ? { ...step, ...action.payload.data }
            : step
        ),
        success: action.payload.message,
        isFutureTeamUpdate: true,
        isFutureTeamUpdateFail: false
      };

    case UPDATE_FUTURETEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isFutureTeamUpdate: false,
        isFutureTeamUpdateFail: true,
      };

    
    //Status Change
    case STATUS_CHANGE_FUTURETEAM_SUCCESS:
      return {
        ...state,
        futureteam: state.futureteam.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_FUTURETEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    //Delete
    case DELETE_FUTURETEAM_SUCCESS:
      return { 
        ...state,
        futureteam: state.futureteam.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_FUTURETEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
    //Save Ordering
    case SAVE_ORDERING_FUTURETEAM_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_FUTURETEAM_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };

    //Save Ordering reset flag 
    case SAVE_ORDERING_FUTURETEAM_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    //Modify flag  
    case MODIFY_FUTURETEAM_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };
    
    //Reset Flag
    case RESET_FUTURETEAM_FLAG:
      return {
        ...state,
        isFutureTeamAdd: false,
        isFutureTeamAddFail: false,
        isSuccess: false,
        isFutureTeamDetails: false,
        isFutureTeamUpdate: false,
        isFutureTeamUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default FutureTeam;
