import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateHighlight,
  resetHighlightFlag,
  getHighlightDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

const HighlightEdit = () => {
  document.title = "Edit Highlight | CEO";
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const { isHighlightUpdate, isHighlightUpdateFail, error, success, details } =
    useSelector((state) => ({
      isHighlightUpdate: state.Highlights.isHighlightUpdate,
      isHighlightUpdateFail: state.Highlights.isHighlightUpdateFail,
      error: state.Highlights.error,
      success: state.Highlights.success,
      details: state.Highlights.details,
    }));
console.log(details);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: details && details.name ? details.name : "",
      video_url: details && details.video_url ? details.video_url : "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      video_url: Yup.string().required("Please Enter Your Video Url"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setIsCreating(true);
      const data = {
        id: id,
        name: values.name,
        video_url: values.video_url,
      };
      dispatch(updateHighlight(data));
    },
  });
  console.log(details);
  useEffect(() => {
    if (isHighlightUpdate || isHighlightUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetHighlightFlag());
        history.push("/highlights");
      }, 3000);
    }
  }, [dispatch, isHighlightUpdate, isHighlightUpdateFail, history, validation]);
  useEffect(() => {
    dispatch(getHighlightDetails({ id: id }));
  }, [dispatch, id]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Edit Highlight"
            pageTitle="Highlights"
            pageUrl="/highlights"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Highlight" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Video URL *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Video URL"
                              name="video_url"
                              value={validation.values.video_url || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.video_url &&
                                validation.errors.video_url
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.video_url &&
                            validation.errors.video_url ? (
                              <FormFeedback type="invalid">
                                {validation.errors.video_url}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/highlights" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isHighlightUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isHighlightUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default HighlightEdit;
