import {
  GET_ALLERGIES,
  GET_ALLERGIES_SUCCESS,
  GET_ALLERGIES_FAIL,
  ADD_NEW_ALLERGIE,
  ADD_NEW_ALLERGIE_SUCCESS,
  ADD_NEW_ALLERGIE_FAIL,
  GET_ALLERGIE_DETAILS,
  GET_ALLERGIE_DETAILS_SUCCESS,
  GET_ALLERGIE_DETAILS_FAIL,
  UPDATE_ALLERGIE,
  UPDATE_ALLERGIE_SUCCESS,
  UPDATE_ALLERGIE_FAIL,
  RESET_ALLERGIE_FLAG,
  DELETE_ALLERGIE,
  DELETE_ALLERGIE_SUCCESS,
  DELETE_ALLERGIE_FAIL,
  STATUS_CHANGE_ALLERGIE,
  STATUS_CHANGE_ALLERGIE_SUCCESS,
  STATUS_CHANGE_ALLERGIE_FAIL,
  MODIFY_ALLERGIE_FLAG,
  SAVE_ORDERING_ALLERGIE,
  SAVE_ORDERING_ALLERGIE_SUCCESS,
  SAVE_ORDERING_ALLERGIE_FAIL,
  SAVE_ORDERING_ALLERGIE_RESET_FLAG
} from "./actionTypes";

export const getAllergies = data => ({
  type: GET_ALLERGIES,
  payload: data,
});

export const getAllergiesSuccess = data => ({
  type: GET_ALLERGIES_SUCCESS,
  payload: data,
});

export const getAllergiesFail = error => ({
  type: GET_ALLERGIES_FAIL,
  payload: error,
});

export const addNewAllergie = allergie=> ({
  type: ADD_NEW_ALLERGIE,
  payload: allergie,
});

export const addNewAllergieSuccess = allergie => ({
  type: ADD_NEW_ALLERGIE_SUCCESS,
  payload: allergie,
});

export const addNewAllergieFail = error => ({
  type: ADD_NEW_ALLERGIE_FAIL,
  payload: error,
});

export const updateAllergie = allergie => ({
  type: UPDATE_ALLERGIE,
  payload: allergie,
});

export const updateAllergieSuccess = allergie => ({
  type: UPDATE_ALLERGIE_SUCCESS,
  payload: allergie,
});

export const updateAllergieFail = error => ({
  type: UPDATE_ALLERGIE_FAIL,
  payload: error,
});

export const getAllergieDetails = allergie => ({
  type: GET_ALLERGIE_DETAILS,
  payload: allergie,
});

export const getAllergieDetailsSuccess = allergie => ({
  type: GET_ALLERGIE_DETAILS_SUCCESS,
  payload: allergie,
});

export const getAllergieDetailsFail = error => ({
  type: GET_ALLERGIE_DETAILS_FAIL,
  payload: error,
});

export const resetAllergieFlag = () => {
  return {
    type: RESET_ALLERGIE_FLAG,
  }
}

//Delete
export const deleteAllergie = allergie => ({
  type: DELETE_ALLERGIE,
  payload: allergie,
});

export const deleteAllergieSuccess = allergie => ({
  type: DELETE_ALLERGIE_SUCCESS,
  payload: allergie,
});

export const deleteAllergieFail = error => ({
  type: DELETE_ALLERGIE_FAIL,
  payload: error,
});

//Status Change
export const statusChangeAllergie = allergie => ({
  type: STATUS_CHANGE_ALLERGIE,
  payload: allergie,
});

export const statusChangeAllergieSuccess = allergie => ({
  type: STATUS_CHANGE_ALLERGIE_SUCCESS,
  payload: allergie,
});

export const statusChangeAllergieFail = error => ({
  type: STATUS_CHANGE_ALLERGIE_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyAllergieFlag = () => {
  return {
    type: MODIFY_ALLERGIE_FLAG,
  }
}

//Save Ordaring
export const saveOrderingAllergie = data => ({
  type: SAVE_ORDERING_ALLERGIE,
  payload: data,
});

export const saveOrderingAllergieSuccess = data => ({
  type: SAVE_ORDERING_ALLERGIE_SUCCESS,
  payload: data,
});

export const saveOrderingAllergieFail = error => ({
  type: SAVE_ORDERING_ALLERGIE_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingAllergieResetFlag = () => {
  return {
    type: SAVE_ORDERING_ALLERGIE_RESET_FLAG,
  }
}
