import {
  GET_SERVICES,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAIL,
  ADD_NEW_SERVICE,
  ADD_NEW_SERVICE_SUCCESS,
  ADD_NEW_SERVICE_FAIL,
  GET_SERVICE_DETAILS,
  GET_SERVICE_DETAILS_SUCCESS,
  GET_SERVICE_DETAILS_FAIL,
  UPDATE_SERVICE,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  RESET_SERVICE_FLAG,
  DELETE_SERVICE,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,

  SAVE_ORDERING_SERVICE,
  SAVE_ORDERING_SERVICE_SUCCESS,
  SAVE_ORDERING_SERVICE_FAIL,
  SAVE_ORDERING_SERVICE_RESET_FLAG,
  
  STATUS_CHANGE_SERVICE,
  STATUS_CHANGE_SERVICE_SUCCESS,
  STATUS_CHANGE_SERVICE_FAIL,
  MODIFY_SERVICE_FLAG
} from "./actionTypes";

export const getServices = data => ({
  type: GET_SERVICES,
  payload: data,
});

export const getServicesSuccess = data => ({
  type: GET_SERVICES_SUCCESS,
  payload: data,
});

export const getServicesFail = error => ({
  type: GET_SERVICES_FAIL,
  payload: error,
});

export const addNewService = service=> ({
  type: ADD_NEW_SERVICE,
  payload: service,
});

export const addNewServiceSuccess = service => ({
  type: ADD_NEW_SERVICE_SUCCESS,
  payload: service,
});

export const addNewServiceFail = error => ({
  type: ADD_NEW_SERVICE_FAIL,
  payload: error,
});

export const updateService = service => ({
  type: UPDATE_SERVICE,
  payload: service,
});

export const updateServiceSuccess = service => ({
  type: UPDATE_SERVICE_SUCCESS,
  payload: service,
});

export const updateServiceFail = error => ({
  type: UPDATE_SERVICE_FAIL,
  payload: error,
});

export const getServiceDetails = service => ({
  type: GET_SERVICE_DETAILS,
  payload: service,
});

export const getServiceDetailsSuccess = service => ({
  type: GET_SERVICE_DETAILS_SUCCESS,
  payload: service,
});

export const getServiceDetailsFail = error => ({
  type: GET_SERVICE_DETAILS_FAIL,
  payload: error,
});

export const resetServiceFlag = () => {
  return {
    type: RESET_SERVICE_FLAG,
  }
}

//Delete
export const deleteService = service => ({
  type: DELETE_SERVICE,
  payload: service,
});

export const deleteServiceSuccess = service => ({
  type: DELETE_SERVICE_SUCCESS,
  payload: service,
});

export const deleteServiceFail = error => ({
  type: DELETE_SERVICE_FAIL,
  payload: error,
});

//Save Ordaring
export const saveOrderingService = data => ({
  type: SAVE_ORDERING_SERVICE,
  payload: data,
});

export const saveOrderingServiceSuccess = data => ({
  type: SAVE_ORDERING_SERVICE_SUCCESS,
  payload: data,
});

export const saveOrderingServiceFail = error => ({
  type: SAVE_ORDERING_SERVICE_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingServiceResetFlag = () => {
  return {
    type: SAVE_ORDERING_SERVICE_RESET_FLAG,
  }
}

//Status Change
export const statusChangeService = service => ({
  type: STATUS_CHANGE_SERVICE,
  payload: service,
});

export const statusChangeServiceSuccess = service => ({
  type: STATUS_CHANGE_SERVICE_SUCCESS,
  payload: service,
});

export const statusChangeServiceFail = error => ({
  type: STATUS_CHANGE_SERVICE_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyServiceFlag = () => {
  return {
    type: MODIFY_SERVICE_FLAG,
  }
}