import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import {
  GET_BLOGS,
  GET_BLOGCOMMENTS,
  ADD_NEW_BLOG,
  GET_BLOG_DETAILS,
  UPDATE_BLOG,
  DELETE_BLOG,
  DELETE_BLOGCOMMENT,
  STATUS_CHANGE_BLOG,

  SAVE_ORDERING_BLOG,
  STATUS_CHANGE_BLOGCOMMENT,
} from "./actionTypes";

import {
  getBlogsSuccess,
  getBlogsFail,
  getBlogcommentsSuccess,
  getBlogcommentsFail,
  addNewBlogSuccess,
  addNewBlogFail,
  getBlogDetailsSuccess,
  getBlogDetailsFail,
  updateBlogSuccess,
  updateBlogFail,
  deleteBlogSuccess,
  deleteBlogFail,
  deleteBlogCommentSuccess,
  deleteBlogCommentFail,
  statusChangeBlogSuccess,
  statusChangeBlogFail,
  statusChangeBlogCommentSuccess,
  statusChangeBlogCommentFail,
  saveOrderingBlogSuccess,
  saveOrderingBlogFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getBlogList,
  getBlogCommentList,
  addNewBlog,
  getBlogDetails,
  updateBlog,
  delBlog,
  delBlogComment,
  statusChangeBlog,
  statusChangeBlogComment,
  saveOrdering,
} from "../../helpers/fakebackend_helper";

function* fetchBlogs({ payload: data }) {
  try {
    const response = yield call(getBlogList, data);
    yield put(getBlogsSuccess(response));
  } catch (error) {
    yield put(getBlogsFail(error));
  }
}
function* fetchBlogcomments({ payload: data }) {
  try {
    const response = yield call(getBlogCommentList, data);
    yield put(getBlogcommentsSuccess(response));
  } catch (error) {
    yield put(getBlogcommentsFail(error));
  }
}

function* onAddNewBlog({ payload: blog }) {
  try {
    const response = yield call(addNewBlog, blog);
    if (response.status === 200) {
      yield put(addNewBlogSuccess(response));
    } else {
      yield put(addNewBlogFail(response));
    }
  } catch (error) {
    yield put(addNewBlogFail(error));
  }
}


function* onBlogDetails({ payload: blog }) {
  try {
    const response = yield call(getBlogDetails, blog);
    if (response.status === 200) {
      yield put(getBlogDetailsSuccess(response));
    } else {
      yield put(getBlogDetailsFail(response));
    }
  } catch (error) {
    yield put(getBlogDetailsFail(error));
  }
}

function* onBlogUpdate({ payload: blog }) {
  try {
    const response = yield call(updateBlog, blog);
    if (response.status === 200) {
      yield put(updateBlogSuccess(response));
    } else {
      yield put(updateBlogFail(response));
    }
  } catch (error) {
    yield put(updateBlogFail(error));
  }
}

function* delBlogs({ payload: data }) {
  try {
    const response = yield call(delBlog, data);
    yield put(deleteBlogSuccess(response));
  } catch (error) {
    yield put(deleteBlogFail(error));
  }
}

function* deleteBlogComment({ payload: data }) {
  try {
    const response = yield call(delBlogComment, data);
    yield put(deleteBlogCommentSuccess(response));
  } catch (error) {
    yield put(deleteBlogCommentFail(error));
  }
}

//Status Change
function* onStatusChangeBlog({ payload: blog }) {
  try {
    const response = yield call(statusChangeBlog, blog);
    yield put(statusChangeBlogSuccess({ blog, ...response }));
  } catch (error) {
    yield put(statusChangeBlogFail(error));
  }
}

function* onStatusChangeBlogComment({ payload: data }) {
  try {
    const response = yield call(statusChangeBlogComment, data);
    yield put(statusChangeBlogCommentSuccess({ data, ...response }));
  } catch (error) {
    yield put(statusChangeBlogCommentFail(error));
  }
}

//Save Ordering
function* onSaveOrderingBlog({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingBlogSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingBlogFail(error));
  }
}



function* BlogSaga() {
  yield takeEvery(GET_BLOGS, fetchBlogs);
  yield takeEvery(GET_BLOGCOMMENTS, fetchBlogcomments);
  yield takeEvery(ADD_NEW_BLOG, onAddNewBlog);
  yield takeEvery(GET_BLOG_DETAILS, onBlogDetails);
  yield takeEvery(UPDATE_BLOG, onBlogUpdate);
  yield takeEvery(DELETE_BLOG, delBlogs);
  yield takeEvery(DELETE_BLOGCOMMENT, deleteBlogComment);
  yield takeEvery(STATUS_CHANGE_BLOG, onStatusChangeBlog);
  yield takeEvery(SAVE_ORDERING_BLOG, onSaveOrderingBlog);
  yield takeEvery(STATUS_CHANGE_BLOGCOMMENT, onStatusChangeBlogComment);
}

export default BlogSaga;
