import {
  GET_FUTURETEAM,
  GET_FUTURETEAM_SUCCESS,
  GET_FUTURETEAM_FAIL,

  ADD_NEW_FUTURETEAM,
  ADD_NEW_FUTURETEAM_SUCCESS,
  ADD_NEW_FUTURETEAM_FAIL,

  GET_FUTURETEAM_DETAILS,
  GET_FUTURETEAM_DETAILS_SUCCESS,
  GET_FUTURETEAM_DETAILS_FAIL,

  UPDATE_FUTURETEAM,
  UPDATE_FUTURETEAM_SUCCESS,
  UPDATE_FUTURETEAM_FAIL,

  STATUS_CHANGE_FUTURETEAM,
  STATUS_CHANGE_FUTURETEAM_SUCCESS,
  STATUS_CHANGE_FUTURETEAM_FAIL,

  DELETE_FUTURETEAM,
  DELETE_FUTURETEAM_SUCCESS,
  DELETE_FUTURETEAM_FAIL,

  SAVE_ORDERING_FUTURETEAM,
  SAVE_ORDERING_FUTURETEAM_SUCCESS,
  SAVE_ORDERING_FUTURETEAM_FAIL,
  SAVE_ORDERING_FUTURETEAM_RESET_FLAG,

  RESET_FUTURETEAM_FLAG,
  MODIFY_FUTURETEAM_FLAG,

} from "./actionTypes";

//List
export const getFutureTeam = data => ({
  type: GET_FUTURETEAM,
  payload: data,
});

export const getFutureTeamSuccess = data => ({
  type: GET_FUTURETEAM_SUCCESS,
  payload: data,
});

export const getFutureTeamFail = error => ({
  type: GET_FUTURETEAM_FAIL,
  payload: error,
});


//Add New
export const addNewFutureTeam = data=> ({
  type: ADD_NEW_FUTURETEAM,
  payload: data,
});

export const addNewFutureTeamSuccess = data => ({
  type: ADD_NEW_FUTURETEAM_SUCCESS,
  payload: data,
});

export const addNewFutureTeamFail = error => ({
  type: ADD_NEW_FUTURETEAM_FAIL,
  payload: error,
});


//Details
export const getFutureTeamDetails = data => ({
  type: GET_FUTURETEAM_DETAILS,
  payload: data,
});

export const getFutureTeamDetailsSuccess = data => ({
  type: GET_FUTURETEAM_DETAILS_SUCCESS,
  payload: data,
});

export const getFutureTeamDetailsFail = error => ({
  type: GET_FUTURETEAM_DETAILS_FAIL,
  payload: error,
});


//Update
export const updateFutureTeam = data => ({
  type: UPDATE_FUTURETEAM,
  payload: data,
});

export const updateFutureTeamSuccess = data => ({
  type: UPDATE_FUTURETEAM_SUCCESS,
  payload: data,
});

export const updateFutureTeamFail = error => ({
  type: UPDATE_FUTURETEAM_FAIL,
  payload: error,
});


//Status Change
export const statusChangeFutureTeam = data => ({
  type: STATUS_CHANGE_FUTURETEAM,
  payload: data,
});

export const statusChangeFutureTeamSuccess = data => ({
  type: STATUS_CHANGE_FUTURETEAM_SUCCESS,
  payload: data,
});

export const statusChangeFutureTeamFail = error => ({
  type: STATUS_CHANGE_FUTURETEAM_FAIL,
  payload: error,
});


//Delete
export const deleteFutureTeam = data => ({
  type: DELETE_FUTURETEAM,
  payload: data,
});

export const deleteFutureTeamSuccess = data => ({
  type: DELETE_FUTURETEAM_SUCCESS,
  payload: data,
});

export const deleteFutureTeamFail = error => ({
  type: DELETE_FUTURETEAM_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingFutureTeam = data => ({
  type: SAVE_ORDERING_FUTURETEAM,
  payload: data,
});

export const saveOrderingFutureTeamSuccess = data => ({
  type: SAVE_ORDERING_FUTURETEAM_SUCCESS,
  payload: data,
});

export const saveOrderingFutureTeamFail = error => ({
  type: SAVE_ORDERING_FUTURETEAM_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingFutureTeamResetFlag = () => {
  return {
    type: SAVE_ORDERING_FUTURETEAM_RESET_FLAG,
  }
}


//Reset Flag
export const resetFutureTeamFlag = () => {
  return {
    type: RESET_FUTURETEAM_FLAG,
  }
}


//Modify Flag for toaster
export const modifyFutureTeamFlag = () => {
  return {
    type: MODIFY_FUTURETEAM_FLAG,
  }
}