/* ALLERGIES */
export const GET_ALLERGIES = "GET_ALLERGIES";
export const GET_ALLERGIES_SUCCESS = "GET_ALLERGIES_SUCCESS";
export const GET_ALLERGIES_FAIL = "GET_ALLERGIES_FAIL";

export const ADD_NEW_ALLERGIE = "ADD_NEW_ALLERGIE";
export const ADD_NEW_ALLERGIE_SUCCESS = "ADD_NEW_ALLERGIE_SUCCESS";
export const ADD_NEW_ALLERGIE_FAIL = "ADD_NEW_ALLERGIE_FAIL";

export const UPDATE_ALLERGIE = "UPDATE_ALLERGIE";
export const UPDATE_ALLERGIE_SUCCESS = "UPDATE_ALLERGIE_SUCCESS";
export const UPDATE_ALLERGIE_FAIL = "UPDATE_ALLERGIE_FAIL";

export const GET_ALLERGIE_DETAILS = "GET_ALLERGIE_DETAILS";
export const GET_ALLERGIE_DETAILS_SUCCESS = "GET_ALLERGIE_DETAILS_SUCCESS";
export const GET_ALLERGIE_DETAILS_FAIL = "GET_ALLERGIE_DETAILS_FAIL";

export const RESET_ALLERGIE_FLAG = "RESET_ALLERGIE_FLAG";

export const DELETE_ALLERGIE = "DELETE_ALLERGIE";
export const DELETE_ALLERGIE_SUCCESS = "DELETE_ALLERGIE_SUCCESS";
export const DELETE_ALLERGIE_FAIL = "DELETE_ALLERGIE_FAIL";

export const STATUS_CHANGE_ALLERGIE = "STATUS_CHANGE_ALLERGIE";
export const STATUS_CHANGE_ALLERGIE_SUCCESS = "STATUS_CHANGE_ALLERGIE_SUCCESS";
export const STATUS_CHANGE_ALLERGIE_FAIL = "STATUS_CHANGE_ALLERGIE_FAIL";

export const SAVE_ORDERING_ALLERGIE = "SAVE_ORDERING_ALLERGIE";
export const SAVE_ORDERING_ALLERGIE_SUCCESS = "SAVE_ORDERING_ALLERGIE_SUCCESS";
export const SAVE_ORDERING_ALLERGIE_FAIL = "SAVE_ORDERING_ALLERGIE_FAIL";
export const SAVE_ORDERING_ALLERGIE_RESET_FLAG = "SAVE_ORDERING_ALLERGIE_RESET_FLAG";

export const MODIFY_ALLERGIE_FLAG = "MODIFY_ALLERGIE_FLAG";