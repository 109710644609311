import React, { useEffect, useState, useRef, Fragment } from "react";
import {
    Container,
    Row,
    Col,
    CardHeader,
    Card,
    CardBody,
    Button,
    UncontrolledTooltip,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
    UncontrolledDropdown,
    Modal, ModalHeader, ModalBody,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
    getOrganizations,
    delOrganization,
    statusChangeOrganization,
    modifyOrganizationFlag,
    getOrganizationDetails
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";

const OrganizationDetailsList = () => {
    document.title = "Organization Details list | Dabbawala - Admin Panel";
    const fp = useRef(null);
    const [keyword, setKeyword] = useState("");
    const { id } = useParams();
    const [status, setStatus] = useState("");
    const [type, setType] = useState(3);
    const [date, setDate] = useState(null);
    const [sortByField, setSortByField] = useState("created_at");
    const [sortByValue, setSortByValue] = useState(-1);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [noOfPage, setNoOfPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const {
        organisations,
        totalDataCount,
        isSuccess,
        error,
        success,
        statusSuccess,
        statusFaild,
        details,
        transactionList
    } = useSelector((state) => ({
        organisations: state.Organisations.organisations,
        totalDataCount: state.Organisations.totalDataCount,
        isSuccess: state.Organisations.isSuccess,
        statusSuccess: state.Organisations.statusSuccess,
        statusFaild: state.Organisations.statusFaild,
        error: state.Organisations.error,
        success: state.Organisations.success,
        details: state.Organisations.details,
        transactionList: state.Organisations.transactionList
    }));
    // console.log("details", details);

    useEffect(() => {
        dispatch(
            getOrganizations({
                keyword,
                date,
                type,
                status,
                sortByField,
                sortByValue,
                limit,
                offset,
            })
        );
    }, [
        dispatch,
        keyword,
        date,
        type,
        status,
        sortByField,
        sortByValue,
        limit,
        offset,
    ]);

    useEffect(() => {
        setNoOfPage(Math.ceil(totalDataCount / limit));
    }, [limit, totalDataCount]);

    const sortBy = (fieldName) => {
        if (fieldName === sortByField) {
            if (sortByValue === 1) {
                setSortByValue(-1);
            } else {
                setSortByValue(1);
            }
        } else {
            setSortByValue(-1);
        }
        setSortByField(fieldName);
    };

    

    

    useEffect(() => {
        if (statusSuccess || statusFaild) {
            setTimeout(() => {
                dispatch(modifyOrganizationFlag());
            }, 3000);
        }
    }, [dispatch, statusSuccess, statusFaild]);

    useEffect(() => {
        dispatch(getOrganizationDetails({ id: id }));
    }, [dispatch, id]);

    

    

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Organization Details" />
                    <Row>
                        <Col lg={12}>
                            <Card id="orderList">
                                <CardHeader className="card-header border-0 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">Organization Details List</h5>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <form>
                                        <Row className="g-3">
                                            <Col sm={2} className="col-xxl-2">
                                                <select
                                                    className="form-select"
                                                    value={limit}
                                                    onChange={(e) => setLimit(e.target.value)}
                                                >
                                                    <option disabled>Select Page</option>
                                                    <option value="10">Per page 10</option>
                                                    <option value="20">Per page 20</option>
                                                    <option value="30">Per page 30</option>
                                                    <option value="40">Per page 40</option>
                                                    <option value="50">Per page 50</option>
                                                    <option value="9999999999">All</option>
                                                </select>
                                            </Col>

                                            <Col sm={4} className="col-xxl-4">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Search"
                                                        value={keyword}
                                                        onChange={(e) => setKeyword(e.target.value)}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </Col>



                                            <Col sm={2} className="col-xxl-2">
                                                <select
                                                    className="form-select"
                                                    value={status}
                                                    onChange={(e) => {
                                                        // console.log(
                                                        //     typeof e.target.value,
                                                        //     e.target.value.length
                                                        // );
                                                        const value =
                                                            e.target.value === "true"
                                                                ? Boolean(e.target.value)
                                                                : e.target.value === "false"
                                                                    ? false
                                                                    : "";
                                                        setStatus(value);
                                                    }}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>Inactive</option>
                                                </select>
                                            </Col>

                                            <Col sm={4} className="col-xxl-3">
                                                <div>
                                                    <Flatpickr
                                                        ref={fp}
                                                        className="form-control"
                                                        id="datepicker-publish-input"
                                                        placeholder="Select a date"
                                                        onChange={(e) => {
                                                            setDate({ e });
                                                        }}
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            mode: "range",
                                                            dateFormat: "d.m.y",
                                                        }}
                                                    />
                                                </div>
                                            </Col>


                                        </Row>
                                    </form>
                                </CardBody>
                                <CardBody className="pt-0">
                                    {isSuccess ? (
                                        <div className="table-responsive table-card mt-3 mb-1">
                                            {transactionList.length ? (
                                                <>
                                                    <table
                                                        className="table align-middle table-nowrap"
                                                        id="customerTable"
                                                    >
                                                        <thead className="table-light">
                                                            <tr>

                                                                <th
                                                                    className="sort cursor-pointer"
                                                                    data-sort="name"
                                                                    onClick={() => sortBy("name")}
                                                                >
                                                                    User Name/Email
                                                                </th>
                                                                <th
                                                                    className="sort cursor-pointer"
                                                                    data-sort="title"
                                                                    onClick={() => sortBy("title")}
                                                                >
                                                                    Organization Title
                                                                </th>
                                                                <th>Description</th>
                                                                <th>Point</th>
                                                                <th>Point Type</th>
                                                                <th>Order Amount</th>
                                                                <th>Status</th>
                                                                <th
                                                                    className="sort cursor-pointer"
                                                                    data-sort="created_at"
                                                                    onClick={() => sortBy("created_at")}
                                                                >
                                                                    Created Date
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="list form-check-all">

                                                            {transactionList.map((item) => (
                                                                <tr key={item._id}>
                                                                    <td>{item.user_details.name}
                                                                        <br />
                                                                        {item.user_details.email}
                                                                    </td>
                                                                    <td>{item.organization_details.title}</td>
                                                                    <td>{item.organization_details.description}</td>
                                                                    <td>{item.point}</td>
                                                                    <td>{item.point_type}</td>
                                                                    <td>{item.order_amount}</td>
                                                                    <td>
                                                                        {item.status === 1 && (
                                                                            <span className="badge badge-soft-success text-uppercase">
                                                                                In
                                                                            </span>
                                                                        )}
                                                                        {item.status === 2 && (
                                                                            <span className="badge badge-soft-danger text-uppercase">
                                                                                Out
                                                                            </span>
                                                                        )}
                                                                        
                                                                    </td>
                                                                    
                                                                   
                                                                    
                                                                    <td>
                                                                        <Moment format="D MMM YYYY">
                                                                            {item.created_at}
                                                                        </Moment>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {noOfPage > 1 && (
                                                        <Pagination className="px-3">
                                                            {Array(noOfPage)
                                                                .fill()
                                                                .map((_, i) => {
                                                                    return (
                                                                        <Fragment key={i}>
                                                                            {offset > 0 && i === 0 && (
                                                                                <PaginationItem>
                                                                                    {" "}
                                                                                    <PaginationLink
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            setOffset(offset - limit);
                                                                                            setCurrentPage(currentPage - 1);
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        ← &nbsp; Prev{" "}
                                                                                    </PaginationLink>{" "}
                                                                                </PaginationItem>
                                                                            )}
                                                                            {currentPage === i + 1 && (
                                                                                <PaginationItem active>
                                                                                    {" "}
                                                                                    <PaginationLink>
                                                                                        {" "}
                                                                                        {i + 1}{" "}
                                                                                    </PaginationLink>{" "}
                                                                                </PaginationItem>
                                                                            )}
                                                                            {currentPage !== i + 1 && (
                                                                                <PaginationItem>
                                                                                    {" "}
                                                                                    <PaginationLink
                                                                                        to="#"
                                                                                        onClick={() => {
                                                                                            setOffset(limit * i);
                                                                                            setCurrentPage(i + 1);
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        {i + 1}{" "}
                                                                                    </PaginationLink>{" "}
                                                                                </PaginationItem>
                                                                            )}
                                                                            {currentPage !== noOfPage &&
                                                                                i + 1 === noOfPage && (
                                                                                    <PaginationItem>
                                                                                        {" "}
                                                                                        <PaginationLink
                                                                                            to="#"
                                                                                            onClick={() => {
                                                                                                setOffset(offset + limit);
                                                                                                setCurrentPage(currentPage + 1);
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            Next &nbsp; →{" "}
                                                                                        </PaginationLink>{" "}
                                                                                    </PaginationItem>
                                                                                )}
                                                                        </Fragment>
                                                                    );
                                                                })}
                                                        </Pagination>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="noresult">
                                                    <div className="text-center">
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a"
                                                            style={{ width: "75px", height: "75px" }}
                                                        ></lord-icon>
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <Loader error={error} />
                                    )}
                                    {/* <Modal
                                        isOpen={modal}
                                        toggle={openDeleteConfrontation}
                                        trigger="click"
                                    >
                                        <ModalHeader>
                                            Delete
                                        </ModalHeader>
                                        <ModalBody>
                                            Are you sure want to delete?
                                            <div className="d-flex align-items-center justify-content-end mt-2">
                                                <Button
                                                    onClick={() => {
                                                        setModal(!modal);
                                                    }
                                                    }
                                                    className="btn btn-sm btn-light">
                                                    No
                                                </Button>

                                                <Button
                                                    onClick={() => {
                                                        onClickDeleteData(deletedId);
                                                        setModal(!modal);
                                                    }
                                                    }
                                                    className="btn btn-sm btn-danger ms-2"
                                                >
                                                    Yes
                                                </Button>
                                            </div>
                                        </ModalBody>
                                    </Modal> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {statusSuccess ? (
                        <MsgToast
                            msg={success}
                            color="success"
                            icon="ri-checkbox-circle-line"
                        />
                    ) : null}
                    {statusFaild ? (
                        <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
                    ) : null}
                </Container>
            </div>
        </>
    );
};

export default OrganizationDetailsList;
