import {
  ADD_NEW_JOB_LOCATION_TYPE,
  ADD_NEW_JOB_LOCATION_TYPE_SUCCESS,
  ADD_NEW_JOB_LOCATION_TYPE_FAIL,
  GET_JOB_LOCATION_DETAILS,
  GET_JOB_LOCATION_DETAILS_SUCCESS,
  GET_JOB_LOCATION_DETAILS_FAIL,
  UPDATE_JOB_LOCATION_TYPE,
  UPDATE_JOB_LOCATION_TYPE_SUCCESS,
  UPDATE_JOB_LOCATION_TYPE_FAIL,
  GET_JOB_LOCATION_TYPE_LIST,
  GET_JOB_LOCATION_TYPE_LIST_SUCCESS,
  GET_JOB_LOCATION_TYPE_LIST_FAIL,
  DELETE_JOB_LOCATION_TYPE,
  DELETE_JOB_LOCATION_TYPE_SUCCESS,
  DELETE_JOB_LOCATION_TYPE_FAIL,
  MODIFY_JOB_LOCATION_TYPE_FLAG,
  RESET_JOB_LOCATION_TYPE_FLAG,
  STATUS_CHANGE_JOB_LOCATION,
  STATUS_CHANGE_JOB_LOCATION_SUCCESS,
  STATUS_CHANGE_JOB_LOCATION_FAIL
} from "./actionTypes";

export const addNewJobLocationType = (job) => ({
  type: ADD_NEW_JOB_LOCATION_TYPE,
  payload: job,
});

export const addNewJobLocationTypeSuccess = (job) => ({
  type: ADD_NEW_JOB_LOCATION_TYPE_SUCCESS,
  payload: job,
});

export const addNewJobLocationTypeFail = (error) => ({
  type: ADD_NEW_JOB_LOCATION_TYPE_FAIL,
  payload: error,
});

export const updateJobLocationType = (job) => ({
  type: UPDATE_JOB_LOCATION_TYPE,
  payload: job,
});

export const updateJobLocationTypeSuccess = (job) => ({
  type: UPDATE_JOB_LOCATION_TYPE_SUCCESS,
  payload: job,
});

export const updateJobLocationTypeFail = (error) => ({
  type: UPDATE_JOB_LOCATION_TYPE_FAIL,
  payload: error,
});

export const getJobLocationDetails = job => ({
  type: GET_JOB_LOCATION_DETAILS,
  payload: job,
});

export const getJobLocationDetailsSuccess = job => ({
  type: GET_JOB_LOCATION_DETAILS_SUCCESS,
  payload: job,
});

export const getJobLocationDetailsFail = error => ({
  type: GET_JOB_LOCATION_DETAILS_FAIL,
  payload: error,
});

// Status
export const statusChangeJobLocation = job => ({
  type: STATUS_CHANGE_JOB_LOCATION,
  payload: job,
});

export const statusChangeJobLocationSuccess = job => ({
  type: STATUS_CHANGE_JOB_LOCATION_SUCCESS,
  payload: job,
});

export const statusChangeJobLocationFail = error => ({
  type: STATUS_CHANGE_JOB_LOCATION_FAIL,
  payload: error,
});

// Job Location Type List
export const getJobLocationTypeList = job => ({
  type: GET_JOB_LOCATION_TYPE_LIST,
  payload: job,
});

export const getJobLocationTypeListSuccess = (job) => ({
  type: GET_JOB_LOCATION_TYPE_LIST_SUCCESS,
  payload: job,
});

export const getJobLocationTypeListFail = (error) => ({
  type: GET_JOB_LOCATION_TYPE_LIST_FAIL,
  payload: error,
});
export const deleteJobLocationTypeList = data => ({
  type: DELETE_JOB_LOCATION_TYPE,
  payload: data,
});

export const deleteJobLocationTypeListSuccess = data => ({
  type: DELETE_JOB_LOCATION_TYPE_SUCCESS,
  payload: data,
});

export const deleteJobLocationTypeListFail = data => ({
  type: DELETE_JOB_LOCATION_TYPE_FAIL,
  payload: data,
});
export const modifyJobLocTypeFlag = () => {
  return {
    type: MODIFY_JOB_LOCATION_TYPE_FLAG,
  }
}
export const resetJobLocationTypeFlag = () => {
  return {
    type: RESET_JOB_LOCATION_TYPE_FLAG,
  };
};
