import {
  GET_SPICES_SUCCESS,
  GET_SPICES_FAIL,
  ADD_NEW_SPICE_SUCCESS,
  ADD_NEW_SPICE_FAIL,
  GET_SPICE_DETAILS_SUCCESS,
  GET_SPICE_DETAILS_FAIL,
  UPDATE_SPICE_SUCCESS,
  UPDATE_SPICE_FAIL,
  RESET_SPICE_FLAG,
  DELETE_SPICE_SUCCESS,
  DELETE_SPICE_FAIL,
  STATUS_CHANGE_SPICE_SUCCESS,
  STATUS_CHANGE_SPICE_FAIL,
  MODIFY_SPICE_FLAG, 
  SAVE_ORDERING_SPICE_SUCCESS,
  SAVE_ORDERING_SPICE_FAIL,
  SAVE_ORDERING_SPICE_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  spices: []
};

const Spices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SPICES_SUCCESS:
      return {
        ...state,
        spices: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_SPICES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_SPICE_SUCCESS:
      return {
        ...state,
        spices: [...state.spices, action.payload.data],
        success: action.payload.message,
        isSpiceAdd: true,
        isSpiceAddFail: false,
      };

    case ADD_NEW_SPICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSpiceAdd: false,
        isSpiceAddFail: true,
      };

    case UPDATE_SPICE_SUCCESS:
      return {
        ...state,
        spices: state.spices.map(spice =>
          spice._id.toString() === action.payload.data._id.toString()
            ? { ...spice, ...action.payload.data }
            : spice
        ),
        success: action.payload.message,
        isSpiceUpdate: true,
        isSpiceUpdateFail: false
      };

    case UPDATE_SPICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSpiceUpdate: false,
        isSpiceUpdateFail: true,
      };

    case GET_SPICE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_SPICE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_SPICE_SUCCESS:
      return { 
        ...state,
        spices: state.spices.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_SPICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_SPICE_SUCCESS:
      return {
        ...state,
        spices: state.spices.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_SPICE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_SPICE_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_SPICE_FLAG:
      return {
        ...state,
        isSpiceAdd: false,
        isSpiceAddFail: false,
        isSuccess: false,
        isSpiceDetails: false,
        isSpiceUpdate: false,
        isSpiceUpdateFail: false,
        error: false
      };


      //Save Ordering
      case SAVE_ORDERING_SPICE_SUCCESS:
        return {
          ...state,
          success: action.payload.message,
          saveOrderingSuccess: true,
          saveOrderingFaild: false,
        };
          
      case SAVE_ORDERING_SPICE_FAIL:
        return {
          ...state,
          error: action.payload.message,
          saveOrderingSuccess: false,
          saveOrderingFaild: true,
        };
  
  
      //Save Ordering reset flag 
      case SAVE_ORDERING_SPICE_RESET_FLAG:
        return {
          ...state,
          saveOrderingSuccess: false,
          saveOrderingFaild: false,
          error: false
        };

    default:
      return state;
  }
};

export default Spices;
