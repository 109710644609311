/* INGREDIENTS */
export const GET_INGREDIENTS = "GET_INGREDIENTS";
export const GET_INGREDIENTS_SUCCESS = "GET_INGREDIENTS_SUCCESS";
export const GET_INGREDIENTS_FAIL = "GET_INGREDIENTS_FAIL";

export const ADD_NEW_INGREDIENT = "ADD_NEW_INGREDIENT";
export const ADD_NEW_INGREDIENT_SUCCESS = "ADD_NEW_INGREDIENT_SUCCESS";
export const ADD_NEW_INGREDIENT_FAIL = "ADD_NEW_INGREDIENT_FAIL";

export const UPDATE_INGREDIENT = "UPDATE_INGREDIENT";
export const UPDATE_INGREDIENT_SUCCESS = "UPDATE_INGREDIENT_SUCCESS";
export const UPDATE_INGREDIENT_FAIL = "UPDATE_INGREDIENT_FAIL";

export const GET_INGREDIENT_DETAILS = "GET_INGREDIENT_DETAILS";
export const GET_INGREDIENT_DETAILS_SUCCESS = "GET_INGREDIENT_DETAILS_SUCCESS";
export const GET_INGREDIENT_DETAILS_FAIL = "GET_INGREDIENT_DETAILS_FAIL";

export const RESET_INGREDIENT_FLAG = "RESET_INGREDIENT_FLAG";

export const DELETE_INGREDIENT = "DELETE_INGREDIENT";
export const DELETE_INGREDIENT_SUCCESS = "DELETE_INGREDIENT_SUCCESS";
export const DELETE_INGREDIENT_FAIL = "DELETE_INGREDIENT_FAIL";

export const STATUS_CHANGE_INGREDIENT = "STATUS_CHANGE_INGREDIENT";
export const STATUS_CHANGE_INGREDIENT_SUCCESS = "STATUS_CHANGE_INGREDIENT_SUCCESS";
export const STATUS_CHANGE_INGREDIENT_FAIL = "STATUS_CHANGE_INGREDIENT_FAIL";

export const MODIFY_INGREDIENT_FLAG = "MODIFY_INGREDIENT_FLAG";