/* BENEFITS */
export const GET_BENEFITS = "GET_BENEFITS";
export const GET_BENEFITS_SUCCESS = "GET_BENEFITS_SUCCESS";
export const GET_BENEFITS_FAIL = "GET_BENEFITS_FAIL";

export const ADD_NEW_BENEFIT = "ADD_NEW_BENEFIT";
export const ADD_NEW_BENEFIT_SUCCESS = "ADD_NEW_BENEFIT_SUCCESS";
export const ADD_NEW_BENEFIT_FAIL = "ADD_NEW_BENEFIT_FAIL";

export const UPDATE_BENEFIT = "UPDATE_BENEFIT";
export const UPDATE_BENEFIT_SUCCESS = "UPDATE_BENEFIT_SUCCESS";
export const UPDATE_BENEFIT_FAIL = "UPDATE_BENEFIT_FAIL";

export const GET_BENEFIT_DETAILS = "GET_BENEFIT_DETAILS";
export const GET_BENEFIT_DETAILS_SUCCESS = "GET_BENEFIT_DETAILS_SUCCESS";
export const GET_BENEFIT_DETAILS_FAIL = "GET_BENEFIT_DETAILS_FAIL";

export const RESET_BENEFIT_FLAG = "RESET_BENEFIT_FLAG";

export const DELETE_BENEFIT = "DELETE_BENEFIT";
export const DELETE_BENEFIT_SUCCESS = "DELETE_BENEFIT_SUCCESS";
export const DELETE_BENEFIT_FAIL = "DELETE_BENEFIT_FAIL";

export const STATUS_CHANGE_BENEFIT = "STATUS_CHANGE_BENEFIT";
export const STATUS_CHANGE_BENEFIT_SUCCESS = "STATUS_CHANGE_BENEFIT_SUCCESS";
export const STATUS_CHANGE_BENEFIT_FAIL = "STATUS_CHANGE_BENEFIT_FAIL";

export const SAVE_ORDERING_BENEFIT = "SAVE_ORDERING_BENEFIT";
export const SAVE_ORDERING_BENEFIT_SUCCESS = "SAVE_ORDERING_BENEFIT_SUCCESS";
export const SAVE_ORDERING_BENEFIT_FAIL = "SAVE_ORDERING_BENEFIT_FAIL";
export const SAVE_ORDERING_BENEFIT_RESET_FLAG = "SAVE_ORDERING_BENEFIT_RESET_FLAG";


export const MODIFY_BENEFIT_FLAG = "MODIFY_BENEFIT_FLAG";