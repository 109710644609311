import {
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_FAIL,
  ADD_NEW_PRODUCT_CATEGORY,
  ADD_NEW_PRODUCT_CATEGORY_SUCCESS,
  ADD_NEW_PRODUCT_CATEGORY_FAIL,
  GET_PRODUCT_CATEGORY_DETAILS,
  GET_PRODUCT_CATEGORY_DETAILS_SUCCESS,
  GET_PRODUCT_CATEGORY_DETAILS_FAIL,
  UPDATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  RESET_PRODUCT_CATEGORY_FLAG,
  DELETE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAIL,
  STATUS_CHANGE_PRODUCT_CATEGORY,
  STATUS_CHANGE_PRODUCT_CATEGORY_SUCCESS,
  STATUS_CHANGE_PRODUCT_CATEGORY_FAIL,
  MODIFY_PRODUCT_CATEGORY_FLAG,
  SAVE_ORDERING_PRODUCT_CATEGORY,
  SAVE_ORDERING_PRODUCT_CATEGORY_SUCCESS,
  SAVE_ORDERING_PRODUCT_CATEGORY_FAIL,
  SAVE_ORDERING_PRODUCT_CATEGORY_RESET_FLAG,
} from "./actionTypes";

export const getProductCategories = data => ({
  type: GET_PRODUCT_CATEGORIES,
  payload: data,
});

export const getProductCategoriesSuccess = data => ({
  type: GET_PRODUCT_CATEGORIES_SUCCESS,
  payload: data,
});

export const getProductCategoriesFail = error => ({
  type: GET_PRODUCT_CATEGORIES_FAIL,
  payload: error,
});

export const addNewProductCategory = category=> ({
  type: ADD_NEW_PRODUCT_CATEGORY,
  payload: category,
});

export const addNewProductCategorySuccess = category => ({
  type: ADD_NEW_PRODUCT_CATEGORY_SUCCESS,
  payload: category,
});

export const addNewProductCategoryFail = error => ({
  type: ADD_NEW_PRODUCT_CATEGORY_FAIL,
  payload: error,
});

export const updateProductCategory = category => ({
  type: UPDATE_PRODUCT_CATEGORY,
  payload: category,
});

export const updateProductCategorySuccess = category => ({
  type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
  payload: category,
});

export const updateProductCategoryFail = error => ({
  type: UPDATE_PRODUCT_CATEGORY_FAIL,
  payload: error,
});

export const getProductCategoryDetails = category => ({
  type: GET_PRODUCT_CATEGORY_DETAILS,
  payload: category,
});

export const getProductCategoryDetailsSuccess = category => ({
  type: GET_PRODUCT_CATEGORY_DETAILS_SUCCESS,
  payload: category,
});

export const getProductCategoryDetailsFail = error => ({
  type: GET_PRODUCT_CATEGORY_DETAILS_FAIL,
  payload: error,
});

export const resetProductCategoryFlag = () => {
  return {
    type: RESET_PRODUCT_CATEGORY_FLAG,
  }
}

//Delete
export const deleteProductCategory = category => ({
  type: DELETE_PRODUCT_CATEGORY,
  payload: category,
});

export const deleteProductcategorySuccess = category => ({
  type: DELETE_PRODUCT_CATEGORY_SUCCESS,
  payload: category,
});

export const deleteProductcategoryFail = error => ({
  type: DELETE_PRODUCT_CATEGORY_FAIL,
  payload: error,
});

//Status Change
export const statusChangeProductcategory = category => ({
  type: STATUS_CHANGE_PRODUCT_CATEGORY,
  payload: category,
});

export const statusChangeProductcategorySuccess = category => ({
  type: STATUS_CHANGE_PRODUCT_CATEGORY_SUCCESS,
  payload: category,
});

export const statusChangeProductcategoryFail = error => ({
  type: STATUS_CHANGE_PRODUCT_CATEGORY_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyProductCategoryFlag = () => {
  return {
    type: MODIFY_PRODUCT_CATEGORY_FLAG,
  }
}


  //Save Ordaring
  export const saveOrderingProductCategory = data => ({
    type: SAVE_ORDERING_PRODUCT_CATEGORY,
    payload: data,
  });
  
  export const saveOrderingProductCategorySuccess = data => ({
    type: SAVE_ORDERING_PRODUCT_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const saveOrderingProductCategoryFail = error => ({
    type: SAVE_ORDERING_PRODUCT_CATEGORY_FAIL,
    payload: error,
  });
  
  
  //Save ordering reset Flag for toaster
  export const saveOrderingProductCategoryResetFlag = () => {
    return {
      type: SAVE_ORDERING_PRODUCT_CATEGORY_RESET_FLAG,
    }
  }