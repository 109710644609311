import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  ADD_NEW_ORDER,
  ADD_NEW_ORDER_SUCCESS,
  ADD_NEW_ORDER_FAIL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  ORDER_PAYMENT_STATUS_CHANGE,
  ORDER_PAYMENT_STATUS_CHANGE_SUCCESS,
  ORDER_PAYMENT_STATUS_CHANGE_FAIL,
  RESET_ORDER_FLAG,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  STATUS_CHANGE_ORDER,
  STATUS_CHANGE_ORDER_SUCCESS,
  STATUS_CHANGE_ORDER_FAIL,
  MODIFY_ORDER_FLAG
} from "./actionTypes";

export const getOrders = data => ({
  type: GET_ORDERS,
  payload: data,
});

export const getOrdersSuccess = data => ({
  type: GET_ORDERS_SUCCESS,
  payload: data,
});

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
});

export const addNewOrder = order=> ({
  type: ADD_NEW_ORDER,
  payload: order,
});

export const addNewOrderSuccess = order => ({
  type: ADD_NEW_ORDER_SUCCESS,
  payload: order,
});

export const addNewOrderFail = error => ({
  type: ADD_NEW_ORDER_FAIL,
  payload: error,
});

//Status Change
export const statusChangeOrderPayment = order => ({
  type: ORDER_PAYMENT_STATUS_CHANGE,
  payload: order,
});

export const statusChangeOrderPaymentSuccess = order => ({
  type: ORDER_PAYMENT_STATUS_CHANGE_SUCCESS,
  payload: order,
});

export const statusChangeOrderPaymentFail = error => ({
  type: ORDER_PAYMENT_STATUS_CHANGE_FAIL,
  payload: error,
});

export const getOrderDetails = order => ({
  type: GET_ORDER_DETAILS,
  payload: order,
});

export const getOrderDetailsSuccess = order => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload: order,
});

export const getOrderDetailsFail = error => ({
  type: GET_ORDER_DETAILS_FAIL,
  payload: error,
});

export const resetOrderFlag = () => {
  return {
    type: RESET_ORDER_FLAG,
  }
}

//Delete
export const deleteOrder = order => ({
  type: DELETE_ORDER,
  payload: order,
});

export const deleteOrderSuccess = order => ({
  type: DELETE_ORDER_SUCCESS,
  payload: order,
});

export const deleteOrderFail = error => ({
  type: DELETE_ORDER_FAIL,
  payload: error,
});

//Status Change
export const statusChangeOrder = order => ({
  type: STATUS_CHANGE_ORDER,
  payload: order,
});

export const statusChangeOrderSuccess = order => ({
  type: STATUS_CHANGE_ORDER_SUCCESS,
  payload: order,
});

export const statusChangeOrderFail = error => ({
  type: STATUS_CHANGE_ORDER_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyOrderFlag = () => {
  return {
    type: MODIFY_ORDER_FLAG,
  }
}