import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  FormFeedback,
  CardBody,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { editCategory, updateCategory } from "../../store/actions";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { convertToBase64 } from "../../helpers/image";
import "react-toastify/dist/ReactToastify.css";


function Edit() {
  document.title = "Edit Category | Dabbawala";
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedCatType, setselectedCatType] = useState("");
  const [catType, setCatType] = useState("");
  const [image, setImage] = useState();
  const { id } = useParams();
  const categoryType = [
    {
      options: [
        { label: "Videos", value: "videos" },
        { label: "Blogs", value: "blogs" },
        { label: "FAQS", value: "faqs" },
      ],
    },
  ];

  useEffect(() => {
    dispatch(editCategory({ id }));
  }, [dispatch,id]);

  const { category, isSuccess, error } = useSelector((state) => ({
    category: state.Categories.category,
    isSuccess: state.Categories.isSuccess,
    error: state.Categories.error,
  }));
  useEffect(() => {
    if (category && category._id) {
      setCatType({
        label: category.category_type.replace(/\b\w/g, (x) => x.toUpperCase()),
        value: category.category_type,
      });
    }
  }, [category]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: category?.name || "",
      category_type: selectedCatType || "",
      description: category.description,
      image: category.image || ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Category Name"),
    }),
    onSubmit: (values) => {
      const data = {
        id: id,
        name: values.name,
        category_type: values.category_type?.value,
        status: 1,
        description: values.description,
        image: image
      };
      const res = dispatch(updateCategory(data));
      toast("Category Successfully updated");
    },
  });

  
  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <>
      {" "}
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Edit Category"
            pageTitle="Categories"
            pageUrl={`/${category?.category_type?.slice(0,-1)}-categories`}
          />
          <Row>
            <Col lg={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <PreviewCardHeader title="Edit Category" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Category Name
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="name"
                              className="form-control"
                              value={validation.values.name || ""}
                              placeholder="Category name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category Type
                            </Label>
                            <Select
                              value={catType || ""}
                              onChange={(selectedCatType) => {
                                let event = {
                                  target: {
                                    name: "category_type",
                                    value: selectedCatType,
                                  },
                                };
                                //console.log(event.target);
                                setCatType(event.target.value);
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={categoryType}
                              name="type"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category_type &&
                                validation.errors.category_type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category_type &&
                            validation.errors.category_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Category Description
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              name="description"
                              className="form-control"
                              value={validation.values.description || ""}
                              placeholder="Category description"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.description &&
                                validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Image
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImg1Change}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link
                    to={`/${category?.category_type?.slice(0,-1)}-categories`}
                    className="btn btn-danger w-sm me-1"
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-success w-sm">
                    Update
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>{" "}
      <ToastContainer />
    </>
  );
}

export default Edit;
