import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getPages,
  resetPageFlag,
  getProductCategories,
} from "../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import { convertToBase64 } from "../../helpers/image";
import { ToastContainer, toast } from "react-toastify";
import { hostUrl } from "../../config/host";
import axios from "axios";

const PageList = () => {
  document.title = "Addon Group list | Dabbawala";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [image, setImage] = useState(null);
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("created_at");
  const [sortByValue, setSortByValue] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();


  const [ addonGrouplist, setAddonGroupList ] = useState();




  const [categoryData, setCategoryData] = useState();
  const [categoryOpt, setCategoryOpt] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [productData, setProductData] = useState();
  const [productOpt, setProductOpt] = useState([]);
  const [productArr, setProductArr] = useState([]);


  const { pages, totalDataCount, isSuccess, error, productcategories } =
    useSelector((state) => ({
      pages: state.Pages.pages,
      totalDataCount: state.Pages.totalDataCount,
      isSuccess: state.Pages.isSuccess,
      error: state.Pages.error,
      productcategories: state.Productcategories.productcategories,
    }));

  const [modal, setModal] = useState(false);
  const [modalAddon, setModalAddon] = useState(false);

  useEffect(() => {
    setGroupName('');
    setMInQty(1);
    setMaxQty(1);
    setCategoryArr([]);
    setProductArr([]);
    axios
      .post(
        `${hostUrl}/addon-group/list`,
        {
          keyword: "",
          sortByField: "created_at",
          sortByValue:-1,
          limit: 9999,
          offset: 0
        },
        {}
      )
      .then(function (response) {
        setAddonGroupList(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [modal])
  

  useEffect(() => {
    axios
      .post(
        `${hostUrl}/v2/products/list`,
        {
          keyword: "",
          product_type: 1,
          product_category_id: "",
          status: 1,
          spice_level_id: [],
          allergie_id: [],
          diet_id: [],
          featured: "",
          limit: 9999,
        },
        {}
      )
      .then(function (response) {
        setProductData(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    productData?.forEach((item) => {
      if (productData?.length > productOpt?.length) {
        productOpt.push({ label: item.title, value: item._id });
      }
    });
  }, [productData]);

  useEffect(() => {
    dispatch(getProductCategories());
  }, [dispatch]);

  useEffect(() => {
    setCategoryData(productcategories);
  }, [productcategories]);

  useEffect(() => {
    categoryData?.forEach((item) => {
      if (categoryData.length > categoryOpt.length) {
        categoryOpt.push({ label: item.title, value: item._id });
      }
    });
  }, [categoryData]);

  //console.log(categoryOpt);
  useEffect(() => {
    dispatch(
      getPages({ keyword, sortByField, sortByValue, limit, offset, date })
    );
  }, [dispatch, keyword, sortByField, sortByValue, limit, offset, date]);

  // useEffect(() => {
  //   let dateRange = "";
  //   if (date && date.e && date.e[0] && date.e[1]) {
  //     dateRange = {
  //       start: date.e[0],
  //       end: date.e[1],
  //     };
  //     dispatch(getPages({ limit, dateRange, offset, keyword }));
  //   }
  // }, [dispatch, limit, offset, date, keyword]);
  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    setKeyword("");

    axios
      .post(
        `${hostUrl}/addon-group/list`,
        {
          keyword: keyword,
          sortByField: "created_at",
          sortByValue:-1,
          limit: 9999,
          offset: 0
        },
        {}
      )
      .then(function (response) {
        setAddonGroupList(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    
    //fp.current.flatpickr.clear();
    //setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === "ASC") {
        setSortByValue("DESC");
      } else {
        setSortByValue("ASC");
      }
    } else {
      setSortByValue("DESC");
    }
    setSortByField(fieldName);
  };

  const [plu_code, setplu_code] = useState();

  // useEffect(() => {
  //   setplu_code(Date.now());
  // }, [])

  

  function handleCreate() {
    setModal(!modal);
  }

  function handleCreateAddon() {
    setModalAddon(!modalAddon);
    setplu_code(Date.now());
  }

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const [isCreated, setIsCreated] = useState(false);
  const [addonTitle, setAddonTitle] = useState("");
  const [addonPrice, setAddonPrice] = useState("");
  const [addonDesc, setAddonDesc] = useState("");
  const [newAddonList, setNewAddonList] = useState([]);



  const [tempCatArr, setTempCatArr] = useState([]); // send when edit
  const [tempCatOpt, setTempCatOpt] = useState([]); // used in edit

  const [tempProductArr, setTempProductArr] = useState([]); // send when edit
  const [tempProductOpt, setTempProductOpt] = useState([]); // used in edit

  //console.log(addonTitle, addonPrice, addonDesc);

  function handleAddonCreate(e) {
    e.preventDefault();

    
    axios
      .post(
        `${hostUrl}/v2/products/create`,
        {
          addon_group_id: currId,
          image: image,
          in_stock: true,
          product_type: 2,
          regular_price: addonPrice,
          short_description: addonDesc,
          plu_code: plu_code,
          status: 1,
          title: addonTitle,
        },
        {}
      )
      .then(function (response) {
        //setAllergyData(response.data);
        if (response.status === 400) {
          toast.error(response.message, { theme: "colored" });
        }
        if (response.status === 200) {
          toast.success(response.message, { theme: "colored" });
          console.log(response.data);
          setModalAddon(false);
          newAddonList.push(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //console.log(productData);

  function handleAddonDelete(id, idx) {
    //console.log(id, idx);
    axios
      .post(
        `${hostUrl}/v2/products/remove`,
        {
          id: id
        },
        {}
      )
      .then(function (response) {
        //setAllergyData(response.data);
        if (response.status === 400) {
          toast.error(response.message, { theme: "colored" });
        }
        if (response.status === 200) {
          toast.success(response.message, { theme: "colored" });
          //console.log(response.data);
          if (newAddonList.length == 1) {
            setNewAddonList([]);
          } else {
            setNewAddonList(newAddonList.filter((val) => val !== newAddonList[idx]));
          }
          // setModalAddon(false);
          // newAddonList.push(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    
  }

  


  function handleChangeProduct(e) {
    // let a = e.target.checked;
    // if (a) {
    //   setSpiceLevelArray([...spiceLevelArray, e.target.value]);
    // } else {
    //   // remove from list
    //   setSpiceLevelArray(
    //     spiceLevelArray.filter((data) => data !== e.target.value)
    //   );
    // }
    setTempProductArr(e);
    let a = []
    e.forEach((item) => {
      a.push(item.value)
    })
    setProductArr(a);
  }

  function handleChangeCategory(e) {
    // let a = e.target.checked;
    // if (a) {
    //   setSpiceLevelArray([...spiceLevelArray, e.target.value]);
    // } else {
    //   // remove from list
    //   setSpiceLevelArray(
    //     spiceLevelArray.filter((data) => data !== e.target.value)
    //   );
    // }
    setTempCatArr(e);
    let a = []
    e.forEach((item) => {
      a.push(item.value)
    })
    setCategoryArr(a);
  }

  //console.log(productArr, categoryArr);

  const [groupName, setGroupName] = useState('');
  const [ minQty, setMInQty ] = useState(1);
  const [ maxQty, setMaxQty ] = useState(1);

  const [ currId, setCurrId ] = useState(); 

  function handleCreateGroup(e) {
    e.preventDefault();
    

    if (isCreated) {
      axios
      .post(
        `${hostUrl}/addon-group/edit`,
        {
          id: currId,
          title: groupName,
          min_buy_qty: minQty,
          max_buy_qty: maxQty,
          product_category_id: [...new Set(categoryArr)],
          product_id: [...new Set(productArr)]
        },
        {  }
      )
      .then(function (response) {
        //setAllergyData(response.data);
        if (response.status === 400) {
          toast.error(response.message, { theme: "colored" });
        }
        if (response.status === 200) {
          toast.success(response.message, { theme: "colored" });
          setModal(!modal);
          setProductArr([]);
          setCategoryArr([]);
          //console.log(response.data);
          //setCurrId(response.data._id)
          //setIsCreated(true);
        }
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {

      axios
      .post(
        `${hostUrl}/addon-group/create`,
        {
          title: groupName,
          description: null,
          min_buy_qty: minQty,
          max_buy_qty: maxQty,
          product_category_id: [...new Set(categoryArr)],
          product_id: [...new Set(productArr)]
        },
        {  }
      )
      .then(function (response) {
        //setAllergyData(response.data);
        if (response.status === 400) {
          toast.error(response.message, { theme: "colored" });
        }
        if (response.status === 200) {
          toast.success(response.message, { theme: "colored" });
          console.log(response.data);
          setCurrId(response.data._id);
          setIsCreated(true);
        }
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }

  const [deletedId, setDeletedId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  function openDeleteConfrontation(id) {

    setDeletedId(id);
    setDeleteModal(!deleteModal);
    
  }

  async function deleteAddonGroup(id) {
    axios
      .post(
        `${hostUrl}/addon-group/remove`,
        {
          id: id
        },
        {}
      )
      .then(function (response) {
        //setAllergyData(response.data);
        if (response.status === 400) {
          toast.error(response.message, { theme: "colored" });
          //setDeleteModal(false);
        }
        if (response.status === 200) {
          toast.success(response.message, { theme: "colored" });
          //setDeleteModal(false);
          //console.log(response.data);
          // if (newAddonList.length == 1) {
          //   setNewAddonList([]);
          // } else {
          //   setNewAddonList(newAddonList.filter((val) => val !== newAddonList[idx]));
          // }
          // setModalAddon(false);
          // newAddonList.push(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        //setDeleteModal(false);
      });
  }

const [ editId, setEditId ] = useState(null);
const [ details, setDetails ] = useState(null);


useEffect(() => {

  let a = [];
  details?.category_list?.map((item) => {
    if(details?.category_list?.length > a.length){
      categoryArr?.push(item._id);
      a.push({label: item.title, value: item._id})
    }
  })
  setTempCatArr([...new Set(a)]);
},[details]);

useEffect(() => {

  let a = [];
  details?.product_list?.map((item) => {
    if(details?.product_list?.length > a.length){
      productArr?.push(item._id);
      a.push({label: item.title, value: item._id})
    }
  })
  setTempProductArr([...new Set(a)]);
},[details]);

useEffect(() => {
  setTempCatOpt(categoryOpt.filter(({ value: id1 }) => !tempCatArr.some(({ value: id2 }) => id2 === id1)));
}, [tempCatArr]);

useEffect(() => {
  setTempProductOpt(productOpt.filter(({ value: id1 }) => !tempProductArr.some(({ value: id2 }) => id2 === id1)));
}, [tempProductArr]);

  useEffect(() => {
    //addon-group/details

    axios
      .post(
        `${hostUrl}/addon-group/details`,
        {
          id: editId
        },
        {}
      )
      .then(function (response) {
        //console.log(response);

        setDetails(response.data);
        
        // if (response.status === 400) {
        //   toast.error(response.message, { theme: "colored" });
        //   //setDeleteModal(false);
        // }
        // if (response.status === 200) {
        //   toast.success(response.message, { theme: "colored" });
        //   //setDeleteModal(false);
        //   //console.log(response.data);
        //   // if (newAddonList.length == 1) {
        //   //   setNewAddonList([]);
        //   // } else {
        //   //   setNewAddonList(newAddonList.filter((val) => val !== newAddonList[idx]));
        //   // }
        //   // setModalAddon(false);
        //   // newAddonList.push(response.data);
        // }
      })
      .catch(function (error) {
        console.log(error);
        //setDeleteModal(false);
      });
  }, [editModal]);

  useEffect(() => {
    setCurrId(details?._id)
    setGroupName(details?.title);
    setMInQty(details?.min_buy_qty);
    setMaxQty(details?.max_buy_qty);
    setNewAddonList(details?.addon_list);
    //setGroupName(details?.title);
  }, [details])


  function handleEditGroup(e) {
    e.preventDefault();

    axios
      .post(
        `${hostUrl}/addon-group/edit`,
        {
          id: currId,
          title: groupName,
          min_buy_qty: minQty,
          max_buy_qty: maxQty,
          product_category_id: [...new Set(categoryArr)],
          product_id: [...new Set(productArr)]
        },
        {  }
      )
      .then(function (response) {
        //setAllergyData(response.data);
        if (response.status === 400) {
          toast.error(response.message, { theme: "colored" });
        }
        if (response.status === 200) {
          toast.success(response.message, { theme: "colored" });
          setEditModal(!editModal);
          setGroupName('');
          setMInQty(1);
          setMaxQty(1);
          setProductArr([]);
          setCategoryArr([]);
          setTempCatArr([]);
          setTempProductArr([]);
          //console.log(response.data);
          //setCurrId(response.data._id)
          //setIsCreated(true);
        }
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  

  //console.log(details);
  return (
    <>
      <ToastContainer theme="colored" />
      <Modal
                    isOpen={deleteModal}
                    //toggle={openDeleteConfrontation}
                    trigger="click"
                  >
                    <ModalHeader>
                      Delete
                    </ModalHeader>
                    <ModalBody>
                      Are you sure want to delete?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button
                          onClick={() => {
                            setDeleteModal(false);
                          }
                          }
                          className="btn btn-sm btn-light">
                          No
                        </Button>

                        <Button
                          onClick={() => {
                            deleteAddonGroup(deletedId);
                            setDeleteModal(!deleteModal);
                            resetFilter();
                          }
                          }
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Addon-group" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Addon Group List
                  </h5>
                  <div className="flex-shrink-0 ms-auto">
                    <div
                      className="btn btn-success add-btn"
                      id="createPageTooltip"
                      onClick={() => {handleCreate(); setIsCreated(false); setNewAddonList([]);}}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Addon Group
                    </div>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createPageTooltip"
                    >
                      Create Addon Group
                    </UncontrolledTooltip>
                    {/* <Button color="danger" id="deleteTooltip">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                      Delete
                    </UncontrolledTooltip> */}
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      {/* <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(parseInt(e.target.value))}
                        >
                          <option disabled>Select Addon Group</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col> */}
                      {/* <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col> */}

                      {/* <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col> */}

                      <Col sm={4} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {addonGrouplist?.length > 0 ? (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th> */}

                                <th
                                  // className="sort cursor-pointer"
                                  // data-sort="title"
                                  // onClick={() => sortBy("title")}
                                >
                                  Title
                                </th>
                                <th
                                  // className="sort cursor-pointer"
                                  // data-sort="subtitle"
                                  // onClick={() => sortBy("subtitle")}
                                >
                                  Min qty.
                                </th>
                                <th
                                  // className="sort cursor-pointer"
                                  // data-sort="slug"
                                  // onClick={() => sortBy("slug")}
                                >
                                  Max qty.
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {addonGrouplist.map((item) => (
                                <tr key={item._id}>
                                  {/* <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value={item._id}
                                      />
                                    </div>
                                  </th> */}

                                  <td>{item.title}</td>
                                  <td>{item.min_buy_qty}</td>
                                  <td>{item.max_buy_qty}</td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>

                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <UncontrolledDropdown>
                                        <DropdownToggle className="dropdown-menu-icon">
                                          <i className="ri-more-2-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                          {/* <DropdownItem>
                                            <Link
                                              to={`/seo/page/${item._id}`}
                                              className="color-btn-black d-flex align-items-center edit-item-btn"
                                            >
                                              <i className="ri-bookmark-line fs-16 mr-2"></i>{" "}
                                              Seo
                                            </Link>
                                          </DropdownItem> */}

                                          <DropdownItem>
                                            <div
                                              onClick={() => {setEditModal(!editModal); setEditId(item?._id);}}
                                              className="color-btn-black d-flex align-items-center edit-item-btn"
                                            >
                                              <i className="ri-pencil-fill fs-16 mr-2"></i>{" "}
                                              Edit
                                            </div>
                                          </DropdownItem>
                                          <DropdownItem>
                                          <div className="p-0 bg-transparent border-0 d-flex align-items-center text-danger"
                                            onClick={()=>openDeleteConfrontation(item?._id)}
                                          >
                                              <i className="ri-delete-bin-5-fill fs-16 mr-2"></i> Delete
                                          </div>
                                        </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                      {/* <li
                                        className="list-inline-item edit"
                                        title="Banner"
                                      >
                                        <Link
                                          to="banners"
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-flag-2-line"></i>
                                        </Link>
                                      </li> */}
                                      {/* <li
                                        className="list-inline-item edit"
                                        title="Seo"
                                      >
                                        <Link
                                          to={`/seo/page/${item._id}`}
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-bookmark-line"></i>
                                        </Link>
                                      </li>
                                      <li
                                        className="list-inline-item edit"
                                        title="Edit"
                                      >
                                        <Link
                                          to={`/page/edit/${item.slug}`}
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-pencil-fill fs-16"></i>
                                        </Link>
                                      </li>
                                      <li
                                        className="list-inline-item"
                                        title="Remove"
                                        id={"deletePopover" + item._id}
                                      >
                                         <Button className="p-0 bg-transparent border-0 text-danger">
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </Button> 
                                      </li> */}
                                      {/* <UncontrolledPopover
                                        placement="left"
                                        target={"deletePopover" + item._id}
                                      >
                                        <PopoverHeader>
                                          Delete Addon Group
                                        </PopoverHeader>
                                        <PopoverBody>
                                          Are you sure want to delete?
                                          <div className="d-flex align-items-center justify-content-end mt-2">
                                            {/* <Button className='btn btn-sm btn-light' >No</Button>
                                             <Button onClick={() => deletePage()} className='btn btn-sm btn-danger ms-2' >Yes</Button> 
                                          </div>
                                        </PopoverBody>
                                      </UncontrolledPopover> */}
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {/* {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )} */}
                        </>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        //toggle={openDeleteConfrontation}
        //trigger="click"
        centered={true}
        size="lg"
      >
        <form onSubmit={(e) => handleCreateGroup(e)}>
        <ModalHeader>{isCreated ? "Edit" : "Create"} Group</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Name *</Label>
                    <Input type="text" value={'' || groupName} onChange={(e) => setGroupName(e.target.value)}/>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Category</Label>
                    <Select
                      // closeMenuOnSelect={false}
                      // value={tempCatArr}
                      isMulti
                      options={categoryOpt}
                      onChange={(e) => handleChangeCategory(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <div className="mb-3">
                    <Label className="form-label">Min</Label>
                    <Input type="number" min={0} value={minQty} onChange={(e) => setMInQty(e.target.value)} />
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Label className="form-label">Max</Label>
                    <Input type="number" value={maxQty} onChange={(e) => setMaxQty(e.target.value)} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Product</Label>
                    <Select
                      // closeMenuOnSelect={false}
                      // value={tempCatArr}
                      isMulti
                      options={productOpt}
                      onChange={(e) => handleChangeProduct(e)}
                    />
                  </div>
                </Col>
              </Row>
              <span
                className="link-success"
                style={{ cursor: "pointer" }}
                onClick={() => handleCreateAddon()}
              >
                {isCreated && <u className="mb-3">+New Modification</u>}
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "150px",
                  gap: "5px",
                }}
              >
                {newAddonList?.length > 0 &&
                  newAddonList.map((item, i) => {
                    return (
                      <a
                        className="btn btn-light btn-sm"
                        key={item._id}
                        style={{ height: "30px" }}
                      >
                        {item?.title}
                        <span
                          type="button"
                          className="btn-close btn-light"
                          style={{ position: "absolute", right: 0 }}
                          onClick={() => handleAddonDelete(item._id, i)}
                        ></span>
                      </a>
                    );
                  })}
              </div>
            </CardBody>
          </Card>
          <div className="d-flex align-items-center justify-content-end mt-2">
            <Button
              onClick={() => {
                setModal(!modal);
                setNewAddonList([]);
              }}
              className="btn btn-sm btn-light"
            >
              
              {isCreated ? "Close" : "Cancel"}
            </Button>

            <Button
              //   onClick={ () =>
              //     {
              //       onClickDeleteData(deletedId);
              //       setModal(!modal);
              //     }
              //   }
              type="submit"
              className="btn btn-sm btn-success ms-2"
            >
              {isCreated ? "Save" : "Create"}
            </Button>
          </div>
        </ModalBody>
        </form>

        
      </Modal>
      
      <Modal
          isOpen={modalAddon}
          //toggle={openDeleteConfrontation}
          trigger="click"
          centered={true}
        >
          <form onSubmit={(e) => handleAddonCreate(e)}>
            <ModalHeader>Create Addon</ModalHeader>
            <ModalBody>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label">Addon Title</Label>
                        <Input
                          type="text"
                          value={addonTitle}
                          onChange={(e) => setAddonTitle(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label">Price</Label>
                        <Input
                          type="number"
                          min={0}
                          step={.01}
                          value={addonPrice}
                          onChange={(e) => setAddonPrice(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label">PLU Code</Label>
                        <Input type="text" value={plu_code} />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label">Addon image</Label>
                        <Input
                          type="file"
                          id="placeholderInput"
                          className="form-control"
                          placeholder="Place your image"
                          name="image"
                          onChange={onImg1Change}
                        />
                        <img alt="" id="image1" width="100px"></img>
                      </div>
                    </Col>
                  </Row>
                  <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <Input
                      type="textarea"
                      value={addonDesc}
                      onChange={(e) => setAddonDesc(e.target.value)}
                    />
                  </div>
                </CardBody>
              </Card>
              <div className="d-flex align-items-center justify-content-end mt-2">
                <Button
                  onClick={() => {
                    setModalAddon(!modalAddon);
                  }}
                  className="btn btn-sm btn-danger ms-2"
                >
                  Cancel
                </Button>

                <Button
                  //   onClick={ () =>
                  //     {
                  //       onClickDeleteData(deletedId);
                  //       setModal(!modal);
                  //     }
                  //   }
                  type="submit"
                  className="btn btn-sm btn-success ms-2"
                >
                  Create
                </Button>
              </div>
            </ModalBody>
          </form>
      </Modal>

      <Modal
        isOpen={editModal}
        //toggle={openDeleteConfrontation}
        //trigger="click"
        centered={true}
        size="lg"
      >
        <form onSubmit={(e) => handleEditGroup(e)}>
        <ModalHeader>Edit Group</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Name *</Label>
                    <Input type="text" value={groupName} onChange={(e) => setGroupName(e.target.value)}/>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Category</Label>
                    <Select
                      // closeMenuOnSelect={false}
                      value={tempCatArr}
                      isMulti
                      options={tempCatOpt}
                      onChange={(e) => handleChangeCategory(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <div className="mb-3">
                    <Label className="form-label">Min</Label>
                    <Input type="number" min={0} value={minQty} onChange={(e) => setMInQty(e.target.value)} />
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Label className="form-label">Max</Label>
                    <Input type="number" value={maxQty} onChange={(e) => setMaxQty(e.target.value)} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Product</Label>
                    <Select
                      // closeMenuOnSelect={false}
                      value={tempProductArr}
                      isMulti
                      options={tempProductOpt}
                      onChange={(e) => handleChangeProduct(e)}
                    />
                  </div>
                </Col>
              </Row>
              <span
                className="link-success"
                style={{ cursor: "pointer" }}
                onClick={() => handleCreateAddon()}
              >
                <u className="mb-3">+New Modification</u>
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "150px",
                  gap: "5px",
                }}
              >
                {newAddonList?.length > 0 &&
                  newAddonList?.map((item, i) => {
                    return (
                      <a
                        className="btn btn-light btn-sm"
                        key={item._id}
                        style={{ height: "30px" }}
                      >
                        {item?.title}
                        <span
                          type="button"
                          className="btn-close btn-light"
                          style={{ position: "absolute", right: 0 }}
                          onClick={() => handleAddonDelete(item._id, i)}
                        ></span>
                      </a>
                    );
                  })}
              </div>
            </CardBody>
          </Card>
          <div className="d-flex align-items-center justify-content-end mt-2">
            <Button
              onClick={() => {
                setEditModal(!editModal);
                setNewAddonList([]);
                setGroupName('');
                setMInQty(1);
                setMaxQty(1);
                setTempCatArr([]);
                setTempProductArr([]);
              }}
              className="btn btn-sm btn-light"
            >
              
              Close
            </Button>

            <Button
              //   onClick={ () =>
              //     {
              //       onClickDeleteData(deletedId);
              //       setModal(!modal);
              //     }
              //   }
              type="submit"
              className="btn btn-sm btn-success ms-2"
            >
              Save
            </Button>
          </div>
        </ModalBody>
        </form>

        
      </Modal>
                  
    </>
  );
};

export default PageList;
