import {
  GET_COMBO_SUCCESS,
  GET_COMBO_FAIL,
  ADD_NEW_COMBO_SUCCESS,
  ADD_NEW_COMBO_FAIL,
  GET_COMBO_DETAILS_SUCCESS,
  GET_COMBO_DETAILS_FAIL,
  UPDATE_COMBO_SUCCESS,
  UPDATE_COMBO_FAIL,
  DELETE_COMBO_SUCCESS,
  DELETE_COMBO_FAIL,
  MODIFY_COMBO_FLAG,
  RESET_COMBO_FLAG,
  STATUS_CHANGE_COMBO_SUCCESS,
  STATUS_CHANGE_COMBO_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  combo: [],
};

const Combos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMBO_SUCCESS:
      return {
        ...state,
        combo: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_COMBO_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case ADD_NEW_COMBO_SUCCESS:
      return {
        ...state,
        combos: [...state.combo, action.payload.data],
        success: action.payload.message,
        isComboAdd: true,
        isComboAddFail: false,
      };

    case ADD_NEW_COMBO_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isComboAdd: false,
        isComboAddFail: true,
      };

    case UPDATE_COMBO_SUCCESS:
      return {
        ...state,
        combos: state.combo.map((combo) =>
          combo._id.toString() === action.payload.data._id.toString()
            ? { ...combo, ...action.payload.data }
            : combo
        ),
        success: action.payload.message,
        isComboUpdate: true,
        isComboUpdateFail: false,
      };

    case UPDATE_COMBO_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isComboUpdate: false,
        isComboUpdateFail: true,
      };

    case GET_COMBO_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_COMBO_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case DELETE_COMBO_SUCCESS:
      return {
        ...state,
        combos: state.combos.filter((getData) => {
          return getData._id !== action.payload.data._id;
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isComboRemove: true,
        isComboRemoveFail: false,
      };

    case DELETE_COMBO_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isComboRemove: false,
        isComboRemoveFail: true,
      };
    //Status Change
    case STATUS_CHANGE_COMBO_SUCCESS:
      return {
        ...state,
        combos: state.combos.map((combo) =>
          combo._id.toString() === action.payload.data._id.toString()
            ? { ...combo, ...action.payload.data }
            : combo
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_COMBO_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };
    case RESET_COMBO_FLAG:
      return {
        ...state,
        isComboAdd: false,
        isComboAddFail: false,
        isSuccess: false,
        isComboDetails: false,
        isComboUpdate: false,
        isComboUpdateFail: false,
        error: false,
      };
    case MODIFY_COMBO_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };
    default:
      return state;
  }
};

export default Combos;
