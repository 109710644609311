import { highlight } from "prismjs";
import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = data => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = data => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = data => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);
export const getProfileData = data => api.get(url.GET_PROFILE_DATA, data);
export const postFakeProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = data => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = data => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);

// Calendar


//Category
export const getCategoryList = data => api.create(url.GET_CATEGORIES_LIST,data);
export const addCategory = data => api.create(url.ADD_CATEGORY,data);
export const getCategory = data => api.create(url.GET_CATEGORY,data);
export const updCategory = data => api.create(url.UPDATE_CATEGORY,data);
export const delCategory = data => api.create(url.DELETE_CATEGORY,data);
// status change  Category
export const statusChangeCat = cat => api.create(url.STATUS_CHANGE_CAT ,cat);

//Blog
export const getBlogList = data => api.create(url.GET_BLOGS_LIST,data);
export const addNewBlog = data => api.create(url.ADD_NEW_BLOG,data);
export const updateBlog = data => api.create(url.UPDATE_BLOG,data);
export const getBlogDetails = data => api.create(url.GET_BLOG_DETAILS, data);
export const delBlog = data => api.create(url.DELETE_BLOG,data);
export const getBlogCommentList = data => api.create(url.GET_BLOGCOMMENTS_LIST,data);
export const delBlogComment = data => api.create(url.DELETE_BLOGCOMMENT,data);
export const statusChangeBlogComment = data => api.create(url.STATUS_CHANGE_BLOGCOMMENT,data);
// status change blog
export const statusChangeBlog = blog => api.create(url.STATUS_CHANGE_BLOG ,blog);

// Video list
export const getVideoList = data => api.create(url.GET_VIDEO_LIST,data);
export const storeVideo = data => api.create(url.STORE_VIDEO,data);
export const updateVideo = data => api.create(url.UPDATE_VIDEO,data);
export const getVideoData = data => api.create(url.VIDEO_DETAIL,data);
export const delVideo = data => api.create(url.DELETE_VIDEO,data);
// status change  Video
export const statusChangeVideo = video => api.create(url.STATUS_CHANGE_VIDEO ,video);
// Job list
export const getJobList = job => api.create(url.GET_JOB_LIST, job);

// Job Create
export const addNewJob = job => api.create(url.ADD_NEW_JOB, job);

// Job Update
export const updateJob = job => api.create(url.UPDATE_JOB, job);

// Job Details
export const getJobDetails = job => api.create(url.GET_JOB_DETAILS, job);


// Job Type Create
export const addNewJobType = job => api.create(url.ADD_NEW_JOB_TYPE, job);

// Job Types List

export const getJobTypeList = job => api.create(url.GET_JOB_TYPE_LIST, job);

// Job Type Update
export const updateJobType = job => api.create(url.UPDATE_JOB_TYPE, job);

// Job Type Status Change
export const statusChangeJobType = jobtype => api.create(url.STATUS_CHANGE_JOB_TYPE ,jobtype);


// Job Type Details
export const getJobTypeDetails = job => api.create(url.GET_JOB_TYPE_DETAILS, job);

// DELETE JOB TYPE

export const delJobType = data => api.create(url.DELETE_JOB_TYPE,data);

// Job Location Types

// Job Location Type Create
export const addNewJobLocationType = job => api.create(url.ADD_NEW_JOB_LOCATION_TYPE, job);

// Job Location Types List

export const getJobLocationTypeList = job => api.create(url.GET_JOB_LOCATION_TYPE_LIST, job);
export const deleteJobLocTypeList = data => api.create(url.DELETE_JOB_LOCATION_TYPE,data);

// Status change Job LOcation
export const statusChangeJobLocation = job => api.create(url.STATUS_CHANGE_JOB_LOCATION ,job);

// Job Remove
export const delJob = job => api.create(url.DELETE_JOB, job);
// Job Location Type Update
export const updateJobLocationType = job => api.create(url.UPDATE_JOB_LOCATION_TYPE, job);

// Job Location Details
export const getJobLocationDetails = job => api.create(url.GET_JOB_LOCATION_DETAILS, job);

// status change  job
export const statusChangeJob = job => api.create(url.STATUS_CHANGE_JOB ,job);

// Job-apply-list
export const getJobapplyList = jobapply => api.create(url.GET_JOB_APPLY_LIST, jobapply);

// Job-apply-Create
export const addNewJobapply = jobapply => api.create(url.ADD_NEW_JOB_APPLY, jobapply);

// Job-apply Update
export const updateJobapply = jobapply => api.create(url.UPDATE_JOBAPPLY, jobapply);

// Job-apply Remove
export const delJobapply = jobapply => api.create(url.DELETE_JOB_APPLY, jobapply);

// User Create
export const addNewUser = user => api.create(url.ADD_NEW_USER, user);

//User List
export const getUserList = data => api.create(url.GET_USER_LIST, data);

// User Update
export const updateUser = user => api.create(url.UPDATE_USER, user);

// User Details
export const getUserDetails = user => api.create(url.GET_USER_DETAILS, user);
// Delete User
export const delUser = data => api.create(url.DELETE_USER,data);

// status change of user
export const statusChangeUser = user => api.create(url.STATUS_CHANGE_USER ,user);
//Admin List
export const getAdminList = data => api.create(url.GET_ADMIN_LIST, data);


// Banner list
export const getBannerList = banner => api.create(url.GET_BANNER_LIST, banner);

// Banner Create
export const addNewBanner = banner => api.create(url.ADD_NEW_BANNER, banner);

// Banner Update
export const updateBanner = banner => api.create(url.UPDATE_BANNER, banner);

// Banner Details
export const getBannerDetails = banner => api.create(url.GET_BANNER_DETAILS, banner);

// Banner Remove
export const delBanner = banner => api.create(url.DELETE_BANNER, banner);


// Step list
export const getStepList = step => api.create(url.GET_STEP_LIST, step);

// Step Create
export const addNewStep = step => api.create(url.ADD_NEW_STEP, step);

// Step Update
export const updateStep = step => api.create(url.UPDATE_STEP, step);

// Step Details
export const getStepDetails = step => api.create(url.GET_STEP_DETAILS, step);

//Step Delete
export const deleteStep = step => api.create(url.DELETE_STEP, step);

//Step Status Change
export const statusChangeStep = step => api.create(url.STATUS_CHANGE_STEP, step);


// Benefit list
export const getBenefitList = benefit => api.create(url.GET_BENEFIT_LIST, benefit);

// Benefit Create
export const addNewBenefit = benefit => api.create(url.ADD_NEW_BENEFIT, benefit);

// Benefit Update
export const updateBenefit = benefit => api.create(url.UPDATE_BENEFIT, benefit);

// Benefit Details
export const getBenefitDetails = benefit => api.create(url.GET_BENEFIT_DETAILS, benefit);

//Benefit Delete
export const deleteBenefit = benefit => api.create(url.DELETE_BENEFIT, benefit);

//Benefit Status change
export const statusChangeBenefit = benefit => api.create(url.STATUS_CHANGE_BENEFIT, benefit);


// Ingredient list
export const getIngredientList = ingredient => api.create(url.GET_INGREDIENT_LIST, ingredient);

// Ingredient Create
export const addNewIngredient = ingredient => api.create(url.ADD_NEW_INGREDIENT, ingredient);

// Ingredient Update
export const updateIngredient = ingredient => api.create(url.UPDATE_INGREDIENT, ingredient);

// Ingredient Details
export const getIngredientDetails = ingredient => api.create(url.GET_INGREDIENT_DETAILS, ingredient);

//Ingredient Delete
export const deleteIngredient = ingredient => api.create(url.DELETE_INGREDIENT, ingredient);

//Ingredient Status change
export const statusChangeIngredient = ingredient => api.create(url.STATUS_CHANGE_INGREDIENT, ingredient);

// Spice list
export const getSpiceList = spice => api.create(url.GET_SPICE_LIST, spice);

// Spice Create
export const addNewSpice = spice => api.create(url.ADD_NEW_SPICE, spice);

// Spice Update
export const updateSpice = spice => api.create(url.UPDATE_SPICE, spice);

// Spice Details
export const getSpiceDetails = spice => api.create(url.GET_SPICE_DETAILS, spice);

//Spice Delete
export const deleteSpice = spice => api.create(url.DELETE_SPICE, spice);

//Spice Status Change
export const statusChangeSpice = spice => api.create(url.STATUS_CHANGE_SPICE, spice);


// Testimonial list
export const getTestimonialList = testimonial => api.create(url.GET_TESTIMONIAL_LIST, testimonial);

// Testimonial Create
export const addNewTestimonial = testimonial => api.create(url.ADD_NEW_TESTIMONIAL, testimonial);

// Testimonial Update
export const updateTestimonial = testimonial => api.create(url.UPDATE_TESTIMONIAL, testimonial);

// Testimonial Details
export const getTestimonialDetails = testimonial => api.create(url.GET_TESTIMONIAL_DETAILS, testimonial);

//Testimonial Delete
export const deleteTestimonial = testimonial => api.create(url.DELETE_TESTIMONIAL, testimonial);

//Testimonial Status change
export const statusChangeTestimonial = testimonial => api.create(url.STATUS_CHANGE_TESTIMONIAL, testimonial);


// Funfact list
export const getFunfactList = funfact => api.create(url.GET_FUNFACT_LIST, funfact);

// Funfact Create
export const addNewFunfact = funfact => api.create(url.ADD_NEW_FUNFACT, funfact);

// Funfact Update
export const updateFunfact = funfact => api.create(url.UPDATE_FUNFACT, funfact);

// Funfact Details
export const getFunfactDetails = funfact => api.create(url.GET_FUNFACT_DETAILS, funfact);

//Funfact Delete
export const deleteFunfact = funfact => api.create(url.DELETE_FUNFACT, funfact);

//Funfact Status change
export const statusChangeFunfact = funfact => api.create(url.STATUS_CHANGE_FUNFACT, funfact);


// Service list
export const getServiceList = service => api.create(url.GET_SERVICE_LIST, service);

// Service Create
export const addNewService = service => api.create(url.ADD_NEW_SERVICE, service);

// Service Update
export const updateService = service => api.create(url.UPDATE_SERVICE, service);

// Service Details
export const getServiceDetails = service => api.create(url.GET_SERVICE_DETAILS, service);
//Service Delete
export const deleteService = service => api.create(url.DELETE_SERVICE, service);

//Service Status Change
export const statusChangeService = service => api.create(url.STATUS_CHANGE_SERVICE, service);


// Team list
export const getTeamList = team => api.create(url.GET_TEAM_LIST, team);

// Team Create
export const addNewTeam = team => api.create(url.ADD_NEW_TEAM, team);

// Team Update
export const updateTeam = team => api.create(url.UPDATE_TEAM, team);

// Team Details
export const getTeamDetails = team => api.create(url.GET_TEAM_DETAILS, team);

//Team Delete
export const deleteTeam = team => api.create(url.DELETE_TEAM, team);

//Team Status Change
export const statusChangeTeam = team => api.create(url.STATUS_CHANGE_TEAM, team);


// Mission list
export const getMissionList = mission => api.create(url.GET_MISSION_LIST, mission);

// Mission Create
export const addNewMission = mission => api.create(url.ADD_NEW_MISSION, mission);

// Mission Update
export const updateMission = mission => api.create(url.UPDATE_MISSION, mission);

// Mission Details
export const getMissionDetails = mission => api.create(url.GET_MISSION_DETAILS, mission);

//Mission Delete
export const deleteMission = mission => api.create(url.DELETE_MISSION, mission);

//Mission Status Change
export const statusChangeMission = mission => api.create(url.STATUS_CHANGE_MISSION, mission);


// Vision list
export const getVisionList = vision => api.create(url.GET_VISION_LIST, vision);

// Vision Create
export const addNewVision = vision => api.create(url.ADD_NEW_VISION, vision);

// Vision Update
export const updateVision = vision => api.create(url.UPDATE_VISION, vision);

// Vision Details
export const getVisionDetails = vision => api.create(url.GET_VISION_DETAILS, vision);

//Vision Delete
export const deleteVision = vision => api.create(url.DELETE_VISION, vision);

//Vision Status Change
export const statusChangeVision = vision => api.create(url.STATUS_CHANGE_VISION, vision);


// Highlight list
export const getHighlightList = highlight => api.create(url.GET_HIGHLIGHT_LIST, highlight);

// Highlight Create
export const addNewHighlight = highlight => api.create(url.ADD_NEW_HIGHLIGHT, highlight);

// Highlight Update
export const updateHighlight = highlight => api.create(url.UPDATE_HIGHLIGHT, highlight);

// Highlight Details
export const getHighlightDetails = highlight => api.create(url.GET_HIGHLIGHT_DETAILS, highlight);

//Highlight Delete
export const delHighlight = highlight => api.create(url.DELETE_HIGHLIGHT, highlight);

//Highlight status change
export const statusChangeHighlight = highlight => api.create(url.STATUS_CHANGE_HIGHLIGHT, highlight);





// Site Update
export const updateSite = job => api.create(url.UPDATE_SITE, job);

// Site Details
export const getSiteDetails = site => api.create(url.GET_SITE_DETAILS, site);


// Page list
export const getPageList = page => api.create(url.GET_PAGE_LIST, page);

// Page Create
export const addNewPage = page => api.create(url.ADD_NEW_PAGE, page);

// Page Update
export const updatePage = page => api.create(url.UPDATE_PAGE, page);

// Page Details
export const getPageDetails = page => api.create(url.GET_PAGE_DETAILS, page);


// Seo list
export const getSeoList = seo => api.create(url.GET_SEO_LIST, seo);

// Seo Create
export const addNewSeo = seo => api.create(url.ADD_NEW_SEO, seo);

// Seo Update
export const updateSeo = seo => api.create(url.UPDATE_SEO, seo);

// Seo Details
export const getSeoDetails = seo => api.create(url.GET_SEO_DETAILS, seo);

// Seo Remove
export const delSeo = seo => api.create(url.DELETE_SEO, seo);

// Contact list
export const getContactList = contact => api.create(url.GET_CONTACT_LIST, contact);

// Contact Create
export const addNewContact = contact => api.create(url.ADD_NEW_CONTACT, contact);

// Contact Update
// export const updateContact = contact => api.create(url.UPDATE_CONTACT, contact);

// Contact Details
// export const getContactDetails = contact => api.create(url.GET_CONTACT_DETAILS, contact);

// Contact Remove
export const delContact = contact => api.create(url.DELETE_CONTACT, contact);

// status change contact
export const statusChangeContact = contact => api.create(url.STATUS_CHANGE_CONTACT ,contact);

// Faq list
export const getFaqList = faq => api.create(url.GET_FAQ_LIST,faq);

// Faq Create
export const addNewFaq = faq => api.create(url.ADD_NEW_FAQ, faq);

// Faq Update
export const updateFaq = faq => api.create(url.UPDATE_FAQ, faq);

// Faq Details
export const getFaqDetails = faq => api.create(url.GET_FAQ_DETAILS, faq);

// Faq Remove
export const delFaq = faq => api.create(url.DELETE_FAQ, faq);

// status change  faq
export const statusChangeFaq = faq => api.create(url.STATUS_CHANGE_FAQ ,faq);

// Faq feedback list
export const getFaqFeedbackList = faq => api.create(url.GET_FAQ_FEEDBACK_LIST ,faq);

//Product-Section

// Product-Category list
export const getProductCategoryList = category => api.create(url.GET_PRODUCT_CATEGORY_LIST, category);

// Product-Category Create
export const addProductCategory = category => api.create(url.ADD_NEW_PRODUCT_CATEGORY, category);

// Product-Category Update
export const updateProductCategory = category => api.create(url.UPDATE_PRODUCT_CATEGORY, category);

// Product-Category Details
export const ProductCategoryDetails = category => api.create(url.GET_PRODUCT_CATEGORY_DETAILS, category);

//Product-Category Delete
export const delProductCategory = category => api.create(url.DELETE_PRODUCT_CATEGORY, category);

//Product-Category Status Change
export const statusChangeProductCategory = category => api.create(url.STATUS_CHANGE_PRODUCT_CATEGORY, category);


// Product list
export const getProductList = product => api.create(url.GET_PRODUCT_LIST,product);

// Product Create
export const addNewProduct = product => api.create(url.ADD_NEW_PRODUCT, product);

// Product Update
export const updateProduct = product => api.create(url.UPDATE_PRODUCT, product);

// Product Details
export const getProductDetails = product => api.create(url.GET_PRODUCT_DETAILS, product);

// Product Remove
export const delProduct = product => api.create(url.DELETE_PRODUCT, product);

// status change  product
export const statusChangeProduct = product => api.create(url.STATUS_CHANGE_PRODUCT ,product);


// Allergie list
export const getAllergieList = allergie => api.create(url.GET_ALLERGIE_LIST,allergie);

// Allergie Create
export const addNewAllergie = allergie => api.create(url.ADD_NEW_ALLERGIE, allergie);

// Allergie Update
export const updateAllergie = allergie => api.create(url.UPDATE_ALLERGIE, allergie);

// Allergie Details
export const getAllergieDetails = allergie => api.create(url.GET_ALLERGIE_DETAILS, allergie);

// Allergie Remove
export const deleteAllergie = allergie => api.create(url.DELETE_ALLERGIE, allergie);

// status change  Allergie
export const statusChangeAllergie = allergie => api.create(url.STATUS_CHANGE_ALLERGIE ,allergie);


// Diet list
export const getDietList = diet => api.create(url.GET_DIET_LIST,diet);

// Diet Create
export const addNewDiet = diet => api.create(url.ADD_NEW_DIET, diet);

// Diet Update
export const updateDiet = diet => api.create(url.UPDATE_DIET, diet);

// Diet Details
export const getDietDetails = diet => api.create(url.GET_DIET_DETAILS, diet);

// Diet Remove
export const deleteDiet = diet => api.create(url.DELETE_DIET, diet);

// status change  Diet
export const statusChangeDiet = diet => api.create(url.STATUS_CHANGE_DIET ,diet);

// Organization
export const getOrganizationList = organisation => api.create(url.GET_ORGANIZATION_LIST,organisation);
export const addNewOrganization = organisation => api.create(url.ADD_NEW_ORGANIZATION, organisation);
export const updateOrganization = organisation => api.create(url.UPDATE_ORGANIZATION, organisation);
export const getOrganizationDetails = organisation => api.create(url.GET_ORGANIZATION_DETAILS, organisation);
export const deleteOrganization = organisation => api.create(url.DELETE_ORGANIZATION, organisation);
export const statusChangeOrganization = organisation => api.create(url.STATUS_CHANGE_ORGANIZATION ,organisation);

// Coupon
export const getCouponList = coupon => api.create(url.GET_COUPON_LIST,coupon);
export const addNewCoupon = coupon => api.create(url.ADD_NEW_COUPON, coupon);
export const updateCoupon = coupon => api.create(url.UPDATE_COUPON, coupon);
export const getCouponDetails = coupon => api.create(url.GET_COUPON_DETAILS, coupon);
export const deleteCoupon = coupon => api.create(url.DELETE_COUPON, coupon);
export const statusChangeCoupon = coupon => api.create(url.STATUS_CHANGE_COUPON ,coupon);

// Author
export const getAuthorList = author => api.create(url.GET_AUTHOR_LIST,author);
export const addNewAuthor = author => api.create(url.ADD_NEW_AUTHOR, author);
export const updateAuthor = author => api.create(url.UPDATE_AUTHOR, author);
export const getAuthorDetails = author => api.create(url.GET_AUTHOR_DETAILS, author);
export const deleteAuthor = author => api.create(url.DELETE_AUTHOR, author);
export const statusChangeAuthor = author => api.create(url.STATUS_CHANGE_AUTHOR ,author);


// Point List
export const getPointList = point => api.create(url.GET_POINT_LIST, point);


//Order
export const getFutureTeamList = data => api.create(url.GET_FUTURETEAM_LIST, data);
export const addNewFutureTeam = data => api.create(url.ADD_NEW_FUTURETEAM, data);
export const getFutureTeamDetails = data => api.create(url.GET_FUTURETEAM_DETAILS, data);
export const updateFutureTeam = data => api.create(url.UPDAT_EFUTURETEAM, data);
export const statusChangeFutureTeam = data => api.create(url.STATUS_CHANGE_FUTURETEAM, data);
export const deleteFutureTeam = data => api.create(url.DELETE_FUTURETEAM, data);

//Combo
export const getComboList = data => api.create(url.GET_COMBO_LIST, data);
export const addNewCombo = data => api.create(url.ADD_NEW_COMBO, data);
export const getComboDetails = data => api.create(url.GET_COMBO_DETAILS, data);
export const updateCombo = data => api.create(url.UPDATE_COMBO, data);
export const statusChangeCombo = data => api.create(url.STATUS_CHANGE_COMBO, data);
export const delCombo = data => api.create(url.DELETE_COMBO, data);


//Future Team
export const getOrderList = data => api.create(url.GET_ORDER_LIST, data);
export const addNewOrder = data => api.create(url.ADD_NEW_ORDER, data);
export const getOrderDetails = data => api.create(url.GET_ORDER_DETAILS, data);
export const getOrderStatusList = data => api.create(url.ORDER_STATUS_LIST, data);
export const statusChangeOrder = data => api.create(url.STATUS_CHANGE_ORDER, data);
export const statusChangeOrderPayment = data => api.create(url.ORDER_PAYMENT_STATUS_CHANGE, data);
export const deleteOrder = data => api.create(url.DELETE_ORDER, data);


//Opening Hour
export const getOpeningHourList = data => api.create(url.GET_OPENING_HOUR_LIST, data);
export const addNewOpeningHour = data => api.create(url.ADD_NEW_OPENING_HOUR, data);
export const getOpeningHourDetails = data => api.create(url.GET_OPENING_HOUR_DETAILS, data);
export const updateOpeningHour = data => api.create(url.UPDATE_OPENING_HOUR, data);
export const statusChangeOpeningHour = data => api.create(url.STATUS_CHANGE_OPENING_HOUR, data);
export const deleteOpeningHour = data => api.create(url.DELETE_OPENING_HOUR, data);



// Pin list
export const getPinList = pin => api.create(url.GET_PIN_LIST, pin);

// Pin Create
export const addNewPin = pin => api.create(url.ADD_NEW_PIN, pin);

// Pin Update
export const updatePin = pin => api.create(url.UPDATE_PIN, pin);

// Pin Details
export const getPinDetails = pin => api.create(url.GET_PIN_DETAILS, pin);

//Pin Delete
export const deletePin = pin => api.create(url.DELETE_PIN, pin);

//Pin Status Change
export const statusChangePin = pin => api.create(url.STATUS_CHANGE_PIN, pin);


// TakeofTime List
export const getTakeoftimeList = takeoftime => api.create(url.GET_TAKEOFTIME_LIST, takeoftime);

// TakeofTime Create
export const addNewTakeoftime = takeoftime => api.create(url.ADD_NEW_TAKEOFTIME, takeoftime);

// TakeofTime Delete
export const delTakeofTime = takeoftime => api.create(url.DELETE_NEW_TAKEOFTIME, takeoftime);


// StatusChange Takeoftime
export const statusChangeTakeofTime = takeoftime => api.create(url.STATUS_CHANGE_TAKEOFTIME, takeoftime);

// Takeoftime Update
export const updateTakeofTime = takeoftime => api.create(url.UPDATE_TAKEOFTIME, takeoftime);

// Takeoftime Details
export const getTakeofTimeDetails = takeoftime => api.create(url.GET_TAKEOFTIME_DETAILS, takeoftime);


// Notification
export const getSocketNotificationList = socketNotification => api.create(url.GET_SOCKET_NOTIFICATION, socketNotification);


// SAVE ORDERING
export const saveOrdering = product => api.create(url.SAVE_ORDERING ,product);




