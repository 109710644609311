import {
    GET_AUTHORS_SUCCESS,
    GET_AUTHORS_FAIL,
    ADD_NEW_AUTHOR_SUCCESS,
    ADD_NEW_AUTHOR_FAIL,
    GET_AUTHOR_DETAILS_SUCCESS,
    GET_AUTHOR_DETAILS_FAIL,
    UPDATE_AUTHOR_SUCCESS,
    UPDATE_AUTHOR_FAIL,
    RESET_AUTHOR_FLAG,
    DELETE_AUTHOR_SUCCESS,
    DELETE_AUTHOR_FAIL,
    STATUS_CHANGE_AUTHOR_SUCCESS,
    STATUS_CHANGE_AUTHOR_FAIL,
    MODIFY_AUTHOR_FLAG, 
  } from "./actionTypes";
  
  const INIT_STATE = {
    authors: [],
    totalDataCount: 0,
    isSuccess: false,
  };
  
  const Authors = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_AUTHORS_SUCCESS:
        return {
          ...state,
          authors: action.payload.data,
          totalDataCount: action.payload.count,
          isSuccess: true
        };
  
      case GET_AUTHORS_FAIL:
        return {
          ...state,
          error: action.payload.message,
          totalDataCount: 0,
          isSuccess: false
        };
  
      case ADD_NEW_AUTHOR_SUCCESS:
        return {
          ...state,
          authors : [...state.authors, action.payload.data],
          success: action.payload.message,
          isAuthorAdd: true,
          isAuthorAddFail: false,
        };
  
      case ADD_NEW_AUTHOR_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isAuthorAdd: false,
          isAuthorAddFail: true,
        };
  
      case UPDATE_AUTHOR_SUCCESS:
        return {
          ...state,
          authors: state.authors.map(author =>
            author._id.toString() === action.payload.data._id.toString()
              ? { ...author, ...action.payload.data }
              : author
          ),
          success: action.payload.message,
          isAuthorUpdate: true,
          isAuthorUpdateFail: false
        };
  
      case UPDATE_AUTHOR_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isAuthorUpdate: false,
          isAuthorUpdateFail: true,
        };
  
      case GET_AUTHOR_DETAILS_SUCCESS:
        return {
          ...state,
          details: action.payload.data,
        };
  
      case GET_AUTHOR_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload.message,
        };
      //Delete
      case DELETE_AUTHOR_SUCCESS:
        return { 
          ...state,
          authors : state.authors.filter(getData =>
            {
              //console.log(getData, action.payload);
              return getData._id !== action.payload.data._id
            }
            
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
    
      case DELETE_AUTHOR_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
    
        
      //Status Change
      case STATUS_CHANGE_AUTHOR_SUCCESS:
        return {
          ...state,
          authors: state.authors.map(getData =>
            getData._id.toString() === action.payload.data._id.toString()
              ? { ...getData, ...action.payload.data }
            : getData
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
          
      case STATUS_CHANGE_AUTHOR_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
  
      case MODIFY_AUTHOR_FLAG:
        return {
          ...state,
          statusSuccess: false,
          statusFaild: false,
          error: false
        };
  
      case RESET_AUTHOR_FLAG:
        return {
          ...state,
          isAuthorAdd: false,
          isAuthorAddFail: false,
          isSuccess: false,
          isAuthorDetails: false,
          isAuthorUpdate: false,
          isAuthorUpdateFail: false,
          error: false
        };
  
      default:
        return state;
    }
  };
  
  export default Authors;
  