import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import slugify from "slugify";
import axios from "axios";
import hostUrl from "../../../config";

import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  CardHeader,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateProduct,
  resetProductFlag,
  getCategories,
  getProductDetails,
  getProducts,
  getProductCategories,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertToBase64 } from "../../../helpers/image";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ProductEdit = () => {

  document.title = "Edit Product | Dabbawala";
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState(null);
  const [productData, setProductData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [allergyData, setAllergyData] = useState();
  const [addonProductArray, setAddonProductArray] = useState([]);
  const [dietData, setDietData] = useState();
  const [spiceData, setSpiceData] = useState();
  const [description, setData] = useState();
  const [Type, setType] = useState();
  const [spiceLevelArray, setSpiceLevelArray] = useState([]);

  const [categoryOption, setCategoryOption] = useState([]);
  const [allergyOption, setAllergyOption] = useState([]);
  const [spiceOption, setSpiceOption] = useState([]);

  const [slugData, setSlugData] = useState();

  const {
    isProductUpdate,
    isProductUpdateFail,
    error,
    success,
    details,
    categories,
    products,
    productcategories,
  } = useSelector((state) => ({
    isProductUpdate: state.Products.isProductUpdate,
    isProductUpdateFail: state.Products.isProductUpdateFail,
    error: state.Products.error,
    success: state.Products.success,
    details: state.Products.details,
    categories: state.Categories.categories,
    products: state.Products.products,
    productcategories: state.Productcategories.productcategories,
  }));

  useEffect(() => {
    dispatch( getProducts({product_type: 2}));
  },[dispatch]);

  useEffect(() => {
    dispatch(getProductCategories());
  }, [dispatch]);

  useEffect(() => {

    setProductData(products);
    setCategoryData(productcategories);

    axios
      .post(
        `${hostUrl.api.API_URL}product_ingredients/list`,
        { status: true, type: 1 },
        {}
      )
      .then(function (response) {
        setAllergyData(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `${hostUrl.api.API_URL}product_ingredients/list`,
        { status: true, type: 2 },
        {}
      )
      .then(function (response) {
        setSpiceData(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `${hostUrl.api.API_URL}product_ingredients/list`,
        { status: true, type: 3 },
        {}
      )
      .then(function (response) {
        setDietData(response.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [products]);





  const buyWithOptions = [
    { value: 1, label: "Price" },
    { value: 2, label: "Point" },
  ];

  var selectedBuyWithOption = {};
  if(details?.buy_with === 2){
    selectedBuyWithOption = {
      value: 2,
      label: "Point",
    };
  }else{
    selectedBuyWithOption = {
      value: 1,
      label: "Price",
    };
  }
  




  const selectedDietOption = {
    value: details && details.diet ? details.diet?._id : "",
    label: details && details.diet ? details.diet?.title : "",
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: details && details?.title ? details.title : "",
      slug: details && details?.plu_id ? details.plu_id : "",
      short_description:
        details && details?.short_description ? details.short_description : "",
      sale_price: details?.sale_price,
      regular_price: details?.regular_price,
      price_point: details?.price_point,
      diet_id: selectedDietOption,
      buy_with: selectedBuyWithOption,
      size: details && details?.size ? details.size : "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please enter your title"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        plu_code: slugData ? slugData : values.plu_code,
        regular_price: values.regular_price,
        sale_price: values.sale_price,
        price_point: values.price_point,
        short_description: description,
        product_type: type,
        featured: feature.value,
        image: image,
        in_stock: stock.value,
        spice_level_id: spiceLevelArray,
        diet_id: values.diet_details?._id,
        allergie_id: allergyArray,
        product_category_id: categoryArray,
        addon_id: addonProductArray,
        buy_with: values.buy_with.value,
        size: values.size,
      };
      //console.log(data)
      dispatch(updateProduct(data));
    },
  });

  useEffect(() => {
    if (isProductUpdate || isProductUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetProductFlag());
        //history.push(`/products/${type}`);
      }, 3000);
    }
  }, [dispatch, isProductUpdate, isProductUpdateFail, validation, history]);

  useEffect(() => {
    dispatch(getProductDetails({ id: id }));
  }, [dispatch, id]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const type = details?.product_type;
  const types = {
    1: "Food Product",
    2: "Add-on Product",
    3: "Merchandise Product",
  };
  const options = [
    { value: 1, label: "Food Product" },
    { value: 2, label: "Add-on Product" },
    { value: 3, label: "Merchandise Product" },
  ];
  const [selectData, setSelectData] = useState();

  useEffect(() => {
    setSelectData({
      label: types[type],
      value: parseInt(type),
    });
  }, [type]);

  // function handleSelectGroup(label, value) {
  //   setSelectData({ label: label, value: value });
  //   setType(value);
  // }

  function handleFeature(label, value) {
    setFeature({ label: label, value: value });
  }

  function handleStock(label, value) {
    setStock({ label: label, value: value });
  }

  const optionsStock = [
    { value: 1, label: "In Stock" },
    { value: 2, label: "Sold Out for Today" },
    { value: 3, label: "Out of Stock" },
  ];

  const optionsFeature = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const [feature, setFeature] = useState();
  const [stock, setStock] = useState();
  const [allergyArray, setAllergyArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);

  useEffect(() => {
    setData(details?.short_description);
    setType(type);
    setStock({
      value: details?.in_stock,
      label: details?.in_stock === true ? "In Stock" : "Out of Stock",
    });
    setFeature({
      value: details?.featured,
      label: details?.featured === true ? "Yes" : "No",
    });

    // const uniqueAllergie = details?.allergie
    //   ? [...new Set(details?.allergie.map((item) => item._id))]
    //   : null;
    // setAllergyArray(uniqueAllergie);

    // const uniqueCategory = details?.category
    //   ? [...new Set(details?.category.map((item) => item._id))]
    //   : null;
    // setCategoryArray(uniqueCategory);

    // const uniqueSpiceLevel = details?.spice_level
    //   ? [...new Set(details?.spice_level.map((item) => item._id))]
    //   : null;
    //   setSpiceLevelArray(uniqueSpiceLevel);


    const uniqueAddonData = details?.addons
      ? [...new Set(details?.addons.map((item) => item._id))]
      : null;
      setAddonProductArray(uniqueAddonData);


  }, [details]);

  const [tempCatArr, setTempCatArr] = useState([]);
  const [tempCatOpt, setTempCatOpt] = useState([]);

  const [tempSpiceArr, setTempSpiceArr] = useState([]);
  const [tempSpiceOpt, setTempSpiceOpt] = useState([]);

  const [tempAlgArr, setTempAlgArr] = useState([]);
  const [tempAlgOpt, setTempAlgOpt] = useState([]);
  

  useEffect(() => {
    let a = [];
    details?.category_list?.map((item) => {
      if(details?.category_list?.length > a.length){
        categoryArray?.push(item._id);
        a.push({label: item.title, value: item._id})
      }
    })
    setTempCatArr(a);
  },[details, id]);

  //console.log(tempCatArr); spice_level_list  allergie_list


  useEffect(() => {
    let a = [];
    details?.spice_level_list?.map((item) => {
      if(details?.spice_level_list?.length > a.length){
        spiceLevelArray?.push(item._id);
        a.push({label: item.title, value: item._id})
      }
    })
    setTempSpiceArr(a);
  },[details, id]);


  useEffect(() => {
    let a = [];
    details?.allergie_list?.map((item) => {
      if(details?.allergie_list?.length > a.length){
        allergyArray?.push(item._id);
        a.push({label: item.title, value: item._id})
      }
    })
    setTempAlgArr(a);
  },[details, id]);
  

  //Category On change
  function handleChange(e) {
    //let a = e.target.value;
    // if (a) {
      //   setCategoryArray([...categoryArray, e.target.value]);
      // } else {
        //   // remove from list
        //   setCategoryArray(categoryArray.filter((data) => data !== e.target.value));
        // }
        //console.log(e);
    setTempCatArr(e);
    
    let a = [];
    e.forEach((item) => {
      a.push(item.value)
    })
    setCategoryArray(a);
  }

  function handleChangeAllergy(e) {
    // let a = e.target.checked;
    // if (a) {
    //   setAllergyArray([...allergyArray, e.target.value]);
    // } else {
    //   setAllergyArray(allergyArray.filter((data) => data !== e.target.value));
    // }
    setTempAlgArr(e);
    let a = [];
    e.forEach((item) => {
      a.push(item.value)
    })
    setAllergyArray(a);
  }

  function handleChangeSpiceLevel(e) {
    // let a = e.target.checked;
    // if (a) {
    //   setSpiceLevelArray([...spiceLevelArray, e.target.value]);
    // } else {
    //   // remove from list
    //   setSpiceLevelArray(
    //     spiceLevelArray.filter((data) => data !== e.target.value)
    //   );
    // }
    setTempSpiceArr(e);
    let a = []
    e.forEach((item) => {
      a.push(item.value)
    })
    setSpiceLevelArray(a)
  }


  function handleChangeAddonProduct(e) {
    let a = e.target.checked;
    if (a) {
      setAddonProductArray([...addonProductArray, e.target.value]);
    } else {
      setAddonProductArray(
        addonProductArray.filter((data) => data !== e.target.value)
      );
    }
  }

  useEffect(() => {
    categoryData?.forEach((item) => {
      if (categoryData?.length > categoryOption?.length) {
        categoryOption.push({label: item.title, value: item._id})
      }
    })
  }, [categoryData])

  useEffect(() => {
    setTempCatOpt(categoryOption.filter(({ value: id1 }) => !tempCatArr.some(({ value: id2 }) => id2 === id1)));
  }, [tempCatArr]);

  useEffect(() => {
    setTempSpiceOpt(spiceOption.filter(({ value: id1 }) => !tempSpiceArr.some(({ value: id2 }) => id2 === id1)));
  }, [tempSpiceArr]);

  useEffect(() => {
    setTempAlgOpt(allergyOption.filter(({ value: id1 }) => !tempAlgArr.some(({ value: id2 }) => id2 === id1)));
  }, [tempAlgArr]);

  useEffect(() => {
    spiceData?.forEach((item) => {
      if (spiceData?.length > spiceOption?.length) {
        spiceOption.push({label: item.title, value: item._id})
      }
    })
  }, [spiceData])

  useEffect(() => {
    allergyData?.forEach((item) => {
      if (allergyData?.length > allergyOption?.length) {
        
        allergyOption.push({label: item.title, value: item._id})
      }
    })
  }, [allergyData]);

  const [enable, setEnable] = useState(details?.price_point ? details.price_point : false);

  
  console.log(tempAlgArr, tempCatArr, tempSpiceArr);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Edit Product"
            pageTitle="Products"
            pageUrl={`/products/${''+type}`}
          />

          <Row>
            <Col lg={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  {/* <CardBody>
                    <Row>
                      <Col lg={6}>
                        <Label>Select Product Type</Label>

                        <Select
                          value={selectData}
                          onChange={(e) => {
                            handleSelectGroup(e.label, e.value);
                          }}
                          options={options}
                          name="choices-category-input"
                          classNamePrefix="select2-selection form-select"
                        />
                      </Col>
                      </Row>
                      </CardBody> */}

                  <CardBody>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          {Type === "1" ? "Food" : Type === "2" ? "Add-On" : " Merchandise"} Name *
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="product-title-input"
                          name="title"
                          placeholder="Enter product name"
                          value={validation.values.title || ""}
                          onChange={(e) => {
                            validation.handleChange(e);
                          }}
                          onBlur={validation.handleBlur}
                          validate={{
                            required: { value: true },
                          }}
                          invalid={
                            validation.touched.title && validation.errors.title
                              ? true
                              : false
                          }
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="product-title-input"
                        >
                          PLU code {" "}
                          <button type='button' style={{position: 'absolute', left: '80px'}} onClick={() => setSlugData(Date.now())}>Generate</button>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="product-title-input"
                          placeholder=""
                          name="slug"
                          value={slugData ? slugData : details?.plu_code ? details.plu_code : ''}
                          onChange={(e) => {
                            validation.handleChange(e);
                          }}
                          validate={{
                            required: { value: true },
                          }}
                          invalid={
                            validation.touched.slug && validation.errors.slug
                              ? true
                              : false
                          }
                        />
                        {validation.touched.slug && validation.errors.slug ? (
                          <FormFeedback type="invalid">
                            {validation.errors.slug}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>
                    

                    <Row>
                    {Type !== 3 && <><Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Regular Price: *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="product-title-input"
                            placeholder="Enter regular price"
                            name="regular_price"
                            value={validation.values.regular_price || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.regular_price &&
                              validation.errors.regular_price
                                ? true
                                : false
                            }
                          />
                          {validation.touched.regular_price &&
                          validation.errors.regular_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.regular_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Sale Price: *
                          </Label>

                          <Input
                            type="number"
                            className="form-control"
                            id="product-title-input"
                            placeholder="Enter sale price"
                            name="sale_price"
                            value={validation.values.sale_price || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            invalid={
                              validation.touched.sale_price &&
                              validation.errors.sale_price
                                ? true
                                : false
                            }
                          />
                          {validation.touched.sale_price &&
                          validation.errors.sale_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sale_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col></>}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Point Price
                          </Label>
                          <input type='checkbox' onClick={() => {setEnable(!enable);}}></input>

                          <Input
                            type="number"
                            className="form-control"
                            id="product-title-input"
                            placeholder="Enter point price"
                            name="price_point"
                            value={validation.values.price_point || ""}
                            onChange={validation.handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            readOnly={enable}
                            invalid={
                              validation.touched.price_point &&
                              validation.errors.price_point
                                ? true
                                : false
                            }
                          />
                          {validation.touched.price_point &&
                          validation.errors.price_point ? (
                            <FormFeedback type="invalid">
                              {validation.errors.price_point}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              rows="3"
                              className="form-control"
                              placeholder="Enter description"
                              name="short_description"
                              value={validation.values.short_description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.short_description &&
                                validation.errors.short_description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.short_description &&
                            validation.errors.short_description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.short_description}
                              </FormFeedback>
                            ) : null}
                          </div>
                  </CardBody>
                </Card>

                <Row>
              <Col lg={6}>
                <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Image</h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <Input
                      type="file"
                      id="placeholderInput"
                      className="form-control"
                      placeholder="Place your image"
                      name="image"
                      onChange={onImg1Change}
                    />
                    <img
                      src={details?.image || ""}
                      alt=""
                      id="image1"
                      width="100px"
                    ></img>
                    {validation.touched.image && validation.errors.image ? (
                      <FormFeedback type="invalid">
                        {validation.errors.image}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Info</h5>
                </CardHeader>
                <CardBody>
                  {/* <div className="mb-3">
                  <Label>Select Product Type *</Label>

                  <Select
                    value={selectData}
                    onChange={(e) => {
                      handleSelectGroup(e.label, e.value);
                    }}
                    options={options}
                    name="choices-category-input"
                    classNamePrefix="select2-selection form-select"
                  />
                </div> */}
                  <div className="mb-3">
                    {Type == "1" && (
                      <>
                        <Label>Popular *</Label>
                        <Select
                          value={feature}
                          onChange={(e) => handleFeature(e.label, e.value)}
                          options={optionsFeature}
                          name="choices-category-input"
                          classNamePrefix="select2-selection form-select"
                        />
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <Label>Stock Manage *</Label>
                    <Select
                      value={stock}
                      onChange={(e) => handleStock(e.label, e.value)}
                      options={optionsStock}
                      name="choices-category-input"
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  {/* <div className="mb-3">
                    <Label>Buy with</Label>
                      <Select
                        type="text"
                        name="buy_with"
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "buy_with",
                              value: selectedOption,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          buyWithOptions &&
                          buyWithOptions.map((item, index) => ({
                            label: item.label,
                            value: item.value,
                          }))
                        }
                        value={validation.values.buy_with || ""}
                      />

                      {validation.touched.diet_id &&
                      validation.errors.diet_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.diet_id}
                        </FormFeedback>
                      ) : null}




                  </div>

                  <div>
                    <Label
                      className="form-label"
                      htmlFor="product-title-input"
                    >
                      Size
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="product-title-input"
                      name="size"
                      placeholder="Enter size"
                      value={validation.values.size || ""}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      validate={{
                        required: { value: false },
                      }}
                      invalid={
                        validation.touched.size && validation.errors.size
                          ? true
                          : false
                      }
                    />
                    {validation.touched.size && validation.errors.size ? (
                      <FormFeedback type="invalid">
                        {validation.errors.size}
                      </FormFeedback>
                    ) : null}
                  </div> */}



                </CardBody>
              </Card>
              </Col>
              </Row>

                <Row>
                  <Col lg={6}>
                {Type == "1" && (
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Product Category</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                      <Select
                          closeMenuOnSelect={false}
                          value={tempCatArr}
                          isMulti
                          options={tempCatOpt}
                          onChange={(e) => handleChange(e)}
                      />
                      </Row>
                    </CardBody>
                  </Card>
                )}
                </Col>
                  <Col lg={6}>
                {Type == "1" && (
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Add-on Products</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                      {productData &&
                          productData.map((item, key) => (
                            <li className="col-6"
                              key={key}
                              style={{
                                listStyle: "none",
                                marginBottom: "0.1em",
                              }}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  //id={item._id ? item._id : ""}
                                  value={item._id ? item._id : ""}
                                  onChange={(e) => handleChangeAddonProduct(e)}
                                  checked={addonProductArray?.includes(item._id)}
                                />{" "}
                                {item.title}
                              </label>
                            </li>
                          ))}
                      </Row>
                    </CardBody>
                  </Card>
                )}
                </Col>
                </Row>
              
              {Type == "1" && (
                <>
                <Row>
                  <Col lg={4}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Allergies</h5>
                    </CardHeader>
                    <CardBody>
                    <Select
                            closeMenuOnSelect={false}
                            //defaultValue={tempAlgArr}
                            value={tempAlgArr}
                            isMulti
                            options={tempAlgOpt}
                            onChange={(e) => handleChangeAllergy(e)}
                        />
                    </CardBody>
                  </Card>
                  </Col>
                  <Col lg={4}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Spice Level</h5>
                    </CardHeader>

                    <CardBody>
                    <Select
                            closeMenuOnSelect={false}
                            value={tempSpiceArr}
                            isMulti
                            options={tempSpiceOpt}
                            onChange={(e) => handleChangeSpiceLevel(e)}
                        />
                    </CardBody>



                    {/* <CardBody>
                      <Select
                        type="text"
                        name="spice_level_id"
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "spice_level_id",
                              value: selectedOption,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          spiceData &&
                          spiceData.map((item, index) => ({
                            label: item.title,
                            value: item._id,
                          }))
                        }
                        value={validation.values.spice_level_id || ""}
                      />
                      {validation.touched.spice_level_id &&
                      validation.errors.spice_level_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.spice_level_id}
                        </FormFeedback>
                      ) : null}
                    </CardBody> */}
                  </Card>
                  </Col>
                  <Col lg={4}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Diet</h5>
                    </CardHeader>
                    <CardBody>
                      <Select
                        type="text"
                        name="diet_id"
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "diet_id",
                              value: selectedOption,
                            },
                          };
                          validation.handleChange(event);
                        }}
                        onBlur={validation.handleBlur}
                        options={
                          dietData &&
                          dietData.map((item, index) => ({
                            label: item.title,
                            value: item._id,
                          }))
                        }
                        defaultValue={[{label: details?.diet_details?.title, value: details?.diet_details?._id}]}
                      />

                      {validation.touched.diet_id &&
                      validation.errors.diet_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.diet_id}
                        </FormFeedback>
                      ) : null}
                    </CardBody>
                  </Card>
                  </Col>
                </Row>
                </>
              )}

                <div className="text-end mb-4">
                  <Link
                    to={`/products/${type}`}
                    className="btn btn-danger w-sm me-1"
                  >
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </form>
              {isProductUpdate ? (
                <MsgToast
                  msg={success}
                  color="success"
                  icon="ri-checkbox-circle-line"
                />
              ) : null}
              {isProductUpdateFail ? (
                <MsgToast
                  msg={error}
                  color="danger"
                  icon="ri-error-warning-line"
                />
              ) : null}
              <ToastContainer limit={1} closeButton={false} />
              
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProductEdit;
