import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updateOpeningHour,
  resetOpeningHourFlag,
  getOpeningHourDetails,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

const OpeningHourEdit = () => {
  document.title = "Edit OpeningHour | Defendit - Admin Panel";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [isCloseFlag, setCloseFlag] = useState(false);

  const isCloseDataSet = [
    {label : 'Open', value : false},
    {label : 'Close', value : true},
  ];
  const dayDataSet = [
    {label : 'Sunday', value : 'sunday'},
    {label : 'Monday', value : 'monday'},
    {label : 'Tuesday', value : 'tuesday'},
    {label : 'Wednesday', value : 'wednesday'},
    {label : 'Thursday', value : 'thursday'},
    {label : 'Friday', value : 'friday'},
    {label : 'Saturday', value : 'saturday'},
  ];


  const {
    isOpeningHourUpdate,
    isOpeningHourUpdateFail,
    error,
    success,
    details,
  } = useSelector((state) => ({
    isOpeningHourUpdate: state.OpeningHour.isOpeningHourUpdate,
    isOpeningHourUpdateFail: state.OpeningHour.isOpeningHourUpdateFail,
    error: state.OpeningHour.error,
    success: state.OpeningHour.success,
    details: state.OpeningHour.details,
  }));

  let selectedIsCloseOption = {};
  if( details && details?.is_closed){
    selectedIsCloseOption = {
      value: true,
      label: "Close",
    };
  }else{
    selectedIsCloseOption = {
      value: false,
      label: "Open",
    };
  }

  const selectedDayNameOption = {
    value: details && details.day_name ? details.day_name : "",
    label: details && details.day_name ? details.day_name : "",
  };


  useEffect(() => {
    setCloseFlag(details && details?.is_closed)
  }, [dispatch, details]);

  const isClosedHandelChange = event => {
    setCloseFlag(event.target.value.value)
  }
  
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      start_time: details && details.start_time ? details.start_time: "",
      end_time: details && details.end_time ? details.end_time: "",
      is_closed : selectedIsCloseOption, 
      day_name : selectedDayNameOption, 
    },
    // validationSchema: Yup.object({
    //   //day_name: Yup.string().required("Please Enter Day"),
    // }),
    onSubmit: (values) => {
      setIsCreating(true);
      function isIsoDate(str) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
        const d = new Date(str); 
        return d instanceof Date && !isNaN(d) && d.toISOString()===str; // valid date 
      }

      let startNewTime = '';
      let endNewTime = '';
      const defaultDate = new Date(null).toDateString();
      if(isIsoDate(values.start_time)){
        startNewTime = values.start_time;
      }else{
        startNewTime = new Date(`${defaultDate} ${values.start_time}`).toISOString();
      }

      if(isIsoDate(values.end_time)){
        endNewTime = values.end_time;
      }else{
        endNewTime = new Date(`${defaultDate} ${values.end_time}`).toISOString();
      }

      const data = {
        id: id,
        day_name: values.day_name.value,
        start_time: startNewTime,
        end_time: endNewTime,
        is_closed: values.is_closed.value,
      };
      dispatch(updateOpeningHour(data));
    },
  });

  useEffect(() => {
    if (isOpeningHourUpdate || isOpeningHourUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetOpeningHourFlag());
        history.push("/opening-hours");
      }, 3000);
    }
  }, [dispatch, isOpeningHourUpdate, isOpeningHourUpdateFail, history]);

  useEffect(() => {
    dispatch(getOpeningHourDetails({ id: id }));
  }, [dispatch, id]);

  


  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Edit Opening Hours"
            pageTitle="Opening Hours"
            pageUrl="/opening-hour"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Opening Hour" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="product-title-input"
                            >
                            Select Day
                            </Label>
                            <Select
                              type="text"
                              name="day_name"
                              placeholder="Select"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "day_name",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                dayDataSet &&
                                dayDataSet.map((item, index) => ({
                                  label: item.label,
                                  value: item.value,
                                }))
                              }
                              value={validation.values.day_name || ""}
                            />
                            {validation.touched.day_name &&
                            validation.errors.day_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.day_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="product-title-input"
                            >
                              Opening Status
                            </Label>
                            <Select
                              type="text"
                              name="is_closed"
                              placeholder="Select"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "is_closed",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                                isClosedHandelChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                isCloseDataSet &&
                                isCloseDataSet.map((item, index) => ({
                                  label: item.label,
                                  value: item.value,
                                }))
                              }
                              value={validation.values.is_closed || ""}
                            />
                            {validation.touched.is_closed &&
                            validation.errors.is_closed ? (
                              <FormFeedback type="invalid">
                                {validation.errors.is_closed}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        {isCloseFlag === false ?
                          <>
                          <Col xxl={3} md={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="product-title-input"
                              >
                                Opning Time
                              </Label>
                              <Flatpickr
                                type="text"
                                name="start_time"
                                className="form-control"
                                placeholder="Select Opning Time"
                                onChange={(selectedDates, startTimeStr, instance) => {
                                  let event = {
                                    target: {
                                      name: "start_time",
                                      value: startTimeStr,
                                    },
                                  };
                                  validation.handleChange(event);
                                }}
                                options={{
                                  noCalendar: true,
                                  enableTime: true,
                                  dateFormat: 'h:i K'
                                }}
                                value={validation.values.start_time || ""}
                              />
                              {validation.touched.start_time &&
                              validation.errors.start_time ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.start_time}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={3} md={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="product-title-input"
                              >
                                Closing Time
                              </Label>
                              <Flatpickr
                                type="text"
                                name="start_time"
                                className="form-control"
                                placeholder="Select Closing Time"
                                onChange={(selectedDates, endTimeStr, instance) => {
                                  let event = {
                                    target: {
                                      name: "end_time",
                                      value: endTimeStr,
                                    },
                                  };
                                  validation.handleChange(event);
                                }}
                                options={{
                                  noCalendar: true,
                                  enableTime: true,
                                  dateFormat: 'h:i K'
                                }}
                                value={validation.values.end_time || ""}
                              />
                              {validation.touched.end_time &&
                              validation.errors.end_time ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.end_time}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          </>
                        :''
                        }


                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/opening-hours" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isOpeningHourUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isOpeningHourUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default OpeningHourEdit;
