/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";
export const MODIFY_CAT_FLAG = "MODIFY_CAT_FLAG";
export const RESET_ADD_CATEGORY = "RESET_ADD_CATEGORY";

// STATUS CHANGE
export const STATUS_CHANGE_CAT = "STATUS_CHANGE_CAT";
export const STATUS_CHANGE_CAT_SUCCESS = "STATUS_CHANGE_CAT_SUCCESS";
export const STATUS_CHANGE_CAT_FAIL = "STATUS_CHANGE_CAT_FAIL";


export const SAVE_ORDERING_CATEGORY = "SAVE_ORDERING_CATEGORY";
export const SAVE_ORDERING_CATEGORY_SUCCESS = "SAVE_ORDERING_CATEGORY_SUCCESS";
export const SAVE_ORDERING_CATEGORY_FAIL = "SAVE_ORDERING_CATEGORY_FAIL";
export const SAVE_ORDERING_CATEGORY_RESET_FLAG = "SAVE_ORDERING_CATEGORY_RESET_FLAG";