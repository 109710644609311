import {
  GET_BENEFITS,
  GET_BENEFITS_SUCCESS,
  GET_BENEFITS_FAIL,
  ADD_NEW_BENEFIT,
  ADD_NEW_BENEFIT_SUCCESS,
  ADD_NEW_BENEFIT_FAIL,
  GET_BENEFIT_DETAILS,
  GET_BENEFIT_DETAILS_SUCCESS,
  GET_BENEFIT_DETAILS_FAIL,
  UPDATE_BENEFIT,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_BENEFIT_FAIL,
  RESET_BENEFIT_FLAG,
  DELETE_BENEFIT,
  DELETE_BENEFIT_SUCCESS,
  DELETE_BENEFIT_FAIL,
  STATUS_CHANGE_BENEFIT,
  STATUS_CHANGE_BENEFIT_SUCCESS,
  STATUS_CHANGE_BENEFIT_FAIL,
  MODIFY_BENEFIT_FLAG,

  SAVE_ORDERING_BENEFIT,
  SAVE_ORDERING_BENEFIT_SUCCESS,
  SAVE_ORDERING_BENEFIT_FAIL,
  SAVE_ORDERING_BENEFIT_RESET_FLAG,
} from "./actionTypes";

export const getBenefits = data => ({
  type: GET_BENEFITS,
  payload: data,
});

export const getBenefitsSuccess = data => ({
  type: GET_BENEFITS_SUCCESS,
  payload: data,
});

export const getBenefitsFail = error => ({
  type: GET_BENEFITS_FAIL,
  payload: error,
});

export const addNewBenefit = benefit=> ({
  type: ADD_NEW_BENEFIT,
  payload: benefit,
});

export const addNewBenefitSuccess = benefit => ({
  type: ADD_NEW_BENEFIT_SUCCESS,
  payload: benefit,
});

export const addNewBenefitFail = error => ({
  type: ADD_NEW_BENEFIT_FAIL,
  payload: error,
});

export const updateBenefit = benefit => ({
  type: UPDATE_BENEFIT,
  payload: benefit,
});

export const updateBenefitSuccess = benefit => ({
  type: UPDATE_BENEFIT_SUCCESS,
  payload: benefit,
});

export const updateBenefitFail = error => ({
  type: UPDATE_BENEFIT_FAIL,
  payload: error,
});

export const getBenefitDetails = benefit => ({
  type: GET_BENEFIT_DETAILS,
  payload: benefit,
});

export const getBenefitDetailsSuccess = benefit => ({
  type: GET_BENEFIT_DETAILS_SUCCESS,
  payload: benefit,
});

export const getBenefitDetailsFail = error => ({
  type: GET_BENEFIT_DETAILS_FAIL,
  payload: error,
});

export const resetBenefitFlag = () => {
  return {
    type: RESET_BENEFIT_FLAG,
  }
}

//Delete
export const deleteBenefit = benefit => ({
  type: DELETE_BENEFIT,
  payload: benefit,
});

export const deleteBenefitSuccess = benefit => ({
  type: DELETE_BENEFIT_SUCCESS,
  payload: benefit,
});

export const deleteBenefitFail = error => ({
  type: DELETE_BENEFIT_FAIL,
  payload: error,
});

//Status Change
export const statusChangeBenefit = benefit => ({
  type: STATUS_CHANGE_BENEFIT,
  payload: benefit,
});

export const statusChangeBenefitSuccess = benefit => ({
  type: STATUS_CHANGE_BENEFIT_SUCCESS,
  payload: benefit,
});

export const statusChangeBenefitFail = error => ({
  type: STATUS_CHANGE_BENEFIT_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingBenefit = data => ({
  type: SAVE_ORDERING_BENEFIT,
  payload: data,
});

export const saveOrderingBenefitSuccess = data => ({
  type: SAVE_ORDERING_BENEFIT_SUCCESS,
  payload: data,
});

export const saveOrderingBenefitFail = error => ({
  type: SAVE_ORDERING_BENEFIT_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingBenefitResetFlag = () => {
  return {
    type: SAVE_ORDERING_BENEFIT_RESET_FLAG,
  }
}



//Modify Flag for toaster
export const modifyBenefitFlag = () => {
  return {
    type: MODIFY_BENEFIT_FLAG,
  }
}