import {
  GET_FUNFACTS_SUCCESS,
  GET_FUNFACTS_FAIL,
  ADD_NEW_FUNFACT_SUCCESS,
  ADD_NEW_FUNFACT_FAIL,
  GET_FUNFACT_DETAILS_SUCCESS,
  GET_FUNFACT_DETAILS_FAIL,
  UPDATE_FUNFACT_SUCCESS,
  UPDATE_FUNFACT_FAIL,
  RESET_FUNFACT_FLAG,
  DELETE_FUNFACT_SUCCESS,
  DELETE_FUNFACT_FAIL,
  SAVE_ORDERING_FUNFACT_SUCCESS,
  SAVE_ORDERING_FUNFACT_FAIL,
  SAVE_ORDERING_FUNFACT_RESET_FLAG,
  STATUS_CHANGE_FUNFACT_SUCCESS,
  STATUS_CHANGE_FUNFACT_FAIL,
  MODIFY_FUNFACT_FLAG, 
} from "./actionTypes";

const INIT_STATE = {
  funfacts: []
};

const Funfacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FUNFACTS_SUCCESS:
      return {
        ...state,
        funfacts: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_FUNFACTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_FUNFACT_SUCCESS:
      return {
        ...state,
        funfacts: [...state.funfacts, action.payload.data],
        success: action.payload.message,
        isFunfactAdd: true,
        isFunfactAddFail: false,
      };

    case ADD_NEW_FUNFACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isFunfactAdd: false,
        isFunfactAddFail: true,
      };

    case UPDATE_FUNFACT_SUCCESS:
      return {
        ...state,
        funfacts: state.funfacts.map(funfact =>
          funfact._id.toString() === action.payload.data._id.toString()
            ? { ...funfact, ...action.payload.data }
            : funfact
        ),
        success: action.payload.message,
        isFunfactUpdate: true,
        isFunfactUpdateFail: false
      };

    case UPDATE_FUNFACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isFunfactUpdate: false,
        isFunfactUpdateFail: true,
      };

    case GET_FUNFACT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_FUNFACT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_FUNFACT_SUCCESS:
      return { 
        ...state,
        funfacts: state.funfacts.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_FUNFACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
        //Save Ordering
    case SAVE_ORDERING_FUNFACT_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_FUNFACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_FUNFACT_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };
    //Status Change
    case STATUS_CHANGE_FUNFACT_SUCCESS:
      return {
        ...state,
        funfacts: state.funfacts.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_FUNFACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_FUNFACT_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_FUNFACT_FLAG:
      return {
        ...state,
        isFunfactAdd: false,
        isFunfactAddFail: false,
        isSuccess: false,
        isFunfactDetails: false,
        isFunfactUpdate: false,
        isFunfactUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Funfacts;
