import {
  GET_HIGHLIGHTS_SUCCESS,
  GET_HIGHLIGHTS_FAIL,
  ADD_NEW_HIGHLIGHT_SUCCESS,
  ADD_NEW_HIGHLIGHT_FAIL,
  GET_HIGHLIGHT_DETAILS_SUCCESS,
  GET_HIGHLIGHT_DETAILS_FAIL,
  UPDATE_HIGHLIGHT_SUCCESS,
  UPDATE_HIGHLIGHT_FAIL,
  DELETE_HIGHLIGHT_SUCCESS,
  DELETE_HIGHLIGHT_FAIL,
  RESET_HIGHLIGHT_FLAG,
  MODIFY_HIGHLIGHT_FLAG,

  STATUS_CHANGE_HIGHLIGHT_SUCCESS,
  STATUS_CHANGE_HIGHLIGHT_FAIL,

  SAVE_ORDERING_HIGHLIGHT_SUCCESS,
  SAVE_ORDERING_HIGHLIGHT_FAIL,
  SAVE_ORDERING_HIGHLIGHT_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  highlights: [],
};

const Highlights = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        highlights: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_HIGHLIGHTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case ADD_NEW_HIGHLIGHT_SUCCESS:
      return {
        ...state,
        highlights: [...state.highlights, action.payload.data],
        success: action.payload.message,
        isHighlightAdd: true,
        isHighlightAddFail: false,
      };

    case ADD_NEW_HIGHLIGHT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isHighlightAdd: false,
        isHighlightAddFail: true,
      };

    case UPDATE_HIGHLIGHT_SUCCESS:
      return {
        ...state,
        highlights: state.highlights.map((highlight) =>
        highlight._id.toString() === action.payload.data._id.toString()
            ? { ...highlight, ...action.payload.data }
            : highlight
        ),
        success: action.payload.message,
        isHighlightUpdate: true,
        isHighlightUpdateFail: false,
      };

    case UPDATE_HIGHLIGHT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isHighlightUpdate: false,
        isHighlightUpdateFail: true,
      };

    case GET_HIGHLIGHT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };

    case GET_HIGHLIGHT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
      };

   
    case RESET_HIGHLIGHT_FLAG:
      return {
        ...state,
        isHighlightAdd: false,
        isHighlightAddFail: false,
        isSuccess: false,
        isHighlightDetails: false,
        isHighlightUpdate: false,
        isHighlightUpdateFail: false,
        error: false,
      };
      case DELETE_HIGHLIGHT_SUCCESS:
        return {
          ...state,
          highlights: state.highlights.filter((getData) => {
            return getData._id !== action.payload.data._id;
          }),
          success: action.payload.message,
          isSuccess: true,
          statusSuccess: true,
          statusFailed: false,
          isHighlightRemove: true,
          isHighlightRemoveFail: false,
        };
  
      case DELETE_HIGHLIGHT_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false,
          statusSuccess: false,
          statusFailed: true,
          isHighlightRemove: false,
          isHighlightRemoveFail: true,
        };


      //Status Change
      case STATUS_CHANGE_HIGHLIGHT_SUCCESS:
        return {
          ...state,
          highlights: state.highlights.map(getData =>
            getData._id.toString() === action.payload.data._id.toString()
              ? { ...getData, ...action.payload.data }
            : getData
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
          
      case STATUS_CHANGE_HIGHLIGHT_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };




      //Save Ordering
      case SAVE_ORDERING_HIGHLIGHT_SUCCESS:
        return {
          ...state,
          success: action.payload.message,
          saveOrderingSuccess: true,
          saveOrderingFaild: false,
        };
          
      case SAVE_ORDERING_HIGHLIGHT_FAIL:
        return {
          ...state,
          error: action.payload.message,
          saveOrderingSuccess: false,
          saveOrderingFaild: true,
        };


      //Save Ordering reset flag 
      case SAVE_ORDERING_HIGHLIGHT_RESET_FLAG:
        return {
          ...state,
          saveOrderingSuccess: false,
          saveOrderingFaild: false,
          error: false
        };

        case MODIFY_HIGHLIGHT_FLAG:
          return {
            ...state,
            statusSuccess: false,
          };
    default:
      return state;
  }
};

export default Highlights;
