import {
    GET_AUTHORS,
    GET_AUTHORS_SUCCESS,
    GET_AUTHORS_FAIL,
    ADD_NEW_AUTHOR,
    ADD_NEW_AUTHOR_SUCCESS,
    ADD_NEW_AUTHOR_FAIL,
    GET_AUTHOR_DETAILS,
    GET_AUTHOR_DETAILS_SUCCESS,
    GET_AUTHOR_DETAILS_FAIL,
    UPDATE_AUTHOR,
    UPDATE_AUTHOR_SUCCESS,
    UPDATE_AUTHOR_FAIL,
    RESET_AUTHOR_FLAG,
    DELETE_AUTHOR,
    DELETE_AUTHOR_SUCCESS,
    DELETE_AUTHOR_FAIL,
    STATUS_CHANGE_AUTHOR,
    STATUS_CHANGE_AUTHOR_SUCCESS,
    STATUS_CHANGE_AUTHOR_FAIL,
    MODIFY_AUTHOR_FLAG
  } from "./actionTypes";
  
  export const getAuthors = data => ({
    type: GET_AUTHORS,
    payload: data,
  });
  
  export const getAuthorsSuccess = data => ({
    type: GET_AUTHORS_SUCCESS,
    payload: data,
  });
  
  export const getAuthorsFail = error => ({
    type: GET_AUTHORS_FAIL,
    payload: error,
  });
  
  export const addNewAuthor = author=> ({
    type: ADD_NEW_AUTHOR,
    payload: author,
  });
  
  export const addNewAuthorsuccess = author => ({
    type: ADD_NEW_AUTHOR_SUCCESS,
    payload: author,
  });
  
  export const addNewAuthorFail = error => ({
    type: ADD_NEW_AUTHOR_FAIL,
    payload: error,
  });
  
  export const updateAuthor = author => ({
    type: UPDATE_AUTHOR,
    payload: author,
  });
  
  export const updateAuthorSuccess = author => ({
    type: UPDATE_AUTHOR_SUCCESS,
    payload: author,
  });
  
  export const updateAuthorFail = error => ({
    type: UPDATE_AUTHOR_FAIL,
    payload: error,
  });
  
  export const getAuthorDetails = author => ({
    type: GET_AUTHOR_DETAILS,
    payload: author,
  });
  
  export const getAuthorDetailsSuccess = author => ({
    type: GET_AUTHOR_DETAILS_SUCCESS,
    payload: author,
  });
  
  export const getAuthorDetailsFail = error => ({
    type: GET_AUTHOR_DETAILS_FAIL,
    payload: error,
  });
  
  export const resetAuthorFlag = () => {
    return {
      type: RESET_AUTHOR_FLAG,
    }
  }
  
  //Delete
  export const deleteAuthor = author => ({
    type: DELETE_AUTHOR,
    payload: author,
  });
  
  export const deleteAuthorSuccess = author => ({
    type: DELETE_AUTHOR_SUCCESS,
    payload: author,
  });
  
  export const deleteAuthorFail = error => ({
    type: DELETE_AUTHOR_FAIL,
    payload: error,
  });
  
  //Status Change
  export const statusChangeAuthor = author => ({
    type: STATUS_CHANGE_AUTHOR,
    payload: author,
  });
  
  export const statusChangeAuthorSuccess = author => ({
    type: STATUS_CHANGE_AUTHOR_SUCCESS,
    payload: author,
  });
  
  export const statusChangeAuthorFail = error => ({
    type: STATUS_CHANGE_AUTHOR_FAIL,
    payload: error,
  });
  
  //Modify Flag for toaster
  export const modifyAuthorFlag = () => {
    return {
      type: MODIFY_AUTHOR_FLAG,
    }
  }