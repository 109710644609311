import {
  GET_HIGHLIGHTS,
  GET_HIGHLIGHTS_SUCCESS,
  GET_HIGHLIGHTS_FAIL,
  ADD_NEW_HIGHLIGHT,
  ADD_NEW_HIGHLIGHT_SUCCESS,
  ADD_NEW_HIGHLIGHT_FAIL,
  GET_HIGHLIGHT_DETAILS,
  GET_HIGHLIGHT_DETAILS_SUCCESS,
  GET_HIGHLIGHT_DETAILS_FAIL,
  UPDATE_HIGHLIGHT,
  UPDATE_HIGHLIGHT_SUCCESS,
  UPDATE_HIGHLIGHT_FAIL,
  DELETE_HIGHLIGHT,
  DELETE_HIGHLIGHT_SUCCESS,
  DELETE_HIGHLIGHT_FAIL,
  RESET_HIGHLIGHT_FLAG,
  MODIFY_HIGHLIGHT_FLAG,


  STATUS_CHANGE_HIGHLIGHT,
  STATUS_CHANGE_HIGHLIGHT_SUCCESS,
  STATUS_CHANGE_HIGHLIGHT_FAIL,


  SAVE_ORDERING_HIGHLIGHT,
  SAVE_ORDERING_HIGHLIGHT_SUCCESS,
  SAVE_ORDERING_HIGHLIGHT_FAIL,
  SAVE_ORDERING_HIGHLIGHT_RESET_FLAG,
} from "./actionTypes";

export const getHighlights = (data) => ({
  type: GET_HIGHLIGHTS,
  payload: data,
});

export const getHighlightsSuccess = (data) => ({
  type: GET_HIGHLIGHTS_SUCCESS,
  payload: data,
});

export const getHighlightsFail = (error) => ({
  type: GET_HIGHLIGHTS_FAIL,
  payload: error,
});

export const addNewHighlight = (highlight) => ({
  type: ADD_NEW_HIGHLIGHT,
  payload: highlight,
});

export const addNewHighlightSuccess = (highlight) => ({
  type: ADD_NEW_HIGHLIGHT_SUCCESS,
  payload: highlight,
});

export const addNewHighlightFail = (error) => ({
  type: ADD_NEW_HIGHLIGHT_FAIL,
  payload: error,
});

export const updateHighlight = (highlight) => ({
  type: UPDATE_HIGHLIGHT,
  payload: highlight,
});

export const updateHighlightSuccess = (highlight) => ({
  type: UPDATE_HIGHLIGHT_SUCCESS,
  payload: highlight,
});

export const updateHighlightFail = (error) => ({
  type: UPDATE_HIGHLIGHT_FAIL,
  payload: error,
});

export const getHighlightDetails = (highlight) => ({
  type: GET_HIGHLIGHT_DETAILS,
  payload: highlight,
});

export const getHighlightDetailsSuccess = (highlight) => ({
  type: GET_HIGHLIGHT_DETAILS_SUCCESS,
  payload: highlight,
});

export const getHighlightDetailsFail = (error) => ({
  type: GET_HIGHLIGHT_DETAILS_FAIL,
  payload: error,
});
export const delHighlight = data => ({
  type: DELETE_HIGHLIGHT,
  payload: data,
});

export const deleteHighlightSuccess = data => ({
  type: DELETE_HIGHLIGHT_SUCCESS,
  payload: data,
});

export const deleteHighlightFail = data => ({
  type: DELETE_HIGHLIGHT_FAIL,
  payload: data,
});
export const resetHighlightFlag = () => {
  return {
    type: RESET_HIGHLIGHT_FLAG,
  };
};

export const modifyHighlightFlag = () => {
  return {
    type: MODIFY_HIGHLIGHT_FLAG,
  }
}


//Status Change
export const statusChangeHighlight = step => ({
  type: STATUS_CHANGE_HIGHLIGHT,
  payload: step,
});

export const statusChangeHighlightSuccess = step => ({
  type: STATUS_CHANGE_HIGHLIGHT_SUCCESS,
  payload: step,
});

export const statusChangeHighlightFail = error => ({
  type: STATUS_CHANGE_HIGHLIGHT_FAIL,
  payload: error,
});



//Save Ordaring
export const saveOrderingHighlight = data => ({
  type: SAVE_ORDERING_HIGHLIGHT,
  payload: data,
});

export const saveOrderingHighlightSuccess = data => ({
  type: SAVE_ORDERING_HIGHLIGHT_SUCCESS,
  payload: data,
});

export const saveOrderingHighlightFail = error => ({
  type: SAVE_ORDERING_HIGHLIGHT_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingHighlightResetFlag = () => {
  return {
    type: SAVE_ORDERING_HIGHLIGHT_RESET_FLAG,
  }
}
