import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_TAKE_OF_TIME,
  ADD_NEW_TAKE_OF_TIME,
  GET_TAKE_OF_TIME_DETAILS,
  UPDATE_TAKE_OF_TIME,
  DELETE_TAKE_OF_TIME,
  STATUS_CHANGE_TAKE_OF_TIME,
  SAVE_ORDERING_TAKE_OF_TIME
} from "./actionTypes"

import {
  getTakeofTimeSuccess,
  getTakeofTimeFail,
  addNewTakeofTimeSuccess,
  addNewTakeofTimeFail,
  getTakeofTimeDetailsSuccess,
  getTakeofTimeDetailsFail,
  updateTakeofTimeSuccess,
  updateTakeofTimeFail,
  deleteTakeofTimeSuccess,
  deleteTakeofTimeFail,
  statusChangeTakeofTimeSuccess,
  statusChangeTakeofTimeFail,
  saveOrderingTakeofTimeSuccess,
  saveOrderingTakeofTimeFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTakeoftimeList,
  addNewTakeoftime,
  getTakeofTimeDetails,
  updateTakeofTime,
  delTakeofTime,
  statusChangeTakeofTime,
  saveOrdering
} from "../../helpers/fakebackend_helper"

function* fetchTakeofTime({ payload: takeoftime }) {
  try {
    const response = yield call(getTakeoftimeList, takeoftime)
    yield put(getTakeofTimeSuccess(response))
  } catch (error) {
    yield put(getTakeofTimeFail(error))
  }
}

export function* watchFetchTakeofTime() {
  yield takeEvery(GET_TAKE_OF_TIME, fetchTakeofTime);
}

function* onAddNewTakeofTime({ payload: takeoftime }) {
  try {
      const response = yield call(addNewTakeoftime, takeoftime);
      if(response.status === 200){
        yield put(addNewTakeofTimeSuccess(response));
      } else {
        yield put(addNewTakeofTimeFail(response));
      }
  } catch (error) {
      yield put(addNewTakeofTimeFail(error));
  }
}

export function* watchAddNewTakeofTime() {
  yield takeEvery(ADD_NEW_TAKE_OF_TIME, onAddNewTakeofTime);
}

function* onTakeofTimeDetails({ payload: takeoftime }) {
  try {
      const response = yield call(getTakeofTimeDetails, takeoftime);
      if(response.status === 200){
        yield put(getTakeofTimeDetailsSuccess(response));
      } else {
        yield put(getTakeofTimeDetailsFail(response));
      }
  } catch (error) {
      yield put(getTakeofTimeDetailsFail(error));
  }
}

export function* watchTakeofTimeDetails() {
  yield takeEvery(GET_TAKE_OF_TIME_DETAILS, onTakeofTimeDetails);
}

function* onProductTakeofTime({ payload: takeoftime }) {
  try {
      const response = yield call(updateTakeofTime, takeoftime);
      if(response.status === 200){
        yield put(updateTakeofTimeSuccess(response));
      } else {
        yield put(updateTakeofTimeFail(response));
      }
  } catch (error) {
      yield put(updateTakeofTimeFail(error));
  }
}

export function* watchUpdateTakeofTime() {
  yield takeEvery(UPDATE_TAKE_OF_TIME, onProductTakeofTime);
}

function* onDeleteTakeofTime({payload : data}) {
  try {
    const response = yield call(delTakeofTime, data)
    yield put(deleteTakeofTimeSuccess(response))
  } catch (error) {
    yield put(deleteTakeofTimeFail(error))
  }
}

export function* watchDeleteTakeofTime() {
  yield takeEvery(DELETE_TAKE_OF_TIME, onDeleteTakeofTime);
}
//Status Change
function* onStatusChangeTakeofTime({ payload: takeoftime }) {
  try {
    const response = yield call(statusChangeTakeofTime, takeoftime);
    yield put(statusChangeTakeofTimeSuccess({ takeoftime, ...response }));
  } catch (error) {
    yield put(statusChangeTakeofTimeFail(error));
  }
}

export function* watchStatusChangeTakeofTime() {
  yield takeEvery(STATUS_CHANGE_TAKE_OF_TIME, onStatusChangeTakeofTime);
}

//Save Ordering
function* onSaveOrderingTakeofTime({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingTakeofTimeSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingTakeofTimeFail(error));
  }
}

export function* watchSaveOrderingTakeofTime() {
  yield takeEvery(SAVE_ORDERING_TAKE_OF_TIME, onSaveOrderingTakeofTime);
}

function* takeofTimeSaga() {
  yield all(
    [
      fork(watchFetchTakeofTime),
      fork(watchAddNewTakeofTime),
      fork(watchTakeofTimeDetails),
      fork(watchUpdateTakeofTime),
      fork(watchDeleteTakeofTime),
      fork(watchStatusChangeTakeofTime),
      fork(watchSaveOrderingTakeofTime)
    ]
  );

}

export default takeofTimeSaga
