import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_COUPONS,
  ADD_NEW_COUPON,
  GET_COUPON_DETAILS,
  UPDATE_COUPON,
  DELETE_COUPON,
  STATUS_CHANGE_COUPON
} from "./actionTypes"

import {
  getCouponsSuccess,
  getCouponsFail,
  addNewCouponSuccess,
  addNewCouponFail,
  getCouponDetailsSuccess,
  getCouponDetailsFail,
  updateCouponSuccess,
  updateCouponFail,
  deleteCouponSuccess,
  deleteCouponFail,
  statusChangeCouponSuccess,
  statusChangeCouponFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCouponList,
  addNewCoupon,
  getCouponDetails,
  updateCoupon,
  deleteCoupon,
  statusChangeCoupon
} from "../../helpers/fakebackend_helper"

function* fetchCoupons({ payload: coupon }) {
  try {
    const response = yield call(getCouponList, coupon)
    yield put(getCouponsSuccess(response))
  } catch (error) {
    yield put(getCouponsFail(error))
  }
}

export function* watchFetchCoupons() {
  yield takeEvery(GET_COUPONS, fetchCoupons);
}

function* onAddNewCoupon({ payload: coupon }) {
  try {
      const response = yield call(addNewCoupon, coupon);
      if(response.status === 200){
        yield put(addNewCouponSuccess(response));
      } else {
        yield put(addNewCouponFail(response));
      }
  } catch (error) {
      yield put(addNewCouponFail(error));
  }
}

export function* watchAddNewCoupon() {
  yield takeEvery(ADD_NEW_COUPON, onAddNewCoupon);
}

function* onCouponDetails({ payload: coupon }) {
  try {
      const response = yield call(getCouponDetails, coupon);
      if(response.status === 200){
        yield put(getCouponDetailsSuccess(response));
      } else {
        yield put(getCouponDetailsFail(response));
      }
  } catch (error) {
      yield put(getCouponDetailsFail(error));
  }
}

export function* watchCouponDetails() {
  yield takeEvery(GET_COUPON_DETAILS, onCouponDetails);
}

function* onCouponUpdate({ payload: coupon }) {
  try {
      const response = yield call(updateCoupon, coupon);
      if(response.status === 200){
        yield put(updateCouponSuccess(response));
      } else {
        yield put(updateCouponFail(response));
      }
  } catch (error) {
      yield put(updateCouponFail(error));
  }
}

export function* watchUpdateCoupon() {
  yield takeEvery(UPDATE_COUPON, onCouponUpdate);
}


//Delete Data
function* onDeleteCoupon({ payload: coupon }) {
  try {
    const response = yield call(deleteCoupon, coupon);
    yield put(deleteCouponSuccess({ coupon, ...response }));
  } catch (error) {
    yield put(deleteCouponFail(error));
  }
}

export function* watchDeleteCoupon() {
  yield takeEvery(DELETE_COUPON, onDeleteCoupon);
}



//Status Change
function* onStatusChangCoupon({ payload: coupon }) {
  try {
    const response = yield call(statusChangeCoupon, coupon);
    yield put(statusChangeCouponSuccess({ coupon, ...response }));
  } catch (error) {
    yield put(statusChangeCouponFail(error));
  }
}

export function* watchStatusChangeCoupon() {
  yield takeEvery(STATUS_CHANGE_COUPON, onStatusChangCoupon);
}



function* couponSaga() {
  yield all(
    [
      fork(watchFetchCoupons),
      fork(watchAddNewCoupon),
      fork(watchCouponDetails),
      fork(watchUpdateCoupon),
      fork(watchDeleteCoupon),
      fork(watchStatusChangeCoupon)
    ]
  );

}

export default couponSaga
