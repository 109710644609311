/* PINS */
export const GET_PINS = "GET_PINS";
export const GET_PINS_SUCCESS = "GET_PINS_SUCCESS";
export const GET_PINS_FAIL = "GET_PINS_FAIL";

export const ADD_NEW_PIN = "ADD_NEW_PIN";
export const ADD_NEW_PIN_SUCCESS = "ADD_NEW_PIN_SUCCESS";
export const ADD_NEW_PIN_FAIL = "ADD_NEW_PIN_FAIL";

export const UPDATE_PIN = "UPDATE_PIN";
export const UPDATE_PIN_SUCCESS = "UPDATE_PIN_SUCCESS";
export const UPDATE_PIN_FAIL = "UPDATE_PIN_FAIL";

export const GET_PIN_DETAILS = "GET_PIN_DETAILS";
export const GET_PIN_DETAILS_SUCCESS = "GET_PIN_DETAILS_SUCCESS";
export const GET_PIN_DETAILS_FAIL = "GET_PIN_DETAILS_FAIL";

export const RESET_PIN_FLAG = "RESET_PIN_FLAG";

export const DELETE_PIN = "DELETE_PIN";
export const DELETE_PIN_SUCCESS = "DELETE_PIN_SUCCESS";
export const DELETE_PIN_FAIL = "DELETE_PIN_FAIL";

export const STATUS_CHANGE_PIN = "STATUS_CHANGE_PIN";
export const STATUS_CHANGE_PIN_SUCCESS = "STATUS_CHANGE_PIN_SUCCESS";
export const STATUS_CHANGE_PIN_FAIL = "STATUS_CHANGE_PIN_FAIL";

export const SAVE_ORDERING_PIN = "SAVE_ORDERING_PIN";
export const SAVE_ORDERING_PIN_SUCCESS = "SAVE_ORDERING_PIN_SUCCESS";
export const SAVE_ORDERING_PIN_FAIL = "SAVE_ORDERING_PIN_FAIL";
export const SAVE_ORDERING_PIN_RESET_FLAG = "SAVE_ORDERING_PIN_RESET_FLAG";

export const MODIFY_PIN_FLAG = "MODIFY_PIN_FLAG";