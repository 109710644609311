import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getFutureTeam,
  deleteFutureTeam,
  statusChangeFutureTeam,
  modifyFutureTeamFlag,
  saveOrderingFutureTeam,
  saveOrderingFutureTeamResetFlag,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Moment from "react-moment";
import "moment-timezone";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";

const FutureTeamList = () => {
  document.title = "FutureTeam list | Defendit - Admin Panel";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [featured, setFeatured] = useState("");
  const [date, setDate] = useState(null);
  const [sortByField, setSortByField] = useState("ordering");
  const [sortByValue, setSortByValue] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const dispatch = useDispatch();
  const {
    futureteam,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFaild,
    saveOrderingSuccess,
    saveOrderingFaild,
  } = useSelector((state) => ({
    futureteam: state.FutureTeam.futureteam,
    totalDataCount: state.FutureTeam.totalDataCount,
    isSuccess: state.FutureTeam.isSuccess,
    statusSuccess: state.FutureTeam.statusSuccess,
    statusFaild: state.FutureTeam.statusFaild,
    error: state.FutureTeam.error,
    success: state.FutureTeam.success,
    saveOrderingSuccess: state.FutureTeam.saveOrderingSuccess,
    saveOrderingFaild: state.FutureTeam.saveOrderingFaild,
  }));

  useEffect(() => {
    dispatch(
      getFutureTeam({
        keyword,
        date,
        type,
        status,
        featured,
        sortByField,
        sortByValue,
        limit,
        offset,
      })
    );
  }, [
    dispatch,
    keyword,
    date,
    type,
    status,
    featured,
    sortByField,
    sortByValue,
    limit,
    offset,
  ]);


  // useEffect(() => {
  //   dispatch(
  //     getFutureTeam({ keyword, sortByField, sortByValue, limit, offset })
  //   );
  // }, [dispatch, keyword, sortByField, sortByValue, limit, offset]);

  // useEffect(() => {
  //   let dateRange = "";
  //   if (date && date.e && date.e[0] && date.e[1]) {
  //     dateRange = {
  //       start: date.e[0],
  //       end: date.e[1],
  //     };
  //     dispatch(getFutureTeam({ limit, dateRange, offset, keyword }));
  //   }
  //   // console.log(date);
  // }, [dispatch, date, limit, offset, keyword]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setOffset(newOffset);
  };

  const resetFilter = () => {
    setKeyword("");
    setType("");
    setStatus("");
    setFeatured("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  //On Click Delete Data
  function onClickDeleteData(id) {
    dispatch(deleteFutureTeam({ id }));
  }

  //On Click Status Change
  function onClickStatusChange(id, flag, status) {
    dispatch(statusChangeFutureTeam({ id, flag: flag, status }));
  }

  useEffect(() => {
    if (statusSuccess || statusFaild) {
      setTimeout(() => {
        dispatch(modifyFutureTeamFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFaild]);

  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");

  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
  };

  //Ordring
  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);

  useEffect(() => {
    setDataList(futureteam);
  }, [futureteam]);

  //console.log(items);
  let a = [];
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for (let i = 0; i < tempData.length; i++) {
      dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
    }
    setOrderingData(dataArray);
    onClickSaveOrdering(dataArray);
  };

  //Ordaring
  function onClickSaveOrdering(orderingData) {
    dispatch(
      saveOrderingFutureTeam({
        orderingData: orderingData,
        tableName: "furute_team",
      })
    );
  }

  useEffect(() => {
    if (saveOrderingSuccess || saveOrderingFaild) {
      setTimeout(() => {
        dispatch(saveOrderingFutureTeamResetFlag());
      }, 3000);
    }
  }, [dispatch, saveOrderingSuccess, saveOrderingFaild]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Future Team" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Future Team List
                  </h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to="/future-team/create"
                      className="btn btn-success add-btn"
                      id="createFutureTeamTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createFutureTeamTooltip"
                    >
                      Create Future Team
                    </UncontrolledTooltip>
                    {/* <Button color="danger" id="deleteTooltip">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                      Delete
                    </UncontrolledTooltip> */}
                    <Button
                      color="warning"
                      onClick={resetFilter}
                      id="resetTooltip"
                    >
                      <i className="ri-restart-line me-1 align-bottom"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="resetTooltip">
                      Reset
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(parseInt(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">Per page 10</option>
                          <option value="20">Per page 20</option>
                          <option value="30">Per page 30</option>
                          <option value="40">Per page 40</option>
                          <option value="50">Per page 50</option>
                          <option value="9999999999">All</option>
                        </select>
                      </Col>
                      <Col sm={4} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={status}
                            onChange={(e) => {
                              const value =
                                e.target.value === "true"
                                  ? Boolean(e.target.value)
                                  : e.target.value === "false"
                                  ? false
                                  : "";
                              setStatus(value);
                            }}
                          >
                            <option value="">Select status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={type}
                            onChange={(e) => {
                              const value =
                                e.target.value === "true"
                                  ? 1
                                  : e.target.value === "false"
                                  ? 2
                                  : "";
                              setType(value);
                            }}
                          >
                            <option value="">Select Type</option>
                            <option value="true">Image</option>
                            <option value="false">Video</option>
                          </select>
                        </div>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={featured}
                            onChange={(e) => {
                              const value =
                                e.target.value === "true"
                                  ? Boolean(e.target.value)
                                  : e.target.value === "false"
                                  ? false
                                  : "";
                              setFeatured(value);
                            }}
                          >
                            <option value="">Select Featured</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-2">
                        <div className="d-flex"></div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {futureteam.length ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="title"
                                  onClick={() => sortBy("title")}
                                >
                                  Name
                                </th>
                                <th>Type</th>
                                <th>Featured</th>
                                <th>Status</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <Droppable droppableId="tbody">
                              {(provided) => (
                                <tbody
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="list form-check-all"
                                >
                                  {dataList?.map((item, i) => (
                                    <Draggable
                                      draggableId={item._id}
                                      key={item._id}
                                      index={i}
                                    >
                                      {(provided) => (
                                        <tr
                                          key={item._id}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <td>{item?.name}</td>

                                          <td>
                                            {item?.type && item?.type === 1 ? (
                                              <span>Image</span>
                                            ) : (
                                              <span>Video</span>
                                            )}
                                          </td>

                                          <td>
                                          <button style={{backgroundColor: "white", border: "1px solid white", borderRadius: "1px"}} title="Click to change status" 
                                          onClick={() =>
                                            onClickStatusChange(
                                              item._id,
                                              "featured",
                                              item.featured
                                                ? "false"
                                                : "true"
                                            )
                                          }>
                                            {item?.featured ? (
                                              <span className="badge badge-soft-success text-uppercase">
                                                Yes
                                              </span>
                                            ) : (
                                              <span className="badge badge-soft-danger text-uppercase">
                                                No
                                              </span>
                                            )}
                                            </button>
                                          </td>

                                          <td>
                                          <button style={{backgroundColor: "white", border: "1px solid white", borderRadius: "1px"}} title="Click to change status" onClick={() =>
                                            onClickStatusChange(
                                              item._id,
                                              "status",
                                              item.status
                                                ? "false"
                                                : "true"
                                            )
                                          }>
                                            {item?.status ? <span className="badge badge-soft-success text-uppercase">Active</span>:<span className="badge badge-soft-danger text-uppercase">Inactive</span>}
                                          </button>
                                          </td>

                                          <td>
                                            <Moment format="D MMM YYYY">
                                              {item?.created_at}
                                            </Moment>
                                          </td>

                                          <td>
                                            <ul className="list-inline hstack gap-2 mb-0">
                                              <UncontrolledDropdown>
                                                <DropdownToggle className="dropdown-menu-icon">
                                                  <i className="ri-more-2-fill"></i>
                                                </DropdownToggle>
                                                <DropdownMenu container="body">
                                                  <DropdownItem>
                                                    <Link
                                                      to={`/future-team/edit/${item._id}`}
                                                      className="color-btn-black d-flex align-items-center edit-item-btn"
                                                    >
                                                      <i className="ri-pencil-fill fs-16 mr-2"></i>{" "}
                                                      Edit
                                                    </Link>
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    className="list-inline-item"
                                                    title="Status Change"
                                                  >
                                                    <div
                                                      onClick={() =>
                                                        onClickStatusChange(
                                                          item._id,
                                                          "status",
                                                          item.status
                                                            ? "false"
                                                            : "true"
                                                        )
                                                      }
                                                      className="p-0 bg-transparent border-0 d-flex align-items-center text-warning"
                                                    >
                                                      <i className="ri-exchange-line fs-16 mr-2"></i>
                                                      Status Change
                                                    </div>
                                                    <i className="fs-16"></i>
                                                  </DropdownItem>

                                                  <DropdownItem
                                                    className="list-inline-item"
                                                    title="Featured Change"
                                                  >
                                                    <div
                                                      onClick={() =>
                                                        onClickStatusChange(
                                                          item._id,
                                                          "featured",
                                                          item.featured
                                                            ? "false"
                                                            : "true"
                                                        )
                                                      }
                                                      className="p-0 bg-transparent border-0 d-flex align-items-center text-warning"
                                                    >
                                                      <i className="ri-exchange-line fs-16 mr-2"></i>
                                                      Featured Change
                                                    </div>
                                                    <i className="fs-16"></i>
                                                  </DropdownItem>

                                                  <DropdownItem>
                                                    <div
                                                      className="p-0 bg-transparent border-0 d-flex align-items-center text-danger"
                                                      onClick={() =>
                                                        openDeleteConfrontation(
                                                          item?._id
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-delete-bin-5-fill fs-16 mr-2"></i>{" "}
                                                      Delete
                                                    </div>
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                            </ul>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                          {noOfPage > 1 && (
                            <div className="pagination-inner">
                              <nav
                                className="px-3"
                                aria-label="Page navigation example"
                              >
                                <ReactPaginate
                                  activeClassName="active"
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  containerClassName="pagination"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  breakLabel="..."
                                  nextLabel="Next &nbsp;→"
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={3}
                                  pageCount={noOfPage}
                                  previousLabel="←&nbsp;Prev"
                                  renderOnZeroPageCount={null}
                                />
                              </nav>
                            </div>
                          )}
                        </DragDropContext>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}

                  <Modal
                    isOpen={modal}
                    toggle={openDeleteConfrontation}
                    trigger="click"
                  >
                    <ModalHeader>Delete</ModalHeader>
                    <ModalBody>
                      Are you sure want to delete?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button
                          onClick={() => {
                            setModal(!modal);
                          }}
                          className="btn btn-sm btn-light"
                        >
                          No
                        </Button>

                        <Button
                          onClick={() => {
                            onClickDeleteData(deletedId);
                            setModal(!modal);
                          }}
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {statusSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {statusFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}

          {saveOrderingSuccess ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {saveOrderingFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default FutureTeamList;
