import {
  GET_COMBO,
  GET_COMBO_SUCCESS,
  GET_COMBO_FAIL,
  ADD_NEW_COMBO,
  ADD_NEW_COMBO_SUCCESS,
  ADD_NEW_COMBO_FAIL,
  GET_COMBO_DETAILS,
  GET_COMBO_DETAILS_SUCCESS,
  GET_COMBO_DETAILS_FAIL,
  UPDATE_COMBO,
  UPDATE_COMBO_SUCCESS,
  UPDATE_COMBO_FAIL,
  DELETE_COMBO,
  DELETE_COMBO_SUCCESS,
  DELETE_COMBO_FAIL,
  MODIFY_COMBO_FLAG,
  RESET_COMBO_FLAG,
  STATUS_CHANGE_COMBO,
  STATUS_CHANGE_COMBO_SUCCESS,
  STATUS_CHANGE_COMBO_FAIL
} from "./actionTypes";

export const getCombo = data => ({
  type: GET_COMBO,
  payload: data,
});

export const getComboSuccess = data => ({
  type: GET_COMBO_SUCCESS,
  payload: data,
});

export const getComboFail = error => ({
  type: GET_COMBO_FAIL,
  payload: error,
});

export const addNewCombo = combo => ({
  type: ADD_NEW_COMBO,
  payload: combo,
});

export const addNewComboSuccess = combo => ({
  type: ADD_NEW_COMBO_SUCCESS,
  payload: combo,
});

export const addNewComboFail = error => ({
  type: ADD_NEW_COMBO_FAIL,
  payload: error,
});

export const updateCombo = combo => ({
  type: UPDATE_COMBO,
  payload: combo,
});

export const updateComboSuccess = combo => ({
  type: UPDATE_COMBO_SUCCESS,
  payload: combo,
});

export const updateComboFail = error => ({
  type: UPDATE_COMBO_FAIL,
  payload: error,
});

export const getComboDetails = combo => ({
  type: GET_COMBO_DETAILS,
  payload: combo,
});

export const getComboDetailsSuccess = combo => ({
  type: GET_COMBO_DETAILS_SUCCESS,
  payload: combo,
});

export const getComboDetailsFail = error => ({
  type: GET_COMBO_DETAILS_FAIL,
  payload: error,
});

export const delCombo = data => ({
  type: DELETE_COMBO,
  payload: data,
});

export const deleteComboSuccess = data => ({
  type: DELETE_COMBO_SUCCESS,
  payload: data,
});

export const deleteComboFail = data => ({
  type: DELETE_COMBO_FAIL,
  payload: data,
});
export const statusChangeCombo = combo => ({
  type: STATUS_CHANGE_COMBO,
  payload: combo,
});

export const statusChangeComboSuccess = combo => ({
  type: STATUS_CHANGE_COMBO_SUCCESS,
  payload: combo,
});

export const statusChangeComboFail = error => ({
  type: STATUS_CHANGE_COMBO_FAIL,
  payload: error,
});

export const modifyComboFlag = () => {
  return {
    type: MODIFY_COMBO_FLAG,
  }
}

export const resetComboFlag = () => {
  return {
    type: RESET_COMBO_FLAG,
  }
}
