import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";



// Video 
import categorySaga from "./categories/saga";
import blogSaga from "./blogs/saga";

//Job
import jobSaga from "./jobs/saga";
//User
import userSaga from "./users/saga";
import jobTypeSaga from "./jobtypes/saga";
//Job  Location Type
import jobLocationTypeSaga from "./joblocationtypes/saga";

//Job-Apply
import jobapplySaga from "./jobapplies/saga";

// Banner
import bannerSaga from "./banner/saga";


//Site
import siteSaga from "./sites/saga";

// Page
import pageSaga from "./page/saga";

// SEO
import seoSaga from "./seo/saga";

//Faq
import faqSaga from "./faqs/saga";

//Step
import stepSaga from "./step/saga";

//Pin
import pinSaga from "./pin/saga";

//Funfact
import funfactSaga from "./funfact/saga";

//Testimonial
import testimonialSaga from "./testimonial/saga";

//Service
import serviceSaga from "./service/saga";

//Team
import teamSaga from "./team/saga";

//Mission
import missionSaga from "./mission/saga";

//Vision
import visionSaga from "./vision/saga";

//Highlight
import hightlightSaga from "./highlights/saga";

//Benefit
import benefitSaga from "./benefit/saga";

//Ingredient
import ingredientSaga from "./ingredient/saga";

//Allergie
import allergieSaga from "./allergie/saga";

//Diet
import dietSaga from "./diet/saga";

// Organisation
import OrganizationSaga from "./Organisation/saga";

// Coupon
import couponSaga from "./coupon/saga";

// Author
import authorSaga from "./author/saga";

//Order
import orderSaga from "./order/saga";

//Point
import pointSaga from "./point/saga";

//Spice
import spiceSaga from "./spice/saga";

//Productcategory
import ProductcategorySaga from "./productcategories/saga";

//Product
import ProductSaga from "./products/saga";

//Future Team
import FutureTeamSaga from "./futureteam/saga";

//Future Team
import OpeningHourSaga from "./openinghour/saga";

//Combo Product
import comboSaga from "./combos/saga";

// takeofTime
import takeofTimeSaga from "./takeofTime/saga";


// Notification
import SocketNotificationSaga from "./notification/saga";



export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(categorySaga),
    fork(blogSaga),
    fork(jobSaga),
    fork(userSaga),
    fork(jobTypeSaga),
    fork(jobLocationTypeSaga),
    fork(jobapplySaga),
    fork(bannerSaga),
    fork(teamSaga),
    fork(missionSaga),
    fork(visionSaga),
    fork(siteSaga),
    fork(pageSaga),
    fork(seoSaga),
    fork(faqSaga),
    fork(stepSaga),
    fork(pinSaga),
    fork(spiceSaga),
    fork(OrganizationSaga),
    fork(funfactSaga),
    fork(testimonialSaga),
    fork(serviceSaga),
    fork(benefitSaga),
    fork(hightlightSaga),
    fork(authorSaga),
    fork(ingredientSaga),
    fork(allergieSaga),
    fork(dietSaga),
    fork(orderSaga),
    fork(pointSaga),
    fork(couponSaga),
    fork(ProductcategorySaga),
    fork(ProductSaga),
    fork(FutureTeamSaga),
    fork(OpeningHourSaga),
    fork(comboSaga),
    fork(takeofTimeSaga),
    fork(takeofTimeSaga),
    fork(SocketNotificationSaga),
  ]);
}
