/* USERS */
export const GET_USERS = "GET_USERS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAIL = "ADD_NEW_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

// STATUS CHANGE
export const STATUS_CHANGE_USER = "STATUS_CHANGE_USER";
export const STATUS_CHANGE_USER_SUCCESS = "STATUS_CHANGE_USER_SUCCESS";
export const STATUS_CHANGE_USER_FAIL = "STATUS_CHANGE_USER_FAIL";

export const MODIFY_USER_FLAG = "MODIFY_USER_FLAG";

export const RESET_USER_FLAG = "RESET_USER_FLAG";
export const RESET_ADMIN_FLAG = "RESET_ADMIN_FLAG";