import {
  GET_FAQS_SUCCESS,
  GET_FAQS_FAIL,
  ADD_NEW_FAQ_SUCCESS,
  ADD_NEW_FAQ_FAIL,
  GET_FAQ_DETAILS_SUCCESS,
  GET_FAQ_DETAILS_FAIL,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAIL,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,
  MODIFY_FAQ_FLAG,
  RESET_FAQ_FLAG,
  STATUS_CHANGE_FAQ_SUCCESS,
  STATUS_CHANGE_FAQ_FAIL,

  SAVE_ORDERING_FAQ_SUCCESS,
  SAVE_ORDERING_FAQ_FAIL,
  SAVE_ORDERING_FAQ_RESET_FLAG,

  GET_FAQ_FEEDBACK_SUCCESS,
  GET_FAQ_FEEDBACK_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  faqs: [],
};

const Faqs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAQS_SUCCESS:
      return {
        ...state,
        faqs: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_FAQS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case ADD_NEW_FAQ_SUCCESS:
      return {
        ...state,
        faqs: [...state.faqs, action.payload.data],
        success: action.payload.message,
        isFaqAdd: true,
        isFaqAddFail: false,
      };

    case ADD_NEW_FAQ_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isFaqAdd: false,
        isFaqAddFail: true,
      };

    case UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: state.faqs.map((faq) =>
          faq._id.toString() === action.payload.data._id.toString()
            ? { ...faq, ...action.payload.data }
            : faq
        ),
        success: action.payload.message,
        isFaqUpdate: true,
        isFaqUpdateFail: false,
      };

    case UPDATE_FAQ_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isFaqUpdate: false,
        isFaqUpdateFail: true,
      };

    case GET_FAQ_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_FAQ_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: state.faqs.filter((getData) => {
          return getData._id !== action.payload.data._id;
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isFaqRemove: true,
        isFaqRemoveFail: false,
      };

    case DELETE_FAQ_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isFaqRemove: false,
        isFaqRemoveFail: true,
      };
    //Status Change
    case STATUS_CHANGE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: state.faqs.map((faq) =>
          faq._id.toString() === action.payload.data._id.toString()
            ? { ...faq, ...action.payload.data }
            : faq
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_FAQ_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    //Save Ordering
    case SAVE_ORDERING_FAQ_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_FAQ_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_FAQ_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };




    case RESET_FAQ_FLAG:
      return {
        ...state,
        isFaqAdd: false,
        isFaqAddFail: false,
        isSuccess: false,
        isFaqDetails: false,
        isFaqUpdate: false,
        isFaqUpdateFail: false,
        error: false,
      };
    case MODIFY_FAQ_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };



    case GET_FAQ_FEEDBACK_SUCCESS:
      return {
        ...state,
        faqFeedback: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_FAQ_FEEDBACK_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };



    default:
      return state;
  }
};

export default Faqs;
