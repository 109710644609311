import {
  GET_SPICES,
  GET_SPICES_SUCCESS,
  GET_SPICES_FAIL,
  ADD_NEW_SPICE,
  ADD_NEW_SPICE_SUCCESS,
  ADD_NEW_SPICE_FAIL,
  GET_SPICE_DETAILS,
  GET_SPICE_DETAILS_SUCCESS,
  GET_SPICE_DETAILS_FAIL,
  UPDATE_SPICE,
  UPDATE_SPICE_SUCCESS,
  UPDATE_SPICE_FAIL,
  RESET_SPICE_FLAG,
  DELETE_SPICE,
  DELETE_SPICE_SUCCESS,
  DELETE_SPICE_FAIL,
  STATUS_CHANGE_SPICE,
  STATUS_CHANGE_SPICE_SUCCESS,
  STATUS_CHANGE_SPICE_FAIL,
  MODIFY_SPICE_FLAG,
  SAVE_ORDERING_SPICE,
  SAVE_ORDERING_SPICE_SUCCESS,
  SAVE_ORDERING_SPICE_FAIL,
  SAVE_ORDERING_SPICE_RESET_FLAG,
} from "./actionTypes";

export const getSpices = data => ({
  type: GET_SPICES,
  payload: data,
});

export const getSpicesSuccess = data => ({
  type: GET_SPICES_SUCCESS,
  payload: data,
});

export const getSpicesFail = error => ({
  type: GET_SPICES_FAIL,
  payload: error,
});

export const addNewSpice = spice=> ({
  type: ADD_NEW_SPICE,
  payload: spice,
});

export const addNewSpiceSuccess = spice => ({
  type: ADD_NEW_SPICE_SUCCESS,
  payload: spice,
});

export const addNewSpiceFail = error => ({
  type: ADD_NEW_SPICE_FAIL,
  payload: error,
});

export const updateSpice = spice => ({
  type: UPDATE_SPICE,
  payload: spice,
});

export const updateSpiceSuccess = spice => ({
  type: UPDATE_SPICE_SUCCESS,
  payload: spice,
});

export const updateSpiceFail = error => ({
  type: UPDATE_SPICE_FAIL,
  payload: error,
});

export const getSpiceDetails = spice => ({
  type: GET_SPICE_DETAILS,
  payload: spice,
});

export const getSpiceDetailsSuccess = spice => ({
  type: GET_SPICE_DETAILS_SUCCESS,
  payload: spice,
});

export const getSpiceDetailsFail = error => ({
  type: GET_SPICE_DETAILS_FAIL,
  payload: error,
});

export const resetSpiceFlag = () => {
  return {
    type: RESET_SPICE_FLAG,
  }
}

//Delete
export const deleteSpice = spice => ({
  type: DELETE_SPICE,
  payload: spice,
});

export const deleteSpiceSuccess = spice => ({
  type: DELETE_SPICE_SUCCESS,
  payload: spice,
});

export const deleteSpiceFail = error => ({
  type: DELETE_SPICE_FAIL,
  payload: error,
});

//Status Change
export const statusChangeSpice = spice => ({
  type: STATUS_CHANGE_SPICE,
  payload: spice,
});

export const statusChangeSpiceSuccess = spice => ({
  type: STATUS_CHANGE_SPICE_SUCCESS,
  payload: spice,
});

export const statusChangeSpiceFail = error => ({
  type: STATUS_CHANGE_SPICE_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifySpiceFlag = () => {
  return {
    type: MODIFY_SPICE_FLAG,
  }
}


  //Save Ordaring
  export const saveOrderingSpice = data => ({
    type: SAVE_ORDERING_SPICE,
    payload: data,
  });
  
  export const saveOrderingSpiceSuccess = data => ({
    type: SAVE_ORDERING_SPICE_SUCCESS,
    payload: data,
  });
  
  export const saveOrderingSpiceFail = error => ({
    type: SAVE_ORDERING_SPICE_FAIL,
    payload: error,
  });
  
  
  //Save ordering reset Flag for toaster
  export const saveOrderingSpiceResetFlag = () => {
    return {
      type: SAVE_ORDERING_SPICE_RESET_FLAG,
    }
  }