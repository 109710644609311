/* MISSIONS */
export const GET_MISSIONS = "GET_MISSIONS";
export const GET_MISSIONS_SUCCESS = "GET_MISSIONS_SUCCESS";
export const GET_MISSIONS_FAIL = "GET_MISSIONS_FAIL";

export const ADD_NEW_MISSION = "ADD_NEW_MISSION";
export const ADD_NEW_MISSION_SUCCESS = "ADD_NEW_MISSION_SUCCESS";
export const ADD_NEW_MISSION_FAIL = "ADD_NEW_MISSION_FAIL";

export const UPDATE_MISSION = "UPDATE_MISSION";
export const UPDATE_MISSION_SUCCESS = "UPDATE_MISSION_SUCCESS";
export const UPDATE_MISSION_FAIL = "UPDATE_MISSION_FAIL";

export const GET_MISSION_DETAILS = "GET_MISSION_DETAILS";
export const GET_MISSION_DETAILS_SUCCESS = "GET_MISSION_DETAILS_SUCCESS";
export const GET_MISSION_DETAILS_FAIL = "GET_MISSION_DETAILS_FAIL";

export const RESET_MISSION_FLAG = "RESET_MISSION_FLAG";

export const DELETE_MISSION = "DELETE_MISSION";
export const DELETE_MISSION_SUCCESS = "DELETE_MISSION_SUCCESS";
export const DELETE_MISSION_FAIL = "DELETE_MISSION_FAIL";

export const STATUS_CHANGE_MISSION = "STATUS_CHANGE_MISSION";
export const STATUS_CHANGE_MISSION_SUCCESS = "STATUS_CHANGE_MISSION_SUCCESS";
export const STATUS_CHANGE_MISSION_FAIL = "STATUS_CHANGE_MISSION_FAIL";

export const SAVE_ORDERING_MISSION = "SAVE_ORDERING_MISSION";
export const SAVE_ORDERING_MISSION_SUCCESS = "SAVE_ORDERING_MISSION_SUCCESS";
export const SAVE_ORDERING_MISSION_FAIL = "SAVE_ORDERING_MISSION_FAIL";
export const SAVE_ORDERING_MISSION_RESET_FLAG = "SAVE_ORDERING_MISSION_RESET_FLAG";

export const MODIFY_MISSION_FLAG = "MODIFY_MISSION_FLAG";