import {
    GET_POINTS_SUCCESS,
    GET_POINTS_FAIL,
    ADD_NEW_POINTS_SUCCESS,
    ADD_NEW_POINTS_FAIL,
    GET_POINTS_DETAILS_SUCCESS,
    GET_POINTS_DETAILS_FAIL,
    POINTS_PAYMENT_STATUS_CHANGE_SUCCESS,
    POINTS_PAYMENT_STATUS_CHANGE_FAIL,
    RESET_POINTS_FLAG,
    DELETE_POINTS_SUCCESS,
    DELETE_POINTS_FAIL,
    STATUS_CHANGE_POINTS_SUCCESS,
    STATUS_CHANGE_POINTS_FAIL,
    MODIFY_POINTS_FLAG, 
  } from "./actionTypes";
  
  const INIT_STATE = {
    points: [],
    totalDataCount: 0,
    isSuccess: false,
  };
  
  const Points = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_POINTS_SUCCESS:
        return {
          ...state,
          points: action.payload.data,
          totalDataCount: action.payload.count,
          isSuccess: true
        };
      case GET_POINTS_FAIL:
        return {
          ...state,
          error: action.payload.message,
          totalDataCount: 0,
          isSuccess: false
        };
  
      case ADD_NEW_POINTS_SUCCESS:
        return {
          ...state,
          points: [...state.points, action.payload.data],
          success: action.payload.message,
          isPointAdd: true,
          isPointAddFail: false,
        };
  
      case ADD_NEW_POINTS_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isPointAdd: false,
          isPointAddFail: true,
        };
  
      case POINTS_PAYMENT_STATUS_CHANGE_SUCCESS:
        return {
          ...state,
          points: state.points.map(getData =>
            getData._id.toString() === action.payload.data._id.toString()
              ? { ...getData, ...action.payload.data }
            : getData
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
  
      case POINTS_PAYMENT_STATUS_CHANGE_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
  
      case GET_POINTS_DETAILS_SUCCESS:
        return {
          ...state,
          details: action.payload.data,
        };
  
      case GET_POINTS_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload.message,
        };
      //Delete
      case DELETE_POINTS_SUCCESS:
        return { 
          ...state,
          points: state.points.filter(getData =>
            {
              console.log(getData, action.payload);
              return getData._id !== action.payload.data._id
            }
            
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
    
      case DELETE_POINTS_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
    
        
      //Status Change
      case STATUS_CHANGE_POINTS_SUCCESS:
        return {
          ...state,
          points: state.points.map(getData =>
            getData._id.toString() === action.payload.data._id.toString()
              ? { ...getData, ...action.payload.data }
            : getData
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
          
      case STATUS_CHANGE_POINTS_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
  
      case MODIFY_POINTS_FLAG:
        return {
          ...state,
          statusSuccess: false,
          statusFaild: false,
          error: false
        };
  
      case RESET_POINTS_FLAG:
        return {
          ...state,
          isPointAdd: false,
          isPointAddFail: false,
          isSuccess: false,
          isPointDetails: false,
          isPointUpdate: false,
          isPointUpdateFail: false,
          error: false
        };
  
      default:
        return state;
    }
  };
  
  export default Points;
  