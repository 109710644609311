import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  addNewMission,
  resetMissionFlag,
  getPages,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";
import Select from "react-select";

const MissionCreate = () => {
  document.title = "Create Mission | Dabbawala";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [pagetype, setPagetype] = useState("");
  const [type, setPageType] = useState("");
  const [image, setImage] = useState();
  const [description, setData] = useState();
  const { isMissionAdd, isMissionAddFail, error, success, pages } = useSelector(
    (state) => ({
      isMissionAdd: state.Missions.isMissionAdd,
      isMissionAddFail: state.Missions.isMissionAddFail,
      error: state.Missions.error,
      success: state.Missions.success,
      pages: state.Pages.pages,
    })
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      description: ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description")
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        type: 2,
        title: values.title,
        description: values.description
      };
      dispatch(addNewMission(data));
    },
  });

  useEffect(() => {
    if (isMissionAdd || isMissionAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetMissionFlag());
        history.push('/missions');
      }, 3000);
    }
  }, [dispatch, isMissionAdd, isMissionAddFail, validation, history]);

  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);

  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
    console.log(pages);
  }, [pages]);

  // const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  // const onImg1Change = (e) => {
  //   if (e.target.files[0]) {
  //     const ext = e.target.files[0].type.split("/")[1];

  //     if (![...allowedExts].includes(ext)) {
  //       console.log("Please upload a valid image");
  //       toast("Please upload a valid image.");
  //     } else {
  //       var output = document.getElementById("image1");
  //       output.src = URL.createObjectURL(e.target.files[0]);
  //       output.onload = function () {
  //         URL.revokeObjectURL(output.src); // free memory
  //       };
  //       convertToBase64(e.target.files[0], setImage);
  //     }
  //   }
  // };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Mission"
            pageTitle="Missions"
            pageUrl="/missions"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Mission" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        
                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                            Description*
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              rows="3"
                              className="form-control"
                              placeholder="Enter description"
                              name="description"
                              value={validation.values.description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.description &&
                                validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/missions" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isMissionAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isMissionAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default MissionCreate;
