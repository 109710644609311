import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Table,
  Input,
  Label
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import MsgToast from "../../../Components/Common/MsgToast";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  getProducts,
  delProduct,
  resetProductFlag,
  modifyProductFlag,
  statusChangeProduct,
} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import bin from "../../../assets/images/bin.gif";
import hostUrl from "../../../config";
import axios from "axios";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const ProductList = () => {
  document.title = "Product list | Dabbawala";
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortKey, setSortByField] = useState("created_at");
  const [sortValue, setSortByValue] = useState("DESC");
  const [status, setStatus] = useState("");
  const [active, setActive] = useState("1");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { type } = useParams();
  const {
    products,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
  } = useSelector((state) => ({
    products: state.Products.products,
    totalDataCount: state.Products.totalDataCount,
    isSuccess: state.Products.isSuccess,
    success: state.Products.success,
    error: state.Products.error,
    statusSuccess: state.Products.statusSuccess,
    statusFailed: state.Products.statusFailed,
  }));

  useEffect(() => {
    dispatch(
      getProducts({
        keyword,
        date,
        status,
        sortKey,
        sortValue,
        product_type: parseInt(type),
        limit,
        offset,
      })
    );
  }, [
    dispatch,
    keyword,
    date,
    status,
    sortKey,
    sortValue,
    limit,
    offset,
    type,
  ]);
  // useEffect(() => {
  //   let dateRange = "";
  //   if (date && date.e && date.e[0] && date.e[1]) {
  //     dateRange = {
  //       start: date.e[0],
  //       end: date.e[1],
  //     };
  //     dispatch(getProducts({ limit, dateRange, offset, keyword }));
  //   }
  //   // console.log(date);
  // }, [dispatch, date, limit, offset, keyword]);
  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };
  const deleteProduct = (id) => {
    dispatch(delProduct({ id }));
  };
  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyProductFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);
  function onClickStatusChange(id, status) {
    console.log(id, status);
    dispatch(statusChangeProduct({ id, status }));
  }

  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");

  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
  };

  const [categoryData, setCategoryData] = useState();
  const [categoryWiseList, setCategoryWiseList] = useState();
  const [category, setCategory] = useState({label: "INDIAN DRINKS", value: "6337175b291f914d15765ac7"})

  useEffect(() => {
    axios
    .post(
      `${hostUrl.api.API_URL}product_categories/list`,
      { sortByField: "ordering", sortByValue:1, limit: 99999 },
      {}
    )
    .then(function (response) {
      setCategoryData(response.data);
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    axios
    .post(
      `${hostUrl.api.API_URL}v2/admin/products/category-wise-list`,
      { limit: 99999, product_category_id: category.value, product_ordering: 1, keyword: keyword},
      {}
    )
    .then(function (response) {
      setCategoryWiseList(response.data);
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [category, keyword]);




  function handleCategory(label, value) {
    setCategory({label: label, value: value});
  }
  const [optionCat, setOptionCat] = useState([]);

  useEffect(() => {
    categoryData?.forEach(element => {
      if (categoryData?.length >= optionCat?.length ) {
        optionCat.push({label: element.title, value: element._id})
      }
    });
  }, [categoryData])

  // console.log(optionCat);
  // console.log(category);


  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);

  useEffect(() => {
    setDataList(categoryWiseList?.length > 0 ? categoryWiseList[0]?.product_list : [])
  }, [categoryWiseList]);

  //console.log(items);
  let a = [];
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for(let i = 0; i<tempData.length; i++){
      dataArray.push({ product_id: tempData[i]._id, ordering: i+1 });
    }
    setOrderingData(dataArray);
    //console.log(dataArray);
    onClickSaveOrdering(dataArray);
  };

  const [orderingSuccess, setOrderingSuccess] = useState(false)
//Ordaring
function onClickSaveOrdering(orderingData) {
  // dispatch(saveOrderingSpice({
  //   orderingData: orderingData,
  //   tableName : 'product_ingredient',
  // }));
  axios
    .post(
      `${hostUrl.api.API_URL}category-wise-product-ordering`,
      { orderingData: orderingData, product_category_id: category.value },
      {}
    )
    .then(function (response) {
      //setCategoryWiseList(response.data);
      console.log(response);
      setOrderingSuccess(!orderingSuccess);
      setTimeout(() => {
        setOrderingSuccess(false);
      }, 3000)

      
    })
    .catch(function (error) {
      console.log(error);
    });
  
}



  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title={
              type === "1"
                ? "Food Products"
                : type === "2"
                ? "Add-on products"
                : "Merchandise products"
            }
          />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <Nav
                        className="nav-tabs-custom card-header-tabs border-bottom-0"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={`fw-semibold ${active === "1" ? "active" : ""}`}
                            onClick={() => {
                              setStatus("");
                              setActive("1");
                            }}
                            href="#"
                          >
                            All{" "}
                            <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`fw-semibold ${active === "2" ? "active" : ""}`}
                            onClick={() => {
                              setStatus(parseInt("1"));
                              setActive("2");
                            }}
                            href="#"
                          >
                            Published{" "}
                            <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`fw-semibold ${active === "3" ? "active" : ""}`}
                            onClick={() => {
                              setStatus(parseInt("0"));
                              setActive("3");
                            }}
                            href="#"
                          >
                            Draft
                          </NavLink>
                        </NavItem>
                      </Nav>
                  {/* <h5 className="card-title mb-0 flex-grow-1">Product List</h5> */}

                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to={`/product/create/${type}`}
                      className="btn btn-success add-btn"
                      id="createProductTooltip"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      {type === "1"
                        ? " Food Product"
                        : type === "2"
                        ? " Add-on Product"
                        : " Merchandise Products"}
                    </Link>{" "}
                    <UncontrolledTooltip
                      placement="top"
                      target="createProductTooltip"
                    >
                      Create Food Product
                    </UncontrolledTooltip>
                    {/* <Button color="danger" id="deleteTooltip">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                      Delete
                    </UncontrolledTooltip> */}
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      {/* <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(+e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col> */}
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      {/* <Col sm={2} className="col-xxl-2">
                        <div>
                            <select className="form-select" data-choices data-choices-search-false
                            id="choices-privacy-status-input" onChange={(e) => setStatus(e.target.value)}>
                                <option value="">Select status</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                      </Col> */}

                      {type == 1 && <Col sm={3} className="col-xxl-2">
                        <div>
                        {/* <Input type="select" name="select" id="exampleSelect" onChange={handleChange}>
                          {categoryData && categoryData.map((item, i) => <option key={i} value={item._id} onClick={() => console.log(item._id)}>{item?.title}</option>)}
                        </Input> */}
                        {/* <Label></Label> */}
                        <Select
                          placeholder="Select Category"
                          value={category}
                          onChange={(e) => handleCategory(e.label, e.value)}
                          options={optionCat}
                          name="choices-category-input"
                          classNamePrefix="select2-selection form-select"
                        />
                        </div>
                      </Col>}

                      {/* <Col sm={3} className="col-xxl-2">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col> */}


                      

                      <Col sm={1} className="col-xxl-2">
                        <div className="d-flex">
                          {/* <Button color="primary" onClick={filter} id='filterTooltip'><i className="ri-equalizer-line me-1 align-bottom"></i></Button>
                                                    <UncontrolledTooltip placement="top" target="filterTooltip">Filter</UncontrolledTooltip> */}
                          <Button
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line me-1 align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {products?.length ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Table hover={true}
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th> */}
                                <th>
                                  Product
                                </th>
                                {type === "1" && <th className="sort cursor-pointer"
                                  data-sort="question"
                                  onClick={() => sortBy("category")}>Category</th>}
                                {["1", "2"].includes(type) && <th className="sort cursor-pointer"
                                  data-sort="question"
                                  onClick={() => sortBy("title")}>Regular Price</th>}
                                {["1", "2"].includes(type) && <th className="sort cursor-pointer"
                                  data-sort="question"
                                  onClick={() => sortBy("title")}>Sale Price</th>}
                                {["1"].includes(type) && <th className="sort cursor-pointer"
                                  data-sort="question"
                                  onClick={() => sortBy("title")}>Add-on</th>}
                                <th className="sort cursor-pointer gap-2"
                                  data-sort="question"
                                  onClick={() => sortBy("title")}>Points</th>
                                <th className="sort cursor-pointer"
                                  data-sort="question"
                                  onClick={() => sortBy("title")}>In Stock</th>
                                {/* <th
                                  className="sort cursor-pointer"
                                  data-sort="answer"
                                  onClick={() => sortBy("answer")}
                                >
                                  Answer
                                </th> */}
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th className="sort cursor-pointer"
                                  data-sort="question"
                                  onClick={() => sortBy("title")}>
                                  Last Changed date
                                </th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            {type !== "1" ? <tbody className="list form-check-all">
                              {products?.map((item) => {
                                function createMarkup(data) {
                                  return { __html: data };
                                }
                                return (
                                  <>
                                    <tr key={item._id}>
                                      {/* <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value={item._id}
                                      />
                                    </div>
                                  </th> */}
                                      <td>
                                        <div
                                          className="d-flex align-items-center"
                                          key={item?._id}
                                        >
                                          <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded">
                                              <img
                                                src={item?.image}
                                                alt=""
                                                className="img-fluid d-flex"
                                              />
                                            </div>
                                          </div>

                                          <div className="flex-grow-1">
                                            <h5 className="fs-14 mb-1">
                                              <div className="text-dark">
                                                {" "}
                                                {item.title}
                                              </div>
                                            </h5>
                                            {/* <p className="text-muted mb-0">
                                        Category :{" "}
                                        <span className="fw-medium">
                                          {" "}
                                          {item?.product_category_id?.title}
                                        </span>
                                      </p> */}
                                          </div>
                                        </div>
                                      </td>
                                      {type === "1" && <td>{item?.category.map((item) => (item?.title))}</td>}
                                      {["1", "2"].includes(type) && <td>€ {item?.regular_price} </td>}
                                      {["1", "2"].includes(type) && <td>€ {item?.sale_price}</td>}
                                      {["1"].includes(type) && <td>{item?.has_addon ? "Yes" : "No"}</td>}
                                      <td>{item?.price_point}</td>
                                      <td>{item?.in_stock ? "Yes" : "No"}</td>
                                      {/* <td>{item.answer.length > 40 ? `${item.answer.substring(0, 35)}...` : item.answer}</td> */}
                                      <td>
                                        <Moment format="D MMM YYYY">
                                          {item.created_at}
                                        </Moment>
                                      </td>
                                      <td>
                                        <Moment format="D MMM YYYY">
                                          {item?.updated_at}
                                        </Moment>
                                      </td>
                                      <td>
                                      <button style={{backgroundColor: "white", border: "1px solid white", borderRadius: "5px"}} title="Click to change status" onClick={() =>
                                            onClickStatusChange(
                                              item._id,
                                              item.status ? 0 : 1
                                            )
                                          }>
                                            {item?.status ? <span className="badge badge-soft-success text-uppercase">Active</span>:<span className="badge badge-soft-danger text-uppercase">Inactive</span>}
                                          </button>
                                      </td>
                                      <td>
                                        <ul className="list-inline hstack gap-2 mb-0">
                                          <UncontrolledDropdown>
                                            <DropdownToggle className="dropdown-menu-icon">
                                              <i className="ri-more-2-fill"></i>
                                            </DropdownToggle>
                                            <DropdownMenu container="body">
                                              <DropdownItem>
                                                <Link
                                                  to={
                                                    "/product/edit/" + item._id
                                                  }
                                                  className="color-btn-black d-flex align-items-center edit-item-btn"
                                                >
                                                  <i className="ri-pencil-fill fs-16 mr-2"></i>{" "}
                                                  Edit
                                                </Link>
                                              </DropdownItem>
                                              <DropdownItem
                                                className="list-inline-item"
                                                title="Status Change"
                                              >
                                                <div
                                                  onClick={() =>
                                                    onClickStatusChange(
                                                      item._id,
                                                      item.status ? 0 : 1
                                                    )
                                                  }
                                                  className="p-0 bg-transparent border-0 text-warning d-flex align-items-center"
                                                >
                                                  <i className="ri-exchange-line fs-16 mr-2"></i>
                                                  Status Change
                                                </div>
                                                <i className="fs-16"></i>
                                              </DropdownItem>

                                              <DropdownItem>
                                                <div
                                                  className="p-0 bg-transparent border-0 d-flex align-items-center text-danger"
                                                  onClick={() =>
                                                    openDeleteConfrontation(
                                                      item?._id
                                                    )
                                                  }
                                                >
                                                  <i className="ri-delete-bin-5-fill fs-16 mr-2"></i>{" "}
                                                  Delete
                                                </div>
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                          {/* <li
                                        className="list-inline-item edit"
                                        title="Edit"
                                      >
                                        <Link
                                          to={`/product/edit/${item._id}`}
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-pencil-fill fs-16"></i>
                                        </Link>
                                      </li>
                                      <li
                                        className="list-inline-item"
                                        title="Remove"
                                        id={"deletePopover" + item._id}
                                      >
                                        <Button className="p-0 bg-transparent border-0 text-danger">
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </Button>
                                      </li>
                                      <UncontrolledPopover
                                        trigger="focus"
                                        placement="left"
                                        target={"deletePopover" + item._id}
                                      >
                                        <PopoverHeader>
                                          Delete Product List
                                        </PopoverHeader>
                                        <PopoverBody>
                                          Are you sure want to delete?
                                          <div className="d-flex align-items-center justify-content-end mt-2">
                                            <Button className="btn btn-sm btn-light">
                                              No
                                            </Button>
                                            <Button
                                              onClick={() =>
                                                deleteProduct(item._id)
                                              }
                                              className="btn btn-sm btn-danger ms-2"
                                            >
                                              Yes
                                            </Button>
                                          </div>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                      <li
                                        className="list-inline-item"
                                        title="Status Change"
                                      >
                                        <Button
                                         onClick={() =>
                                          onClickStatusChange(
                                            item._id,
                                            item.status &&
                                              item.status === true
                                              ? false
                                              : true
                                          )
                                        }
                                          className="p-0 bg-transparent border-0 text-warning"
                                        >
                                          <i className="ri-exchange-line fs-16"></i>
                                        </Button>
                                      </li> */}
                                        </ul>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody> : 
                            <Droppable droppableId="tbody">
                            {
                              (provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps} className="list form-check-all">
                              
                                {dataList?.map((item, k) => { 
                                  return (
                                    <Draggable draggableId={item._id} key={item._id} index={k}>
                                {(provided) => (
                                      <tr key={item._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                        {/* <th scope="row">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="chk_child"
                                          value={item._id}
                                        />
                                      </div>
                                    </th> */}
                                        <td>
                                          <div
                                            className="d-flex align-items-center"
                                            key={item?._id}
                                          >
                                            <div className="flex-shrink-0 me-3">
                                              <div className="avatar-sm bg-light rounded">
                                                <img
                                                  src={item?.image}
                                                  alt=""
                                                  className="img-fluid d-flex"
                                                />
                                              </div>
                                            </div>
  
                                            <div className="flex-grow-1">
                                              <h5 className="fs-14 mb-1">
                                                <div className="text-dark">
                                                  {" "}
                                                  {item.title}
                                                </div>
                                              </h5>
                                              {/* <p className="text-muted mb-0">
                                          Category :{" "}
                                          <span className="fw-medium">
                                            {" "}
                                            {item?.product_category_id?.title}
                                          </span>
                                        </p> */}
                                            </div>
                                          </div>
                                        </td>
                                        {type === "1" && <td>{category.label}</td>}
                                        {["1", "2"].includes(type) && <td>€ {item?.regular_price} </td>}
                                        {["1", "2"].includes(type) && <td>€ {item?.sale_price}</td>}
                                        {["1"].includes(type) && <td>{item?.has_addon ? "Yes" : "No"}</td>}
                                        <td>{item?.price_point}</td>
                                        <td>{item?.in_stock ? "Yes" : "No"}</td>
                                        {/* <td>{item.answer.length > 40 ? `${item.answer.substring(0, 35)}...` : item.answer}</td> */}
                                        <td>
                                          <Moment format="D MMM YYYY">
                                            {item.created_at}
                                          </Moment>
                                        </td>
                                        <td>
                                          <Moment format="D MMM YYYY">
                                            {item?.updated_at}
                                          </Moment>
                                        </td>
                                        <td>
                                        <button style={{backgroundColor: "white", border: "1px solid white", borderRadius: "5px"}} title="Click to change status" onClick={() =>
                                              onClickStatusChange(
                                                item._id,
                                                item.status ? 0 : 1
                                              )
                                            }>
                                              {item?.status ? <span className="badge badge-soft-success text-uppercase">Active</span>:<span className="badge badge-soft-danger text-uppercase">Inactive</span>}
                                            </button>
                                        </td>
                                        <td>
                                          <ul className="list-inline hstack gap-2 mb-0">
                                            <UncontrolledDropdown>
                                              <DropdownToggle className="dropdown-menu-icon">
                                                <i className="ri-more-2-fill"></i>
                                              </DropdownToggle>
                                              <DropdownMenu container="body">
                                                <DropdownItem>
                                                  <Link
                                                    to={
                                                      "/product/edit/" + item._id
                                                    }
                                                    className="color-btn-black d-flex align-items-center edit-item-btn"
                                                  >
                                                    <i className="ri-pencil-fill fs-16 mr-2"></i>{" "}
                                                    Edit
                                                  </Link>
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="list-inline-item"
                                                  title="Status Change"
                                                >
                                                  <div
                                                    onClick={() =>
                                                      onClickStatusChange(
                                                        item._id,
                                                        item.status ? 0 : 1
                                                      )
                                                    }
                                                    className="p-0 bg-transparent border-0 text-warning d-flex align-items-center"
                                                  >
                                                    <i className="ri-exchange-line fs-16 mr-2"></i>
                                                    Status Change
                                                  </div>
                                                  <i className="fs-16"></i>
                                                </DropdownItem>
  
                                                <DropdownItem>
                                                  <div
                                                    className="p-0 bg-transparent border-0 d-flex align-items-center text-danger"
                                                    onClick={() =>
                                                      openDeleteConfrontation(
                                                        item?._id
                                                      )
                                                    }
                                                  >
                                                    <i className="ri-delete-bin-5-fill fs-16 mr-2"></i>{" "}
                                                    Delete
                                                  </div>
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </ul>
                                        </td>
                                      </tr>
                                      )}
                                      </Draggable>
                                  );
                                })}
                                
                                {provided.placeholder}
                            </tbody>)
                            }
                            </Droppable>
                          }
                          </Table>
                          {type !== "1" && noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </DragDropContext>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                  <Modal
                    isOpen={modal}
                    toggle={openDeleteConfrontation}
                    trigger="click"
                    centered={true}
                  >
                    <ModalBody className="py-3 px-5">
                      <div className="mt-2 text-center">
                        {/* <lord-icon
                        src="https://cdn.lordicon.com/exkbusmy.json"
                        trigger="loop"
                        style={{width:"250px", height:"250px"}}>
                    </lord-icon> */}
                        <img src={bin} alt="gif" />
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                          <h4>Are you sure ?</h4>
                          <p className="text-muted mx-4 mb-0">
                            Are you sure you want to remove this record ?
                          </p>
                        </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                          type="button"
                          className="btn w-sm btn-light"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setModal(!modal);
                          }}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn w-sm btn-danger "
                          id="delete-record"
                          onClick={() => {
                            deleteProduct(deletedId);
                            setModal(!modal);
                          }}
                        >
                          Yes, Delete It!
                        </button>
                      </div>
                    </ModalBody>
                    {/* <ModalHeader>
                      Delete
                    </ModalHeader>
                    <ModalBody>
                      Are you sure want to delete?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button 
                          onClick={ () =>
                            {
                              setModal(!modal);
                            }
                          }
                          className="btn btn-sm btn-light">
                          No
                        </Button>

                        <Button
                          onClick={ () =>
                            {
                              deleteProduct(deletedId);
                              setModal(!modal);
                            }
                          }
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody> */}
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
        {orderingSuccess ? (
          <MsgToast
            msg='Ordering changed'
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
      </div>
    </>
  );
};

export default ProductList;
