import {
  GET_PAGES_SUCCESS,
  GET_PAGES_FAIL,
  ADD_NEW_PAGE_SUCCESS,
  ADD_NEW_PAGE_FAIL,
  GET_PAGE_DETAILS_SUCCESS,
  GET_PAGE_DETAILS_FAIL,
  UPDATE_PAGE_SUCCESS,
  UPDATE_PAGE_FAIL,
  RESET_PAGE_FLAG
} from "./actionTypes";

const INIT_STATE = {
  pages: []
};

const Pages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_PAGES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_PAGE_SUCCESS:
      return {
        ...state,
        pages: [...state.pages, action.payload.data],
        success: action.payload.message,
        isPageAdd: true,
        isPageAddFail: false,
      };

    case ADD_NEW_PAGE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isPageAdd: false,
        isPageAddFail: true,
      };

    case UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        pages: state.pages.map(page =>
          page._id.toString() === action.payload.data._id.toString()
            ? { ...page, ...action.payload.data }
            : page
        ),
        success: action.payload.message,
        isPageUpdate: true,
        isPageUpdateFail: false
      };

    case UPDATE_PAGE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isPageUpdate: false,
        isPageUpdateFail: true,
      };

    case GET_PAGE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess:true,
      };

    case GET_PAGE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case RESET_PAGE_FLAG:
      return {
        ...state,
        isPageAdd: false,
        isPageAddFail: false,
        isSuccess: false,
        isPageDetails: false,
        isPageUpdate: false,
        isPageUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Pages;
