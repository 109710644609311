import {
  GET_FAQS,
  GET_FAQS_SUCCESS,
  GET_FAQS_FAIL,
  ADD_NEW_FAQ,
  ADD_NEW_FAQ_SUCCESS,
  ADD_NEW_FAQ_FAIL,
  GET_FAQ_DETAILS,
  GET_FAQ_DETAILS_SUCCESS,
  GET_FAQ_DETAILS_FAIL,
  UPDATE_FAQ,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAIL,
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,
  MODIFY_FAQ_FLAG,
  RESET_FAQ_FLAG,
  STATUS_CHANGE_FAQ,
  STATUS_CHANGE_FAQ_SUCCESS,
  STATUS_CHANGE_FAQ_FAIL,

  SAVE_ORDERING_FAQ,
  SAVE_ORDERING_FAQ_SUCCESS,
  SAVE_ORDERING_FAQ_FAIL,
  SAVE_ORDERING_FAQ_RESET_FLAG,

  GET_FAQ_FEEDBACK,
  GET_FAQ_FEEDBACK_SUCCESS,
  GET_FAQ_FEEDBACK_FAIL,

} from "./actionTypes";

export const getFaqs = data => ({
  type: GET_FAQS,
  payload: data,
});

export const getFaqsSuccess = data => ({
  type: GET_FAQS_SUCCESS,
  payload: data,
});

export const getFaqsFail = error => ({
  type: GET_FAQS_FAIL,
  payload: error,
});

export const addNewFaq = faq => ({
  type: ADD_NEW_FAQ,
  payload: faq,
});

export const addNewFaqSuccess = faq => ({
  type: ADD_NEW_FAQ_SUCCESS,
  payload: faq,
});

export const addNewFaqFail = error => ({
  type: ADD_NEW_FAQ_FAIL,
  payload: error,
});

export const updateFaq = faq => ({
  type: UPDATE_FAQ,
  payload: faq,
});

export const updateFaqSuccess = faq => ({
  type: UPDATE_FAQ_SUCCESS,
  payload: faq,
});

export const updateFaqFail = error => ({
  type: UPDATE_FAQ_FAIL,
  payload: error,
});

export const getFaqDetails = faq => ({
  type: GET_FAQ_DETAILS,
  payload: faq,
});

export const getFaqDetailsSuccess = faq => ({
  type: GET_FAQ_DETAILS_SUCCESS,
  payload: faq,
});

export const getFaqDetailsFail = error => ({
  type: GET_FAQ_DETAILS_FAIL,
  payload: error,
});

export const delFaq = data => ({
  type: DELETE_FAQ,
  payload: data,
});

export const deleteFaqSuccess = data => ({
  type: DELETE_FAQ_SUCCESS,
  payload: data,
});

export const deleteFaqFail = data => ({
  type: DELETE_FAQ_FAIL,
  payload: data,
});
export const statusChangeFaq = faq => ({
  type: STATUS_CHANGE_FAQ,
  payload: faq,
});

export const statusChangeFaqSuccess = faq => ({
  type: STATUS_CHANGE_FAQ_SUCCESS,
  payload: faq,
});

export const statusChangeFaqFail = error => ({
  type: STATUS_CHANGE_FAQ_FAIL,
  payload: error,
});

export const modifyFaqFlag = () => {
  return {
    type: MODIFY_FAQ_FLAG,
  }
}

export const resetFaqFlag = () => {
  return {
    type: RESET_FAQ_FLAG,
  }
}


//Save Ordaring
export const saveOrderingFaq = data => ({
  type: SAVE_ORDERING_FAQ,
  payload: data,
});

export const saveOrderingFaqSuccess = data => ({
  type: SAVE_ORDERING_FAQ_SUCCESS,
  payload: data,
});

export const saveOrderingFaqFail = error => ({
  type: SAVE_ORDERING_FAQ_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingFaqResetFlag = () => {
  return {
    type: SAVE_ORDERING_FAQ_RESET_FLAG,
  }
}


//GET FAQ FEEDBACK
export const getFaqFeedback = data => ({
  type: GET_FAQ_FEEDBACK,
  payload: data,
});

export const getFaqFeedbackSuccess = data => ({
  type: GET_FAQ_FEEDBACK_SUCCESS,
  payload: data,
});

export const getFaqFeedbackFail = error => ({
  type: GET_FAQ_FEEDBACK_FAIL,
  payload: error,
});