import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_COMBO,
  ADD_NEW_COMBO,
  GET_COMBO_DETAILS,
  UPDATE_COMBO,
  DELETE_COMBO,
  STATUS_CHANGE_COMBO,
} from "./actionTypes"

import {
  getComboSuccess,
  getComboFail,
  addNewComboSuccess,
  addNewComboFail,
  getComboDetailsSuccess,
  getComboDetailsFail,
  updateComboSuccess,
  updateComboFail,
  deleteComboSuccess,
  deleteComboFail,
  statusChangeComboSuccess,
  statusChangeComboFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getComboList,
  addNewCombo,
  getComboDetails,
  updateCombo,
  delCombo,
  statusChangeCombo,
} from "../../helpers/fakebackend_helper"

function* fetchCombo({ payload: combo }) {
  try {
    const response = yield call(getComboList, combo)
    yield put(getComboSuccess(response))
  } catch (error) {
    yield put(getComboFail(error))
  }
}

export function* watchFetchCombo() {
  yield takeEvery(GET_COMBO, fetchCombo);
}

function* onAddNewCombo({ payload: combo }) {
  try {
      const response = yield call(addNewCombo, combo);
      if(response.status === 200){
        yield put(addNewComboSuccess(response));
      } else {
        yield put(addNewComboFail(response));
      }
  } catch (error) {
      yield put(addNewComboFail(error));
  }
}

export function* watchAddNewCombo() {
  yield takeEvery(ADD_NEW_COMBO, onAddNewCombo);
}

function* onComboDetails({ payload: combo }) {
  try {
      const response = yield call(getComboDetails, combo);
      if(response.status === 200){
        yield put(getComboDetailsSuccess(response));
      } else {
        yield put(getComboDetailsFail(response));
      }
  } catch (error) {
      yield put(getComboDetailsFail(error));
  }
}

export function* watchComboDetails() {
  yield takeEvery(GET_COMBO_DETAILS, onComboDetails);
}

function* onComboUpdate({ payload: combo }) {
  try {
      const response = yield call(updateCombo, combo);
      if(response.status === 200){
        yield put(updateComboSuccess(response));
      } else {
        yield put(updateComboFail(response));
      }
  } catch (error) {
      yield put(updateComboFail(error));
  }
}

export function* watchUpdateCombo() {
  yield takeEvery(UPDATE_COMBO, onComboUpdate);
}

function* onDeleteCombo({payload : data}) {
  try {
    const response = yield call(delCombo,data)
    yield put(deleteComboSuccess(response))
  } catch (error) {
    yield put(deleteComboFail(error))
  }
}

export function* watchDeleteCombo() {
  yield takeEvery(DELETE_COMBO, onDeleteCombo);
}
//Status Change
function* onStatusChangeCombo({ payload: combo }) {
  try {
    const response = yield call(statusChangeCombo, combo);
    yield put(statusChangeComboSuccess({ combo, ...response }));
  } catch (error) {
    yield put(statusChangeComboFail(error));
  }
}

export function* watchStatusChangeCombo() {
  yield takeEvery(STATUS_CHANGE_COMBO, onStatusChangeCombo);
}

function* comboSaga() {
  yield all(
    [
      fork(watchFetchCombo),
      fork(watchAddNewCombo),
      fork(watchComboDetails),
      fork(watchUpdateCombo),
      fork(watchDeleteCombo),
      fork(watchStatusChangeCombo),
    ]
  );

}

export default comboSaga
