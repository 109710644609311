import {
  GET_PINS_SUCCESS,
  GET_PINS_FAIL,
  ADD_NEW_PIN_SUCCESS,
  ADD_NEW_PIN_FAIL,
  GET_PIN_DETAILS_SUCCESS,
  GET_PIN_DETAILS_FAIL,
  UPDATE_PIN_SUCCESS,
  UPDATE_PIN_FAIL,
  RESET_PIN_FLAG,
  DELETE_PIN_SUCCESS,
  DELETE_PIN_FAIL,
  STATUS_CHANGE_PIN_SUCCESS,
  STATUS_CHANGE_PIN_FAIL,
  MODIFY_PIN_FLAG, 

  SAVE_ORDERING_PIN_SUCCESS,
  SAVE_ORDERING_PIN_FAIL,
  SAVE_ORDERING_PIN_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  pins: []
};

const Pins = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PINS_SUCCESS:
      return {
        ...state,
        pins: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_PINS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_PIN_SUCCESS:
      return {
        ...state,
        pins: [...state.pins, action.payload.data],
        success: action.payload.message,
        isPinAdd: true,
        isPinAddFail: false,
      };

    case ADD_NEW_PIN_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isPinAdd: false,
        isPinAddFail: true,
      };

    case UPDATE_PIN_SUCCESS:
      return {
        ...state,
        pins: state.pins.map(pin =>
          pin._id.toString() === action.payload.data._id.toString()
            ? { ...pin, ...action.payload.data }
            : pin
        ),
        success: action.payload.message,
        isPinUpdate: true,
        isPinUpdateFail: false
      };

    case UPDATE_PIN_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isPinUpdate: false,
        isPinUpdateFail: true,
      };

    case GET_PIN_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_PIN_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_PIN_SUCCESS:
      return { 
        ...state,
        pins: state.pins.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_PIN_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_PIN_SUCCESS:
      return {
        ...state,
        pins: state.pins.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_PIN_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    //Save Ordering
    case SAVE_ORDERING_PIN_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_PIN_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_PIN_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    case MODIFY_PIN_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_PIN_FLAG:
      return {
        ...state,
        isPinAdd: false,
        isPinAddFail: false,
        isSuccess: false,
        isPinDetails: false,
        isPinUpdate: false,
        isPinUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Pins;
