import {
  GET_INGREDIENTS,
  GET_INGREDIENTS_SUCCESS,
  GET_INGREDIENTS_FAIL,
  ADD_NEW_INGREDIENT,
  ADD_NEW_INGREDIENT_SUCCESS,
  ADD_NEW_INGREDIENT_FAIL,
  GET_INGREDIENT_DETAILS,
  GET_INGREDIENT_DETAILS_SUCCESS,
  GET_INGREDIENT_DETAILS_FAIL,
  UPDATE_INGREDIENT,
  UPDATE_INGREDIENT_SUCCESS,
  UPDATE_INGREDIENT_FAIL,
  RESET_INGREDIENT_FLAG,
  DELETE_INGREDIENT,
  DELETE_INGREDIENT_SUCCESS,
  DELETE_INGREDIENT_FAIL,
  STATUS_CHANGE_INGREDIENT,
  STATUS_CHANGE_INGREDIENT_SUCCESS,
  STATUS_CHANGE_INGREDIENT_FAIL,
  MODIFY_INGREDIENT_FLAG
} from "./actionTypes";

export const getIngredients = data => ({
  type: GET_INGREDIENTS,
  payload: data,
});

export const getIngredientsSuccess = data => ({
  type: GET_INGREDIENTS_SUCCESS,
  payload: data,
});

export const getIngredientsFail = error => ({
  type: GET_INGREDIENTS_FAIL,
  payload: error,
});

export const addNewIngredient = ingredient=> ({
  type: ADD_NEW_INGREDIENT,
  payload: ingredient,
});

export const addNewIngredientSuccess = ingredient => ({
  type: ADD_NEW_INGREDIENT_SUCCESS,
  payload: ingredient,
});

export const addNewIngredientFail = error => ({
  type: ADD_NEW_INGREDIENT_FAIL,
  payload: error,
});

export const updateIngredient = ingredient => ({
  type: UPDATE_INGREDIENT,
  payload: ingredient,
});

export const updateIngredientSuccess = ingredient => ({
  type: UPDATE_INGREDIENT_SUCCESS,
  payload: ingredient,
});

export const updateIngredientFail = error => ({
  type: UPDATE_INGREDIENT_FAIL,
  payload: error,
});

export const getIngredientDetails = ingredient => ({
  type: GET_INGREDIENT_DETAILS,
  payload: ingredient,
});

export const getIngredientDetailsSuccess = ingredient => ({
  type: GET_INGREDIENT_DETAILS_SUCCESS,
  payload: ingredient,
});

export const getIngredientDetailsFail = error => ({
  type: GET_INGREDIENT_DETAILS_FAIL,
  payload: error,
});

export const resetIngredientFlag = () => {
  return {
    type: RESET_INGREDIENT_FLAG,
  }
}

//Delete
export const deleteIngredient = ingredient => ({
  type: DELETE_INGREDIENT,
  payload: ingredient,
});

export const deleteIngredientSuccess = ingredient => ({
  type: DELETE_INGREDIENT_SUCCESS,
  payload: ingredient,
});

export const deleteIngredientFail = error => ({
  type: DELETE_INGREDIENT_FAIL,
  payload: error,
});

//Status Change
export const statusChangeIngredient = ingredient => ({
  type: STATUS_CHANGE_INGREDIENT,
  payload: ingredient,
});

export const statusChangeIngredientSuccess = ingredient => ({
  type: STATUS_CHANGE_INGREDIENT_SUCCESS,
  payload: ingredient,
});

export const statusChangeIngredientFail = error => ({
  type: STATUS_CHANGE_INGREDIENT_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyIngredientFlag = () => {
  return {
    type: MODIFY_INGREDIENT_FLAG,
  }
}