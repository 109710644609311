import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_SPICES,
  ADD_NEW_SPICE,
  GET_SPICE_DETAILS,
  UPDATE_SPICE,
  DELETE_SPICE,
  STATUS_CHANGE_SPICE,
  SAVE_ORDERING_SPICE
} from "./actionTypes"

import {
  getSpicesSuccess,
  getSpicesFail,
  addNewSpiceSuccess,
  addNewSpiceFail,
  getSpiceDetailsSuccess,
  getSpiceDetailsFail,
  updateSpiceSuccess,
  updateSpiceFail,
  deleteSpiceSuccess,
  deleteSpiceFail,
  statusChangeSpiceSuccess,
  statusChangeSpiceFail,
  saveOrderingSpiceSuccess,
  saveOrderingSpiceFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSpiceList,
  addNewSpice,
  getSpiceDetails,
  updateSpice,
  deleteSpice,
  statusChangeSpice,
  saveOrdering
} from "../../helpers/fakebackend_helper"

function* fetchSpices({ payload: spice }) {
  try {
    const response = yield call(getSpiceList, spice)
    yield put(getSpicesSuccess(response))
  } catch (error) {
    yield put(getSpicesFail(error))
  }
}

export function* watchFetchSpices() {
  yield takeEvery(GET_SPICES, fetchSpices);
}

function* onAddNewSpice({ payload: spice }) {
  try {
      const response = yield call(addNewSpice, spice);
      if(response.status === 200){
        yield put(addNewSpiceSuccess(response));
      } else {
        yield put(addNewSpiceFail(response));
      }
  } catch (error) {
      yield put(addNewSpiceFail(error));
  }
}

export function* watchAddNewSpice() {
  yield takeEvery(ADD_NEW_SPICE, onAddNewSpice);
}

function* onSpiceDetails({ payload: spice }) {
  try {
      const response = yield call(getSpiceDetails, spice);
      if(response.status === 200){
        yield put(getSpiceDetailsSuccess(response));
      } else {
        yield put(getSpiceDetailsFail(response));
      }
  } catch (error) {
      yield put(getSpiceDetailsFail(error));
  }
}

export function* watchSpiceDetails() {
  yield takeEvery(GET_SPICE_DETAILS, onSpiceDetails);
}

function* onSpiceUpdate({ payload: spice }) {
  try {
      const response = yield call(updateSpice, spice);
      if(response.status === 200){
        yield put(updateSpiceSuccess(response));
      } else {
        yield put(updateSpiceFail(response));
      }
  } catch (error) {
      yield put(updateSpiceFail(error));
  }
}

export function* watchUpdateSpice() {
  yield takeEvery(UPDATE_SPICE, onSpiceUpdate);
}


//Delete Data
function* onDeleteSpice({ payload: spice }) {
  try {
    const response = yield call(deleteSpice, spice);
    yield put(deleteSpiceSuccess({ spice, ...response }));
  } catch (error) {
    yield put(deleteSpiceFail(error));
  }
}

export function* watchDeleteSpice() {
  yield takeEvery(  DELETE_SPICE, onDeleteSpice);
}



//Status Change
function* onStatusChangSpice({ payload: spice }) {
  try {
    const response = yield call(statusChangeSpice, spice);
    yield put(statusChangeSpiceSuccess({ spice, ...response }));
  } catch (error) {
    yield put(statusChangeSpiceFail(error));
  }
}

export function* watchStatusChangeSpice() {
  yield takeEvery(STATUS_CHANGE_SPICE, onStatusChangSpice);
}



//Save Ordering
function* onSaveOrderingSpice({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingSpiceSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingSpiceFail(error));
  }
}

export function* watchSaveOrderingSpice() {
  yield takeEvery(SAVE_ORDERING_SPICE, onSaveOrderingSpice);
}



function* spiceSaga() {
  yield all(
    [
      fork(watchFetchSpices),
      fork(watchAddNewSpice),
      fork(watchSpiceDetails),
      fork(watchUpdateSpice),
      fork(watchDeleteSpice),
      fork(watchStatusChangeSpice),
      fork(watchSaveOrderingSpice),
    ]
  );

}

export default spiceSaga
