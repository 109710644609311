import {
  GET_PINS,
  GET_PINS_SUCCESS,
  GET_PINS_FAIL,
  ADD_NEW_PIN,
  ADD_NEW_PIN_SUCCESS,
  ADD_NEW_PIN_FAIL,
  GET_PIN_DETAILS,
  GET_PIN_DETAILS_SUCCESS,
  GET_PIN_DETAILS_FAIL,
  UPDATE_PIN,
  UPDATE_PIN_SUCCESS,
  UPDATE_PIN_FAIL,
  RESET_PIN_FLAG,
  DELETE_PIN,
  DELETE_PIN_SUCCESS,
  DELETE_PIN_FAIL,
  STATUS_CHANGE_PIN,
  STATUS_CHANGE_PIN_SUCCESS,
  STATUS_CHANGE_PIN_FAIL,
  MODIFY_PIN_FLAG,

  SAVE_ORDERING_PIN,
  SAVE_ORDERING_PIN_SUCCESS,
  SAVE_ORDERING_PIN_FAIL,
  SAVE_ORDERING_PIN_RESET_FLAG,

} from "./actionTypes";

export const getPins = data => ({
  type: GET_PINS,
  payload: data,
});

export const getPinsSuccess = data => ({
  type: GET_PINS_SUCCESS,
  payload: data,
});

export const getPinsFail = error => ({
  type: GET_PINS_FAIL,
  payload: error,
});

export const addNewPin = pin=> ({
  type: ADD_NEW_PIN,
  payload: pin,
});

export const addNewPinSuccess = pin => ({
  type: ADD_NEW_PIN_SUCCESS,
  payload: pin,
});

export const addNewPinFail = error => ({
  type: ADD_NEW_PIN_FAIL,
  payload: error,
});

export const updatePin = pin => ({
  type: UPDATE_PIN,
  payload: pin,
});

export const updatePinSuccess = pin => ({
  type: UPDATE_PIN_SUCCESS,
  payload: pin,
});

export const updatePinFail = error => ({
  type: UPDATE_PIN_FAIL,
  payload: error,
});

export const getPinDetails = pin => ({
  type: GET_PIN_DETAILS,
  payload: pin,
});

export const getPinDetailsSuccess = pin => ({
  type: GET_PIN_DETAILS_SUCCESS,
  payload: pin,
});

export const getPinDetailsFail = error => ({
  type: GET_PIN_DETAILS_FAIL,
  payload: error,
});

export const resetPinFlag = () => {
  return {
    type: RESET_PIN_FLAG,
  }
}

//Delete
export const deletePin = pin => ({
  type: DELETE_PIN,
  payload: pin,
});

export const deletePinSuccess = pin => ({
  type: DELETE_PIN_SUCCESS,
  payload: pin,
});

export const deletePinFail = error => ({
  type: DELETE_PIN_FAIL,
  payload: error,
});

//Status Change
export const statusChangePin = pin => ({
  type: STATUS_CHANGE_PIN,
  payload: pin,
});

export const statusChangePinSuccess = pin => ({
  type: STATUS_CHANGE_PIN_SUCCESS,
  payload: pin,
});

export const statusChangePinFail = error => ({
  type: STATUS_CHANGE_PIN_FAIL,
  payload: error,
});

//Save Ordaring
export const saveOrderingPin = data => ({
  type: SAVE_ORDERING_PIN,
  payload: data,
});

export const saveOrderingPinSuccess = data => ({
  type: SAVE_ORDERING_PIN_SUCCESS,
  payload: data,
});

export const saveOrderingPinFail = error => ({
  type: SAVE_ORDERING_PIN_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingPinResetFlag = () => {
  return {
    type: SAVE_ORDERING_PIN_RESET_FLAG,
  }
}



//Modify Flag for toaster
export const modifyPinFlag = () => {
  return {
    type: MODIFY_PIN_FLAG,
  }
}