import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_TEAMS,
  ADD_NEW_TEAM,
  GET_TEAM_DETAILS,
  UPDATE_TEAM,
  DELETE_TEAM,
  STATUS_CHANGE_TEAM,
  SAVE_ORDERING_TEAM,
} from "./actionTypes"

import {
  getTeamsSuccess,
  getTeamsFail,
  addNewTeamSuccess,
  addNewTeamFail,
  getTeamDetailsSuccess,
  getTeamDetailsFail,
  updateTeamSuccess,
  updateTeamFail,
  deleteTeamSuccess,
  deleteTeamFail,
  statusChangeTeamSuccess,
  statusChangeTeamFail,

  saveOrderingTeamSuccess,
  saveOrderingTeamFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTeamList,
  addNewTeam,
  getTeamDetails,
  updateTeam,
  deleteTeam,
  statusChangeTeam,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchTeams({ payload: team }) {
  try {
    const response = yield call(getTeamList, team)
    yield put(getTeamsSuccess(response))
  } catch (error) {
    yield put(getTeamsFail(error))
  }
}

export function* watchFetchTeams() {
  yield takeEvery(GET_TEAMS, fetchTeams);
}

function* onAddNewTeam({ payload: team }) {
  try {
      const response = yield call(addNewTeam, team);
      if(response.status === 200){
        yield put(addNewTeamSuccess(response));
      } else {
        yield put(addNewTeamFail(response));
      }
  } catch (error) {
      yield put(addNewTeamFail(error));
  }
}

export function* watchAddNewTeam() {
  yield takeEvery(ADD_NEW_TEAM, onAddNewTeam);
}

function* onTeamDetails({ payload: team }) {
  try {
      const response = yield call(getTeamDetails, team);
      if(response.status === 200){
        yield put(getTeamDetailsSuccess(response));
      } else {
        yield put(getTeamDetailsFail(response));
      }
  } catch (error) {
      yield put(getTeamDetailsFail(error));
  }
}

export function* watchTeamDetails() {
  yield takeEvery(GET_TEAM_DETAILS, onTeamDetails);
}

function* onTeamUpdate({ payload: team }) {
  try {
      const response = yield call(updateTeam, team);
      if(response.status === 200){
        yield put(updateTeamSuccess(response));
      } else {
        yield put(updateTeamFail(response));
      }
  } catch (error) {
      yield put(updateTeamFail(error));
  }
}

export function* watchUpdateTeam() {
  yield takeEvery(UPDATE_TEAM, onTeamUpdate);
}


//Delete Data
function* onDeleteTeam({ payload: team }) {
  try {
    const response = yield call(deleteTeam, team);
    yield put(deleteTeamSuccess({ team, ...response }));
  } catch (error) {
    yield put(deleteTeamFail(error));
  }
}

export function* watchDeleteTeam() {
  yield takeEvery(DELETE_TEAM, onDeleteTeam);
}



//Status Change
function* onStatusChangTeam({ payload: team }) {
  try {
    const response = yield call(statusChangeTeam, team);
    yield put(statusChangeTeamSuccess({ team, ...response }));
  } catch (error) {
    yield put(statusChangeTeamFail(error));
  }
}

export function* watchStatusChangeTeam() {
  yield takeEvery(STATUS_CHANGE_TEAM, onStatusChangTeam);
}


//Save Ordering
function* onSaveOrderingTeam({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingTeamSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingTeamFail(error));
  }
}

export function* watchSaveOrderingTeam() {
  yield takeEvery(SAVE_ORDERING_TEAM, onSaveOrderingTeam);
}


function* teamSaga() {
  yield all(
    [
      fork(watchFetchTeams),
      fork(watchAddNewTeam),
      fork(watchTeamDetails),
      fork(watchUpdateTeam),
      fork(watchDeleteTeam),
      fork(watchStatusChangeTeam),
      fork(watchSaveOrderingTeam),
    ]
  );

}

export default teamSaga
