import {
  GET_USERS,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,

  MODIFY_USER_FLAG,
  RESET_USER_FLAG,
  RESET_ADMIN_FLAG,
  STATUS_CHANGE_USER,
  STATUS_CHANGE_USER_SUCCESS,
  STATUS_CHANGE_USER_FAIL
  
} from "./actionTypes";

export const getUsers = data => ({
  type: GET_USERS,
  payload: data,
});

export const getUserSuccess = data => ({
  type: GET_USER_SUCCESS,
  payload: data,
});

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
});
export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
});

export const addNewUserSuccess = user => ({
  type: ADD_NEW_USER_SUCCESS,
  payload: user,
});

export const addNewUserFail = error => ({
  type: ADD_NEW_USER_FAIL,
  payload: error,
})
export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
});

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

export const getUserDetails = user => ({
  type: GET_USER_DETAILS,
  payload: user,
});

export const getUserDetailsSuccess = user => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: user,
});

export const getUserDetailsFail = error => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
});
export const delUser = data => ({
  type: DELETE_USER,
  payload: data,
});

export const deleteUserSuccess = data => ({
  type: DELETE_USER_SUCCESS,
  payload: data,
});

export const deleteUserFail = data => ({
  type: DELETE_USER_FAIL,
  payload: data,
});

export const statusChangeUser = user => ({
  type: STATUS_CHANGE_USER,
  payload: user,
});

export const statusChangeUserSuccess = user => ({
  type: STATUS_CHANGE_USER_SUCCESS,
  payload: user,
});

export const statusChangeUserFail = error => ({
  type: STATUS_CHANGE_USER_FAIL,
  payload: error,
});

export const modifyUserFlag = () => {
  return {
    type: MODIFY_USER_FLAG,
  }
}

export const resetUserFlag = () => {
  return {
    type: RESET_USER_FLAG,
  }
}

export const resetAdminFlag = () => {
  return {
    type: RESET_ADMIN_FLAG,
  }
}