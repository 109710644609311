/* POINTS */
export const GET_POINTS = "GET_POINTS";
export const GET_POINTS_SUCCESS = "GET_POINTS_SUCCESS";
export const GET_POINTS_FAIL = "GET_POINTS_FAIL";

export const ADD_NEW_POINTS = "ADD_NEW_POINTS";
export const ADD_NEW_POINTS_SUCCESS = "ADD_NEW_POINTS_SUCCESS";
export const ADD_NEW_POINTS_FAIL = "ADD_NEW_POINTS_FAIL";

export const POINTS_PAYMENT_STATUS_CHANGE = "POINTS_PAYMENT_STATUS_CHANGE";
export const POINTS_PAYMENT_STATUS_CHANGE_SUCCESS = "POINTS_PAYMENT_STATUS_CHANGE_SUCCESS";
export const POINTS_PAYMENT_STATUS_CHANGE_FAIL = "POINTS_PAYMENT_STATUS_CHANGE_FAIL";

export const GET_POINTS_DETAILS = "GET_POINTS_DETAILS";
export const GET_POINTS_DETAILS_SUCCESS = "GET_POINTS_DETAILS_SUCCESS";
export const GET_POINTS_DETAILS_FAIL = "GET_POINTS_DETAILS_FAIL";

export const RESET_POINTS_FLAG = "RESET_POINTS_FLAG";

export const DELETE_POINTS = "DELETE_POINTS";
export const DELETE_POINTS_SUCCESS = "DELETE_POINTS_SUCCESS";
export const DELETE_POINTS_FAIL = "DELETE_POINTS_FAIL";

export const STATUS_CHANGE_POINTS = "STATUS_CHANGE_POINTS";
export const STATUS_CHANGE_POINTS_SUCCESS = "STATUS_CHANGE_POINTS_SUCCESS";
export const STATUS_CHANGE_POINTS_FAIL = "STATUS_CHANGE_POINTS_FAIL";

export const MODIFY_POINTS_FLAG = "MODIFY_POINTS_FLAG";