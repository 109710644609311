import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { updateAllergie, resetAllergieFlag, getAllergieDetails } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";

const AllergieEdit = () => {
    document.title = "Edit Allergie | Defendit - Admin Panel";
    const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [allergieImg, setAllergieImg] = useState();
    const [isCreating, setIsCreating] = useState(false);
    const { isAllergieUpdate, isAllergieUpdateFail, error, success, details } = useSelector(state => ({
        isAllergieUpdate: state.Allergies.isAllergieUpdate,
        isAllergieUpdateFail: state.Allergies.isAllergieUpdateFail,
        error: state.Allergies.error,
        success: state.Allergies.success,
        details: state.Allergies.details,
    }));

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: (details && details.title)?details.title:'',
            image: (details && details.image)?details?.image:'',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Allergie Name")
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            
            const data = {
                id: id,
                title: values.name,
                image: allergieImg
            }
            dispatch(updateAllergie(data))
        },
    });

    useEffect(() => {
        if(isAllergieUpdate || isAllergieUpdateFail){
            setIsCreating(false)
            setTimeout(() => {
                dispatch(resetAllergieFlag())
            }, 3000);
        }
      }, [dispatch, isAllergieUpdate, isAllergieUpdateFail]);

      useEffect(() => {
        dispatch(getAllergieDetails({id:id}))
      }, [dispatch, id]);

      const onImgChange = (e) => {
        if (e.target.files[0]) {
          const ext = e.target.files[0].type.split("/")[1];
    
          if (![...allowedExts].includes(ext)) {
            console.log("Please upload a valid image");
            toast("Please upload a valid image.");
          } else {
            var output = document.getElementById("image1");
            output.src = URL.createObjectURL(e.target.files[0]);
            output.onload = function () {
              URL.revokeObjectURL(output.src); // free memory
            };
            convertToBase64(e.target.files[0], setAllergieImg);
          }
        }
      };

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Edit Allergie" pageTitle="Allergies" pageUrl="/allergies" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            console.log(e);
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Edit Allergie" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Title</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Allergie title"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                {/* <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Icon</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Icon name"
                                                            name='icon'
                                                            value={validation.values.icon || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.icon && validation.errors.icon ? true : false
                                                            }
                                                        />
                                                        {validation.touched.icon && validation.errors.icon ? (
                                                            <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col> */}

                                                <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                             Allergie Image *
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImgChange}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                                                {/* <Col xxl={3} md={12}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Description</Label>
                                                        <Input
                                                            type="textarea"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Description"
                                                            rows="3"
                                                            name='description'
                                                            value={validation.values.description || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.description && validation.errors.description ? true : false
                                                            }
                                                        />
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col> */}

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/allergies" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Update </Spinner>}
                                            <span className="flex-grow-1">
                                                Update
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isAllergieUpdate ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isAllergieUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default AllergieEdit;