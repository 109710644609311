//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";
export const GET_PROFILE_DATA = "/profile-details";

//CATEGORIES
export const GET_CATEGORIES_LIST = "/category/list";
export const ADD_CATEGORY = "/category/add";
export const GET_CATEGORY = "/category/details";
export const UPDATE_CATEGORY = "/category/edit";
export const DELETE_CATEGORY = "/category/delete";

//Status Change Categories

export const STATUS_CHANGE_CAT = "/category/status-change";
//Blogs
export const GET_BLOGS_LIST = "/blog/list";
export const ADD_NEW_BLOG = "/blog/add";
export const UPDATE_BLOG = "/blog/edit";
export const DELETE_BLOG = "/blog/remove";
export const DELETE_BLOGCOMMENT= "/blog_Comment/remove";
export const GET_BLOG_DETAILS = "/blog/details";
export const GET_BLOGCOMMENTS_LIST = "/blog_Comment/list";
export const STATUS_CHANGE_BLOGCOMMENT = "/blog_Comment/status-change"


//Status Change Blog

export const STATUS_CHANGE_BLOG = "/blog/status-change";

// Videos
export const GET_VIDEO_LIST = "/video/list";
export const STORE_VIDEO = "/video/add";
export const UPDATE_VIDEO = "/video/edit";
export const VIDEO_DETAIL = "/video/details";
export const DELETE_VIDEO = "/video/delete";


//Status Change Video

export const STATUS_CHANGE_VIDEO = "/video/status-change";

// Jobs
export const GET_JOB_LIST = "/job/list";
export const GET_JOB_DETAILS = "/job/details";
export const ADD_NEW_JOB = "/job/add";
export const UPDATE_JOB = "/job/edit";
export const DELETE_JOB = "/job/remove";
export const ADD_NEW_JOB_TYPE = "job/type/add";
export const UPDATE_JOB_TYPE = "/job/type/edit";
export const GET_JOB_TYPE_DETAILS = "/job/type/details";
export const GET_JOB_TYPE_LIST = "/job/type/list";
export const DELETE_JOB_TYPE = "/job/type/delete";
export const STATUS_CHANGE_JOB_TYPE = "/job/type/status-change";
export const STATUS_CHANGE_JOB_LOCATION ="job/location-type/status-change";
export const ADD_NEW_JOB_LOCATION_TYPE = "/job/location-type/add";
export const GET_JOB_LOCATION_TYPE_LIST = "/job/location-type/list";
export const GET_JOB_LOCATION_DETAILS = "/job/location-type/details";
export const UPDATE_JOB_LOCATION_TYPE = "/job/location-type/edit";
export const DELETE_JOB_LOCATION_TYPE = "/job/location-type/delete";
export const GET_JOB_APPLY_LIST = "/job/list-apply";
export const ADD_NEW_JOB_APPLY = "/job/add-apply";
export const UPDATE_JOBAPPLY = "/job/edit-apply";
export const DELETE_JOB_APPLY = "/job/remove-apply";



//USER CREATE
export const ADD_NEW_USER = "/user/add";
//USER LIST
export const GET_USER_LIST = "/user-search";
//USER DETAILS
export const GET_USER_DETAILS = "/user/details";
//USER UPDATE
export const UPDATE_USER = "/user/edit";
// DELETE USER
export const DELETE_USER = "/user/delete";
//ADMIN LIST
export const GET_ADMIN_LIST = "/user-search";

//Status Change User

export const STATUS_CHANGE_USER = "/user/status-change";


//Status Change Job

export const STATUS_CHANGE_JOB = "/job/status-change";

// Banner
export const GET_BANNER_LIST = "/banner/list";
export const GET_BANNER_DETAILS = "/banner/details";
export const ADD_NEW_BANNER = "/banner/add";
export const UPDATE_BANNER = "/banner/edit";
export const DELETE_BANNER = "/banner/delete";

// Sites

export const GET_SITE_DETAILS = "/site-setting/details";
export const UPDATE_SITE = "/site-setting/update";

// Pages
export const GET_PAGE_LIST = "/page/list";
export const GET_PAGE_DETAILS = "/page/details";
export const ADD_NEW_PAGE = "/page/add";
export const UPDATE_PAGE = "/page/edit";


// Steps
export const GET_STEP_LIST = "/how_it_work/list";
export const GET_STEP_DETAILS = "/how_it_work/details";
export const ADD_NEW_STEP = "/how_it_work/create";
export const UPDATE_STEP = "/how_it_work/edit";
export const DELETE_STEP = "/how_it_work/remove";
export const STATUS_CHANGE_STEP = "/how_it_work/status-change";

// Benefits
export const GET_BENEFIT_LIST = "/account-benefit/list";
export const GET_BENEFIT_DETAILS = "/account-benefit/details";
export const ADD_NEW_BENEFIT = "/account-benefit/create";
export const UPDATE_BENEFIT = "/account-benefit/edit";
export const DELETE_BENEFIT = "/account-benefit/remove";
export const STATUS_CHANGE_BENEFIT = "/account-benefit/status-change";


// Testimonials
export const GET_TESTIMONIAL_LIST = "/testimonial/list";
export const GET_TESTIMONIAL_DETAILS = "/testimonial/details";
export const ADD_NEW_TESTIMONIAL = "/testimonial/create";
export const UPDATE_TESTIMONIAL = "/testimonial/edit";
export const DELETE_TESTIMONIAL = "/testimonial/remove";
export const STATUS_CHANGE_TESTIMONIAL = "/testimonial/status-change";

// Ingredients
export const GET_INGREDIENT_LIST = "/product_ingredients/list";
export const GET_INGREDIENT_DETAILS = "/product_ingredients/details";
export const ADD_NEW_INGREDIENT = "/product_ingredients/create";
export const UPDATE_INGREDIENT = "/product_ingredients/edit";
export const DELETE_INGREDIENT = "/product_ingredients/remove";
export const STATUS_CHANGE_INGREDIENT = "/product_ingredients/status-change";

// Funfacts
export const GET_FUNFACT_LIST = "/fun-facts/list";
export const GET_FUNFACT_DETAILS = "/fun-facts/details";
export const ADD_NEW_FUNFACT = "/fun-facts/add";
export const UPDATE_FUNFACT = "/fun-facts/edit";
export const DELETE_FUNFACT = "/fun-facts/delete";
export const STATUS_CHANGE_FUNFACT = "/fun-facts/change-status";

// Teams
export const GET_TEAM_LIST = "/team/list";
export const GET_TEAM_DETAILS = "/team/details";
export const ADD_NEW_TEAM = "/team/create";
export const UPDATE_TEAM = "/team/edit";
export const DELETE_TEAM = "/team/remove";
export const STATUS_CHANGE_TEAM = "/team/status-change";

// Services
export const GET_SERVICE_LIST = "/service/list";
export const GET_SERVICE_DETAILS = "/service/details";
export const ADD_NEW_SERVICE = "/service/create";
export const UPDATE_SERVICE = "/service/edit";
export const DELETE_SERVICE = "/service/remove";
export const STATUS_CHANGE_SERVICE= "/service/status-change";

// Missions
export const GET_MISSION_LIST = "/mission-vision/list";
export const GET_MISSION_DETAILS = "/mission-vision/details";
export const ADD_NEW_MISSION = "/mission-vision/create";
export const UPDATE_MISSION = "/mission-vision/edit";
export const DELETE_MISSION = "/mission-vision/remove";
export const STATUS_CHANGE_MISSION= "/mission-vision/status-change";

// Visions
export const GET_VISION_LIST = "/mission-vision/list";
export const GET_VISION_DETAILS = "/mission-vision/details";
export const ADD_NEW_VISION = "/mission-vision/create";
export const UPDATE_VISION = "/mission-vision/edit";
export const DELETE_VISION = "/mission-vision/remove";
export const STATUS_CHANGE_VISION= "/mission-vision/status-change";

// SEO
export const GET_SEO_LIST = "/seo/list";
export const ADD_NEW_SEO = "/seo/add";
export const GET_SEO_DETAILS = "/seo/details";
export const UPDATE_SEO = "/seo/edit";
export const DELETE_SEO = "/seo/delete";

// Highlights
export const GET_HIGHLIGHT_LIST = "/our_highlight/list";
export const GET_HIGHLIGHT_DETAILS = "/our_highlight/details";
export const ADD_NEW_HIGHLIGHT = "/our_highlight/create";
export const UPDATE_HIGHLIGHT = "/our_highlight/edit";
export const DELETE_HIGHLIGHT = "/our_highlight/remove";
export const STATUS_CHANGE_HIGHLIGHT = "/our_highlight/status-change";

// Spices
export const GET_SPICE_LIST = "/product_ingredients/list";
export const GET_SPICE_DETAILS = "/product_ingredients/details";
export const ADD_NEW_SPICE = "/product_ingredients/create";
export const UPDATE_SPICE = "/product_ingredients/edit";
export const DELETE_SPICE = "/product_ingredients/remove";
export const STATUS_CHANGE_SPICE = "/product_ingredients/status-change";

// Ingredients
export const GET_ALLERGIE_LIST = "/product_ingredients/list";
export const GET_ALLERGIE_DETAILS = "/product_ingredients/details";
export const ADD_NEW_ALLERGIE = "/product_ingredients/create";
export const UPDATE_ALLERGIE = "/product_ingredients/edit";
export const DELETE_ALLERGIE = "/product_ingredients/remove";
export const STATUS_CHANGE_ALLERGIE = "/product_ingredients/status-change";

// Diets
export const GET_DIET_LIST = "/product_ingredients/list";
export const GET_DIET_DETAILS = "/product_ingredients/details";
export const ADD_NEW_DIET = "/product_ingredients/create";
export const UPDATE_DIET = "/product_ingredients/edit";
export const DELETE_DIET = "/product_ingredients/remove";
export const STATUS_CHANGE_DIET = "/product_ingredients/status-change";

// COMBO
export const GET_COMBO_LIST = "/combo-product/list";
export const GET_COMBO_DETAILS = "/combo-product/details";
export const ADD_NEW_COMBO = "/combo-product/create";
export const UPDATE_COMBO = "/combo-product/edit";
export const DELETE_COMBO = "/combo-product/remove";
export const STATUS_CHANGE_COMBO = "/combo-product/change-status";

// Coupon
export const GET_COUPON_LIST = "/coupon/list";
export const GET_COUPON_DETAILS = "/coupon/details";
export const ADD_NEW_COUPON = "/coupon/create";
export const UPDATE_COUPON = "/coupon/edit";
export const DELETE_COUPON = "/coupon/remove";
export const STATUS_CHANGE_COUPON = "/coupon/change-status";

// Author
export const GET_AUTHOR_LIST = "/author/list";
export const ADD_NEW_AUTHOR = "/author/create";
export const UPDATE_AUTHOR = "/author/edit";
export const GET_AUTHOR_DETAILS = "/author/details";
export const DELETE_AUTHOR = "/author/remove";
export const STATUS_CHANGE_AUTHOR = "/author/change-status";


// Organization
export const GET_ORGANIZATION_LIST = "/organization/list";
export const ADD_NEW_ORGANIZATION = "/organization/create";
export const UPDATE_ORGANIZATION = "/organization/edit";
export const GET_ORGANIZATION_DETAILS = "/organization/details";
export const DELETE_ORGANIZATION = "/organization/remove";
export const STATUS_CHANGE_ORGANIZATION = "/organization/change-status";

// Order
export const GET_ORDER_LIST = "/order/list";
export const GET_ORDER_DETAILS = "/order/details";
export const ADD_NEW_ORDER = "/order/create";
export const ORDER_PAYMENT_STATUS_CHANGE = "/order/change-payment-status";
export const DELETE_ORDER = "/order/remove";
export const STATUS_CHANGE_ORDER = "/order/change-order-status";
export const ORDER_STATUS_LIST = "/order/status-list";

// Point
 export const GET_POINT_LIST = "/point/list";

// Contacts
export const GET_CONTACT_LIST = "/list-contact";
// export const GET_CONTACT_DETAILS = "/contact/details"; //double check api in postman
export const ADD_NEW_CONTACT = "/add-contact";
// export const UPDATE_CONTACT= "/contact/edit"; //double check api in postman

export const DELETE_CONTACT = "/delete-contact";

//Status Change Contact

export const STATUS_CHANGE_CONTACT = "/status-change-contact";


// Faq
export const GET_FAQ_LIST = "/faq/list";
export const GET_FAQ_DETAILS = "/faq/details";
export const ADD_NEW_FAQ = "/faq/add";
export const UPDATE_FAQ = "/faq/edit";
export const DELETE_FAQ = "/faq/delete";
export const STATUS_CHANGE_FAQ = "faq/status-change";
export const GET_FAQ_FEEDBACK_LIST = "/faq/feedback-list";




//PRODUCT-SECTION

export const GET_PRODUCT_CATEGORY_LIST = "/product_categories/list";
export const GET_PRODUCT_CATEGORY_DETAILS = "/product_categories/details";
export const ADD_NEW_PRODUCT_CATEGORY = "/product_categories/create";
export const UPDATE_PRODUCT_CATEGORY = "/product_categories/edit";
export const DELETE_PRODUCT_CATEGORY = "/product_categories/remove";
export const STATUS_CHANGE_PRODUCT_CATEGORY= "/product_categories/status-change";


export const GET_PRODUCT_LIST = "v2/products/list";
export const GET_PRODUCT_DETAILS = "v2/products/details";
export const ADD_NEW_PRODUCT = "v2/products/create";
export const UPDATE_PRODUCT = "v2/products/edit";
export const DELETE_PRODUCT = "v2/products/remove";
export const STATUS_CHANGE_PRODUCT= "v2/products/status-change";


// Future Team
export const GET_FUTURETEAM_LIST = "/future-team/list";
export const ADD_NEW_FUTURETEAM = "/future-team/create";
export const GET_FUTURETEAM_DETAILS = "/future-team/details";
export const UPDAT_EFUTURETEAM = "/future-team/edit";
export const STATUS_CHANGE_FUTURETEAM = "/future-team/status-change";
export const DELETE_FUTURETEAM = "/future-team/remove";

//Opening Hour
export const GET_OPENING_HOUR_LIST = "/open_hour/list";
export const ADD_NEW_OPENING_HOUR = "/open_hour/create";
export const GET_OPENING_HOUR_DETAILS = "/open_hour/details";
export const UPDATE_OPENING_HOUR = "/open_hour/edit";
export const STATUS_CHANGE_OPENING_HOUR = "/open_hour/status-change";
export const DELETE_OPENING_HOUR = "/open_hour/remove";


// Steps
export const GET_PIN_LIST = "/delivery-zipcode/list";
export const GET_PIN_DETAILS = "/delivery-zipcode/details";
export const ADD_NEW_PIN = "/delivery-zipcode/create";
export const UPDATE_PIN = "/delivery-zipcode/edit";
export const DELETE_PIN = "/delivery-zipcode/remove";
export const STATUS_CHANGE_PIN = "/delivery-zipcode/change-status";


// Take of Time List
export const GET_TAKEOFTIME_LIST = "/take-of-time/list";

// Take of Time Create
export const ADD_NEW_TAKEOFTIME = "/take-of-time/create";

export const DELETE_NEW_TAKEOFTIME = "/take-of-time/remove";

// Take of Time Update
export const UPDATE_TAKEOFTIME = "/take-of-time/edit";

// Take of Time Details
export const GET_TAKEOFTIME_DETAILS ="/take-of-time/details";

// Status change Take of Time
export const STATUS_CHANGE_TAKEOFTIME ="/take-of-time/change-status";

//// Notification
export const GET_SOCKET_NOTIFICATION= "/order-view-status";

// Save ordering
export const SAVE_ORDERING= "/common/ordering";






