import {
  GET_FUNFACTS,
  GET_FUNFACTS_SUCCESS,
  GET_FUNFACTS_FAIL,
  ADD_NEW_FUNFACT,
  ADD_NEW_FUNFACT_SUCCESS,
  ADD_NEW_FUNFACT_FAIL,
  GET_FUNFACT_DETAILS,
  GET_FUNFACT_DETAILS_SUCCESS,
  GET_FUNFACT_DETAILS_FAIL,
  UPDATE_FUNFACT,
  UPDATE_FUNFACT_SUCCESS,
  UPDATE_FUNFACT_FAIL,
  RESET_FUNFACT_FLAG,
  DELETE_FUNFACT,
  DELETE_FUNFACT_SUCCESS,
  DELETE_FUNFACT_FAIL,
  SAVE_ORDERING_FUNFACT,
  SAVE_ORDERING_FUNFACT_SUCCESS,
  SAVE_ORDERING_FUNFACT_FAIL,
  SAVE_ORDERING_FUNFACT_RESET_FLAG,
  STATUS_CHANGE_FUNFACT,
  STATUS_CHANGE_FUNFACT_SUCCESS,
  STATUS_CHANGE_FUNFACT_FAIL,
  MODIFY_FUNFACT_FLAG
} from "./actionTypes";

export const getFunfacts = data => ({
  type: GET_FUNFACTS,
  payload: data,
});

export const getFunfactsSuccess = data => ({
  type: GET_FUNFACTS_SUCCESS,
  payload: data,
});

export const getFunfactsFail = error => ({
  type: GET_FUNFACTS_FAIL,
  payload: error,
});

export const addNewFunfact = funfact=> ({
  type: ADD_NEW_FUNFACT,
  payload: funfact,
});

export const addNewFunfactSuccess = funfact => ({
  type: ADD_NEW_FUNFACT_SUCCESS,
  payload: funfact,
});

export const addNewFunfactFail = error => ({
  type: ADD_NEW_FUNFACT_FAIL,
  payload: error,
});

export const updateFunfact = funfact => ({
  type: UPDATE_FUNFACT,
  payload: funfact,
});

export const updateFunfactSuccess = funfact => ({
  type: UPDATE_FUNFACT_SUCCESS,
  payload: funfact,
});

export const updateFunfactFail = error => ({
  type: UPDATE_FUNFACT_FAIL,
  payload: error,
});

export const getFunfactDetails = funfact => ({
  type: GET_FUNFACT_DETAILS,
  payload: funfact,
});

export const getFunfactDetailsSuccess = funfact => ({
  type: GET_FUNFACT_DETAILS_SUCCESS,
  payload: funfact,
});

export const getFunfactDetailsFail = error => ({
  type: GET_FUNFACT_DETAILS_FAIL,
  payload: error,
});

export const resetFunfactFlag = () => {
  return {
    type: RESET_FUNFACT_FLAG,
  }
}

//Delete
export const deleteFunfact = funfact => ({
  type: DELETE_FUNFACT,
  payload: funfact,
});

export const deleteFunfactSuccess = funfact => ({
  type: DELETE_FUNFACT_SUCCESS,
  payload: funfact,
});

export const deleteFunfactFail = error => ({
  type: DELETE_FUNFACT_FAIL,
  payload: error,
});
//Save Ordaring
export const saveOrderingFunfact = data => ({
  type: SAVE_ORDERING_FUNFACT,
  payload: data,
});

export const saveOrderingFunfactSuccess = data => ({
  type: SAVE_ORDERING_FUNFACT_SUCCESS,
  payload: data,
});

export const saveOrderingFunfactFail = error => ({
  type: SAVE_ORDERING_FUNFACT_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingFunfactResetFlag = () => {
  return {
    type: SAVE_ORDERING_FUNFACT_RESET_FLAG,
  }
}
//Status Change
export const statusChangeFunfact = funfact => ({
  type: STATUS_CHANGE_FUNFACT,
  payload: funfact,
});

export const statusChangeFunfactSuccess = funfact => ({
  type: STATUS_CHANGE_FUNFACT_SUCCESS,
  payload: funfact,
});

export const statusChangeFunfactFail = error => ({
  type: STATUS_CHANGE_FUNFACT_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyFunfactFlag = () => {
  return {
    type: MODIFY_FUNFACT_FLAG,
  }
}