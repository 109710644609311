import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
    Card,
    CardBody,
    Col,
    Container,
    Button,
    Spinner,
    Row,
    Input,
    Label,
    FormFeedback,
    Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    updateCoupon,
    resetCouponFlag,
    getCouponDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import Flatpickr from "react-flatpickr";



const CouponEdit = () => {
    document.title = "Edit Coupon | Dabbawala";
    const dispatch = useDispatch();
    const fp = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const [isCreating, setIsCreating] = useState(false);
    //const [description, setData] = useState();
    const { isCouponUpdate, isCouponUpdateFail, error, success, details } =
        useSelector((state) => ({
            isCouponUpdate: state.Coupons.isCouponUpdate,
            isCouponUpdateFail: state.Coupons.isCouponUpdateFail,
            error: state.Coupons.error,
            success: state.Coupons.success,
            details: state.Coupons.details,
        }));
    // console.log("details", details);


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: details && details.code ? details.code : "",
            description: details && details.description ? details.description : "",
            discount_type: details && details.discount_type ? details.discount_type : "",
            amount: details && details.amount ? details.amount : "",
            start_date: details && details.start_date ? details.start_date : "",
            end_date: details && details.end_date ? details.end_date : "",
        },
        validationSchema: Yup.object({
            code: Yup.string().required("Please Enter Coupon Code"),
            description: Yup.string().required("Please Enter Description"),
            discount_type: Yup.string().required("Please Enter Discount Type"),
            amount: Yup.string().required("Please Enter Amount"),
            start_date: Yup.date().required("Please enter Start date"),
            end_date: Yup.date().required("Please enter End date"),
        }),


        onSubmit: (values) => {
            setIsCreating(true);
            const data = {
                id: id,
                code: values.code,
                description: values.description,
                discount_type: values.discount_type,
                amount: values.amount,
                start_date: values.start_date,
                end_date: values.end_date
            };
            dispatch(updateCoupon(data));
        },
    });

    useEffect(() => {
        if (isCouponUpdate || isCouponUpdateFail) {
            setIsCreating(false);
            validation.resetForm();
            setTimeout(() => {
                dispatch(resetCouponFlag());
                // history.push("/organization")
            }, 3000);
        }
    }, [dispatch, isCouponUpdate, isCouponUpdateFail, validation, history]);

    useEffect(() => {
        dispatch(getCouponDetails({ id: id }));
    }, [dispatch, id]);


    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb
                        title="Edit Coupon"
                        pageTitle="Coupons"
                        pageUrl="/coupon"
                    />
                    <Row>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Edit Coupon" />

                                    <CardBody className="card-body">
                                        <div className="live-preview">
                                        <Row className="gy-4">
                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Coupon Code</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Coupon Code"
                                                            name='code'
                                                            value={validation.values.code || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.code && validation.errors.code ? true : false
                                                            }
                                                        />
                                                        {validation.touched.code && validation.errors.code ? (
                                                            <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={12} md={12}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Coupon Description*
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            id="placeholderInput"
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Enter description"
                                                            name="description"
                                                            value={validation.values.description || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.description &&
                                                                    validation.errors.description
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.description &&
                                                            validation.errors.description ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.description}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Discount Type
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="placeholderInput"
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Enter Discount Type"
                                                            name="discount_type"
                                                            value={validation.values.discount_type || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.discount_type &&
                                                                    validation.errors.discount_type
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.discount_type &&
                                                            validation.errors.discount_type ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.discount_type}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    
                                                </Col>
                                             
                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label
                                                            htmlFor="placeholderInput"
                                                            className="form-label"
                                                        >
                                                            Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="placeholderInput"
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Enter Amount"
                                                            name="amount"
                                                            value={validation.values.amount || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.amount &&
                                                                    validation.errors.amount
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.amount &&
                                                            validation.errors.amount ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.amount}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    
                                                </Col>

                                                <Col sm={4} className="col-xxl-3">
                                                <div>
                                                    <Flatpickr
                                                        ref={fp}
                                                        className="form-control"
                                                        id="datepicker-publish-input"
                                                        placeholder="Select Start date"
                                                        // onChange={(e) => {
                                                        //     setDate({ e });
                                                        // }}
                                                        onChange={validation.handleChange}
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            // mode: "range",
                                                            dateFormat: "d.m.y",
                                                        }}
                                                        validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.start_date &&
                                                                    validation.errors.start_date
                                                                    ? true
                                                                    : false
                                                            }
                                                    />
                                                    {validation.touched.start_date &&
                                                            validation.errors.start_date ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.start_date}
                                                            </FormFeedback>
                                                        ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={4} className="col-xxl-3">
                                                <div>
                                                    <Flatpickr
                                                        ref={fp}
                                                        className="form-control"
                                                        id="datepicker-publish-input"
                                                        placeholder="Select End date"
                                                        // onChange={(e) => {
                                                        //     setEndDate({ e });
                                                        // }}
                                                        onChange={validation.handleChange}
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            // mode: "range",
                                                            dateFormat: "d.m.y",
                                                        }}
                                                    />
                                                </div>
                                            </Col>

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/coupon" className="btn btn-danger w-sm me-1">
                                        Cancel
                                    </Link>
                                    <Button
                                        color="success"
                                        className="btn-load"
                                        disabled={isCreating}
                                        type="submit"
                                    >
                                        <span className="d-flex align-items-center">
                                            {isCreating && (
                                                <Spinner size="sm" className="flex-shrink-0 me-2">
                                                    {" "}
                                                    Update{" "}
                                                </Spinner>
                                            )}
                                            <span className="flex-grow-1">Update</span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isCouponUpdate ? (
                        <MsgToast
                            msg={success}
                            color="success"
                            icon="ri-checkbox-circle-line"
                        />
                    ) : null}
                    {isCouponUpdateFail ? (
                        <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
                    ) : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
};

export default CouponEdit;
