import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  GET_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  STATUS_CHANGE_CAT,
  SAVE_ORDERING_CATEGORY,
} from "./actionTypes"

import {
  getCategoriesSuccess,
  getCategoriesFail,
  AddCategorySuccess,
  AddCategoryFail,
  getCategorySuccess,
  getCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
  statusChangeCatSuccess,
  statusChangeCatFail,

  saveOrderingCategorySuccess,
  saveOrderingCategoryFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCategoryList,addCategory,getCategory,updCategory,delCategory,
  statusChangeCat,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchCategories({payload : data}) {
  try {
    const response = yield call(getCategoryList,data)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* fetchCategory({payload : data}) {
  try {
    const response = yield call(getCategory,data)
    yield put(getCategorySuccess(response))
  } catch (error) {
    yield put(getCategoryFail(error))
  }
}


function* addCategories({payload : data}) {
  try {
    const response = yield call(addCategory,data)
    yield put(AddCategorySuccess(response))
  } catch (error) {
    yield put(AddCategoryFail(error))
  }
}

function* delCategories({payload : data}) {
  try {
    const response = yield call(delCategory,data)
    yield put(deleteCategorySuccess(response))
  } catch (error) {
    yield put(deleteCategoryFail(error))
  }
}
function* updateCategories({payload : data}) {
  try {
    const response = yield call(updCategory,data)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

//Status Change
function* onStatusChangeCat({ payload: cat }) {
  try {
    const response = yield call(statusChangeCat, cat);
    yield put(statusChangeCatSuccess({ cat, ...response }));
  } catch (error) {
    yield put(statusChangeCatFail(error));
  }
}


//Save Ordering
function* onSaveOrderingCategory({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingCategorySuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingCategoryFail(error));
  }
}

// export function* watchSaveOrderingCategory() {
//   yield takeEvery(SAVE_ORDERING_CATEGORY, onSaveOrderingCategory);
// }



function* categorySaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(ADD_CATEGORY, addCategories)
  yield takeEvery(GET_CATEGORY, fetchCategory)
  yield takeEvery(UPDATE_CATEGORY, updateCategories)
  yield takeEvery(DELETE_CATEGORY, delCategories)
  yield takeEvery(STATUS_CHANGE_CAT, onStatusChangeCat);
  yield takeEvery(SAVE_ORDERING_CATEGORY, onSaveOrderingCategory);
}

export default categorySaga
