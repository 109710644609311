import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewFutureTeam, resetFutureTeamFlag } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";

const FutureTeamCreate = () => {
  document.title = "Create Future Team | Defendit - Admin Panel";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState();

  const [typeFlag, setTypeFlag] = useState("");
  const [videoLink, setVideoLink] = useState("");

  const { isFutureTeamAdd, isFutureTeamAddFail, error, success } = useSelector(
    (state) => ({
      isFutureTeamAdd: state.FutureTeam.isFutureTeamAdd,
      isFutureTeamAddFail: state.FutureTeam.isFutureTeamAddFail,
      error: state.FutureTeam.error,
      success: state.FutureTeam.success,
    })
  );

  const typeHandleChange = (event) => {
    setTypeFlag(event.target.value);
  };

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter FutureTeam Name"),
    }),

    onSubmit: (values) => {
      setIsCreating(true);

      let file = "";
      let type = "";
      if (typeFlag === "true") {
        file = image;
        type = 1;
      }
      if (typeFlag === "false") {
        file = videoLink;
        type = 2;
      }

      const data = {
        name: values.name,
        file: file,
        type: type,
      };

      dispatch(addNewFutureTeam(data));
    },
  });

  useEffect(() => {
    if (isFutureTeamAdd || isFutureTeamAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetFutureTeamFlag());
        history.push("/future-team");
      }, 3000);
    }
  }, [dispatch, isFutureTeamAdd, isFutureTeamAddFail, validation, history]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create FutureTeam"
            pageTitle="FutureTeams"
            pageUrl="/future-team"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create FutureTeam" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={6} md={6}>
                          <Label
                            htmlFor="placeholderInput"
                            className="form-label  d-block"
                          >
                            Select Type*
                          </Label>

                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="sms_notification"
                              id="inlineRadio3"
                              value="true"
                              //checked={isSMSNotification === true}
                              onChange={typeHandleChange}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="inlineRadio3"
                            >
                              Image
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="sms_notification"
                              id="inlineRadio4"
                              value="false"
                              //checked={isSMSNotification === false}
                              onChange={typeHandleChange}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="inlineRadio4"
                            >
                              Video
                            </Label>
                          </div>
                        </Col>

                        <Col xxl={6} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name*
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Enter future team name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        {typeFlag === "true" ? (
                          <Col xxl={12} md={12}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Image*
                              </Label>
                              <Input
                                type="file"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Place your image"
                                name="image"
                                onChange={onImgChange}
                              />
                              <img
                                src={validation.values.image || ""}
                                alt=""
                                id="image1"
                                width="100px"
                              ></img>
                              {validation.touched.image &&
                              validation.errors.image ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.image}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {typeFlag === "false" ? (
                          <Col xxl={12} md={12}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Video Link*
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Enter Video Link"
                                //value={validation.values.image || ""}
                                onChange={(e) => {
                                  setVideoLink(e.target.value);
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/future-team" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isFutureTeamAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isFutureTeamAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default FutureTeamCreate;
