import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTakeofTime,
  delTakeofTime,
  modifyTakeofTimeFlag,
  statusChangeTakeofTime,
  resetTakeofTimeFlag,
  saveOrderingTakeofTime,
  saveOrderingTakeofTimeResetFlag,
} from "../../../store/actions";
import Flatpickr from "react-flatpickr";
import MsgToast from "../../../Components/Common/MsgToast";
import Loader from "../../../Components/Common/Loader";
import Moment from "react-moment";
import "moment-timezone";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function List() {
  const dispatch = useDispatch();
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState("");
  const [offset, setOffset] = useState(0);
  const [sortByField, setSortByField] = useState("ordering");
  const [sortByValue, setSortByValue] = useState(1);
  const [status, setVerified] = useState();
  const [limit, setLimit] = useState(10);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getTakeofTime({ limit, date, keyword, status, sortByField,sortByValue }));
  }, [dispatch, limit, offset, date, keyword, status, sortByField,sortByValue]);

  const {
    takeoftime,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
    saveOrderingSuccess,
    saveOrderingFaild
  } = useSelector((state) => ({
    takeoftime: state.takeofTime.takeoftime,
    totalDataCount: state.takeofTime.totalDataCount,
    isSuccess: state.takeofTime.isSuccess,
    success: state.takeofTime.success,
    error: state.takeofTime.error,
    statusSuccess: state.takeofTime.statusSuccess,
    statusFailed: state.takeofTime.statusFailed,
    saveOrderingSuccess: state.takeofTime.saveOrderingSuccess,
    saveOrderingFaild: state.takeofTime.saveOrderingFaild
  }));
  const sortBy = (fieldName) => {
    if (fieldName === sortByField) {
      if (sortByValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setSortByField(fieldName);
  };

  const deleteTakeoftime = (id) => {
    dispatch(delTakeofTime({ id }));
  };

  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyTakeofTimeFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);


  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(
        getTakeofTime({ limit, dateRange, offset, sortByField, sortByValue, keyword })
      );
    }
  }, [dispatch, date, limit, offset, sortByField, sortByValue, keyword]);
  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [dispatch, limit, totalDataCount]);


  function onClickStatusChange(id, status) {
    // console.log(id, status);
    dispatch(statusChangeTakeofTime({ id, status }));
  }

  // const resetFilter = () => {
  //   dispatch(resetTakeofTimeFlag());
  //   setKeyword("");
  //   fp.current.flatpickr.clear();
  //   setDate(null);
  //   dispatch(getTakeofTime({ keyword, date, sortByField, sortByValue, limit, offset }));
  // };

  const [modal, setModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");

  const openDeleteConfrontation = (id) => {
    setDeletedId(id);
    setModal(!modal);
  };


  //Ordring
  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);

  useEffect(() => {
    setDataList(takeoftime)
  }, [takeoftime]);

  //console.log(items);
  let a = [];
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for(let i = 0; i<tempData.length; i++){
      dataArray.push({ id: tempData[i]._id, ordering: i+1 });
    }
    setOrderingData(dataArray);
     onClickSaveOrdering(dataArray);
  };

//Ordaring
function onClickSaveOrdering(orderingData) {
  dispatch(saveOrderingTakeofTime({
    orderingData: orderingData,
    tableName : 'take_of_time',
  }));
  
}


useEffect(() => {
  if (saveOrderingSuccess || saveOrderingFaild) {
    setTimeout(() => {
      dispatch(saveOrderingTakeofTimeResetFlag());
    }, 3000);
  }
}, [dispatch, saveOrderingSuccess, saveOrderingFaild]);

  document.title = "Take of Time List | Dabbawala";
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Take Of Time" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Take of Time List</h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="/takeoftime/create"
                        className="btn btn-success add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Create
                        Take of Time
                      </Link>{" "}
                      {/* <button className="btn btn-soft-danger">
                        <i className="ri-delete-bin-2-line"></i>
                      </button> */}
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(+e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={status}
                          onChange={(e) => {
                            console.log(
                              typeof e.target.value,
                              e.target.value.length
                            );
                            const value =
                              e.target.value === "true"
                                ? Boolean(e.target.value)
                                : e.target.value === "false"
                                ? false
                                : "";
                            setVerified(value);
                          }}
                        >
                          <option value="">Select Status</option>
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-2">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {takeoftime.length ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th
                                  className="sort cursor-pointer"
                                  data-sort="category"
                                  onClick={() => sortBy("category")}
                                >
                                  Category
                                </th> */}
                                <th>Time</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            

                            <Droppable droppableId="tbody">
                              {
                                (provided) => (<tbody ref={provided.innerRef} {...provided.droppableProps} className="list form-check-all">
                                  {dataList?.map((item, i) => (
                                    <Draggable draggableId={item._id} key={item._id} index={i}>
                                    {(provided) => (
                                      <tr key={item._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                     
                                      <td>{item.time}</td>
                                      <td>
                                        <Moment format="D MMM YYYY">
                                          {item.created_at}
                                        </Moment>
                                      </td>
                                      <td>
                                        {item.status ? (
                                          <span className="badge badge-soft-success text-uppercase">
                                            Active
                                          </span>
                                        ) : (
                                          <span className="badge badge-soft-danger text-uppercase">
                                            Inactive
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <ul className="list-inline hstack gap-2 mb-0">
                                          <UncontrolledDropdown>
                                            <DropdownToggle className="dropdown-menu-icon">
                                              <i className="ri-more-2-fill"></i>
                                            </DropdownToggle>
                                            <DropdownMenu container="body">
                                            
                                              <DropdownItem>
                                                <Link
                                                  to={"/takeoftime/edit/" + item._id}
                                                  className="color-btn-black d-flex align-items-center edit-item-btn"
                                                >
                                                  <i className="ri-pencil-fill fs-16 mr-2"></i>{" "}
                                                  Edit
                                                </Link>
                                              </DropdownItem>
                                              <DropdownItem
                                                className="list-inline-item"
                                                title="Status Change"
                                              >
                                                <div
                                                  onClick={() =>
                                                    onClickStatusChange(
                                                      item._id,
                                                      item.status ? "false" : "true"
                                                    )
                                                  }
                                                  className="p-0 bg-transparent border-0 text-warning d-flex align-items-center"
                                                >
                                                  <i className="ri-exchange-line fs-16 mr-2"></i>
                                                  Status Change
                                                </div>
                                                <i className="fs-16"></i>
                                              </DropdownItem>

                                              <DropdownItem>
                                                <div
                                                  className="p-0 bg-transparent border-0 d-flex align-items-center text-danger"
                                                  onClick={() =>
                                                    openDeleteConfrontation(
                                                      item?._id
                                                    )
                                                  }
                                                >
                                                  <i className="ri-delete-bin-5-fill fs-16 mr-2"></i>{" "}
                                                  Delete
                                                </div>
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </ul>
                                      </td>
                                      </tr>
                                    )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>)
                              }
                            </Droppable>


                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </DragDropContext>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                  <Modal
                    isOpen={modal}
                    toggle={openDeleteConfrontation}
                    trigger="click"
                  >
                    <ModalHeader>Delete</ModalHeader>
                    <ModalBody>
                      Are you sure want to delete?
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <Button
                          onClick={() => {
                            setModal(!modal);
                          }}
                          className="btn btn-sm btn-light"
                        >
                          No
                        </Button>

                        <Button
                          onClick={() => {
                            deleteTakeoftime(deletedId);
                            setModal(!modal);
                          }}
                          className="btn btn-sm btn-danger ms-2"
                        >
                          Yes
                        </Button>
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}


          {saveOrderingSuccess ? (
            <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
            />
          ) : null}
          {saveOrderingFaild ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
      </div>
    </>
  );
}

export default List;
