import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
    GET_POINTS,
    ADD_NEW_POINTS,
    GET_POINTS_DETAILS,
    POINTS_PAYMENT_STATUS_CHANGE,
    DELETE_POINTS,
    STATUS_CHANGE_POINTS
} from "./actionTypes"

import {
    getPointsSuccess,
    getPointsFail,
    addNewPointSuccess,
    addNewPointFail,
    getPointDetailsSuccess,
    getPointDetailsFail,
    statusChangePointPaymentSuccess,
    statusChangePointPaymentFail,
    deletePointSuccess,
    deletePointFail,
    statusChangePointSuccess,
    statusChangePointFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getPointList,
    addNewOrder,
    getOrderDetails,
    statusChangeOrderPayment,
    deleteOrder,
    statusChangeOrder
} from "../../helpers/fakebackend_helper"

function* fetchPoints({ payload: point }) {
    try {
        const response = yield call(getPointList, point)
        yield put(getPointsSuccess(response))
    } catch (error) {
        yield put(getPointsFail(error))
    }
}

export function* watchFetchPoints() {
    yield takeEvery(GET_POINTS, fetchPoints);
}

function* onAddNewPoint({ payload: point }) {
    try {
        const response = yield call(addNewOrder, point);
        if (response.status === 200) {
            yield put(addNewPointSuccess(response));
        } else {
            yield put(addNewPointFail(response));
        }
    } catch (error) {
        yield put(addNewPointFail(error));
    }
}

export function* watchAddNewPoint() {
    yield takeEvery(ADD_NEW_POINTS, onAddNewPoint);
}

function* onPointDetails({ payload: point }) {
    try {
        const response = yield call(getOrderDetails, point);
        if (response.status === 200) {
            yield put(getPointDetailsSuccess(response));
        } else {
            yield put(getPointDetailsFail(response));
        }
    } catch (error) {
        yield put(getPointDetailsFail(error));
    }
}

export function* watchPointDetails() {
    yield takeEvery(GET_POINTS_DETAILS, onPointDetails);
}


function* onStatusChangPointPayment({ payload: point }) {
    try {
        const response = yield call(statusChangeOrderPayment, point);
        yield put(statusChangePointPaymentSuccess({ point, ...response }));
    } catch (error) {
        yield put(statusChangePointPaymentFail(error));
    }
}

export function* watchStatusChangePointPayment() {
    yield takeEvery(POINTS_PAYMENT_STATUS_CHANGE, onStatusChangPointPayment);
}

//Delete Data
function* onDeletePoint({ payload: point }) {
    try {
        const response = yield call(deleteOrder, point);
        yield put(deletePointSuccess({ point, ...response }));
    } catch (error) {
        yield put(deletePointFail(error));
    }
}

export function* watchDeleteOrder() {
    yield takeEvery(DELETE_POINTS, onDeletePoint);
}



//Status Change
function* onStatusChangPoint({ payload: point }) {
    try {
        const response = yield call(statusChangeOrder, point);
        yield put(statusChangePointSuccess({ point, ...response }));
    } catch (error) {
        yield put(statusChangePointFail(error));
    }
}

export function* watchStatusChangePoint() {
    yield takeEvery(STATUS_CHANGE_POINTS, onStatusChangPoint);
}



function* pointSaga() {
    yield all(
        [
            fork(watchFetchPoints),
            fork(watchAddNewPoint),
            fork(watchPointDetails),
            fork(watchStatusChangePointPayment),
            fork(watchDeleteOrder),
            fork(watchStatusChangePoint)
        ]
    );

}

export default pointSaga
