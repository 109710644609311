import React from 'react';
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { TiTickOutline } from 'react-icons/ti';
import { BsBicycle } from 'react-icons/bs';
import { TbToolsKitchen2 } from 'react-icons/tb'; //BsBicycle
import { HiHome } from 'react-icons/hi';

function LiveOrder() {
  return (
    <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="LIVE ORDERS" />
          <Row>

            <Col lg={12}>
              <Card id="orderList">
                
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  
                    <Row className="g-1">
                      <Col lg={3}><Card><CardBody className='text-center'><TiTickOutline size={25}/>{" "}<b style={{fontSize: 15}}>Accepted (3) </b></CardBody></Card></Col>
                      <Col lg={3}><Card><CardBody className='text-center'><TbToolsKitchen2 size={23}/>{" "}<b style={{fontSize: 15}}>Kitchen (0)</b></CardBody></Card></Col>
                      <Col lg={3}><Card><CardBody className='text-center'><BsBicycle size={25}/>{" "}<b style={{fontSize: 15}}>Ready (0)</b></CardBody></Card></Col>
                      <Col lg={3}><Card><CardBody className='text-center'><HiHome size={23}/>{" "}<b style={{fontSize: 15}}>Out for Delivery (1)</b></CardBody></Card></Col>
                    </Row>

                    <Row className="g-2">
                      <Col lg={3}>
                        <Card style={{boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",borderRadius: '9px',minHeight: '60vh', padding: '5px'}}>
                        
                                <div style={{border: '1px solid lightgrey', borderRadius: '5px', padding: '5px', marginBottom: '5px', cursor: "pointer"}}>
                                    <Row><Col><strong>#12345</strong></Col><Col className='text-end'><strong>Time left</strong></Col></Row>
                                    <Row><Col>Olympiakade 67</Col></Row>
                                    <Row><Col>1023 AX</Col><Col className='text-end'>icon</Col></Row>
                                </div>

                                <div style={{border: '1px solid lightgrey', borderRadius: '5px', padding: '5px', marginBottom: '5px', cursor: "pointer"}}>
                                    <Row><Col><strong>#12345</strong></Col><Col className='text-end'><strong>Time left</strong></Col></Row>
                                    <Row><Col>Olympiakade 67</Col></Row>
                                    <Row><Col>1023 AX</Col><Col className='text-end'>icon</Col></Row>
                                </div>

                                <div style={{border: '1px solid lightgrey', borderRadius: '5px', padding: '5px', marginBottom: '5px', cursor: "pointer"}}>
                                    <Row><Col><strong>#12345</strong></Col><Col className='text-end'><strong>Time left</strong></Col></Row>
                                    <Row><Col>Olympiakade 67</Col></Row>
                                    <Row><Col>1023 AX</Col><Col className='text-end'>icon</Col></Row>
                                </div>

                               
                        </Card>
                      </Col>

                      <Col lg={3}>
                        <Card style={{boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",borderRadius: '9px', minHeight: '60vh', padding: '5px'}}>
                            <CardBody>
                        
                            </CardBody>
                        
                        </Card>
                      </Col>

                      <Col lg={3}>
                        <Card style={{boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",borderRadius: '9px', minHeight: '60vh', padding: '5px'}}>
                            <CardBody>
                        
                            </CardBody>
                        
                        </Card>
                      </Col>
              
                      <Col lg={3}>
                        <Card style={{boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",borderRadius: '9px', minHeight: '60vh', padding: '5px'}}>
                                
                                <div style={{border: '1px solid lightgrey', borderRadius: '5px', padding: '5px' ,marginBottom: '5px', backgroundColor: 'lightgreen', cursor: "pointer"}}>
                                    <Row><Col><strong>#12345</strong></Col><Col className='text-end'><strong>Time left</strong></Col></Row>
                                    <Row><Col>Olympiakade 67</Col></Row>
                                    <Row><Col>1023 AX</Col><Col className='text-end'>icon</Col></Row>
                                </div>
                        </Card>
                      </Col>
                    </Row>

                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
  )
}

export default LiveOrder;