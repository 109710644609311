import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Card,
  CardBody,
  FormFeedback,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import MsgToast from "../../../Components/Common/MsgToast";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useHistory } from "react-router-dom";


import {
  addNewBlog,
  getCategories,
  resetBlogFlag,
  getAuthors,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { convertToBase64 } from "../../../helpers/image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Add() {
  const allowedExts = ["jpg", "jpeg", "png"];
  document.title = "Create Blog | Dabbawala";
  const [category_type, setCategoryType] = useState("blogs");
  const [blogImg, setBlogImage] = useState(null);
  const [bannerImg, setBannerImg] = useState(null);
  const [status, setStatus] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [short_description, setData] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [enable, setEnable] = useState(true)
  const { isBlogAdd, isBlogAddFail, error, success, categories, authors } = useSelector(
    (state) => ({
      isBlogAdd: state.Blogs.isBlogAdd,
      isBlogAddFail: state.Blogs.isBlogAddFail,
      error: state.Blogs.error,
      success: state.Blogs.success,
      categories: state.Categories.categories,
      authors: state.Authors.authors
    })
  );

  const [selectedAuth, setSelectedAuth] = useState("");
  function handleSelectAuthType(value, label) {
    const setAuthValue = { value: value, label: label };
    setSelectedAuth(setAuthValue);
  }

  const [selectedCatType, setselectedCatType] = useState("");
  function handleSelectType(value, label) {
    const setCategoryValue = { value: value, label: label };
    setselectedCatType(setCategoryValue);
  }


  const dispatch = useDispatch();
  const history = useHistory();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: "",
      category_id: "",
      short_description: "",
      author: "",
      metaText: "",
      readingTime: "",
      comments: "",
      seo_keywords: "",
      seo_author: "",
      seo_title: "",
      seo_description: ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Title"),
    }),
    onSubmit: (values) => {
      const data = {
        title: values.title,
        short_description: short_description,
        category_id: selectedCatType.value,
        image: blogImg,
        bannerimg: bannerImg,
        author: selectedAuth.value,
        metaText: values.metaText,
        readingTime: values.readingTime,
        comments: values.comments,
        seo_keywords: values.seo_keywords,
        seo_author: values.seo_author,
        seo_title: values.seo_title,
        seo_description: values.seo_description
      };
      console.log(data);
      dispatch(addNewBlog(data));
    },
  });

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        convertToBase64(e.target.files[0], setBlogImage);
      }
    }
  };

  const onImageChange = (e) =>{
    if (e.target.files[0]) {
      const exten = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(exten)) {
        console.log("Please upload a valid Banner image");
        toast("Please upload a valid Banner image.");
      } else {
        convertToBase64(e.target.files[0], setBannerImg);
      }
    }
  }

  useEffect(() => {
    if (isBlogAdd || isBlogAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetBlogFlag());
        history.push("/blogs");
      }, 3000);
    }
  }, [dispatch, isBlogAdd, isBlogAddFail, validation, history]);

  useEffect(() => {
    dispatch(getCategories({ category_type, status }));
  }, [dispatch, category_type, status]);

  useEffect(() => {
    dispatch(getAuthors())
  }, [dispatch])

  

  return (
    <>
      {" "}
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create Blog" pageTitle="Blogs" pageUrl="/blogs" />
          <Row>
            <Col lg={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <PreviewCardHeader title="Create Blog" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title*
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="title"
                              className="form-control"
                              placeholder="Blog name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.title || ""}
                              invalid={
                                validation.touched.title &&
                                  validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                              validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Author
                            </Label>
                            <Select
                              value={selectedAuth || ""}
                              onChange={(event) => {
                                handleSelectAuthType(event.value, event.label);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                authors.length &&
                                authors.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))
                              }
                              name="author"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.author &&
                                  validation.errors.author
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.author &&
                              validation.errors.author ? (
                              <FormFeedback type="invalid">
                                {validation.errors.author}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category
                            </Label>
                            <Select
                              value={selectedCatType || ""}
                              onChange={(event) => {
                                handleSelectType(event.value, event.label);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                categories.length &&
                                categories.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))
                              }
                              name="category"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category &&
                                  validation.errors.category
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category &&
                              validation.errors.category ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Blog Image
                            </Label>
                            <Input type="file" onChange={onImgChange} />
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Banner Image
                            </Label>
                            <Input type="file" onChange={onImageChange}/>
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              SEO Keywords
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="seo_keywords"
                              className="form-control"
                              placeholder="SEO Keywords"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.seo_keywords || ""}
                              invalid={
                                validation.touched.seo_keywords &&
                                  validation.errors.seo_keywords
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.seo_keywords &&
                              validation.errors.seo_keywords ? (
                              <FormFeedback type="invalid">
                                {validation.errors.seo_keywords}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              SEO Author
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="seo_author"
                              className="form-control"
                              placeholder="SEO Author"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.seo_author || ""}
                              invalid={
                                validation.touched.seo_author &&
                                  validation.errors.seo_author
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.seo_author &&
                              validation.errors.seo_author ? (
                              <FormFeedback type="invalid">
                                {validation.errors.seo_author}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              SEO Title
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="seo_title"
                              className="form-control"
                              placeholder="SEO Title"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.seo_title || ""}
                              invalid={
                                validation.touched.seo_title &&
                                  validation.errors.seo_title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.seo_title &&
                              validation.errors.seo_title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.seo_title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              SEO Description
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="seo_description"
                              className="form-control"
                              placeholder="SEO Description"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.seo_description || ""}
                              invalid={
                                validation.touched.seo_description &&
                                  validation.errors.seo_description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.seo_description &&
                              validation.errors.seo_description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.seo_description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Reading time
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="readingTime"
                              className="form-control"
                              placeholder="Reading Time"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.readingTime || ""}
                              invalid={
                                validation.touched.readingTime &&
                                  validation.errors.readingTime
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.readingTime &&
                              validation.errors.readingTime ? (
                              <FormFeedback type="invalid">
                                {validation.errors.readingTime}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="product-title-input"
                            >
                              Comments
                            </Label>
                            {" "}
                            <input type='checkbox' onClick={() => { setEnable(!enable) }}></input>
                            
                            <Input
                              type="text"
                              className="form-control"
                              id="product-title-input"
                              placeholder={enable ? "Enter Comments" : "Disabled"}
                              name="comments"
                              readOnly={!enable}
                              value={validation.values.comments || ""}
                              onChange={validation.handleChange}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.comments &&
                                  validation.errors.comments
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.comments &&
                              validation.errors.comments ? (
                              <FormFeedback type="invalid">
                                {validation.errors.comments}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Blog text
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="short_description"
                              data={validation.values.short_description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.short_description &&
                              validation.errors.short_description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.short_description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        
                      </Row>


                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/blogs" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          {isBlogAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isBlogAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
}

export default Add;
