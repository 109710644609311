import {
  GET_BLOGS_SUCCESS,
  GET_BLOGS_FAIL,
  GET_BLOGCOMMENTS_SUCCESS,
  GET_BLOGCOMMENTS_FAIL,
  ADD_NEW_BLOG_SUCCESS,
  ADD_NEW_BLOG_FAIL,
  GET_BLOG_DETAILS_SUCCESS,
  GET_BLOG_DETAILS_FAIL,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG_FAIL,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
  DELETE_BLOGCOMMENT_SUCCESS,
  DELETE_BLOGCOMMENT_FAIL,
  MODIFY_BLOG_FLAG,
  MODIFY_BLOGCOMMENT_FLAG,
  STATUS_CHANGE_BLOG_SUCCESS,
  STATUS_CHANGE_BLOG_FAIL,
  STATUS_CHANGE_BLOGCOMMENT_SUCCESS,
  STATUS_CHANGE_BLOGCOMMENT_FAIL,
  RESET_BLOG_FLAG,
  RESET_BLOGCOMMENT_FLAG,

  SAVE_ORDERING_BLOG_SUCCESS,
  SAVE_ORDERING_BLOG_FAIL,
  SAVE_ORDERING_BLOG_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  blogs: [],
  blogcomments: [],
};

const Blogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload.data,
        isSuccess: true,
      };

    case GET_BLOGS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
      };
 
    case GET_BLOGCOMMENTS_SUCCESS:
      return {
        ...state,
        blogcomments: action.payload.data,
        isSuccess: true,
      };

    case GET_BLOGCOMMENTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
      };

      case ADD_NEW_BLOG_SUCCESS:
      return {
        ...state,
        blogs: [...state.blogs, action.payload.data],
        success: action.payload.message,
        isBlogAdd: true,
        isBlogAddFail: false,
      };

    case ADD_NEW_BLOG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBlogAdd: false,
        isBlogAddFail: true,
      };

    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: state.blogs.map((blog) =>
          blog._id.toString() === action.payload.data._id.toString()
            ? { ...blog, ...action.payload.data }
            : blog
        ),
        success: action.payload.message,
        isBlogUpdate: true,
        isBlogUpdateFail: false,
      };

    case UPDATE_BLOG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBlogUpdate: false,
        isBlogUpdateFail: true,
      };

    case GET_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_BLOG_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: state.blogs.filter((getData) => {
          return getData._id !== action.payload.data._id;
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isBlogRemove: true,
        isBlogRemoveFail: false,
      };

    case DELETE_BLOG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isBlogRemove: false,
        isBlogRemoveFail: true,
      };

      case DELETE_BLOGCOMMENT_SUCCESS:
        return {
          ...state,
          blogcomments: state.blogcomments.filter((getData) => {
            return getData._id !== action.payload.data._id;
          }),
          success: action.payload.message,
          isSuccess: true,
          statusSuccess: true,
          statusFailed: false,
          isBlogCommentRemove: true,
          isBlogCommentRemoveFail: false,
        };
  
      case DELETE_BLOGCOMMENT_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false,
          statusSuccess: false,
          statusFailed: true,
          isBlogCommentRemove: false,
          isBlogCommentRemoveFail: true,
        };

    //Status Change
    case STATUS_CHANGE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: state.blogs.map((blog) =>
          blog._id.toString() === action.payload.data._id.toString()
            ? { ...blog, ...action.payload.data }
            : blog
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_BLOG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    
      case STATUS_CHANGE_BLOGCOMMENT_SUCCESS:
      return {
        ...state,
        blogcomments: state.blogcomments.map((blog) =>
          blog._id.toString() === action.payload.data._id.toString()
            ? { ...blog, ...action.payload.data }
            : blog
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_BLOGCOMMENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };



      //Save Ordering
    case SAVE_ORDERING_BLOG_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_BLOG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_BLOG_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

      
    case RESET_BLOG_FLAG:
      return {
        ...state,
        isBlogAdd: false,
        isBlogAddFail: false,
        isSuccess: false,
        isBlogDetails: false,
        isBlogUpdate: false,
        isBlogUpdateFail: false,
        error: false,
      };
      case RESET_BLOGCOMMENT_FLAG:
        return {
          ...state,
          isBlogCommnetAdd: false,
          isBlogCommnetAddFail: false,
          isSuccess: false,
          isBlogCommnetDetails: false,
          isBlogCommnetUpdate: false,
          isBlogCommnetUpdateFail: false,
          error: false,
        };
    case MODIFY_BLOG_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

      case MODIFY_BLOGCOMMENT_FLAG:
        return {
          ...state,
          statusSuccess: false,
        };

    default:
      return state;
  }
};

export default Blogs;
