import {
  GET_JOBAPPLIES,
  GET_JOBAPPLIES_SUCCESS,
  GET_JOBAPPLIES_FAIL,
  ADD_NEW_JOBAPPLY,
  ADD_NEW_JOBAPPLY_SUCCESS,
  ADD_NEW_JOBAPPLY_FAIL,
  // GET_JOBAPPLY_DETAILS,
  // GET_JOBAPPLY_DETAILS_SUCCESS,
  // GET_JOBAPPLY_DETAILS_FAIL,
  UPDATE_JOBAPPLY,
  UPDATE_JOBAPPLY_SUCCESS,
  UPDATE_JOBAPPLY_FAIL,
  DELETE_JOBAPPLY,
  DELETE_JOBAPPLY_SUCCESS,
  DELETE_JOBAPPLY_FAIL,
  RESET_JOBAPPLY_FLAG,
} from "./actionTypes";

export const getJobapplies = (data) => ({
  type: GET_JOBAPPLIES,
  payload: data,
});

export const getJobappliesSuccess = (data) => ({
  type: GET_JOBAPPLIES_SUCCESS,
  payload: data,
});

export const getJobappliesFail = (error) => ({
  type: GET_JOBAPPLIES_FAIL,
  payload: error,
});

export const addNewJobapply = (jobapply) => ({
  type: ADD_NEW_JOBAPPLY,
  payload: jobapply,
});

export const addNewJobapplySuccess = (jobapply) => ({
  type: ADD_NEW_JOBAPPLY_SUCCESS,
  payload: jobapply,
});

export const addNewJobapplyFail = (error) => ({
  type: ADD_NEW_JOBAPPLY_FAIL,
  payload: error,
});

export const updateJobapply = (jobapply) => ({
  type: UPDATE_JOBAPPLY,
  payload: jobapply,
});

export const updateJobapplySuccess = (jobapply) => ({
  type: UPDATE_JOBAPPLY_SUCCESS,
  payload: jobapply,
});

export const updateJobapplyFail = (error) => ({
  type: UPDATE_JOBAPPLY_FAIL,
  payload: error,
});

// export const getJobapplyDetails = (jobapply) => ({
//   type: GET_JOBAPPLY_DETAILS,
//   payload: jobapply,
// });

// export const getJobapplyDetailsSuccess = (jobapply) => ({
//   type: GET_JOBAPPLY_DETAILS_SUCCESS,
//   payload: jobapply,
// });

// export const getJobapplyDetailsFail = (error) => ({
//   type: GET_JOBAPPLY_DETAILS_FAIL,
//   payload: error,
// });

export const delJobapply = (data) => ({
  type: DELETE_JOBAPPLY,
  payload: data,
});

export const deleteJobapplySuccess = (data) => ({
  type: DELETE_JOBAPPLY_SUCCESS,
  payload: data,
});

export const deleteJobapplyFail = (data) => ({
  type: DELETE_JOBAPPLY_FAIL,
  payload: data,
});

export const resetJobapplyFlag = () => {
  return {
    type: RESET_JOBAPPLY_FLAG,
  };
};
