import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  addNewFunfact,
  resetFunfactFlag,
  getPages,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";
import Select from "react-select";

const FunfactCreate = () => {
  document.title = "Create Funfact | Dabbawala";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [pagetype, setPagetype] = useState("");
  const [type, setPageType] = useState("");
  const [image, setImage] = useState();
  const [description, setData] = useState();
  const { isFunfactAdd, isFunfactAddFail, error, success, pages } = useSelector(
    (state) => ({
      isFunfactAdd: state.Funfacts.isFunfactAdd,
      isFunfactAddFail: state.Funfacts.isFunfactAddFail,
      error: state.Funfacts.error,
      success: state.Funfacts.success,
      pages: state.Pages.pages,
    })
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      description: ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description")
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.title,
        description: values.description
      };
      dispatch(addNewFunfact(data));
    },
  });

  useEffect(() => {
    if (isFunfactAdd || isFunfactAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetFunfactFlag());
        history.push('/funfacts')
      }, 3000);
    }
  }, [dispatch, isFunfactAdd, isFunfactAddFail, validation, history]);

  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);

  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
    console.log(pages);
  }, [pages]);

  // const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  // const onImg1Change = (e) => {
  //   if (e.target.files[0]) {
  //     const ext = e.target.files[0].type.split("/")[1];

  //     if (![...allowedExts].includes(ext)) {
  //       console.log("Please upload a valid image");
  //       toast("Please upload a valid image.");
  //     } else {
  //       var output = document.getElementById("image1");
  //       output.src = URL.createObjectURL(e.target.files[0]);
  //       output.onload = function () {
  //         URL.revokeObjectURL(output.src); // free memory
  //       };
  //       convertToBase64(e.target.files[0], setImage);
  //     }
  //   }
  // };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Funfact"
            pageTitle="Funfacts"
            pageUrl="/funfacts"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Funfact" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        {/* <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Image *
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImg1Change}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                            {validation.touched.image &&
                            validation.errors.image ? (
                              <FormFeedback type="invalid">
                                {validation.errors.image}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        {/* <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Designation *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Designation"
                              name="designation"
                              value={validation.values.designation || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.designation &&
                                validation.errors.designation
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.designation &&
                            validation.errors.designation ? (
                              <FormFeedback type="invalid">
                                {validation.errors.designation}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                            Description*
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              rows="3"
                              className="form-control"
                              placeholder="Enter description"
                              name="description"
                              value={validation.values.description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.description &&
                                validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                    </Col>

                        {/* <Col xxl={3} md={4}>
                          <div>
                            <Label htmlFor="product-title-input">Page *</Label>
                            <Select
                              type="text"
                              name="type"
                              placeholder="Select Page Type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "type",
                                    value: selectedOption,
                                  },
                                };
                                setPageType(selectedOption.value);
                                console.log(type);
                                validation.handleChange(event);
                              }}
                              options={pagetype}
                              onBlur={validation.handleBlur}
                              value={validation.values.type || ""}
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}

                        {/* <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Button Name *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Button Name"
                              name="button_name"
                              value={validation.values.button_name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.button_name &&
                                validation.errors.button_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.button_name &&
                            validation.errors.button_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.button_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Button Url *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Button Url"
                              name="button_url"
                              value={validation.values.button_url || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.button_url &&
                                validation.errors.button_url
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.button_url &&
                            validation.errors.button_url ? (
                              <FormFeedback type="invalid">
                                {validation.errors.button_url}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/funfacts" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isFunfactAdd ? (
            <MsgToast
              msg={success}
              color="danger"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isFunfactAddFail ? (
            <MsgToast msg={error} color="success" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default FunfactCreate;
