import {
    GET_ORGANIZATIONS_SUCCESS,
    GET_ORGANIZATIONS_FAIL,
    ADD_NEW_ORGANIZATION_SUCCESS,
    ADD_NEW_ORGANIZATION_FAIL,
    GET_ORGANIZATION_DETAILS_SUCCESS,
    GET_ORGANIZATION_DETAILS_FAIL,
    UPDATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_FAIL,
    RESET_ORGANIZATION_FLAG,
    DELETE_ORGANIZATION_SUCCESS,
    DELETE_ORGANIZATION_FAIL,
    STATUS_CHANGE_ORGANIZATION_SUCCESS,
    STATUS_CHANGE_ORGANIZATION_FAIL,
    MODIFY_ORGANIZATION_FLAG, 
  } from "./actionTypes";
  
  const INIT_STATE = {
    organisations: [],
    totalDataCount: 0,
    isSuccess: false,
    transactionList: [],
  };
  
  const Organisations = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ORGANIZATIONS_SUCCESS:
        return {
          ...state,
          organisations: action.payload.data,
          totalDataCount: action.payload.count,
          isSuccess: true
        };
  
      case GET_ORGANIZATIONS_FAIL:
        return {
          ...state,
          error: action.payload.message,
          totalDataCount: 0,
          isSuccess: false
        };
  
      case ADD_NEW_ORGANIZATION_SUCCESS:
        return {
          ...state,
          organisations: [...state.organisations, action.payload.data],
          success: action.payload.message,
          isOrganizationAdd: true,
          isOrganizationAddFail: false,
        };
  
      case ADD_NEW_ORGANIZATION_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isOrganizationAdd: false,
          isOrganizationAddFail: true,
        };
  
      case UPDATE_ORGANIZATION_SUCCESS:
        return {
          ...state,
          organisations: state.organisations.map(organization =>
            organization._id.toString() === action.payload.data._id.toString()
              ? { ...organization, ...action.payload.data }
              : organization
          ),
          success: action.payload.message,
          isOrganizationUpdate: true,
          isOrganizationUpdateFail: false
        };
  
      case UPDATE_ORGANIZATION_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isOrganizationUpdate: false,
          isOrganizationUpdateFail: true,
        };
  
      case GET_ORGANIZATION_DETAILS_SUCCESS:
        return {
          ...state,
          details: action.payload.data,
          transactionList: action.payload.translation_list,
        };
  
      case GET_ORGANIZATION_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload.message,
        };
      //Delete
      case DELETE_ORGANIZATION_SUCCESS:
        return { 
          ...state,
          organisations: state.organisations.filter(getData =>
            {
              //console.log(getData, action.payload);
              return getData._id !== action.payload.data._id
            }
            
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
    
      case DELETE_ORGANIZATION_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
    
        
      //Status Change
      case STATUS_CHANGE_ORGANIZATION_SUCCESS:
        return {
          ...state,
          organisations: state.organisations.map(getData =>
            getData._id.toString() === action.payload.data._id.toString()
              ? { ...getData, ...action.payload.data }
            : getData
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
          
      case STATUS_CHANGE_ORGANIZATION_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
  
      case MODIFY_ORGANIZATION_FLAG:
        return {
          ...state,
          statusSuccess: false,
          statusFaild: false,
          error: false
        };
  
      case RESET_ORGANIZATION_FLAG:
        return {
          ...state,
          isOrganizationAdd: false,
          isOrganizationAddFail: false,
          isSuccess: false,
          isOrganizationDetails: false,
          isOrganizationUpdate: false,
          isOrganizationUpdateFail: false,
          error: false
        };
  
      default:
        return state;
    }
  };
  
  export default Organisations;
  