
import {
  GET_JOBS,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAIL,
  ADD_NEW_JOB,
  ADD_NEW_JOB_SUCCESS,
  ADD_NEW_JOB_FAIL,
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_FAIL,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAIL,
 
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAIL,

  MODIFY_JOB_FLAG,
  RESET_JOB_FLAG,
  STATUS_CHANGE_JOB,
  STATUS_CHANGE_JOB_SUCCESS,
  STATUS_CHANGE_JOB_FAIL,

  SAVE_ORDERING_JOB,
  SAVE_ORDERING_JOB_SUCCESS,
  SAVE_ORDERING_JOB_FAIL,
  SAVE_ORDERING_JOB_RESET_FLAG,
} from "./actionTypes";

export const getJobs = data => ({
  type: GET_JOBS,
  payload: data,
});

export const getJobsSuccess = data => ({
  type: GET_JOBS_SUCCESS,
  payload: data,
});

export const getJobsFail = error => ({
  type: GET_JOBS_FAIL,
  payload: error,
});

export const addNewJob = job=> ({
  type: ADD_NEW_JOB,
  payload: job,
});

export const addNewJobSuccess = job => ({
  type: ADD_NEW_JOB_SUCCESS,
  payload: job,
});

export const addNewJobFail = error => ({
  type: ADD_NEW_JOB_FAIL,
  payload: error,
});

export const updateJob = job => ({
  type: UPDATE_JOB,
  payload: job,
});

export const updateJobSuccess = job => ({
  type: UPDATE_JOB_SUCCESS,
  payload: job,
});

export const updateJobFail = error => ({
  type: UPDATE_JOB_FAIL,
  payload: error,
});

export const getJobDetails = job => ({
  type: GET_JOB_DETAILS,
  payload: job,
});

export const getJobDetailsSuccess = job => ({
  type: GET_JOB_DETAILS_SUCCESS,
  payload: job,
});

export const getJobDetailsFail = error => ({
  type: GET_JOB_DETAILS_FAIL,
  payload: error,
});

export const delJob = data => ({
  type: DELETE_JOB,
  payload: data,
});

export const deleteJobSuccess = data => ({
  type: DELETE_JOB_SUCCESS,
  payload: data,
});

export const deleteJobFail = data => ({
  type: DELETE_JOB_FAIL,
  payload: data,
});

export const statusChangeJob = job => ({
  type: STATUS_CHANGE_JOB,
  payload: job,
});

export const statusChangeJobSuccess = job => ({
  type: STATUS_CHANGE_JOB_SUCCESS,
  payload: job,
});

export const statusChangeJobFail = error => ({
  type: STATUS_CHANGE_JOB_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingJob = data => ({
  type: SAVE_ORDERING_JOB,
  payload: data,
});

export const saveOrderingJobSuccess = data => ({
  type: SAVE_ORDERING_JOB_SUCCESS,
  payload: data,
});

export const saveOrderingJobFail = error => ({
  type: SAVE_ORDERING_JOB_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingJobResetFlag = () => {
  return {
    type: SAVE_ORDERING_JOB_RESET_FLAG,
  }
}


export const modifyJobFlag = () => {
  return {
    type: MODIFY_JOB_FLAG,
  }
}

export const resetJobFlag = () => {
  return {
    type: RESET_JOB_FLAG,
  }
}