/* OPENINGHOUR */
export const GET_OPENINGHOUR = "GET_OPENINGHOUR";
export const GET_OPENINGHOUR_SUCCESS = "GET_OPENINGHOUR_SUCCESS";
export const GET_OPENINGHOUR_FAIL = "GET_OPENINGHOUR_FAIL";

export const ADD_NEW_OPENINGHOUR = "ADD_NEW_OPENINGHOUR";
export const ADD_NEW_OPENINGHOUR_SUCCESS = "ADD_NEW_OPENINGHOUR_SUCCESS";
export const ADD_NEW_OPENINGHOUR_FAIL = "ADD_NEW_OPENINGHOUR_FAIL";

export const GET_OPENINGHOUR_DETAILS = "GET_OPENINGHOUR_DETAILS";
export const GET_OPENINGHOUR_DETAILS_SUCCESS = "GET_OPENINGHOUR_DETAILS_SUCCESS";
export const GET_OPENINGHOUR_DETAILS_FAIL = "GET_OPENINGHOUR_DETAILS_FAIL";

export const UPDATE_OPENINGHOUR = "UPDATE_OPENINGHOUR";
export const UPDATE_OPENINGHOUR_SUCCESS = "UPDATE_OPENINGHOUR_SUCCESS";
export const UPDATE_OPENINGHOUR_FAIL = "UPDATE_OPENINGHOUR_FAIL";

export const STATUS_CHANGE_OPENINGHOUR = "STATUS_CHANGE_OPENINGHOUR";
export const STATUS_CHANGE_OPENINGHOUR_SUCCESS = "STATUS_CHANGE_OPENINGHOUR_SUCCESS";
export const STATUS_CHANGE_OPENINGHOUR_FAIL = "STATUS_CHANGE_OPENINGHOUR_FAIL";

export const DELETE_OPENINGHOUR = "DELETE_OPENINGHOUR";
export const DELETE_OPENINGHOUR_SUCCESS = "DELETE_OPENINGHOUR_SUCCESS";
export const DELETE_OPENINGHOUR_FAIL = "DELETE_OPENINGHOUR_FAIL";

export const SAVE_ORDERING_OPENINGHOUR = "SAVE_ORDERING_OPENINGHOUR";
export const SAVE_ORDERING_OPENINGHOUR_SUCCESS = "SAVE_ORDERING_OPENINGHOUR_SUCCESS";
export const SAVE_ORDERING_OPENINGHOUR_FAIL = "SAVE_ORDERING_OPENINGHOUR_FAIL";
export const SAVE_ORDERING_OPENINGHOUR_RESET_FLAG = "SAVE_ORDERING_OPENINGHOUR_RESET_FLAG";

export const RESET_OPENINGHOUR_FLAG = "RESET_OPENINGHOUR_FLAG";
export const MODIFY_OPENINGHOUR_FLAG = "MODIFY_OPENINGHOUR_FLAG";