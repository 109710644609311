import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_SERVICES,
  ADD_NEW_SERVICE,
  GET_SERVICE_DETAILS,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  SAVE_ORDERING_SERVICE,
  STATUS_CHANGE_SERVICE
} from "./actionTypes"

import {
  getServicesSuccess,
  getServicesFail,
  addNewServiceSuccess,
  addNewServiceFail,
  getServiceDetailsSuccess,
  getServiceDetailsFail,
  updateServiceSuccess,
  updateServiceFail,
  deleteServiceSuccess,
  deleteServiceFail,
  saveOrderingServiceSuccess,
  saveOrderingServiceFail,
  statusChangeServiceSuccess,
  statusChangeServiceFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getServiceList,
  addNewService,
  getServiceDetails,
  updateService,
  deleteService,
  saveOrdering,
  statusChangeService
} from "../../helpers/fakebackend_helper"

function* fetchServices({ payload: service }) {
  try {
    const response = yield call(getServiceList, service)
    yield put(getServicesSuccess(response))
  } catch (error) {
    yield put(getServicesFail(error))
  }
}

export function* watchFetchServices() {
  yield takeEvery(GET_SERVICES, fetchServices);
}

function* onAddNewService({ payload: service }) {
  try {
      const response = yield call(addNewService, service);
      if(response.status === 200){
        yield put(addNewServiceSuccess(response));
      } else {
        yield put(addNewServiceFail(response));
      }
  } catch (error) {
      yield put(addNewServiceFail(error));
  }
}

export function* watchAddNewService() {
  yield takeEvery(ADD_NEW_SERVICE, onAddNewService);
}

function* onServiceDetails({ payload: service }) {
  try {
      const response = yield call(getServiceDetails, service);
      if(response.status === 200){
        yield put(getServiceDetailsSuccess(response));
      } else {
        yield put(getServiceDetailsFail(response));
      }
  } catch (error) {
      yield put(getServiceDetailsFail(error));
  }
}

export function* watchServiceDetails() {
  yield takeEvery(GET_SERVICE_DETAILS, onServiceDetails);
}

function* onServiceUpdate({ payload: service }) {
  try {
      const response = yield call(updateService, service);
      if(response.status === 200){
        yield put(updateServiceSuccess(response));
      } else {
        yield put(updateServiceFail(response));
      }
  } catch (error) {
      yield put(updateServiceFail(error));
  }
}

export function* watchUpdateService() {
  yield takeEvery(UPDATE_SERVICE, onServiceUpdate);
}


//Delete Data
function* onDeleteService({ payload: service }) {
  try {
    const response = yield call(deleteService, service);
    yield put(deleteServiceSuccess({ service, ...response }));
  } catch (error) {
    yield put(deleteServiceFail(error));
  }
}

export function* watchDeleteService() {
  yield takeEvery(DELETE_SERVICE, onDeleteService);
}



//Status Change
function* onStatusChangService({ payload: service }) {
  try {
    const response = yield call(statusChangeService, service);
    yield put(statusChangeServiceSuccess({ service, ...response }));
  } catch (error) {
    yield put(statusChangeServiceFail(error));
  }
}

export function* watchStatusChangeService() {
  yield takeEvery(STATUS_CHANGE_SERVICE, onStatusChangService);
}

//Save Ordering
function* onSaveOrderingService({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingServiceSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingServiceFail(error));
  }
}

export function* watchSaveOrderingService() {
  yield takeEvery(SAVE_ORDERING_SERVICE, onSaveOrderingService);
}



function* serviceSaga() {
  yield all(
    [
      fork(watchFetchServices),
      fork(watchAddNewService),
      fork(watchServiceDetails),
      fork(watchUpdateService),
      fork(watchDeleteService),
      fork(watchSaveOrderingService),
      fork(watchStatusChangeService)
    ]
  );

}

export default serviceSaga
