import {
  GET_VISIONS_SUCCESS,
  GET_VISIONS_FAIL,
  ADD_NEW_VISION_SUCCESS,
  ADD_NEW_VISION_FAIL,
  GET_VISION_DETAILS_SUCCESS,
  GET_VISION_DETAILS_FAIL,
  UPDATE_VISION_SUCCESS,
  UPDATE_VISION_FAIL,
  RESET_VISION_FLAG,
  DELETE_VISION_SUCCESS,
  DELETE_VISION_FAIL,
  STATUS_CHANGE_VISION_SUCCESS,
  STATUS_CHANGE_VISION_FAIL,
  MODIFY_VISION_FLAG, 

  SAVE_ORDERING_VISION_SUCCESS,
  SAVE_ORDERING_VISION_FAIL,
  SAVE_ORDERING_VISION_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  visions: []
};

const Visions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VISIONS_SUCCESS:
      return {
        ...state,
        visions: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_VISIONS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_VISION_SUCCESS:
      return {
        ...state,
        visions: [...state.visions, action.payload.data],
        success: action.payload.message,
        isVisionAdd: true,
        isVisionAddFail: false,
      };

    case ADD_NEW_VISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isVisionAdd: false,
        isVisionAddFail: true,
      };

    case UPDATE_VISION_SUCCESS:
      return {
        ...state,
        visions: state.visions.map(vision =>
          vision._id.toString() === action.payload.data._id.toString()
            ? { ...vision, ...action.payload.data }
            : vision
        ),
        success: action.payload.message,
        isVisionUpdate: true,
        isVisionUpdateFail: false
      };

    case UPDATE_VISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isVisionUpdate: false,
        isVisionUpdateFail: true,
      };

    case GET_VISION_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_VISION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_VISION_SUCCESS:
      return { 
        ...state,
        visions: state.visions.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_VISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_VISION_SUCCESS:
      return {
        ...state,
        visions: state.visions.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_VISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    //Save Ordering
    case SAVE_ORDERING_VISION_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_VISION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_VISION_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    case MODIFY_VISION_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_VISION_FLAG:
      return {
        ...state,
        isVisionAdd: false,
        isVisionAddFail: false,
        isSuccess: false,
        isVisionDetails: false,
        isVisionUpdate: false,
        isVisionUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Visions;
