import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { updateIngredient, resetIngredientFlag, getIngredientDetails } from '../../../store/actions';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MsgToast from "../../../Components/Common/MsgToast";
import { call} from "redux-saga/effects"

const IngredientEdit = () => {
    document.title = "Edit Ingredient | Defendit - Admin Panel";
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const { isIngredientUpdate, isIngredientUpdateFail, error, success, details } = useSelector(state => ({
        isIngredientUpdate: state.Ingredients.isIngredientUpdate,
        isIngredientUpdateFail: state.Ingredients.isIngredientUpdateFail,
        error: state.Ingredients.error,
        success: state.Ingredients.success,
        details: state.Ingredients.details,
    }));

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: (details && details.title)?details.title:'',
            icon: (details && details.icon)?details.icon:'',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Ingredient Name"),
            icon: Yup.string().required("Please Enter Icon")
        }),
        onSubmit: (values) => {
            setIsCreating(true)
            
            const data = {
                id: id,
                title: values.name,
                icon: values.icon
            }
            dispatch(updateIngredient(data))
        },
    });

    useEffect(() => {
        if(isIngredientUpdate || isIngredientUpdateFail){
            setIsCreating(false)
            setTimeout(() => {
                dispatch(resetIngredientFlag())
            }, 3000);
        }
      }, [dispatch, isIngredientUpdate, isIngredientUpdateFail]);

      useEffect(() => {
        dispatch(getIngredientDetails({id:id}))
      }, [dispatch, id]);

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="Edit Ingredient" pageTitle="Ingredients" pageUrl="/ingredients" />
                    <Row>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            console.log(e);
                            return false;
                        }}>
                            <Col lg={12}>
                                <Card>
                                    <PreviewCardHeader title="Edit Ingredient" />

                                    <CardBody className="card-body">

                                        <div className="live-preview">
                                            <Row className="gy-4">

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Title</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Ingredient title"
                                                            name='name'
                                                            value={validation.values.name || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col xxl={3} md={6}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Icon</Label>
                                                        <Input
                                                            type="text"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Icon name"
                                                            name='icon'
                                                            value={validation.values.icon || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.icon && validation.errors.icon ? true : false
                                                            }
                                                        />
                                                        {validation.touched.icon && validation.errors.icon ? (
                                                            <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                {/* <Col xxl={3} md={12}>
                                                    <div>
                                                        <Label htmlFor="placeholderInput" className="form-label">Description</Label>
                                                        <Input
                                                            type="textarea"
                                                            id='placeholderInput'
                                                            className="form-control"
                                                            placeholder="Description"
                                                            rows="3"
                                                            name='description'
                                                            value={validation.values.description || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            invalid={
                                                                validation.touched.description && validation.errors.description ? true : false
                                                            }
                                                        />
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col> */}

                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="text-end mb-4">
                                    <Link to="/ingredients" className="btn btn-danger w-sm me-1">Cancel</Link>
                                    <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                                        <span className="d-flex align-items-center">
                                            {isCreating && <Spinner size="sm" className="flex-shrink-0 me-2"> Update </Spinner>}
                                            <span className="flex-grow-1">
                                                Update
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    {isIngredientUpdate ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
                    {isIngredientUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    <ToastContainer limit={1} closeButton={false} />
                </Container>
            </div>
        </>
    );
}

export default IngredientEdit;