import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_DIETS,
  ADD_NEW_DIET,
  GET_DIET_DETAILS,
  UPDATE_DIET,
  DELETE_DIET,
  STATUS_CHANGE_DIET,
  SAVE_ORDERING_DIET
} from "./actionTypes"

import {
  getDietsSuccess,
  getDietsFail,
  addNewDietSuccess,
  addNewDietFail,
  getDietDetailsSuccess,
  getDietDetailsFail,
  updateDietSuccess,
  updateDietFail,
  deleteDietSuccess,
  deleteDietFail,
  statusChangeDietSuccess,
  statusChangeDietFail,
  saveOrderingDietSuccess,
  saveOrderingDietFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDietList,
  addNewDiet,
  getDietDetails,
  updateDiet,
  deleteDiet,
  statusChangeDiet,
  saveOrdering
} from "../../helpers/fakebackend_helper"

function* fetchDiets({ payload: diet }) {
  try {
    const response = yield call(getDietList, diet)
    yield put(getDietsSuccess(response))
  } catch (error) {
    yield put(getDietsFail(error))
  }
}

export function* watchFetchDiets() {
  yield takeEvery(GET_DIETS, fetchDiets);
}

function* onAddNewDiet({ payload: diet }) {
  try {
      const response = yield call(addNewDiet, diet);
      if(response.status === 200){
        yield put(addNewDietSuccess(response));
      } else {
        yield put(addNewDietFail(response));
      }
  } catch (error) {
      yield put(addNewDietFail(error));
  }
}

export function* watchAddNewDiet() {
  yield takeEvery(ADD_NEW_DIET, onAddNewDiet);
}

function* onDietDetails({ payload: diet }) {
  try {
      const response = yield call(getDietDetails, diet);
      if(response.status === 200){
        yield put(getDietDetailsSuccess(response));
      } else {
        yield put(getDietDetailsFail(response));
      }
  } catch (error) {
      yield put(getDietDetailsFail(error));
  }
}

export function* watchDietDetails() {
  yield takeEvery(GET_DIET_DETAILS, onDietDetails);
}

function* onDietUpdate({ payload: diet }) {
  try {
      const response = yield call(updateDiet, diet);
      if(response.status === 200){
        yield put(updateDietSuccess(response));
      } else {
        yield put(updateDietFail(response));
      }
  } catch (error) {
      yield put(updateDietFail(error));
  }
}

export function* watchUpdateDiet() {
  yield takeEvery(UPDATE_DIET, onDietUpdate);
}


//Delete Data
function* onDeleteDiet({ payload: diet }) {
  try {
    const response = yield call(deleteDiet, diet);
    yield put(deleteDietSuccess({ diet, ...response }));
  } catch (error) {
    yield put(deleteDietFail(error));
  }
}

export function* watchDeleteDiet() {
  yield takeEvery(DELETE_DIET, onDeleteDiet);
}



//Status Change
function* onStatusChangDiet({ payload: diet }) {
  try {
    const response = yield call(statusChangeDiet, diet);
    yield put(statusChangeDietSuccess({ diet, ...response }));
  } catch (error) {
    yield put(statusChangeDietFail(error));
  }
}

export function* watchStatusChangeDiet() {
  yield takeEvery(STATUS_CHANGE_DIET, onStatusChangDiet);
}

//Save Ordering
function* onSaveOrderingDiet({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingDietSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingDietFail(error));
  }
}

export function* watchSaveOrderingDiet() {
  yield takeEvery(SAVE_ORDERING_DIET, onSaveOrderingDiet);
}



function* dietSaga() {
  yield all(
    [
      fork(watchFetchDiets),
      fork(watchAddNewDiet),
      fork(watchDietDetails),
      fork(watchUpdateDiet),
      fork(watchDeleteDiet),
      fork(watchStatusChangeDiet),
      fork(watchSaveOrderingDiet)
    ]
  );

}

export default dietSaga
