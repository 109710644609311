/* DIETS */
export const GET_DIETS = "GET_DIETS";
export const GET_DIETS_SUCCESS = "GET_DIETS_SUCCESS";
export const GET_DIETS_FAIL = "GET_DIETS_FAIL";

export const ADD_NEW_DIET = "ADD_NEW_DIET";
export const ADD_NEW_DIET_SUCCESS = "ADD_NEW_DIET_SUCCESS";
export const ADD_NEW_DIET_FAIL = "ADD_NEW_DIET_FAIL";

export const UPDATE_DIET = "UPDATE_DIET";
export const UPDATE_DIET_SUCCESS = "UPDATE_DIET_SUCCESS";
export const UPDATE_DIET_FAIL = "UPDATE_DIET_FAIL";

export const GET_DIET_DETAILS = "GET_DIET_DETAILS";
export const GET_DIET_DETAILS_SUCCESS = "GET_DIET_DETAILS_SUCCESS";
export const GET_DIET_DETAILS_FAIL = "GET_DIET_DETAILS_FAIL";

export const RESET_DIET_FLAG = "RESET_DIET_FLAG";

export const DELETE_DIET = "DELETE_DIET";
export const DELETE_DIET_SUCCESS = "DELETE_DIET_SUCCESS";
export const DELETE_DIET_FAIL = "DELETE_DIET_FAIL";

export const STATUS_CHANGE_DIET = "STATUS_CHANGE_DIET";
export const STATUS_CHANGE_DIET_SUCCESS = "STATUS_CHANGE_DIET_SUCCESS";
export const STATUS_CHANGE_DIET_FAIL = "STATUS_CHANGE_DIET_FAIL";

export const SAVE_ORDERING_DIET = "SAVE_ORDERING_DIET";
export const SAVE_ORDERING_DIET_SUCCESS = "SAVE_ORDERING_DIET_SUCCESS";
export const SAVE_ORDERING_DIET_FAIL = "SAVE_ORDERING_DIET_FAIL";
export const SAVE_ORDERING_DIET_RESET_FLAG = "SAVE_ORDERING_DIET_RESET_FLAG";

export const MODIFY_DIET_FLAG = "MODIFY_DIET_FLAG";