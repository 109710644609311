import {
  GET_STEPS_SUCCESS,
  GET_STEPS_FAIL,
  ADD_NEW_STEP_SUCCESS,
  ADD_NEW_STEP_FAIL,
  GET_STEP_DETAILS_SUCCESS,
  GET_STEP_DETAILS_FAIL,
  UPDATE_STEP_SUCCESS,
  UPDATE_STEP_FAIL,
  RESET_STEP_FLAG,
  DELETE_STEP_SUCCESS,
  DELETE_STEP_FAIL,
  STATUS_CHANGE_STEP_SUCCESS,
  STATUS_CHANGE_STEP_FAIL,
  MODIFY_STEP_FLAG, 

  SAVE_ORDERING_STEP_SUCCESS,
  SAVE_ORDERING_STEP_FAIL,
  SAVE_ORDERING_STEP_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  steps: []
};

const Steps = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STEPS_SUCCESS:
      return {
        ...state,
        steps: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_STEPS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_STEP_SUCCESS:
      return {
        ...state,
        steps: [...state.steps, action.payload.data],
        success: action.payload.message,
        isStepAdd: true,
        isStepAddFail: false,
      };

    case ADD_NEW_STEP_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isStepAdd: false,
        isStepAddFail: true,
      };

    case UPDATE_STEP_SUCCESS:
      return {
        ...state,
        steps: state.steps.map(step =>
          step._id.toString() === action.payload.data._id.toString()
            ? { ...step, ...action.payload.data }
            : step
        ),
        success: action.payload.message,
        isStepUpdate: true,
        isStepUpdateFail: false
      };

    case UPDATE_STEP_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isStepUpdate: false,
        isStepUpdateFail: true,
      };

    case GET_STEP_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_STEP_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_STEP_SUCCESS:
      return { 
        ...state,
        steps: state.steps.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_STEP_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_STEP_SUCCESS:
      return {
        ...state,
        steps: state.steps.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_STEP_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


    //Save Ordering
    case SAVE_ORDERING_STEP_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_STEP_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_STEP_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    case MODIFY_STEP_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_STEP_FLAG:
      return {
        ...state,
        isStepAdd: false,
        isStepAddFail: false,
        isSuccess: false,
        isStepDetails: false,
        isStepUpdate: false,
        isStepUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Steps;
