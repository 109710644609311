import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updateJob,
  resetJobFlag,
  getJobDetails,
  getJobTypeList,
  getJobLocationTypeList,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { call } from "redux-saga/effects";
import Select from "react-select";

const JobEdit = () => {
  document.title = "Edit Job | Dabbawala";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [description, setData] = useState();
  const [jobtype, setJobtype] = useState([]);
  const [joblocationtype, setJoblocationtype] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const {
    isJobUpdate,
    isJobUpdateFail,
    error,
    success,
    details,
    jobTypeList,
    jobLocationTypeList,
  } = useSelector((state) => ({
    isJobUpdate: state.Jobs.isJobUpdate,
    isJobUpdateFail: state.Jobs.isJobUpdateFail,
    error: state.Jobs.error,
    success: state.Jobs.success,
    details: state.Jobs.details,
    jobTypeList: state.JobTypes.jobTypeList,
    jobLocationTypeList: state.JobLocationTypes.jobLocationTypeList,
  }));
  console.log(details);
  const selectedJobtypeOption = {
    value: details && details.job_type ? details.job_type?._id : "",
    label: details && details.job_type ? details.job_type?.type : "",
  };
  const selectedJobloctypeOption = {
    value: details && details.location_type ? details.location_type?._id : "",
    label: details && details.location_type ? details.location_type?.type : "",
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: details && details.title ? details.title : "",
      description: details && details.description ? details.description : "",
      city: details && details.city ? details.city : "",
      state: details && details.state ? details.state : "",
      country: details && details.country ? details.country : "",
      job_type: selectedJobtypeOption,
      location_type: selectedJobloctypeOption,
     
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title Name"),
      city: Yup.string().required("Please Enter City"),
      //   description: Yup.string().required("Please Enter Description"),
      state: Yup.string().required("Please Enter State"),
      country: Yup.string().required("Please Enter Country"),
      //   job_type: Yup.string().required("Please Enter Job Type"),
      //   location_type: Yup.string().required("Please Enter Job Location Type"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        description: description,
        city: values.city,
        state: values.state,
        country: values.country,
        job_type: values.job_type.value,
        location_type: values.location_type.value,
      };
      dispatch(updateJob(data));
      console.log(data);
    },
  });

  useEffect(() => {
    if (isJobUpdate || isJobUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetJobFlag());
      }, 3000);
    }
  }, [dispatch, isJobUpdate, isJobUpdateFail]);

  useEffect(() => {
    dispatch(getJobTypeList());
    dispatch(getJobLocationTypeList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getJobDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    let jobtypes = [];
    if (jobTypeList) {
      for (let i = 0; i < jobTypeList?.length; i++) {
        jobtypes[i] = { label: jobTypeList[i].type, value: jobTypeList[i]._id };
      }
    }
    setJobtype(jobtypes);
    console.log(jobtypes);
  }, [jobTypeList]);

  useEffect(() => {
    let joblocationtypes = [];
    if (jobLocationTypeList) {
      for (let i = 0; i < jobLocationTypeList?.length; i++) {
        joblocationtypes[i] = {
          label: jobLocationTypeList[i].type,
          value: jobLocationTypeList[i]._id,
        };
      }
    }
    setJoblocationtype(joblocationtypes);
    console.log(joblocationtypes);
  }, [jobLocationTypeList]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Job" pageTitle="Jobs" pageUrl="/jobs" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Job" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Job title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              City *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="City name"
                              name="city"
                              value={validation.values.city || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.city &&
                                validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              State *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="State name"
                              name="state"
                              value={validation.values.state || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.state &&
                                validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Country *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Country name"
                              name="country"
                              value={validation.values.country || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.country &&
                                validation.errors.country
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.country &&
                            validation.errors.country ? (
                              <FormFeedback type="invalid">
                                {validation.errors.country}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                    
                        <Col xxl={3} md={6}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="product-title-input"
                            >
                              Job Type
                            </Label>
                            <Select
                              type="text"
                              name="job_type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "job_type",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                jobTypeList &&
                                jobTypeList.map((item, index) => ({
                                  label: item.type,
                                  value: item._id,
                                }))
                              }
                              value={validation.values.job_type || ""}
                            />
                            {validation.touched.job_type &&
                            validation.errors.job_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.job_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        {/* <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="product-title-input">
                              Job Location Type *
                            </Label>
                            <Select
                              type="text"
                              name="location_type"
                              placeholder="Enter Job Loacation Type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "location_type",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              options={joblocationtype}
                              onBlur={validation.handleBlur}
                              value={validation.values.location_type || ""}
                              invalid={
                                validation.touched.location_type &&
                                validation.errors.location_type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.location_type &&
                            validation.errors.location_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.location_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}
                         <Col xxl={3} md={6}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="product-title-input"
                            >
                              Job Location Type
                            </Label>
                            <Select
                              type="text"
                              name="location_type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "location_type",
                                    value: selectedOption,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                jobLocationTypeList &&
                                jobLocationTypeList.map((item, index) => ({
                                  label: item.type,
                                  value: item._id,
                                }))
                              }
                              value={validation.values.location_type || ""}
                            />
                            {validation.touched.location_type &&
                            validation.errors.location_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.location_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description *
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/jobs" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isJobUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isJobUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default JobEdit;
