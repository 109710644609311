import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_JOBAPPLIES,
  ADD_NEW_JOBAPPLY,
  // GET_JOBAPPLY_DETAILS,
  UPDATE_JOBAPPLY,
  DELETE_JOBAPPLY,
} from "./actionTypes";

import {
  getJobappliesSuccess,
  getJobappliesFail,
  addNewJobapplySuccess,
  addNewJobapplyFail,
  // getJobapplyDetailsSuccess,
  // getJobapplyDetailsFail,
  updateJobapplySuccess,
  updateJobapplyFail,
  deleteJobapplySuccess,
  deleteJobapplyFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getJobapplyList,
  addNewJobapply,
  // getJobapplyDetails,
  updateJobapply,
  delJobapply,
} from "../../helpers/fakebackend_helper";


function* fetchJobapplies({ payload: jobapply }) {
  try {
    const response = yield call(getJobapplyList, jobapply);
    yield put(getJobappliesSuccess(response));
  } catch (error) {
    yield put(getJobappliesFail(error));
  }
}

export function* watchFetchJobapplies() {
  yield takeEvery(GET_JOBAPPLIES, fetchJobapplies);
}

function* onAddNewJobapply({ payload: jobapply }) {
  try {
    const response = yield call(addNewJobapply, jobapply);
    if (response.status === 200) {
      yield put(addNewJobapplySuccess(response));
    } else {
      yield put(addNewJobapplyFail(response));
    }
  } catch (error) {
    yield put(addNewJobapplyFail(error));
  }
}

export function* watchAddNewJobapply() {
  yield takeEvery(ADD_NEW_JOBAPPLY, onAddNewJobapply);
}

// function* onJobapplyDetails({ payload: jobapply }) {
//   try {
//     const response = yield call(getJobapplyDetails, jobapply);
//     if (response.status === 200) {
//       yield put(getJobapplyDetailsSuccess(response));
//     } else {
//       yield put(getJobapplyDetailsFail(response));
//     }
//   } catch (error) {
//     yield put(getJobapplyDetailsFail(error));
//   }
// }

// export function* watchJobapplyDetails() {
//   yield takeEvery(GET_JOBAPPLY_DETAILS, onJobapplyDetails);
// }

function* onJobapplyUpdate({ payload: jobapply }) {
  try {
    const response = yield call(updateJobapply, jobapply);
    if (response.status === 200) {
      yield put(updateJobapplySuccess(response));
    } else {
      yield put(updateJobapplyFail(response));
    }
  } catch (error) {
    yield put(updateJobapplyFail(error));
  }
}

export function* watchUpdateJobapply() {
  yield takeEvery(UPDATE_JOBAPPLY, onJobapplyUpdate);
}

function* onJobapplyDelete({ payload: jobapply }) {
  try {
    const response = yield call(delJobapply, jobapply);
    yield put(deleteJobapplySuccess({ jobapply, ...response }));
  } catch (error) {
    yield put(deleteJobapplyFail(error));
  }
}

export function* watchDeleteJobapply() {
  yield takeEvery(DELETE_JOBAPPLY, onJobapplyDelete);
}

function* jobapplySaga() {
  yield all([
    fork(watchFetchJobapplies),
    fork(watchAddNewJobapply),
    // fork(watchJobapplyDetails),
    fork(watchUpdateJobapply),
    fork(watchDeleteJobapply),
  ]);
}

export default jobapplySaga;
