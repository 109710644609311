import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewFaq, resetFaqFlag, getCategories } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

const FaqCreate = () => {
  document.title = "Create Faq | Dabbawala";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [category_type, setCategoryType] = useState("faqs");
  const [answer, setData] = useState();
  // const [status, setStatus] = useState();
  const { isFaqAdd, isFaqAddFail, error, success, categories } = useSelector(
    (state) => ({
      isFaqAdd: state.Faqs.isFaqAdd,
      isFaqAddFail: state.Faqs.isFaqAddFail,
      error: state.Faqs.error,
      success: state.Faqs.success,
      categories: state.Categories.categories,
    })
  );
  const [selectedCatType, setselectedCatType] = useState("");
  function handleSelectType(value,label) {
   
    const setCategoryValue={value:value,label:label}
    setselectedCatType(setCategoryValue);
  }


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      question: "",
      answer: "",
      category: "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Please Enter Your Question"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        question: values.question,
        answer: answer,
        category: selectedCatType.value
      };
      dispatch(addNewFaq(data));
    },
  });

  useEffect(() => {
    if (isFaqAdd || isFaqAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetFaqFlag());
        history.push('/faqs');
      }, 3000);
    }
  }, [dispatch, isFaqAdd, isFaqAddFail, validation]);

  // useEffect(() => {
  //   dispatch(getCategories({ category_type, status }));
  // }, [dispatch, category_type, status]);
  useEffect(() => {
    dispatch(getCategories({ category_type}));
  }, [dispatch, category_type]);
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create Faq" pageTitle="Faqs" pageUrl="/faqs" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Faq" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Question *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Question"
                              name="question"
                              value={validation.values.question || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.question &&
                                validation.errors.question
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.question &&
                            validation.errors.question ? (
                              <FormFeedback type="invalid">
                                {validation.errors.question}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category Type
                            </Label>
                            <Select
                              value={selectedCatType || ""}
                              onChange={(event) => {
                                handleSelectType(event.value,event.label);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                categories.length &&
                                categories.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))
                              }
                              name="category"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category &&
                                validation.errors.category
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category &&
                            validation.errors.category ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Answer*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="answer"
                              data={validation.values.answer || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.answer &&
                            validation.errors.answer ? (
                              <FormFeedback type="invalid">
                                {validation.errors.answer}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/faqs" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isFaqAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isFaqAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default FaqCreate;
