import {
    GET_COUPONS_SUCCESS,
    GET_COUPONS_FAIL,
    ADD_NEW_COUPON_SUCCESS,
    ADD_NEW_COUPON_FAIL,
    GET_COUPON_DETAILS_SUCCESS,
    GET_COUPON_DETAILS_FAIL,
    UPDATE_COUPON_SUCCESS,
    UPDATE_COUPON_FAIL,
    RESET_COUPON_FLAG,
    DELETE_COUPON_SUCCESS,
    DELETE_COUPON_FAIL,
    STATUS_CHANGE_COUPON_SUCCESS,
    STATUS_CHANGE_COUPON_FAIL,
    MODIFY_COUPON_FLAG, 
  } from "./actionTypes";
  
  const INIT_STATE = {
    coupons: [],
    totalDataCount: 0,
    isSuccess: false,
  };
  
  const Coupons = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_COUPONS_SUCCESS:
        return {
          ...state,
          coupons: action.payload.data,
          totalDataCount: action.payload.count,
          isSuccess: true
        };
  
      case GET_COUPONS_FAIL:
        return {
          ...state,
          error: action.payload.message,
          totalDataCount: 0,
          isSuccess: false
        };
  
      case ADD_NEW_COUPON_SUCCESS:
        return {
          ...state,
          coupons : [...state.coupons, action.payload.data],
          success: action.payload.message,
          isCouponAdd: true,
          isCouponAddFail: false,
        };
  
      case ADD_NEW_COUPON_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isCouponAdd: false,
          isCouponAddFail: true,
        };
  
      case UPDATE_COUPON_SUCCESS:
        return {
          ...state,
          coupons: state.coupons.map(coupon =>
            coupon._id.toString() === action.payload.data._id.toString()
              ? { ...coupon, ...action.payload.data }
              : coupon
          ),
          success: action.payload.message,
          isCouponUpdate: true,
          isCouponUpdateFail: false
        };
  
      case UPDATE_COUPON_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isCouponUpdate: false,
          isCouponUpdateFail: true,
        };
  
      case GET_COUPON_DETAILS_SUCCESS:
        return {
          ...state,
          details: action.payload.data,
        };
  
      case GET_COUPON_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload.message,
        };
      //Delete
      case DELETE_COUPON_SUCCESS:
        return { 
          ...state,
          coupons : state.coupons.filter(getData =>
            {
              //console.log(getData, action.payload);
              return getData._id !== action.payload.data._id
            }
            
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
    
      case DELETE_COUPON_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
    
        
      //Status Change
      case STATUS_CHANGE_COUPON_SUCCESS:
        return {
          ...state,
          coupons: state.coupons.map(getData =>
            getData._id.toString() === action.payload.data._id.toString()
              ? { ...getData, ...action.payload.data }
            : getData
          ),
          success: action.payload.message,
          statusSuccess: true,
          statusFaild: false,
        };
          
      case STATUS_CHANGE_COUPON_FAIL:
        return {
          ...state,
          error: action.payload.message,
          statusSuccess: false,
          statusFaild: true,
        };
  
      case MODIFY_COUPON_FLAG:
        return {
          ...state,
          statusSuccess: false,
          statusFaild: false,
          error: false
        };
  
      case RESET_COUPON_FLAG:
        return {
          ...state,
          isCouponAdd: false,
          isCouponAddFail: false,
          isSuccess: false,
          isCouponDetails: false,
          isCouponUpdate: false,
          isCouponUpdateFail: false,
          error: false
        };
  
      default:
        return state;
    }
  };
  
  export default Coupons;
  