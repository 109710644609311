import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_ALLERGIES,
  ADD_NEW_ALLERGIE,
  GET_ALLERGIE_DETAILS,
  UPDATE_ALLERGIE,
  DELETE_ALLERGIE,
  STATUS_CHANGE_ALLERGIE,
  SAVE_ORDERING_ALLERGIE
} from "./actionTypes"

import {
  getAllergiesSuccess,
  getAllergiesFail,
  addNewAllergieSuccess,
  addNewAllergieFail,
  getAllergieDetailsSuccess,
  getAllergieDetailsFail,
  updateAllergieSuccess,
  updateAllergieFail,
  deleteAllergieSuccess,
  deleteAllergieFail,
  statusChangeAllergieSuccess,
  statusChangeAllergieFail,
  saveOrderingAllergieSuccess,
  saveOrderingAllergieFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAllergieList,
  addNewAllergie,
  getAllergieDetails,
  updateAllergie,
  deleteAllergie,
  statusChangeAllergie,
  saveOrdering
} from "../../helpers/fakebackend_helper"

function* fetchAllergies({ payload: allergie }) {
  try {
    const response = yield call(getAllergieList, allergie)
    yield put(getAllergiesSuccess(response))
  } catch (error) {
    yield put(getAllergiesFail(error))
  }
}

export function* watchFetchAllergies() {
  yield takeEvery(GET_ALLERGIES, fetchAllergies);
}

function* onAddNewAllergie({ payload: allergie }) {
  try {
      const response = yield call(addNewAllergie, allergie);
      if(response.status === 200){
        yield put(addNewAllergieSuccess(response));
      } else {
        yield put(addNewAllergieFail(response));
      }
  } catch (error) {
      yield put(addNewAllergieFail(error));
  }
}

export function* watchAddNewAllergie() {
  yield takeEvery(ADD_NEW_ALLERGIE, onAddNewAllergie);
}

function* onAllergieDetails({ payload: allergie }) {
  try {
      const response = yield call(getAllergieDetails, allergie);
      if(response.status === 200){
        yield put(getAllergieDetailsSuccess(response));
      } else {
        yield put(getAllergieDetailsFail(response));
      }
  } catch (error) {
      yield put(getAllergieDetailsFail(error));
  }
}

export function* watchAllergieDetails() {
  yield takeEvery(GET_ALLERGIE_DETAILS, onAllergieDetails);
}

function* onAllergieUpdate({ payload: allergie }) {
  try {
      const response = yield call(updateAllergie, allergie);
      if(response.status === 200){
        yield put(updateAllergieSuccess(response));
      } else {
        yield put(updateAllergieFail(response));
      }
  } catch (error) {
      yield put(updateAllergieFail(error));
  }
}

export function* watchUpdateAllergie() {
  yield takeEvery(UPDATE_ALLERGIE, onAllergieUpdate);
}


//Delete Data
function* onDeleteAllergie({ payload: allergie }) {
  try {
    const response = yield call(deleteAllergie, allergie);
    yield put(deleteAllergieSuccess({ allergie, ...response }));
  } catch (error) {
    yield put(deleteAllergieFail(error));
  }
}

export function* watchDeleteAllergie() {
  yield takeEvery(DELETE_ALLERGIE, onDeleteAllergie);
}



//Status Change
function* onStatusChangAllergie({ payload: allergie }) {
  try {
    const response = yield call(statusChangeAllergie, allergie);
    yield put(statusChangeAllergieSuccess({ allergie, ...response }));
  } catch (error) {
    yield put(statusChangeAllergieFail(error));
  }
}

export function* watchStatusChangeAllergie() {
  yield takeEvery(STATUS_CHANGE_ALLERGIE, onStatusChangAllergie);
}

//Save Ordering
function* onSaveOrderingAllergie({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingAllergieSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingAllergieFail(error));
  }
}

export function* watchSaveOrderingAllergie() {
  yield takeEvery(SAVE_ORDERING_ALLERGIE, onSaveOrderingAllergie);
}




function* allergieSaga() {
  yield all(
    [
      fork(watchFetchAllergies),
      fork(watchAddNewAllergie),
      fork(watchAllergieDetails),
      fork(watchUpdateAllergie),
      fork(watchDeleteAllergie),
      fork(watchStatusChangeAllergie),
      fork(watchSaveOrderingAllergie),
    ]
  );

}

export default allergieSaga
