import {
  GET_SITE_DETAILS,
  GET_SITE_DETAILS_SUCCESS,
  GET_SITE_DETAILS_FAIL,
  UPDATE_SITE,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAIL,
  RESET_SITE_FLAG
} from "./actionTypes";



export const updateSite = site => ({
  type: UPDATE_SITE,
  payload: site,
});

export const updateSiteSuccess = site => ({
  type: UPDATE_SITE_SUCCESS,
  payload: site,
});

export const updateSiteFail = error => ({
  type: UPDATE_SITE_FAIL,
  payload: error,
});

export const getSiteDetails = site => ({
  type: GET_SITE_DETAILS,
  payload: site,
});

export const getSiteDetailsSuccess = site => ({
  type: GET_SITE_DETAILS_SUCCESS,
  payload: site,
});

export const getSiteDetailsFail = error => ({
  type: GET_SITE_DETAILS_FAIL,
  payload: error,
});
export const resetSiteFlag = () => {
  return {
    type: RESET_SITE_FLAG,
  }
}