import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_AUTHORS,
  ADD_NEW_AUTHOR,
  GET_AUTHOR_DETAILS,
  UPDATE_AUTHOR,
  DELETE_AUTHOR,
  STATUS_CHANGE_AUTHOR
} from "./actionTypes"

import {
  getAuthorsSuccess,
  getAuthorsFail,
  addNewAuthorsuccess,
  addNewAuthorFail,
  getAuthorDetailsSuccess,
  getAuthorDetailsFail,
  updateAuthorSuccess,
  updateAuthorFail,
  deleteAuthorSuccess,
  deleteAuthorFail,
  statusChangeAuthorSuccess,
  statusChangeAuthorFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAuthorList,
  addNewAuthor,
  getAuthorDetails,
  updateAuthor,
  deleteAuthor,
  statusChangeAuthor
} from "../../helpers/fakebackend_helper"

function* fetchAuthors({ payload: author }) {
  try {
    const response = yield call(getAuthorList, author)
    yield put(getAuthorsSuccess(response))
  } catch (error) {
    yield put(getAuthorsFail(error))
  }
}

export function* watchFetchAuthors() {
  yield takeEvery(GET_AUTHORS, fetchAuthors);
}

function* onAddNewAuthor({ payload: author }) {
  try {
      const response = yield call(addNewAuthor, author);
      if(response.status === 200){
        yield put(addNewAuthorsuccess(response));
      } else {
        yield put(addNewAuthorFail(response));
      }
  } catch (error) {
      yield put(addNewAuthorFail(error));
  }
}

export function* watchAddNewAuthor() {
  yield takeEvery(ADD_NEW_AUTHOR, onAddNewAuthor);
}

function* onAuthorDetails({ payload: author }) {
  try {
      const response = yield call(getAuthorDetails, author);
      if(response.status === 200){
        yield put(getAuthorDetailsSuccess(response));
      } else {
        yield put(getAuthorDetailsFail(response));
      }
  } catch (error) {
      yield put(getAuthorDetailsFail(error));
  }
}

export function* watchAuthorDetails() {
  yield takeEvery(GET_AUTHOR_DETAILS, onAuthorDetails);
}

function* onAuthorUpdate({ payload: author }) {
  try {
      const response = yield call(updateAuthor, author);
      if(response.status === 200){
        yield put(updateAuthorSuccess(response));
      } else {
        yield put(updateAuthorFail(response));
      }
  } catch (error) {
      yield put(updateAuthorFail(error));
  }
}

export function* watchUpdateAuthor() {
  yield takeEvery(UPDATE_AUTHOR, onAuthorUpdate);
}


//Delete Data
function* onDeleteAuthor({ payload: author }) {
  try {
    const response = yield call(deleteAuthor, author);
    yield put(deleteAuthorSuccess({ author, ...response }));
  } catch (error) {
    yield put(deleteAuthorFail(error));
  }
}

export function* watchDeleteAuthor() {
  yield takeEvery(DELETE_AUTHOR, onDeleteAuthor);
}



//Status Change
function* onStatusChangAuthor({ payload: author }) {
  try {
    const response = yield call(statusChangeAuthor, author);
    yield put(statusChangeAuthorSuccess({ author, ...response }));
  } catch (error) {
    yield put(statusChangeAuthorFail(error));
  }
}

export function* watchStatusChangeAuthor() {
  yield takeEvery(STATUS_CHANGE_AUTHOR, onStatusChangAuthor);
}



function* authorSaga() {
  yield all(
    [
      fork(watchFetchAuthors),
      fork(watchAddNewAuthor),
      fork(watchAuthorDetails),
      fork(watchUpdateAuthor),
      fork(watchDeleteAuthor),
      fork(watchStatusChangeAuthor)
    ]
  );

}

export default authorSaga
