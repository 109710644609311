import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_OPENINGHOUR,
  ADD_NEW_OPENINGHOUR,
  GET_OPENINGHOUR_DETAILS,
  UPDATE_OPENINGHOUR,
  STATUS_CHANGE_OPENINGHOUR,
  DELETE_OPENINGHOUR,
  SAVE_ORDERING_OPENINGHOUR,
  
} from "./actionTypes"

import {
  getOpeningHourSuccess, 
  getOpeningHourFail,

  addNewOpeningHourSuccess,
  addNewOpeningHourFail,

  getOpeningHourDetailsSuccess,
  getOpeningHourDetailsFail,

  updateOpeningHourSuccess,
  updateOpeningHourFail,

  statusChangeOpeningHourSuccess,
  statusChangeOpeningHourFail,

  deleteOpeningHourSuccess,
  deleteOpeningHourFail,
  
  saveOrderingOpeningHourSuccess,
  saveOrderingOpeningHourFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getOpeningHourList,
  addNewOpeningHour,
  getOpeningHourDetails,
  updateOpeningHour,
  statusChangeOpeningHour,
  deleteOpeningHour,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchOpeningHour({ payload: data }) {
  try {
    const response = yield call(getOpeningHourList, data)
    yield put(getOpeningHourSuccess(response))
  } catch (error) {
    yield put(getOpeningHourFail(error))
  }
}

export function* watchFetchOpeningHour() {
  yield takeEvery(GET_OPENINGHOUR, fetchOpeningHour);
}

function* onAddNewOpeningHour({ payload: data }) {
  try {
      const response = yield call(addNewOpeningHour, data);
      if(response.status === 200){
        yield put(addNewOpeningHourSuccess(response));
      } else {
        yield put(addNewOpeningHourFail(response));
      }
  } catch (error) {
      yield put(addNewOpeningHourFail(error));
  }
}

export function* watchAddNewOpeningHour() {
  yield takeEvery(ADD_NEW_OPENINGHOUR, onAddNewOpeningHour);
}

function* onOpeningHourDetails({ payload: data }) {
  try {
      const response = yield call(getOpeningHourDetails, data);
      if(response.status === 200){
        yield put(getOpeningHourDetailsSuccess(response));
      } else {
        yield put(getOpeningHourDetailsFail(response));
      }
  } catch (error) {
      yield put(getOpeningHourDetailsFail(error));
  }
}

export function* watchOpeningHourDetails() {
  yield takeEvery(GET_OPENINGHOUR_DETAILS, onOpeningHourDetails);
}

function* onOpeningHourUpdate({ payload: data }) {
  try {
      const response = yield call(updateOpeningHour, data);
      if(response.status === 200){
        yield put(updateOpeningHourSuccess(response));
      } else {
        yield put(updateOpeningHourFail(response));
      }
  } catch (error) {
      yield put(updateOpeningHourFail(error));
  }
}

export function* watchUpdateOpeningHour() {
  yield takeEvery(UPDATE_OPENINGHOUR, onOpeningHourUpdate);
}


//Delete Data
function* onDeleteOpeningHour({ payload: data }) {
  try {
    const response = yield call(deleteOpeningHour, data);
    yield put(deleteOpeningHourSuccess({ data, ...response }));
  } catch (error) {
    yield put(deleteOpeningHourFail(error));
  }
}

export function* watchDeleteOpeningHour() {
  yield takeEvery(DELETE_OPENINGHOUR, onDeleteOpeningHour);
}



//Status Change
function* onStatusChangOpeningHour({ payload: data }) {
  try {
    const response = yield call(statusChangeOpeningHour, data);
    yield put(statusChangeOpeningHourSuccess({ data, ...response }));
  } catch (error) {
    yield put(statusChangeOpeningHourFail(error));
  }
}

export function* watchStatusChangeOpeningHour() {
  yield takeEvery(STATUS_CHANGE_OPENINGHOUR, onStatusChangOpeningHour);
}


//Save Ordering
function* onSaveOrderingOpeningHour({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingOpeningHourSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingOpeningHourFail(error));
  }
}

export function* watchSaveOrderingOpeningHour() {
  yield takeEvery(SAVE_ORDERING_OPENINGHOUR, onSaveOrderingOpeningHour);
}



function* dataSaga() {
  yield all(
    [
      fork(watchFetchOpeningHour),
      fork(watchAddNewOpeningHour),
      fork(watchOpeningHourDetails),
      fork(watchUpdateOpeningHour),
      fork(watchStatusChangeOpeningHour),
      fork(watchDeleteOpeningHour),
      fork(watchSaveOrderingOpeningHour),
    ]
  );

}

export default dataSaga
