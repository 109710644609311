import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Card,
  CardBody,
  FormFeedback,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import MsgToast from "../../../Components/Common/MsgToast";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import {
  addNewTakeofTime,
  resetTakeofTimeFlag,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Add() {
  const allowedExts = ["jpg", "jpeg", "png"];
  document.title = "Create Take of Time | Dabbawala";
  const [status, setStatus] = useState();
  const [time, setTime] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const { istakeoftimeAdd, istakeoftimeAddFail, error, success } = useSelector(
    (state) => ({
      istakeoftimeAdd: state.takeofTime.istakeoftimeAdd,
      istakeoftimeAddFail: state.takeofTime.istakeoftimeAddFail,
      error: state.takeofTime.error,
      success: state.takeofTime.success,
    })
  );


  const dispatch = useDispatch();
  const history = useHistory();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
         time: "",
    },
    validationSchema: Yup.object({
      time: Yup.string().required("Please Enter Time"),
    }),
    onSubmit: (values) => {
      const data = {
        // title: values.title,
        time: time
      };
      console.log(data);
      dispatch(addNewTakeofTime(data));
    },
  });

  
  useEffect(() => {
    if (istakeoftimeAdd || istakeoftimeAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetTakeofTimeFlag());
        history.push("/takeoftime");
      }, 3000);
    }
  }, [dispatch, istakeoftimeAdd, istakeoftimeAddFail, validation]);
  useEffect(() => {
  }, [dispatch, status]);
  return (
    <>
      {" "}
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create Take of Time" pageTitle="Take of Time" pageUrl="/takeoftime" />
          <Row>
            <Col lg={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <PreviewCardHeader title="Create Take of Time" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Time
                            </Label>
                            
                            <Flatpickr
                                type="text"
                                name="time"
                                className="form-control"
                                placeholder="Select Opning Time"
                                onChange={(selectedDates, startTimeStr, instance) => {
                                  let event = {
                                    target: {
                                      name: "time",
                                      value: startTimeStr,
                                      
                                    },
                                  };
                                  setTime(startTimeStr)
                                  validation.handleChange(event);
                                }}
                                options={{
                                  noCalendar: true,
                                  enableTime: true,
                                  dateFormat: 'H:i',
                                  time_24hr: true
                                }}
                                value={validation.values.time || ""}
                              />
                             {validation.touched.time &&
                              validation.errors.time ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.time}
                                </FormFeedback>
                              ) : null}
                            
                          </div>
                        </Col>
        
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/takeoftime" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          {istakeoftimeAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {istakeoftimeAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
}

export default Add;
