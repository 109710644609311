import {
  GET_DIETS_SUCCESS,
  GET_DIETS_FAIL,
  ADD_NEW_DIET_SUCCESS,
  ADD_NEW_DIET_FAIL,
  GET_DIET_DETAILS_SUCCESS,
  GET_DIET_DETAILS_FAIL,
  UPDATE_DIET_SUCCESS,
  UPDATE_DIET_FAIL,
  RESET_DIET_FLAG,
  DELETE_DIET_SUCCESS,
  DELETE_DIET_FAIL,
  STATUS_CHANGE_DIET_SUCCESS,
  STATUS_CHANGE_DIET_FAIL,
  MODIFY_DIET_FLAG, 
  SAVE_ORDERING_DIET_SUCCESS,
  SAVE_ORDERING_DIET_FAIL,
  SAVE_ORDERING_DIET_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  diets: []
};

const Diets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DIETS_SUCCESS:
      return {
        ...state,
        diets: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_DIETS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_DIET_SUCCESS:
      return {
        ...state,
        diets: [...state.diets, action.payload.data],
        success: action.payload.message,
        isDietAdd: true,
        isDietAddFail: false,
      };

    case ADD_NEW_DIET_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isDietAdd: false,
        isDietAddFail: true,
      };

    case UPDATE_DIET_SUCCESS:
      return {
        ...state,
        diets: state.diets.map(diet =>
          diet._id.toString() === action.payload.data._id.toString()
            ? { ...diet, ...action.payload.data }
            : diet
        ),
        success: action.payload.message,
        isDietUpdate: true,
        isDietUpdateFail: false
      };

    case UPDATE_DIET_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isDietUpdate: false,
        isDietUpdateFail: true,
      };

    case GET_DIET_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_DIET_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_DIET_SUCCESS:
      return { 
        ...state,
        diets: state.diets.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_DIET_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_DIET_SUCCESS:
      return {
        ...state,
        diets: state.diets.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_DIET_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_DIET_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_DIET_FLAG:
      return {
        ...state,
        isDietAdd: false,
        isDietAddFail: false,
        isSuccess: false,
        isDietDetails: false,
        isDietUpdate: false,
        isDietUpdateFail: false,
        error: false
      };

    
           //Save Ordering
           case SAVE_ORDERING_DIET_SUCCESS:
            return {
              ...state,
              success: action.payload.message,
              saveOrderingSuccess: true,
              saveOrderingFaild: false,
            };
              
          case SAVE_ORDERING_DIET_FAIL:
            return {
              ...state,
              error: action.payload.message,
              saveOrderingSuccess: false,
              saveOrderingFaild: true,
            };
      
      
          //Save Ordering reset flag 
          case SAVE_ORDERING_DIET_RESET_FLAG:
            return {
              ...state,
              saveOrderingSuccess: false,
              saveOrderingFaild: false,
              error: false
            };

    default:
      return state;
  }
};

export default Diets;
