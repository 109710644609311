import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, Form , Modal, ModalHeader, ModalBody,} from 'reactstrap';

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//import Components
import SearchOption from '../Components/Common/SearchOption';
import LanguageDropdown from '../Components/Common/LanguageDropdown';
import WebAppsDropdown from '../Components/Common/WebAppsDropdown';
import MyCartDropdown from '../Components/Common/MyCartDropdown';
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import LightDark from '../Components/Common/LightDark';

import { SocketContext } from "../socket/socketio";
import { useSelector, useDispatch } from "react-redux";
import {updateSocketNotification, getSocketNotification, resetSocketNotification} from "../store/actions";

import { ToastContainer, toast } from "react-toastify";

import classnames from 'classnames';
import SimpleBar from "simplebar-react";


const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {

    const {
        socketNotification,
        orderNotification,
    } = useSelector((state) => ({
        socketNotification: state.SocketNotification.socketNotification,
        orderNotification: state.SocketNotification.orderNotification,
    }));

    const dispatch = useDispatch();
    const [orderNotfiModal, setOrderNotfiModal] = useState(false);
    const [sockNotiTmpData, setSockNotiTmpData] = useState();

    const socket = useContext(SocketContext);
    //Listen from client end:
    useEffect(()=>{
        if(socket?.io){
            socket.on('orderNotificationResponse', (data) => {
                setSockNotiTmpData(data);
                setOrderNotfiModal(true);
            });
        }
        return () => {
            socket.off("orderNotificationResponse", () => {})
        };
    },[socket?.io]);

    useEffect(() => {
        dispatch(getSocketNotification());
    },[dispatch]);


    useEffect(() => {
        if(sockNotiTmpData){
            dispatch(updateSocketNotification({data:sockNotiTmpData}));
        }
    },[dispatch, sockNotiTmpData]);



    const history = useHistory();
    const redirectToOrderView = (e)=>{
        if(e === 1){
            history.push(`/order/details/${socketNotification[0]._id}`);
            dispatch(resetSocketNotification());
        }
        if(e === 2){
            history.push(`/orders`);
            dispatch(resetSocketNotification());
        }
    }



    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

//=========================================================//

    const [search, setSearch] = useState(false);
    const toogleSearch = () => {
        setSearch(!search);
    };

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical menu
        if (document.documentElement.getAttribute('data-layout') === "vertical") {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }

        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };
    return (
        <React.Fragment>
            <ToastContainer theme="colored" />
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">

                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>
                            <span>{socketNotification?.id}</span>
                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>


                            {/* <SearchOption /> */}
                        </div>

                        <div className="d-flex align-items-center">

                            <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className="bx bx-search fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..."
                                                    aria-label="Recipient's username" />
                                                <button className="btn btn-primary" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown>

                            {/* LanguageDropdown */}
                            {/* <LanguageDropdown /> */}

                            {/* WebAppsDropdown */}
                            {/* <WebAppsDropdown /> */}

                            {/* MyCartDropdwon */}
                            {/* <MyCartDropdown /> */}

                            {/* FullScreenDropdown */}
                            {/* <FullScreenDropdown /> */}

                            {/* Dark/Light Mode set */}
                            {/* <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            /> */}

                            {/* NotificationDropdown */}
                            {/* <NotificationDropdown /> */}

                            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">

                                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className='bx bx-bell fs-22'></i>
                                    <span
                                    className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{orderNotification?.length}
                                    </span>
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                                        <div className="p-3">
                                            <Row className="align-items-center">
                                                <Col>
                                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Order Notifications </h6>
                                                </Col>
                                                <div className="col-auto dropdown-tabs">
                                                    <span className="badge badge-soft-light fs-13"> {orderNotification?.length} New</span>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                    {orderNotification && 
                                        orderNotification.map((item, index)=>(
                                            <div className="text-reset notification-item d-block dropdown-item position-relative" key={index}>
                                                <div className="d-flex">
                                                    <div className="avatar-xs me-3">
                                                        <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                                        <i className="bx bx-badge-check"></i>
                                                        </span>
                                                    </div>
                                                    <div className="flex-1">
                                                        <Link to={`/order/details/${item?._id}`} className="stretched-link">
                                                        <h6 className="mt-0 mb-2 lh-base">New Order : {item?.order_id}</h6>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </DropdownMenu>
                            </Dropdown>


                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>

            <Modal
                isOpen={orderNotfiModal}
                trigger="click"
                >
                <ModalBody>
                    <h3 className="text-center d-flex align-items-center justify-content-center m-auto mb-3" style={{width:'80px', height:'80px', borderRadius: '50%', background:'#f9f9f9'}}>{socketNotification.length}</h3>    
                    <p className="text-center">You have recived new Order.</p>
                    <div className="d-flex align-items-center justify-content-center mt-2">
                    {
                        (socketNotification?.length > 1)
                            ?(<button
                                onClick={ () =>{
                                    setOrderNotfiModal(!orderNotfiModal);
                                    redirectToOrderView(2);
                                }}
                                className="btn btn-sm btn-danger ms-2"
                                >
                                View Order
                            </button>)
                        : (<button
                            onClick={ () =>{
                                setOrderNotfiModal(!orderNotfiModal);
                                redirectToOrderView(1);
                            }}
                            className="btn btn-sm btn-danger ms-2"
                            >
                            View Order
                        </button>)
                    }
                    </div>
                </ModalBody>
            </Modal>


        </React.Fragment>
    );
};

export default Header;