import {
  GET_BENEFITS_SUCCESS,
  GET_BENEFITS_FAIL,
  ADD_NEW_BENEFIT_SUCCESS,
  ADD_NEW_BENEFIT_FAIL,
  GET_BENEFIT_DETAILS_SUCCESS,
  GET_BENEFIT_DETAILS_FAIL,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_BENEFIT_FAIL,
  RESET_BENEFIT_FLAG,
  DELETE_BENEFIT_SUCCESS,
  DELETE_BENEFIT_FAIL,
  STATUS_CHANGE_BENEFIT_SUCCESS,
  STATUS_CHANGE_BENEFIT_FAIL,
  MODIFY_BENEFIT_FLAG,
  
  SAVE_ORDERING_BENEFIT_SUCCESS,
  SAVE_ORDERING_BENEFIT_FAIL,
  SAVE_ORDERING_BENEFIT_RESET_FLAG,

} from "./actionTypes";

const INIT_STATE = {
  benefits: []
};

const Benefits = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BENEFITS_SUCCESS:
      return {
        ...state,
        benefits: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_BENEFITS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_BENEFIT_SUCCESS:
      return {
        ...state,
        benefits: [...state.benefits, action.payload.data],
        success: action.payload.message,
        isBenefitAdd: true,
        isBenefitAddFail: false,
      };

    case ADD_NEW_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBenefitAdd: false,
        isBenefitAddFail: true,
      };

    case UPDATE_BENEFIT_SUCCESS:
      return {
        ...state,
        benefits: state.benefits.map(benefit =>
          benefit._id.toString() === action.payload.data._id.toString()
            ? { ...benefit, ...action.payload.data }
            : benefit
        ),
        success: action.payload.message,
        isBenefitUpdate: true,
        isBenefitUpdateFail: false
      };

    case UPDATE_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isBenefitUpdate: false,
        isBenefitUpdateFail: true,
      };

    case GET_BENEFIT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_BENEFIT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    //Delete
    case DELETE_BENEFIT_SUCCESS:
      return { 
        ...state,
        benefits: state.benefits.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_BENEFIT_SUCCESS:
      return {
        ...state,
        benefits: state.benefits.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };


      //Save Ordering
      case SAVE_ORDERING_BENEFIT_SUCCESS:
        return {
          ...state,
          success: action.payload.message,
          saveOrderingSuccess: true,
          saveOrderingFaild: false,
        };
          
      case SAVE_ORDERING_BENEFIT_FAIL:
        return {
          ...state,
          error: action.payload.message,
          saveOrderingSuccess: false,
          saveOrderingFaild: true,
        };


      //Save Ordering reset flag 
      case SAVE_ORDERING_BENEFIT_RESET_FLAG:
        return {
          ...state,
          saveOrderingSuccess: false,
          saveOrderingFaild: false,
          error: false
        };



    case MODIFY_BENEFIT_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_BENEFIT_FLAG:
      return {
        ...state,
        isBenefitAdd: false,
        isBenefitAddFail: false,
        isSuccess: false,
        isBenefitDetails: false,
        isBenefitUpdate: false,
        isBenefitUpdateFail: false,
        error: false
      };

    default:
      return state;
  }
};

export default Benefits;
