/* AUTHOR */
export const GET_AUTHORS = "GET_AUTHORS";
export const GET_AUTHORS_SUCCESS = "GET_AUTHORS_SUCCESS";
export const GET_AUTHORS_FAIL = "GET_AUTHORS_FAIL";

export const ADD_NEW_AUTHOR = "ADD_NEW_AUTHOR";
export const ADD_NEW_AUTHOR_SUCCESS = "ADD_NEW_AUTHOR_SUCCESS";
export const ADD_NEW_AUTHOR_FAIL = "ADD_NEW_AUTHOR_FAIL";

export const UPDATE_AUTHOR = "UPDATE_AUTHOR";
export const UPDATE_AUTHOR_SUCCESS = "UPDATE_AUTHOR_SUCCESS";
export const UPDATE_AUTHOR_FAIL = "UPDATE_AUTHOR_FAIL";

export const GET_AUTHOR_DETAILS = "GET_AUTHOR_DETAILS";
export const GET_AUTHOR_DETAILS_SUCCESS = "GET_AUTHOR_DETAILS_SUCCESS";
export const GET_AUTHOR_DETAILS_FAIL = "GET_AUTHOR_DETAILS_FAIL";

export const RESET_AUTHOR_FLAG = "RESET_AUTHOR_FLAG";

export const DELETE_AUTHOR = "DELETE_AUTHOR";
export const DELETE_AUTHOR_SUCCESS = "DELETE_AUTHOR_SUCCESS";
export const DELETE_AUTHOR_FAIL = "DELETE_AUTHOR_FAIL";

export const STATUS_CHANGE_AUTHOR = "STATUS_CHANGE_AUTHOR";
export const STATUS_CHANGE_AUTHOR_SUCCESS = "STATUS_CHANGE_AUTHOR_SUCCESS";
export const STATUS_CHANGE_AUTHOR_FAIL = "STATUS_CHANGE_AUTHOR_FAIL";

export const MODIFY_AUTHOR_FLAG = "MODIFY_AUTHOR_FLAG";