import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  addNewJobLocationType,
  resetJobLocationTypeFlag,
  getJobs,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

const JobLocationTypeCreate = () => {
  document.title =
    "Create Job Location Type | Dabbawala";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const { isJobLocationTypeAdd, isJobLocationTypeAddFail, error, success } =
    useSelector((state) => ({
      isJobLocationTypeAdd: state.JobLocationTypes.isJobLocationTypeAdd,
      isJobLocationTypeAddFail: state.JobLocationTypes.isJobLocationTypeAddFail,
      error: state.JobLocationTypes.error,
      success: state.JobLocationTypes.success,
    }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please Enter Job Location Type"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        type: values.type,
      };
      dispatch(addNewJobLocationType(data));
      console.log(data);
    },
  });

  useEffect(() => {
    if (isJobLocationTypeAdd || isJobLocationTypeAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetJobLocationTypeFlag());
        history.push('/joblocationtypes')
      }, 3000);
    }
  }, [dispatch, isJobLocationTypeAdd, isJobLocationTypeAddFail, validation]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Job Location Type"
            pageTitle="Job Location Types"
            pageUrl="/joblocationtypes"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Job Location Type" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Job LocationType *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Job Location Type"
                              name="type"
                              value={validation.values.type || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link
                    to="/joblocationtypes"
                    className="btn btn-danger w-sm me-1"
                  >
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isJobLocationTypeAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isJobLocationTypeAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default JobLocationTypeCreate;
