import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
    GET_ORGANIZATIONS,
    ADD_NEW_ORGANIZATION,
    GET_ORGANIZATION_DETAILS,
    UPDATE_ORGANIZATION,
    DELETE_ORGANIZATION,
    STATUS_CHANGE_ORGANIZATION
} from "./actionTypes"

import {
    getOrganizationsSuccess,
    getOrganizationsFail,
    addNewOrganizationSuccess,
    addNewOrganizationFail,
    getOrganizationDetailsSuccess,
    getOrganizationDetailsFail,
    updateOrganizationSuccess,
    updateOrganizationFail,
    deleteOrganizationSuccess,
    deleteOrganizationFail,
    statusChangeOrganizationSuccess,
    statusChangeOrganizationFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getOrganizationList,
    addNewOrganization,
    getOrganizationDetails,
    updateOrganization,
    deleteOrganization,
    statusChangeOrganization
} from "../../helpers/fakebackend_helper"

function* fetchOrganisations({ payload: organisation }) {
    try {
        const response = yield call(getOrganizationList, organisation)
        yield put(getOrganizationsSuccess(response))
    } catch (error) {
        yield put(getOrganizationsFail(error))
    }
}

export function* watchFetchOrganisations() {
    yield takeEvery(GET_ORGANIZATIONS, fetchOrganisations);
}

function* onAddNewOrganization({ payload: organisation }) {
    try {
        const response = yield call(addNewOrganization, organisation);
        if (response.status === 200) {
            yield put(addNewOrganizationSuccess(response));
        } else {
            yield put(addNewOrganizationFail(response));
        }
    } catch (error) {
        yield put(addNewOrganizationFail(error));
    }
}

export function* watchAddNewOrganization() {
    yield takeEvery(ADD_NEW_ORGANIZATION, onAddNewOrganization);
}

function* onOrganizationDetails({ payload: organisation }) {
    try {
        const response = yield call(getOrganizationDetails, organisation);
        if (response.status === 200) {
            yield put(getOrganizationDetailsSuccess(response));
        } else {
            yield put(getOrganizationDetailsFail(response));
        }
    } catch (error) {
        yield put(getOrganizationDetailsFail(error));
    }
}

export function* watchOrganizationDetails() {
    yield takeEvery(GET_ORGANIZATION_DETAILS, onOrganizationDetails);
}

function* onOrganizationUpdate({ payload: organisation }) {
    try {
        const response = yield call(updateOrganization, organisation);
        if (response.status === 200) {
            yield put(updateOrganizationSuccess(response));
        } else {
            yield put(updateOrganizationFail(response));
        }
    } catch (error) {
        yield put(updateOrganizationFail(error));
    }
}

export function* watchUpdateOrganization() {
    yield takeEvery(UPDATE_ORGANIZATION, onOrganizationUpdate);
}


//Delete Data
function* onDeleteOrganization({ payload: organisation }) {
    try {
        const response = yield call(deleteOrganization, organisation);
        yield put(deleteOrganizationSuccess({ organisation, ...response }));
    } catch (error) {
        yield put(deleteOrganizationFail(error));
    }
}

export function* watchDeleteOrganization() {
    yield takeEvery(DELETE_ORGANIZATION, onDeleteOrganization);
}



//Status Change
function* onStatusChangOrganization({ payload: organisation }) {
    try {
        const response = yield call(statusChangeOrganization, organisation);
        yield put(statusChangeOrganizationSuccess({ organisation, ...response }));
    } catch (error) {
        yield put(statusChangeOrganizationFail(error));
    }
}

export function* watchStatusChangeOrganization() {
    yield takeEvery(STATUS_CHANGE_ORGANIZATION, onStatusChangOrganization);
}



function* OrganizationSaga() {
    yield all(
        [
            fork(watchFetchOrganisations),
            fork(watchAddNewOrganization),
            fork(watchOrganizationDetails),
            fork(watchUpdateOrganization),
            fork(watchDeleteOrganization),
            fork(watchStatusChangeOrganization)
        ]
    );

}

export default OrganizationSaga
