import {
  GET_TEAMS,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAIL,
  ADD_NEW_TEAM,
  ADD_NEW_TEAM_SUCCESS,
  ADD_NEW_TEAM_FAIL,
  GET_TEAM_DETAILS,
  GET_TEAM_DETAILS_SUCCESS,
  GET_TEAM_DETAILS_FAIL,
  UPDATE_TEAM,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  RESET_TEAM_FLAG,
  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  STATUS_CHANGE_TEAM,
  STATUS_CHANGE_TEAM_SUCCESS,
  STATUS_CHANGE_TEAM_FAIL,
  MODIFY_TEAM_FLAG,

  SAVE_ORDERING_TEAM,
  SAVE_ORDERING_TEAM_SUCCESS,
  SAVE_ORDERING_TEAM_FAIL,
  SAVE_ORDERING_TEAM_RESET_FLAG,
} from "./actionTypes";

export const getTeams = data => ({
  type: GET_TEAMS,
  payload: data,
});

export const getTeamsSuccess = data => ({
  type: GET_TEAMS_SUCCESS,
  payload: data,
});

export const getTeamsFail = error => ({
  type: GET_TEAMS_FAIL,
  payload: error,
});

export const addNewTeam = team=> ({
  type: ADD_NEW_TEAM,
  payload: team,
});

export const addNewTeamSuccess = team => ({
  type: ADD_NEW_TEAM_SUCCESS,
  payload: team,
});

export const addNewTeamFail = error => ({
  type: ADD_NEW_TEAM_FAIL,
  payload: error,
});

export const updateTeam = team => ({
  type: UPDATE_TEAM,
  payload: team,
});

export const updateTeamSuccess = team => ({
  type: UPDATE_TEAM_SUCCESS,
  payload: team,
});

export const updateTeamFail = error => ({
  type: UPDATE_TEAM_FAIL,
  payload: error,
});

export const getTeamDetails = team => ({
  type: GET_TEAM_DETAILS,
  payload: team,
});

export const getTeamDetailsSuccess = team => ({
  type: GET_TEAM_DETAILS_SUCCESS,
  payload: team,
});

export const getTeamDetailsFail = error => ({
  type: GET_TEAM_DETAILS_FAIL,
  payload: error,
});

export const resetTeamFlag = () => {
  return {
    type: RESET_TEAM_FLAG,
  }
}

//Delete
export const deleteTeam = team => ({
  type: DELETE_TEAM,
  payload: team,
});

export const deleteTeamSuccess = team => ({
  type: DELETE_TEAM_SUCCESS,
  payload: team,
});

export const deleteTeamFail = error => ({
  type: DELETE_TEAM_FAIL,
  payload: error,
});

//Status Change
export const statusChangeTeam = team => ({
  type: STATUS_CHANGE_TEAM,
  payload: team,
});

export const statusChangeTeamSuccess = team => ({
  type: STATUS_CHANGE_TEAM_SUCCESS,
  payload: team,
});

export const statusChangeTeamFail = error => ({
  type: STATUS_CHANGE_TEAM_FAIL,
  payload: error,
});

//Modify Flag for toaster
export const modifyTeamFlag = () => {
  return {
    type: MODIFY_TEAM_FLAG,
  }
}


//Save Ordaring
export const saveOrderingTeam = data => ({
  type: SAVE_ORDERING_TEAM,
  payload: data,
});

export const saveOrderingTeamSuccess = data => ({
  type: SAVE_ORDERING_TEAM_SUCCESS,
  payload: data,
});

export const saveOrderingTeamFail = error => ({
  type: SAVE_ORDERING_TEAM_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingTeamResetFlag = () => {
  return {
    type: SAVE_ORDERING_TEAM_RESET_FLAG,
  }
}