import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormText
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { getUser,editProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = () => {
  const dispatch = useDispatch();

  const [email, setemail] = useState("admin@gmail.com");
  const [idx, setidx] = useState("1");
  const allowedExts = ['jpg', 'jpeg', 'png'];
  const [userName, setUserName] = useState("Admin");

  useEffect(()=>{
    dispatch(getUser({}));
  },[]);

  const { user, success, error } = useSelector(state => ({
    user: state.Profile.getuser,
    success: state.Profile.success,
    error: state.Profile.error
  }));


  console.log(user);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));

      if (!isEmpty(user)) {
        obj.data.first_name = user.first_name;
        sessionStorage.removeItem("authUser");
        sessionStorage.setItem("authUser", JSON.stringify(obj));
      }

      setUserName(obj.data.first_name);
      setemail(obj.data.email);
      setidx(obj.data._id || "1");

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, user]);



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: userName || 'Admin',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });

  const onImgChange = (e) => {
        
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];

      if (![...allowedExts].includes(ext)) {
        console.log('Please upload a valid image');
        toast("Please upload a valid image.");
      } else {
       // console.log(e.target.files[0]);
       // convertToBase64(e.target.files[0], setBlogImage);
        let reader = new FileReader();
        let preview = document.getElementById("preview");
        reader.onload = function (el) {
          preview.setAttribute("src", el.target.result);
          // setPreviewImg(el.target.result);
          console.log(el);
        };
      }
    }
  };


  document.title = "Profile | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">Username Updated To {userName}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img id="preview"
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userName || "Admin"}</h5>
                        <p className="mb-1">Email Id : {email}</p>
                        <p className="mb-0">Id No : #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Edit Profile</h4>
          
          <Card>
            <CardBody>
             
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                 <Row>

                 <Col xxl={3} md={4}>
                <div className="form-group">
                  <Label className="form-label">Profile Image</Label>
                  <Input
                    name="name"
                    className="form-control"
                    type="file" onChange={onImgChange}/>
            
                </div>
                </Col>

                    <Col xxl={3} md={4}>
                <div className="form-group">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="name"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={
                      validation.touched.first_name && validation.errors.first_name ? true : false
                    }
                  />
                  {validation.touched.first_name && validation.errors.first_name ? (
                    <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                </Col>
                <Col xxl={3} md={4}>

                <div className="form-group">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    // value={name}
                    className="form-control"
                    placeholder="Enter Email"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email ? true : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                  ) : null}
                 
                </div>
                </Col>

                <Col xxl={3} md={4}>

<div className="form-group">
  <Label className="form-label">City</Label>
  <Input
    name="email"
    // value={name}
    className="form-control"
    placeholder="Enter Email"
    type="text"
    onChange={validation.handleChange}
    onBlur={validation.handleBlur}
    value={validation.values.email || ""}
    invalid={
      validation.touched.email && validation.errors.email ? true : false
    }
  />
  {validation.touched.email && validation.errors.email ? (
    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
  ) : null}
 
</div>
</Col>
<Col xxl={3} md={4}>
<div className="form-group">
  <Label className="form-label">State</Label>
  <Input
    name="email"
    // value={name}
    className="form-control"
    placeholder="Enter Email"
    type="text"
    onChange={validation.handleChange}
    onBlur={validation.handleBlur}
    value={validation.values.email || ""}
    invalid={
      validation.touched.email && validation.errors.email ? true : false
    }
  />
  {validation.touched.email && validation.errors.email ? (
    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
  ) : null}
 
</div>
</Col>
<Col xxl={3} md={4}>
<div className="form-group">
  <Label className="form-label">Country</Label>
  <Input
    name="email"
    // value={name}
    className="form-control"
    placeholder="Enter Email"
    type="text"
    onChange={validation.handleChange}
    onBlur={validation.handleBlur}
    value={validation.values.email || ""}
    invalid={
      validation.touched.email && validation.errors.email ? true : false
    }
  />
  {validation.touched.email && validation.errors.email ? (
    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
  ) : null}
 
</div>
                </Col>
                <Col xxl={3} md={12}>
                <div className="form-group">
                  <Label className="form-label">Description</Label>
                <Input type="textarea" name="text" id="exampleText" />
                </div>
                </Col>
                </Row>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update
                  </Button>
                </div>
              </Form>
              
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer/>
    </React.Fragment>
  );
};

export default UserProfile;
