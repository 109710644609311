import {
  GET_OPENINGHOUR,
  GET_OPENINGHOUR_SUCCESS,
  GET_OPENINGHOUR_FAIL,

  ADD_NEW_OPENINGHOUR,
  ADD_NEW_OPENINGHOUR_SUCCESS,
  ADD_NEW_OPENINGHOUR_FAIL,

  GET_OPENINGHOUR_DETAILS,
  GET_OPENINGHOUR_DETAILS_SUCCESS,
  GET_OPENINGHOUR_DETAILS_FAIL,

  UPDATE_OPENINGHOUR,
  UPDATE_OPENINGHOUR_SUCCESS,
  UPDATE_OPENINGHOUR_FAIL,

  STATUS_CHANGE_OPENINGHOUR,
  STATUS_CHANGE_OPENINGHOUR_SUCCESS,
  STATUS_CHANGE_OPENINGHOUR_FAIL,

  DELETE_OPENINGHOUR,
  DELETE_OPENINGHOUR_SUCCESS,
  DELETE_OPENINGHOUR_FAIL,

  SAVE_ORDERING_OPENINGHOUR,
  SAVE_ORDERING_OPENINGHOUR_SUCCESS,
  SAVE_ORDERING_OPENINGHOUR_FAIL,
  SAVE_ORDERING_OPENINGHOUR_RESET_FLAG,

  RESET_OPENINGHOUR_FLAG,
  MODIFY_OPENINGHOUR_FLAG,

} from "./actionTypes";

//List
export const getOpeningHour = data => ({
  type: GET_OPENINGHOUR,
  payload: data,
});

export const getOpeningHourSuccess = data => ({
  type: GET_OPENINGHOUR_SUCCESS,
  payload: data,
});

export const getOpeningHourFail = error => ({
  type: GET_OPENINGHOUR_FAIL,
  payload: error,
});


//Add New
export const addNewOpeningHour = data=> ({
  type: ADD_NEW_OPENINGHOUR,
  payload: data,
});

export const addNewOpeningHourSuccess = data => ({
  type: ADD_NEW_OPENINGHOUR_SUCCESS,
  payload: data,
});

export const addNewOpeningHourFail = error => ({
  type: ADD_NEW_OPENINGHOUR_FAIL,
  payload: error,
});


//Details
export const getOpeningHourDetails = data => ({
  type: GET_OPENINGHOUR_DETAILS,
  payload: data,
});

export const getOpeningHourDetailsSuccess = data => ({
  type: GET_OPENINGHOUR_DETAILS_SUCCESS,
  payload: data,
});

export const getOpeningHourDetailsFail = error => ({
  type: GET_OPENINGHOUR_DETAILS_FAIL,
  payload: error,
});


//Update
export const updateOpeningHour = data => ({
  type: UPDATE_OPENINGHOUR,
  payload: data,
});

export const updateOpeningHourSuccess = data => ({
  type: UPDATE_OPENINGHOUR_SUCCESS,
  payload: data,
});

export const updateOpeningHourFail = error => ({
  type: UPDATE_OPENINGHOUR_FAIL,
  payload: error,
});


//Status Change
export const statusChangeOpeningHour = data => ({
  type: STATUS_CHANGE_OPENINGHOUR,
  payload: data,
});

export const statusChangeOpeningHourSuccess = data => ({
  type: STATUS_CHANGE_OPENINGHOUR_SUCCESS,
  payload: data,
});

export const statusChangeOpeningHourFail = error => ({
  type: STATUS_CHANGE_OPENINGHOUR_FAIL,
  payload: error,
});


//Delete
export const deleteOpeningHour = data => ({
  type: DELETE_OPENINGHOUR,
  payload: data,
});

export const deleteOpeningHourSuccess = data => ({
  type: DELETE_OPENINGHOUR_SUCCESS,
  payload: data,
});

export const deleteOpeningHourFail = error => ({
  type: DELETE_OPENINGHOUR_FAIL,
  payload: error,
});


//Save Ordaring
export const saveOrderingOpeningHour = data => ({
  type: SAVE_ORDERING_OPENINGHOUR,
  payload: data,
});

export const saveOrderingOpeningHourSuccess = data => ({
  type: SAVE_ORDERING_OPENINGHOUR_SUCCESS,
  payload: data,
});

export const saveOrderingOpeningHourFail = error => ({
  type: SAVE_ORDERING_OPENINGHOUR_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingOpeningHourResetFlag = () => {
  return {
    type: SAVE_ORDERING_OPENINGHOUR_RESET_FLAG,
  }
}


//Reset Flag
export const resetOpeningHourFlag = () => {
  return {
    type: RESET_OPENINGHOUR_FLAG,
  }
}


//Modify Flag for toaster
export const modifyOpeningHourFlag = () => {
  return {
    type: MODIFY_OPENINGHOUR_FLAG,
  }
}