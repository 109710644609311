import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Widget from "./Widgets";
import RecentOrders from "./RecentOrders";

import Section from "./Section";
import axios from "axios";
import { hostUrl } from "../../config/host";
import { useEffect } from "react";
import { useState, useMemo } from "react";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment/moment";
import { BsBicycle } from "react-icons/bs";
import { BiShoppingBag } from "react-icons/bi";
import Pagination from "../../Components/Pagination";

const DashboardEcommerce = () => {
  document.title = "Dashboard | Dabbawala";

  const [channel, setchannel] = useState();
  const [menuItem, setMenuItem] = useState();
  const [orderSec, setOrderSec] = useState();
  const [postCodeSec, setPostCode] = useState();
  const [userSec, setUserSec] = useState();

  const [sortKey, setSortKey] = useState(-1);
  const [sortVal, setSortVal] = useState(-1);
  const [dateRange, setDateRange] = useState({
    start: null//new Date(moment().startOf("week").format("YYYY-MM-DD")).toISOString(),
    ,end: null//new Date(moment().endOf("day").format("YYYY-MM-DD")).toISOString(),
  });
  //dashboard/product-section   dashboard/postcode-section
  useEffect(() => {
    axios
      .post(`${hostUrl}/dashboard/delivery-pickup`, { store_id: "" }, {})
      .then(function (response) {
        setchannel(response);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `${hostUrl}/dashboard/product-section`,
        { store_id: "", dateRange: dateRange, sortKey: "total_number_sold", sortValue: sortVal, limit: 9999 },
        {}
      )
      .then(function (response) {
        setMenuItem(response.data);
        setMenutData(response.data);
        //console.log(response); dashboard/order-section
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `${hostUrl}/dashboard/order-section`,
        { store_id: "", dateRange: dateRange },
        {}
      )
      .then(function (response) {
        setOrderSec(response);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `${hostUrl}/dashboard/postcode-section`,
        { store_id: "", dateRange: dateRange, sortKey: "number_of_order", sortValue: sortKey, limit: 9999 },
        {}
      )
      .then(function (response) {
        setPostCode(response.postcodeList);
        setZiptData(response.postcodeList);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });


      axios
      .post(
        `${hostUrl}/dashboard/user-section`,
        { limit: 10 },
        {  }
      )
      .then(function (response) {
        setUserSec(response.userList);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dateRange, sortKey, sortVal]);

  const colourOptions = [
    { value: "blue", label: "DutchDabbaWala - Amsterdam" },
    { value: "ocean", label: "DutchDabbaWala - Den Haag" },
  ];

  const optionsStock = [
    {
      value: {
        start: moment().startOf("day").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      label: "Today (" + moment().format("DD MMM") + ")",
    },
    {
      value: {
        start: moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
        end: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Yesterday (" +
        moment().subtract(1, "day").startOf("day").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("week").format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "This Week (" +
        moment().startOf("week").format("DD MMM") +
        " - " +
        moment().format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(2, "weeks")
          .startOf("day")
          .format("YYYY-MM-DD"),
        end: moment().subtract(1, "week").endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Last Week (" +
        moment().subtract(1, "week").startOf("week").format("DD MMM") +
        " - " +
        moment().subtract(1, "week").endOf("week").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "This Month (" +
        moment().startOf("month").format("DD MMM") +
        " - " +
        moment().format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      },
      label:
        "Last Month (" +
        moment().subtract(1, "month").startOf("month").format("DD MMM") +
        " - " +
        moment().subtract(1, "month").endOf("month").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "quarter")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Current Quarter (" +
        moment().subtract(1, "quarter").startOf("quarter").format("DD MMM") +
        " - " +
        moment().endOf("day").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Current Year (" +
        moment().startOf("year").format("DD MMM") +
        " - " +
        moment().endOf("day").format("DD MMM") +
        ")",
    },
  ];

  const animatedComponents = makeAnimated();

  const [active, setActive] = useState(true);
  

  function handleDateRange(e) {
    if (active) {
      setDateRange({
        start: new Date(e.value.start).toISOString(),
        end: new Date(e.value.end).toISOString(),
      });
    } else if (e[0] && e[1]) {
      setDateRange({
        start: new Date(e[0]).toISOString(),
        end: new Date(e[1]).toISOString(),
      });
    }
  }

  function handleSort() {
    if (sortKey === -1) {
      setSortKey(1);
    } else {
      setSortKey(-1);
    }

  }

  function handleMenuSort() {
    if (sortVal === -1) {
      setSortVal(1);
    } else {
      setSortVal(-1);
    }

  }


  let PageSize = 10;
  const [menuData, setMenutData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return menuData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, menuData]);


  const [zipData, setZiptData] = useState();
  const [zipcurrentPage, setZipCurrentPage] = useState(1);

  const currentZipTableData = useMemo(() => {
    const firstPageIndex = (zipcurrentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return zipData?.slice(firstPageIndex, lastPageIndex);
  }, [zipcurrentPage, zipData]);


  //console.log(userSec);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard" pageTitle="Dashboards" />

          <Row>
            <Col>
              <div className="h-100">
                <Row className="mb-3 pb-1">
                  <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column gap-2">
                      {/* <div >
                            <h4 className="fs-16 mb-1">Good Morning, Admin!</h4>
                            <p className="text-muted mb-0">Here's what's happening with your store today.</p>
                        </div> */}
                      <Col xs={3} lg={7}>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[0]]}
                          isMulti
                          options={colourOptions}
                        />
                      </Col>
                      <button
                        style={{ borderColor: "white" }}
                        onClick={() => setActive(!active)}
                      >
                        Switch
                      </button>
                      <Col xs={4} lg={3}>
                        {active ? (
                          <Select
                            options={optionsStock}
                            defaultValue={optionsStock[2]}
                            name="choices-category-input"
                            classNamePrefix="select2-selection form-select"
                            onChange={(e) => handleDateRange(e)}
                          />
                        ) : (
                          <Flatpickr
                            className="form-control border-0 dash-filter-picker shadow"
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                              defaultDate: [moment().format("DD MMM YYYY")],
                            }}
                            onChange={(e) => handleDateRange(e)}
                          />
                        )}
                      </Col>
                      <div className="mt-3 mt-lg-0">
                        <form action="#">
                          <Row className="g-3 mb-0 align-items-center">
                            {/* <div className="col-sm-auto">
                                        <div className="input-group">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d M, Y",
                                                    defaultDate: ["01 Jan 2022", "31 Jan 2022"]
                                                }}
                                            />
                                            <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                                        </div>
                                    </div> */}
                            {/* <div className="col-auto">
                                        <button type="button" className="btn btn-soft-success"><i className="ri-add-circle-line align-middle me-1"></i> Add Product</button>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-soft-info btn-icon waves-effect waves-light layout-rightside-btn" onClick={() => sidebar()} ><i className="ri-pulse-line"></i></button>
                                    </div> */}
                          </Row>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Widget />
                </Row> */}
                {orderSec && (
                  <Card>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px",
                        fontSize: "15px",
                      }}
                    >
                      <Col sm={3} lg={3} style={{ textAlign: "center" }}>
                        <div>Total orders</div>
                        <div>
                          <b>{orderSec?.total_order}</b>
                        </div>
                      </Col>
                      <Col
                        sm={3}
                        lg={3}
                        style={{ textAlign: "center", width: "150px" }}
                      >
                        <div>Accepted orders</div>
                        <div style={{ borderBottom: "2px solid black" }}>
                          <b>{orderSec?.total_accept_order}</b>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              borderRight: "2px solid black",
                              display: "flex",
                              flexDirection: "column",
                              padding: "3px",
                            }}
                          >
                            <BsBicycle size={25} />
                            <b>{orderSec?.total_delivery_order_count}</b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "3px",
                            }}
                          >
                            <BiShoppingBag size={25} />
                            <b>{orderSec?.total_pickup_order_count}</b>
                          </div>
                        </div>
                      </Col>
                      <Col sm={3} lg={3} style={{ textAlign: "center" }}>
                        <div>Gross revenue</div>
                        <div>
                          <b>€ {orderSec?.gross_amount}</b>
                        </div>
                      </Col>
                      <Col sm={3} lg={3} style={{ textAlign: "center" }}>
                        <div>Average order value</div>
                        <div>
                          <b>€ {orderSec?.average_order_amount?.toFixed(2)}</b>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
                <Col>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title">Menu Items</h4>
                    </CardHeader>
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th>Menu Item</th>
                            <th className="sort cursor-pointer"
                                  data-sort="zip_code"
                                  onClick={() => handleMenuSort()}>Total Number Sold</th>
                            <th>Total Sales</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData &&
                            currentTableData?.map((item, i) => (
                              <tr key={i}>
                                <td>{item?.product_name}</td>
                                <td>{item?.total_number_sold}</td>
                                <td>{item?.total_sales}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                            {menuData && <Pagination
                              className="pagination-bar"
                              currentPage={currentPage}
                              totalCount={menuData?.length}
                              pageSize={PageSize}
                              onPageChange={page => setCurrentPage(page)}
                            />}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title">Channels</h4>
                    </CardHeader>
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th>Channel</th>
                            <th>Order</th>
                            <th>Revenue</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Delivery</td>
                            <td>
                              {channel?.total_delivery_order_count} (
                              {(
                                (channel?.total_delivery_order_count /
                                  (channel?.total_delivery_order_count + channel?.total_pickup_order_count)) *
                                100
                              )?.toFixed(2)}
                              %)
                            </td>
                            <td>
                              €{" "}
                              {channel?.delevery_gross_amount.toLocaleString(
                                "en-US"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Pick-Up</td>
                            <td>
                              {channel?.total_pickup_order_count} (
                              {(
                                (channel?.total_pickup_order_count /
                                  (channel?.total_delivery_order_count + channel?.total_pickup_order_count)) *
                                100
                              )?.toFixed(2)}
                              %)
                            </td>
                            <td>
                              €{" "}
                              {channel?.pickup_gross_amount.toLocaleString(
                                "en-US"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title">Top users</h4>
                    </CardHeader>
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Username</th>
                            <th>Phone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userSec && userSec.map((item, i) => (
                            <tr key={i}>
                              <td>
                              <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm bg-light rounded">
                                              <img
                                                src={item?.user_status_details?.image}
                                                alt=""
                                                className="img-fluid d-flex"
                                              />
                                            </div>
                                          </div>
                              </td>
                              <td>{item?.user_status_details?.name}</td>
                              <td>+{item?.user_status_details?.phone}</td>
                            </tr>
                          ))}
                          
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title">Regional Data</h4>
                    </CardHeader>
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th>Post Code</th>
                            <th className="sort cursor-pointer"
                                  data-sort="zip_code"
                                  onClick={() => handleSort()}>Best Selling</th>
                            <th>Avg. Order Value</th>
                            <th>Revenue</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentZipTableData &&
                            currentZipTableData?.map((item, i) => (
                              <tr key={i}>
                                <td>{item?.zip_code}</td>
                                <td>{item?.number_of_order}</td>
                                <td>€ {item?.average_order_value?.toFixed(2)}</td>
                                <td>€ {item?.total_order_amount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      {zipData && <Pagination
                              className="pagination-bar"
                              currentPage={zipcurrentPage}
                              totalCount={zipData?.length}
                              pageSize={PageSize}
                              onPageChange={page => setZipCurrentPage(page)}
                            />}
                    </CardBody>
                  </Card>
                </Col>
                <Row>{/* <RecentOrders /> */}</Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
