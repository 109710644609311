import {
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_FAIL,
  ADD_NEW_PRODUCT_CATEGORY_SUCCESS,
  ADD_NEW_PRODUCT_CATEGORY_FAIL,
  GET_PRODUCT_CATEGORY_DETAILS_SUCCESS,
  GET_PRODUCT_CATEGORY_DETAILS_FAIL,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  RESET_PRODUCT_CATEGORY_FLAG,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAIL,
  STATUS_CHANGE_PRODUCT_CATEGORY_SUCCESS,
  STATUS_CHANGE_PRODUCT_CATEGORY_FAIL,
  MODIFY_PRODUCT_CATEGORY_FLAG,
  SAVE_ORDERING_PRODUCT_CATEGORY_SUCCESS,
  SAVE_ORDERING_PRODUCT_CATEGORY_FAIL,
  SAVE_ORDERING_PRODUCT_CATEGORY_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  productcategories: []
};

const Productcategories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        productcategories: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_PRODUCT_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productcategories: [...state.productcategories, action.payload.data],
        success: action.payload.message,
        isProductcategoryAdd: true,
        isProductcategoryAddFail: false,
      };

    case ADD_NEW_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isProductcategoryAdd: false,
        isProductcategoryAddFail: true,
      };

    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productcategories: state.productcategories.map(productcategory =>
          productcategory._id.toString() === action.payload.data._id.toString()
            ? { ...productcategory, ...action.payload.data }
            : productcategory
        ),
        success: action.payload.message,
        isProductcategoryUpdate: true,
        isProductcategoryUpdateFail: false
      };

    case UPDATE_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isProductcategoryUpdate: false,
        isProductcategoryUpdateFail: true,
      };

    case GET_PRODUCT_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_PRODUCT_CATEGORY_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_PRODUCT_CATEGORY_SUCCESS:
      return { 
        ...state,
        productcategories: state.productcategories.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productcategories: state.productcategories.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_PRODUCT_CATEGORY_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_PRODUCT_CATEGORY_FLAG:
      return {
        ...state,
        isProductcategoryAdd: false,
        isProductcategoryAddFail: false,
        isSuccess: false,
        isProductcategoryDetails: false,
        isProductcategoryUpdate: false,
        isProductcategoryUpdateFail: false,
        error: false
      };

       //Save Ordering
    case SAVE_ORDERING_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };
        
    case SAVE_ORDERING_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };


    //Save Ordering reset flag 
    case SAVE_ORDERING_PRODUCT_CATEGORY_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false
      };

    default:
      return state;
  }
};

export default Productcategories;
