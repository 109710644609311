import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Notification Redux States
import {
    GET_SOCKET_NOTIFICATION
} from "./actionTypes"

import {
    getSocketNotificationSuccess,
    getSocketNotificationFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
    getSocketNotificationList,
} from "../../helpers/fakebackend_helper"

function* fetchSocketNotification({ payload: data }) {
  try {
    const response = yield call(getSocketNotificationList, data)
    yield put(getSocketNotificationSuccess(response))
  } catch (error) {
    yield put(getSocketNotificationFail(error))
  }
}

export function* watchFetchSocketNotification() {
  yield takeEvery(GET_SOCKET_NOTIFICATION, fetchSocketNotification);
}

function* socketNotificationSaga() {
  yield all(
    [
      fork(watchFetchSocketNotification)
    ]
  );

}

export default socketNotificationSaga
