import {
    GET_ORGANIZATIONS,
    GET_ORGANIZATIONS_FAIL,
    GET_ORGANIZATIONS_SUCCESS,
    ADD_NEW_ORGANIZATION,
    ADD_NEW_ORGANIZATION_SUCCESS,
    ADD_NEW_ORGANIZATION_FAIL,
    GET_ORGANIZATION_DETAILS,
    GET_ORGANIZATION_DETAILS_SUCCESS,
    GET_ORGANIZATION_DETAILS_FAIL,
    UPDATE_ORGANIZATION,
    UPDATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_FAIL,
    DELETE_ORGANIZATION,
    DELETE_ORGANIZATION_SUCCESS,
    DELETE_ORGANIZATION_FAIL,
    MODIFY_ORGANIZATION_FLAG,
    STATUS_CHANGE_ORGANIZATION,
    STATUS_CHANGE_ORGANIZATION_SUCCESS,
    STATUS_CHANGE_ORGANIZATION_FAIL,
    RESET_ORGANIZATION_FLAG,
  } from "./actionTypes";

  export const getOrganizations = data => ({
    type: GET_ORGANIZATIONS,
    payload: data,
  });
  
  export const getOrganizationsSuccess = organisation => ({
    type: GET_ORGANIZATIONS_SUCCESS,
    payload: organisation,
  });
  
  export const getOrganizationsFail = error => ({
    type: GET_ORGANIZATIONS_FAIL,
    payload: error,
  });
  export const addNewOrganization = organisation => ({
    type: ADD_NEW_ORGANIZATION,
    payload: organisation,
  });
  
  export const addNewOrganizationSuccess = organisation => ({
    type: ADD_NEW_ORGANIZATION_SUCCESS,
    payload: organisation,
  });
  
  export const addNewOrganizationFail = error => ({
    type: ADD_NEW_ORGANIZATION_FAIL,
    payload: error,
  });

  
  export const updateOrganization = organisation => ({
    type: UPDATE_ORGANIZATION,
    payload: organisation,
  });
  
  export const updateOrganizationSuccess = organisation => ({
    type: UPDATE_ORGANIZATION_SUCCESS,
    payload: organisation,
  });
  
  export const updateOrganizationFail = error => ({
    type: UPDATE_ORGANIZATION_FAIL,
    payload: error,
  });
  
  export const getOrganizationDetails = organisation => ({
    type: GET_ORGANIZATION_DETAILS,
    payload: organisation,
  });
  
  export const getOrganizationDetailsSuccess = organisation => ({
    type: GET_ORGANIZATION_DETAILS_SUCCESS,
    payload: organisation,
  });
  
  export const getOrganizationDetailsFail = error => ({
    type: GET_ORGANIZATION_DETAILS_FAIL,
    payload: error,
  });
  
  export const delOrganization = data => ({
    type: DELETE_ORGANIZATION,
    payload: data,
  });
  
  export const deleteOrganizationSuccess = data => ({
    type: DELETE_ORGANIZATION_SUCCESS,
    payload: data,
  });
  
  export const deleteOrganizationFail = data => ({
    type: DELETE_ORGANIZATION_FAIL,
    payload: data,
  });

  export const statusChangeOrganization = organisation => ({
    type: STATUS_CHANGE_ORGANIZATION,
    payload: organisation,
  });
  
  export const statusChangeOrganizationSuccess = organisation => ({
    type: STATUS_CHANGE_ORGANIZATION_SUCCESS,
    payload: organisation,
  });
  
  export const statusChangeOrganizationFail = error => ({
    type: STATUS_CHANGE_ORGANIZATION_FAIL,
    payload: error,
  });
  export const modifyOrganizationFlag = () => {
    return {
      type: MODIFY_ORGANIZATION_FLAG,
    }
  }
  export const resetOrganizationFlag = () => {
    return {
      type: RESET_ORGANIZATION_FLAG,
    }
  }
 
