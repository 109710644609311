import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updateJobLocationType,
  resetJobLocationTypeFlag,
  getJobLocationDetails,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { call } from "redux-saga/effects";


const JobEdit = () => {
  document.title = "Edit Job Location Type | Dabbawala";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const {
    isJobLocationTypeUpdate,
    isJobLocationTypeUpdateFail,
    error,
    success,
    details,
  } = useSelector((state) => ({
    isJobLocationTypeUpdate: state.JobLocationTypes.isJobLocationTypeUpdate,
    isJobLocationTypeUpdateFail: state.JobLocationTypes.isJobLocationTypeUpdateFail,
    error: state.JobLocationTypes.error,
    success: state.JobLocationTypes.success,
    details: state.JobLocationTypes.details,
    jobLocationTypeList: state.JobLocationTypes.jobLocationTypeList,
  }));
  console.log(details);
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: details && details.type ? details.type : "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please Enter Job Location Type"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,

        type: values.type,
      };
      dispatch(updateJobLocationType(data));
    },
  });

  useEffect(() => {
    if (isJobLocationTypeUpdate || isJobLocationTypeUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetJobLocationTypeFlag());
        history.push('/joblocationtypes')
      }, 3000);
    }
  }, [dispatch, isJobLocationTypeUpdate, isJobLocationTypeUpdateFail]);

  useEffect(() => {
    dispatch(getJobLocationDetails({ id: id }));
  }, [dispatch, id]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Job Location Type" pageTitle="Job Location Types" pageUrl="/joblocationtypes" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Job Location Type" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                      <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Job Location Type *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Job Location Type"
                              name="type"
                              value={validation.values.type || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/joblocationtypes" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isJobLocationTypeUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isJobLocationTypeUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default JobEdit;
