import {
    GET_BLOGS,
    GET_BLOGS_FAIL,
    GET_BLOGS_SUCCESS,
    GET_BLOGCOMMENTS,
    GET_BLOGCOMMENTS_FAIL,
    GET_BLOGCOMMENTS_SUCCESS,
    ADD_NEW_BLOG,
    ADD_NEW_BLOG_SUCCESS,
    ADD_NEW_BLOG_FAIL,
    GET_BLOG_DETAILS,
    GET_BLOG_DETAILS_SUCCESS,
    GET_BLOG_DETAILS_FAIL,
    UPDATE_BLOG,
    UPDATE_BLOG_SUCCESS,
    UPDATE_BLOG_FAIL,
    DELETE_BLOG,
    DELETE_BLOG_SUCCESS,
    DELETE_BLOG_FAIL,
    DELETE_BLOGCOMMENT,
    DELETE_BLOGCOMMENT_SUCCESS,
    DELETE_BLOGCOMMENT_FAIL,
    MODIFY_BLOG_FLAG,
    MODIFY_BLOGCOMMENT_FLAG,
    STATUS_CHANGE_BLOG,
    STATUS_CHANGE_BLOG_SUCCESS,
    STATUS_CHANGE_BLOG_FAIL,
    STATUS_CHANGE_BLOGCOMMENT,
    STATUS_CHANGE_BLOGCOMMENT_SUCCESS,
    STATUS_CHANGE_BLOGCOMMENT_FAIL,
    RESET_BLOG_FLAG,
    RESET_BLOGCOMMENT_FLAG,
    SAVE_ORDERING_BLOG,
    SAVE_ORDERING_BLOG_SUCCESS,
    SAVE_ORDERING_BLOG_FAIL,
    SAVE_ORDERING_BLOG_RESET_FLAG,
  } from "./actionTypes";

  export const getBlogs = data => ({
    type: GET_BLOGS,
    payload: data,
  });
  
  export const getBlogsSuccess = blogs => ({
    type: GET_BLOGS_SUCCESS,
    payload: blogs,
  });
  
  export const getBlogsFail = error => ({
    type: GET_BLOGS_FAIL,
    payload: error,
  });
  export const getBlogcomments = data => ({
    type: GET_BLOGCOMMENTS,
    payload: data,
  });
  
  export const getBlogcommentsSuccess = blogs => ({
    type: GET_BLOGCOMMENTS_SUCCESS,
    payload: blogs,
  });
  
  export const getBlogcommentsFail = error => ({
    type: GET_BLOGCOMMENTS_FAIL,
    payload: error,
  });
  export const addNewBlog = blog => ({
    type: ADD_NEW_BLOG,
    payload: blog,
  });
  
  export const addNewBlogSuccess = blog => ({
    type: ADD_NEW_BLOG_SUCCESS,
    payload: blog,
  });
  
  export const addNewBlogFail = error => ({
    type: ADD_NEW_BLOG_FAIL,
    payload: error,
  });

  
  export const updateBlog = blog => ({
    type: UPDATE_BLOG,
    payload: blog,
  });
  
  export const updateBlogSuccess = blog => ({
    type: UPDATE_BLOG_SUCCESS,
    payload: blog,
  });
  
  export const updateBlogFail = error => ({
    type: UPDATE_BLOG_FAIL,
    payload: error,
  });
  
  export const getBlogDetails = blog => ({
    type: GET_BLOG_DETAILS,
    payload: blog,
  });
  
  export const getBlogDetailsSuccess = blog => ({
    type: GET_BLOG_DETAILS_SUCCESS,
    payload: blog,
  });
  
  export const getBlogDetailsFail = error => ({
    type: GET_BLOG_DETAILS_FAIL,
    payload: error,
  });
  
  export const delBlog = data => ({
    type: DELETE_BLOG,
    payload: data,
  });
  
  export const deleteBlogSuccess = data => ({
    type: DELETE_BLOG_SUCCESS,
    payload: data,
  });
  
  export const deleteBlogFail = data => ({
    type: DELETE_BLOG_FAIL,
    payload: data,
  });

  export const deleteBlogComment = data => ({
    type: DELETE_BLOGCOMMENT,
    payload: data,
  });
  
  export const deleteBlogCommentSuccess = data => ({
    type: DELETE_BLOGCOMMENT_SUCCESS,
    payload: data,
  });
  
  export const deleteBlogCommentFail = data => ({
    type: DELETE_BLOGCOMMENT_FAIL,
    payload: data,
  });

  export const statusChangeBlog = blog => ({
    type: STATUS_CHANGE_BLOG,
    payload: blog,
  });
  
  export const statusChangeBlogSuccess = blog => ({
    type: STATUS_CHANGE_BLOG_SUCCESS,
    payload: blog,
  });
  
  export const statusChangeBlogFail = error => ({
    type: STATUS_CHANGE_BLOG_FAIL,
    payload: error,
  });
  export const modifyBlogFlag = () => {
    return {
      type: MODIFY_BLOG_FLAG,
    }
  }
  export const modifyBlogCommentFlag = () => {
    return {
      type: MODIFY_BLOGCOMMENT_FLAG,
    }
  }
  export const resetBlogFlag = () => {
    return {
      type: RESET_BLOG_FLAG,
    }
  }
 
  export const resetBlogCommentFlag = () => {
    return {
      type: RESET_BLOGCOMMENT_FLAG,
    }
  }

  export const statusChangeBlogComment = data => ({
    type: STATUS_CHANGE_BLOGCOMMENT,
    payload: data,
  });
  
  export const statusChangeBlogCommentSuccess = blog => ({
    type: STATUS_CHANGE_BLOGCOMMENT_SUCCESS,
    payload: blog,
  });
  
  export const statusChangeBlogCommentFail = error => ({
    type: STATUS_CHANGE_BLOGCOMMENT_FAIL,
    payload: error,
  });

  //Save Ordaring
export const saveOrderingBlog = data => ({
  type: SAVE_ORDERING_BLOG,
  payload: data,
});

export const saveOrderingBlogSuccess = data => ({
  type: SAVE_ORDERING_BLOG_SUCCESS,
  payload: data,
});

export const saveOrderingBlogFail = error => ({
  type: SAVE_ORDERING_BLOG_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingBlogResetFlag = () => {
  return {
    type: SAVE_ORDERING_BLOG_RESET_FLAG,
  }
}
 
