/* SITES */


export const UPDATE_SITE = "UPDATE_SITE";
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS";
export const UPDATE_SITE_FAIL = "UPDATE_SITE_FAIL";

export const GET_SITE_DETAILS = "GET_SITE_DETAILS";
export const GET_SITE_DETAILS_SUCCESS = "GET_SITE_DETAILS_SUCCESS";
export const GET_SITE_DETAILS_FAIL = "GET_SITE_DETAILS_FAIL";

export const RESET_SITE_FLAG = "RESET_SITE_FLAG";