import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  FormFeedback,
  Input,
} from "reactstrap";
import Select from "react-select";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetAddCategory } from "../../store/actions";
import MsgToast from "../../Components/Common/MsgToast";
import { convertToBase64 } from "../../helpers/image";

function Add() {
  document.title = "Create Category | Dabbawala";
  const history = useHistory();

  const dispatch = useDispatch();
  const [selectedCatType, setselectedCatType] = useState("");
  const [image, setImage] = useState();
  const categoryType = [
    {
      options: [
        { label: "Videos", value: "videos" },
        { label: "Blogs", value: "blogs" },
        { label: "FAQS", value: "faqs" },
      ],
    },
  ];

  const catType = (e) => {
    setselectedCatType(e.target.value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      category_type: selectedCatType || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Category Name"),
    }),
    onSubmit: (values) => {
      const data = {
        name: values.name,
        category_type: values.category_type?.value,
        category_alias: convertToSlug(values.name),
        status: 1,
        description: values.description,
        image: image
      };
      const res = dispatch(addCategory(data));
      history.push(`/${data?.category_type?.slice(0,-1)}-categories`)
      //console.log(data.category_type);
    },
  });

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }

  const { status_code, message } = useSelector((state) => ({
    status_code: state.Categories.status_code,
    message: state.Categories.message,
    isData: state.Categories.isData,
  }));

  useEffect(() => {
    if (status_code) {
      setTimeout(() => {
        dispatch(resetAddCategory({}));
      }, 3000);
    }
  }, [dispatch,status_code]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImg1Change = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        console.log("Please upload a valid image");
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <>
      {" "}
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Category"
            pageTitle="Categories"
            pageUrl="/categories"
          />
          <Row>
            <Col lg={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <PreviewCardHeader title="Create Category" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Category Name
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="name"
                              className="form-control"
                              placeholder="Category name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category Type
                            </Label>
                            <Select
                              value={validation.values.category_type || ""}
                              onChange={(selectedCatType) => {
                                let event = {
                                  target: {
                                    name: "category_type",
                                    value: selectedCatType,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={categoryType}
                              name="type"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category_type &&
                                validation.errors.category_type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category_type &&
                            validation.errors.category_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Category Description
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              name="description"
                              className="form-control"
                              placeholder="Category description"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ""}
                              invalid={
                                validation.touched.description &&
                                validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Image
                            </Label>
                            <Input
                              type="file"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Place your image"
                              name="image"
                              onChange={onImg1Change}
                            />
                            <img
                              src={validation.values.image || ""}
                              alt=""
                              id="image1"
                              width="100px"
                            ></img>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  {/* <Link to="/categories" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link> */}
                  <button type="submit" className="btn btn-success w-sm">
                    Create
                  </button>
                </div>
              </form>
            </Col>
          </Row>
          {status_code === 200 ? (
            <MsgToast
              msg={message}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {status_code && status_code !== 200 ? (
            <MsgToast
              msg={message}
              color="danger"
              icon="ri-error-warning-line"
            />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
}

export default Add;
