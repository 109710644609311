import {
  GET_ALLERGIES_SUCCESS,
  GET_ALLERGIES_FAIL,
  ADD_NEW_ALLERGIE_SUCCESS,
  ADD_NEW_ALLERGIE_FAIL,
  GET_ALLERGIE_DETAILS_SUCCESS,
  GET_ALLERGIE_DETAILS_FAIL,
  UPDATE_ALLERGIE_SUCCESS,
  UPDATE_ALLERGIE_FAIL,
  RESET_ALLERGIE_FLAG,
  DELETE_ALLERGIE_SUCCESS,
  DELETE_ALLERGIE_FAIL,
  STATUS_CHANGE_ALLERGIE_SUCCESS,
  STATUS_CHANGE_ALLERGIE_FAIL,
  MODIFY_ALLERGIE_FLAG,
  SAVE_ORDERING_ALLERGIE_SUCCESS,
  SAVE_ORDERING_ALLERGIE_FAIL,
  SAVE_ORDERING_ALLERGIE_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  allergies: []
};

const Allergies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALLERGIES_SUCCESS:
      return {
        ...state,
        allergies: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_ALLERGIES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false
      };

    case ADD_NEW_ALLERGIE_SUCCESS:
      return {
        ...state,
        allergies: [...state.allergies, action.payload.data],
        success: action.payload.message,
        isAllergieAdd: true,
        isAllergieAddFail: false,
      };

    case ADD_NEW_ALLERGIE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isAllergieAdd: false,
        isAllergieAddFail: true,
      };

    case UPDATE_ALLERGIE_SUCCESS:
      return {
        ...state,
        allergies: state.allergies.map(allergie =>
          allergie._id.toString() === action.payload.data._id.toString()
            ? { ...allergie, ...action.payload.data }
            : allergie
        ),
        success: action.payload.message,
        isAllergieUpdate: true,
        isAllergieUpdateFail: false
      };

    case UPDATE_ALLERGIE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isAllergieUpdate: false,
        isAllergieUpdateFail: true,
      };

    case GET_ALLERGIE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
      };

    case GET_ALLERGIE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    //Delete
    case DELETE_ALLERGIE_SUCCESS:
      return { 
        ...state,
        allergies: state.allergies.filter(getData =>
          {
            //console.log(getData, action.payload);
            return getData._id !== action.payload.data._id
          }
          
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
  
    case DELETE_ALLERGIE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };
  
      
    //Status Change
    case STATUS_CHANGE_ALLERGIE_SUCCESS:
      return {
        ...state,
        allergies: state.allergies.map(getData =>
          getData._id.toString() === action.payload.data._id.toString()
            ? { ...getData, ...action.payload.data }
          : getData
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFaild: false,
      };
        
    case STATUS_CHANGE_ALLERGIE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFaild: true,
      };

    case MODIFY_ALLERGIE_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false
      };

    case RESET_ALLERGIE_FLAG:
      return {
        ...state,
        isAllergieAdd: false,
        isAllergieAddFail: false,
        isSuccess: false,
        isAllergieDetails: false,
        isAllergieUpdate: false,
        isAllergieUpdateFail: false,
        error: false
      };

      //Save Ordering
      case SAVE_ORDERING_ALLERGIE_SUCCESS:
        return {
          ...state,
          success: action.payload.message,
          saveOrderingSuccess: true,
          saveOrderingFaild: false,
        };
          
      case SAVE_ORDERING_ALLERGIE_FAIL:
        return {
          ...state,
          error: action.payload.message,
          saveOrderingSuccess: false,
          saveOrderingFaild: true,
        };
  
  
      //Save Ordering reset flag 
      case SAVE_ORDERING_ALLERGIE_RESET_FLAG:
        return {
          ...state,
          saveOrderingSuccess: false,
          saveOrderingFaild: false,
          error: false
        };

    default:
      return state;
  }
};

export default Allergies;
