/* SEOS */
export const GET_SEOS = "GET_SEOS";
export const GET_SEOS_SUCCESS = "GET_SEOS_SUCCESS";
export const GET_SEOS_FAIL = "GET_SEOS_FAIL";

export const ADD_NEW_SEO = "ADD_NEW_SEO";
export const ADD_NEW_SEO_SUCCESS = "ADD_NEW_SEO_SUCCESS";
export const ADD_NEW_SEO_FAIL = "ADD_NEW_SEO_FAIL";

export const UPDATE_SEO = "UPDATE_SEO";
export const UPDATE_SEO_SUCCESS = "UPDATE_SEO_SUCCESS";
export const UPDATE_SEO_FAIL = "UPDATE_SEO_FAIL";

export const GET_SEO_DETAILS = "GET_SEO_DETAILS";
export const GET_SEO_DETAILS_SUCCESS = "GET_SEO_DETAILS_SUCCESS";
export const GET_SEO_DETAILS_FAIL = "GET_SEO_DETAILS_FAIL";

export const RESET_SEO_FLAG = "RESET_SEO_FLAG";