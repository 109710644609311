import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateFaq,
  resetFaqFlag,
  getCategories,
  getFaqDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const FaqEdit = () => {
  document.title = "Edit Faq | CEO";
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [category_type, setCategoryType] = useState("faqs");
  const [status, setStatus] = useState();
  const [answer, setData] = useState();
  const { isFaqUpdate, isFaqUpdateFail, error, success, details, categories } =
    useSelector((state) => ({
      isFaqUpdate: state.Faqs.isFaqUpdate,
      isFaqUpdateFail: state.Faqs.isFaqUpdateFail,
      error: state.Faqs.error,
      success: state.Faqs.success,
      details: state.Faqs.details,
      categories: state.Categories.categories,
    }));

  //const [selectedCatType, setselectedCatType] = useState("");

  const [selectedCattypeOption, setSelectedCattypeOption] = useState({
    value: details && details?.category ? details.category?._id : "",
    label: details && details?.category ? details.category?.name : "",
  });

  useEffect(() => {
    setSelectedCattypeOption({value: details?.category ? details.category?._id : "", label: details && details?.category ? details.category?.name : ""})
  }, [details]);
  // function handleSelectType(value, label) {
  //   const setCategoryValue = { value: value, label: label };
  //   setselectedCatType(setCategoryValue);
  // }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: details && details?.question ? details.question : "",
      answer: details && details?.answer ? details.answer : "",
      category: selectedCattypeOption
    },

    validationSchema: Yup.object({
      question: Yup.string().required("Please Enter Your Question"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setIsCreating(true);
      const data = {
        id: id,
        question: values.question,
        answer: answer,
        category: selectedCattypeOption.value,
      };
      dispatch(updateFaq(data));
    },
  });
  // console.log(id);
  // console.log(validation.category);
  useEffect(() => {
    if (isFaqUpdate || isFaqUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetFaqFlag());
        history.push('/faqs')
      }, 3000);
    }
  }, [dispatch, isFaqUpdate, isFaqUpdateFail, validation]);
  useEffect(() => {
    dispatch(getFaqDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getCategories({ category_type, status }));
  }, [dispatch, category_type, status]);
console.log(selectedCattypeOption);
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Faq" pageTitle="Faqs" pageUrl="/faqs" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Faq" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={6} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Question *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Question"
                              name="question"
                              value={validation.values.question || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.question &&
                                validation.errors.question
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.question &&
                            validation.errors.question ? (
                              <FormFeedback type="invalid">
                                {validation.errors.question}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={6} md={6}>
                          <div>
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category Type
                            </Label>
                            <Select
                              value={selectedCattypeOption || ""}
                              onChange={(selectedCatType) => {
                                let event = {
                                  target: {
                                    value: selectedCatType,
                                  },
                                };
                                //console.log(event.target);
                                setSelectedCattypeOption(event.target.value);
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                categories.length &&
                                categories.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))
                              }
                              name="category"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category &&
                                validation.errors.category
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category &&
                            validation.errors.category ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Answer*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="answer"
                              data={validation.values.answer || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.answer &&
                            validation.errors.answer ? (
                              <FormFeedback type="invalid">
                                {validation.errors.answer}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/faqs" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isFaqUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isFaqUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default FaqEdit;
