import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  GET_PINS,
  ADD_NEW_PIN,
  GET_PIN_DETAILS,
  UPDATE_PIN,
  DELETE_PIN,
  STATUS_CHANGE_PIN,
  SAVE_ORDERING_PIN,
  
} from "./actionTypes"

import {
  getPinsSuccess,
  getPinsFail,
  addNewPinSuccess,
  addNewPinFail,
  getPinDetailsSuccess,
  getPinDetailsFail,
  updatePinSuccess,
  updatePinFail,
  deletePinSuccess,
  deletePinFail,
  statusChangePinSuccess,
  statusChangePinFail,

  saveOrderingPinSuccess,
  saveOrderingPinFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPinList,
  addNewPin,
  getPinDetails,
  updatePin,
  deletePin,
  statusChangePin,
  saveOrdering,
} from "../../helpers/fakebackend_helper"

function* fetchPins({ payload: pin }) {
  try {
    const response = yield call(getPinList, pin)
    yield put(getPinsSuccess(response))
  } catch (error) {
    yield put(getPinsFail(error))
  }
}

export function* watchFetchPins() {
  yield takeEvery(GET_PINS, fetchPins);
}

function* onAddNewPin({ payload: pin }) {
  try {
      const response = yield call(addNewPin, pin);
      if(response.status === 200){
        yield put(addNewPinSuccess(response));
      } else {
        yield put(addNewPinFail(response));
      }
  } catch (error) {
      yield put(addNewPinFail(error));
  }
}

export function* watchAddNewPin() {
  yield takeEvery(ADD_NEW_PIN, onAddNewPin);
}

function* onPinDetails({ payload: pin }) {
  try {
      const response = yield call(getPinDetails, pin);
      if(response.status === 200){
        yield put(getPinDetailsSuccess(response));
      } else {
        yield put(getPinDetailsFail(response));
      }
  } catch (error) {
      yield put(getPinDetailsFail(error));
  }
}

export function* watchPinDetails() {
  yield takeEvery(GET_PIN_DETAILS, onPinDetails);
}

function* onPinUpdate({ payload: pin }) {
  try {
      const response = yield call(updatePin, pin);
      if(response.status === 200){
        yield put(updatePinSuccess(response));
      } else {
        yield put(updatePinFail(response));
      }
  } catch (error) {
      yield put(updatePinFail(error));
  }
}

export function* watchUpdatePin() {
  yield takeEvery(UPDATE_PIN, onPinUpdate);
}


//Delete Data
function* onDeletePin({ payload: pin }) {
  try {
    const response = yield call(deletePin, pin);
    yield put(deletePinSuccess({ pin, ...response }));
  } catch (error) {
    yield put(deletePinFail(error));
  }
}

export function* watchDeletePin() {
  yield takeEvery(DELETE_PIN, onDeletePin);
}



//Status Change
function* onStatusChangPin({ payload: pin }) {
  try {
    const response = yield call(statusChangePin, pin);
    yield put(statusChangePinSuccess({ pin, ...response }));
  } catch (error) {
    yield put(statusChangePinFail(error));
  }
}

export function* watchStatusChangePin() {
  yield takeEvery(STATUS_CHANGE_PIN, onStatusChangPin);
}


//Save Ordering
function* onSaveOrderingPin({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingPinSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingPinFail(error));
  }
}

export function* watchSaveOrderingPin() {
  yield takeEvery(SAVE_ORDERING_PIN, onSaveOrderingPin);
}



function* pinSaga() {
  yield all(
    [
      fork(watchFetchPins),
      fork(watchAddNewPin),
      fork(watchPinDetails),
      fork(watchUpdatePin),
      fork(watchDeletePin),
      fork(watchStatusChangePin),
      fork(watchSaveOrderingPin)
    ]
  );

}

export default pinSaga
